import { createContext, useContext, useEffect, useMemo, useState } from "react"
import ClientesService from "../Services/ClientesService"
import { interfaceClient } from "../Interface/interfaceClient"
import { enqueueSnackbar } from "notistack"
import { DropResult } from "react-beautiful-dnd"
import { toUpperSnakeCase } from "../Helpers/formatters"
import { updateClient } from "../Components/Common/NewProfileClient/servicesCliente"
import ClientMirrorService from "../Services/ClientMirrorService"
import { interfaceSchedule } from "../Interface/intarfaceSchedule"
import { createTaskIfNotExists } from "../Components/Common/NewProfileClient/crudTask"

export type CategoriaKanban = 'lead' | 'r1' | 'othersMeeting' | 'waitingClient' | 'cliente';

const categoriaMapping: Record<CategoriaKanban, string> = {
  lead: "LEAD",
  r1: "R1",
  othersMeeting: "OTHERS_MEETING",
  waitingClient: "WAITING_CLIENT",
  cliente: "CLIENTE",
};

export interface IKanbanList {
  [key: string]: {
    name: string;
    data: interfaceClient[];
    amountMoney: number;
  };
}

export type SortConfig = {
  key: 'Preco' | 'UltimaAtividade' | 'NomeCompleto';
  direction: 'ascending' | 'descending';
};

export const useKanban = () => {
  const clientesService = new ClientesService();
  const clientMirrorService = new ClientMirrorService();

  const [selectedAdvisorId, setSelectedAdvisorId] = useState('');
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFiltered, setStatusFiltered] = useState<'OWNER' | 'MIRROR'>('OWNER');

  const [movedToClientDialog, setMovedToClientDialog] = useState<{ client?: interfaceClient, open: boolean }>({
    open: false
  })

  const [addActivityDialog, setAddActivityDialog] = useState<{ client?: interfaceClient, open: boolean }>({
    open: false
  })

  const [kanbanList, setKanbanList] = useState<IKanbanList>({
    lead: {
      name: 'Lead',
      data: [],
      amountMoney: 0
    },
    r1: {
      name: 'R1',
      data: [],
      amountMoney: 0
    },
    othersMeeting: {
      name: 'R2',
      data: [],
      amountMoney: 0
    },
    waitingClient: {
      name: 'Aguardando Habilitação',
      data: [],
      amountMoney: 0
    },
    cliente: {
      name: 'Conta Habilitada',
      data: [],
      amountMoney: 0
    }
  })
  const [filteredKanbanList, setFilteredKanbanList] = useState<IKanbanList>(kanbanList);

  useEffect(() => {
    if (!searchTerm) {
      setFilteredKanbanList(kanbanList);
    } else {
      const newFilteredKanbanList = Object.entries(kanbanList).reduce((acc, [categoria, { name, data, amountMoney }]) => {
        const filteredData = data.filter(client =>
          client.NomeCompleto?.toLowerCase().includes(searchTerm.toLowerCase())
        );

        acc[categoria] = {
          name,
          data: filteredData,
          amountMoney: filteredData.reduce((sum, { Preco = 0 }) => sum + (Preco ?? 0), 0),
        };

        return acc;
      }, {} as IKanbanList);

      setFilteredKanbanList(newFilteredKanbanList);
    }
  }, [searchTerm, kanbanList]);

  const loadClientesData = (categoria: CategoriaKanban, assessorId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const categoriaServico = categoriaMapping[categoria];

      if (categoria === 'cliente') {
        clientesService.ListarClientesDataCadastro(assessorId)
          .then((clients: interfaceClient[]) => {
            const filtered = clients.sort((a, b) => compareClients(a, b, 'UltimaAtividade', 'descending'));
            const totalAmount = filtered.reduce((sum, client) => sum + (client.Preco || 0), 0);

            setKanbanList(prevState => ({
              ...prevState,
              [categoria]: { ...prevState[categoria], data: filtered, amountMoney: totalAmount }
            }));
            resolve();
          })
          .catch(e => {
            enqueueSnackbar(`Ops... tivemos um problema ao carregar ${categoria}.`, { variant: 'error' });
            reject(e);
          });
      } else {
        clientesService.ListarClientes(assessorId, categoriaServico)
          .then((clients: interfaceClient[]) => {
            const filtered = clients.sort((a, b) => compareClients(a, b, 'UltimaAtividade', 'descending'));
            const totalAmount = filtered.reduce((sum, client) => sum + (client.Preco || 0), 0);

            setKanbanList(prevState => ({
              ...prevState,
              [categoria]: { ...prevState[categoria], data: filtered, amountMoney: totalAmount }
            }));
            resolve();
          })
          .catch(e => {
            enqueueSnackbar(`Ops... tivemos um problema ao carregar ${categoria}.`, { variant: 'error' });
            reject(e);
          });
      }
    });
  };

  useEffect(() => {
    if (!selectedAdvisorId) return;

    setLoading(true);
    let cancel = false;

    if (statusFiltered === 'OWNER') {
      const categorias: CategoriaKanban[] = ['lead', 'r1', 'othersMeeting', 'waitingClient', 'cliente'];
      const promises = categorias.map(categoria =>
        loadClientesData(categoria, selectedAdvisorId)
          .catch(e => {
            enqueueSnackbar(`Ops... tivemos um problema ao carregar ${categoria}.`, { variant: 'error' });
          })
      );

      Promise.all(promises).then(() => {
        if (!cancel) setLoading(false);
      });
    } else if (statusFiltered === 'MIRROR') {
      clientMirrorService.ListClientsMirror(selectedAdvisorId)
        .then((response) => {
          const espelhados = response.Espelhados;

          // Estrutura inicial com todas as categorias vazias
          const initialData: Record<CategoriaKanban, { name: string; data: interfaceClient[]; amountMoney: number; }> = {
            lead: { name: 'Lead', data: [], amountMoney: 0 },
            r1: { name: 'R1', data: [], amountMoney: 0 },
            othersMeeting: { name: 'Outras Reuniões', data: [], amountMoney: 0 },
            waitingClient: { name: 'Aguardando Cliente', data: [], amountMoney: 0 },
            cliente: { name: 'Cliente', data: [], amountMoney: 0 },
          };

          // Itera pelos clientes espelhados, converte o status para camelCase e aloca cada um na categoria correspondente
          espelhados.forEach(({ Cliente }) => {
            const statusCamelCase = toCamelCase(Cliente.Status); // Converte o status para camelCase
            if (initialData[statusCamelCase]) {
              initialData[statusCamelCase].data.push(Cliente);
              initialData[statusCamelCase].amountMoney += Cliente.Preco || 0;
            }
          });

          setKanbanList(initialData);
        })
        .catch(e => {
          enqueueSnackbar('Ops... tivemos um problema ao carregar os dados.', { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    }

    return () => {
      cancel = true;
    };
  }, [selectedAdvisorId, statusFiltered]);

  const toCamelCase = (status: string): CategoriaKanban => {
    const mapping: Record<string, CategoriaKanban> = {
      LEAD: "lead",
      R1: "r1",
      OTHERS_MEETING: "othersMeeting",
      WAITING_CLIENT: "waitingClient",
      CLIENTE: "cliente",
    };
    return mapping[status];
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!destination || (destination.droppableId === source.droppableId)) {
      return;
    }

    const startColumnData = kanbanList[source.droppableId].data;
    const finishColumnData = kanbanList[destination.droppableId].data;

    const clientMoved = startColumnData.find(client => client.Id === draggableId);
    if (!clientMoved || clientMoved === null || typeof clientMoved === 'undefined') {
      enqueueSnackbar('Ops... tivemos um problema.', { variant: 'error' });
      return;
    }

    if (!destination.droppableId || destination.droppableId === null || typeof destination.droppableId === 'undefined') {
      enqueueSnackbar('Ops... tivemos um problema.', { variant: 'error' });
      return;
    }

    const newStatus = toUpperSnakeCase(destination.droppableId);

    clientesService.UpdateStatus(clientMoved.Id, newStatus)
      .then(() => {
        clientesService.UpdateUltimaAtividade(clientMoved.Id)
          .then(() => {
            if (clientMoved.Id) {
              updateClientInKanbanList(clientMoved.Id, { UltimaAtividade: now, Status: newStatus as interfaceClient['Status'] });

              if (destination.droppableId === 'r1' || destination.droppableId === 'othersMeeting') {
                const title = destination.droppableId === 'r1' ? '1° reunião' : '2° reunião';
                const threeHoursAgo = (date: Date) => new Date(date.setHours(date.getHours() - 3));

                const date = threeHoursAgo(new Date());

                const newTask: interfaceSchedule = {
                  AssessorUsuarioId: selectedAdvisorId,
                  ClienteId: clientMoved.Id,
                  Title: title,
                  Descricao: `Movido para ${title}`,
                  Visto: false,
                  DataEvento: date,
                  DataTermino: date,
                  StatusTarefa: 'FINISHED',
                  AtividadeFinalizada: true,
                  AtividadeFinalizadaData: date,
                  AtividadeTodoDia: false
                };
                createTaskIfNotExists(newTask)
                  .catch(error => {
                    console.error('Erro ao criar tarefa:', error);
                  });
              }
            }
          })
          .catch(e => {
            enqueueSnackbar('Erro ao atualizar a última atividade.', { variant: 'error' });
          });
      })
      .catch(e => {
        enqueueSnackbar('Erro ao atualizar status.', { variant: 'error' });
      });

    const now = new Date().toISOString();
    // Atualização para refletir a mudança no estado
    const newStartColumnData = startColumnData.filter(client => client.Id !== draggableId);
    const updatedClientMoved = { ...clientMoved, UltimaAtividade: now, Status: newStatus };
    const newFinishColumnData = [updatedClientMoved, ...finishColumnData];

    setKanbanList(prevState => ({
      ...prevState,
      [source.droppableId]: {
        ...prevState[source.droppableId],
        data: newStartColumnData,
        amountMoney: newStartColumnData.reduce((sum, client) => sum + (client.Preco || 0), 0)
      },
      [destination.droppableId]: {
        ...prevState[destination.droppableId],
        data: newFinishColumnData,
        amountMoney: newFinishColumnData.reduce((sum, client) => sum + (client.Preco || 0), 0)
      }
    } as IKanbanList));

    if (destination.droppableId === 'cliente') {
      setMovedToClientDialog({ client: clientMoved, open: true })
    }
  };

  const updateClientInKanbanList = (clientId: string, updatedData: Partial<interfaceClient>): void => {
    setKanbanList(prevState => {
      const updatedList = { ...prevState };

      Object.keys(updatedList).forEach(key => {
        let amountMoney = 0;

        updatedList[key].data = updatedList[key].data.map(client => {
          if (client.Id === clientId) {
            const updatedClient = { ...client, ...updatedData };
            amountMoney += updatedClient.Preco || 0;

            return updatedClient;
          } else {
            amountMoney += client.Preco || 0;

            return client;
          }
        });

        updatedList[key].amountMoney = amountMoney;
      });

      return updatedList;
    });
  };

  const sortData = (data: interfaceClient[], config: SortConfig): interfaceClient[] => {
    return [...data].sort((a, b) => compareClients(a, b, config.key, config.direction));
  };

  const compareClients = (a: interfaceClient, b: interfaceClient, key: 'Preco' | 'UltimaAtividade' | 'NomeCompleto', direction: 'ascending' | 'descending') => {
    if (key === 'Preco') {
      return direction === 'ascending'
        ? (a.Preco || 0) - (b.Preco || 0)
        : (b.Preco || 0) - (a.Preco || 0);
    } else if (key === 'UltimaAtividade') {
      const dateA = a.UltimaAtividade ? new Date(a.UltimaAtividade).getTime() : Number.MAX_SAFE_INTEGER;
      const dateB = b.UltimaAtividade ? new Date(b.UltimaAtividade).getTime() : Number.MAX_SAFE_INTEGER;

      if (dateA === Number.MAX_SAFE_INTEGER && dateB === Number.MAX_SAFE_INTEGER) {
        return 0;
      } else if (dateA === Number.MAX_SAFE_INTEGER) {
        return 1;
      } else if (dateB === Number.MAX_SAFE_INTEGER) {
        return -1;
      } else {
        return direction === 'descending'
          ? dateA - dateB
          : dateB - dateA;
      }
    } else if (key === 'NomeCompleto') {
      if (!a.NomeCompleto || !b.NomeCompleto) {
        if (!a.NomeCompleto && !b.NomeCompleto) {
          return 0; // Ambos são considerados iguais se ambos faltarem
        }
        return !a.NomeCompleto ? -1 : 1; // Se a faltar, b vem primeiro; se b faltar, a vem primeiro
      }

      if (direction === 'ascending') {
        return b.NomeCompleto.localeCompare(a.NomeCompleto);
      } else {
        return a.NomeCompleto.localeCompare(b.NomeCompleto);
      }
    }
    return 0;
  };

  const handleWinClient = (updatedClient: interfaceClient): Promise<void> => {
    return new Promise((resolve, reject) => {
      updateClient({ ...updatedClient, Status: 'CLIENTE' })
        .then(() => {
          enqueueSnackbar('Parabéns!', { variant: 'success' });

          if (updatedClient.Id) {
            updateClientInKanbanList(updatedClient.Id, { ...updatedClient, Status: 'CLIENTE' });
          }

          setMovedToClientDialog({ open: false });
          resolve();
        })
        .catch((err: any) => {
          enqueueSnackbar('Erro ao atualizar cliente.', { variant: 'error' });
          reject(err);
        });
    });
  };


  const changeClientStatus = (client: interfaceClient, newStatus: CategoriaKanban) => {
    if (!client || client === null || typeof client === 'undefined') {
      return;
    }

    if (!newStatus || newStatus === null || typeof newStatus === 'undefined') {
      return;
    }

    const newStatusUpper = toUpperSnakeCase(newStatus) as interfaceClient['Status'];
    const oldStatus = client.Status.toLowerCase().replace(/_+/g, '') as CategoriaKanban;

    clientesService.UpdateStatus(client.Id, newStatusUpper)
      .then(() => {
        if (client.Id) {
          // Encontrar e remover o cliente da lista antiga
          const oldList = kanbanList[oldStatus].data.filter(c => c.Id !== client.Id);
          const oldListTotal = oldList.reduce((sum, client) => sum + (client.Preco || 0), 0);

          // Adicionar o cliente à nova lista
          const updatedClient = { ...client, Status: newStatusUpper };
          const newList = [updatedClient, ...kanbanList[newStatus].data];
          const newListTotal = newList.reduce((sum, client) => sum + (client.Preco || 0), 0);

          // Atualizar o estado do Kanban
          setKanbanList(prevState => ({
            ...prevState,
            [oldStatus]: { ...prevState[oldStatus], data: oldList, amountMoney: oldListTotal },
            [newStatus]: { ...prevState[newStatus], data: newList, amountMoney: newListTotal }
          }));
        }
      })
      .catch(e => {
        enqueueSnackbar('Erro ao atualizar o status do cliente.', { variant: 'error' });
      });
  };


  const totalAmount = useMemo(() => {
    return Object.values(kanbanList).reduce((total, category) => total + category.amountMoney, 0);
  }, [kanbanList]);

  const totalClientsCount = useMemo(() => {
    return Object.values(kanbanList).reduce((total, category) => total + category.data.length, 0);
  }, [kanbanList]);

  const updateSortConfig = (newConfig: SortConfig) => {
    setKanbanList(prevState => {
      const updatedState = { ...prevState };

      Object.keys(updatedState).forEach(key => {
        updatedState[key].data = sortData(updatedState[key].data, newConfig);
      });

      return updatedState;
    });
  };

  return {
    kanbanList: filteredKanbanList,
    loading,
    setKanbanList,
    onDragEnd,
    updateSortConfig,
    totalAmount,
    totalClientsCount,
    movedToClientDialog,
    setMovedToClientDialog,
    handleWinClient,
    changeClientStatus,
    selectedAdvisorId,
    setSelectedAdvisorId,
    addActivityDialog,
    setAddActivityDialog,
    searchTerm,
    setSearchTerm,
    statusFiltered,
    setStatusFiltered
  }
}
interface KanbanContextProps {
  kanbanList: IKanbanList;
  setKanbanList: React.Dispatch<React.SetStateAction<IKanbanList>>;
  loading: boolean;
  onDragEnd: (result: DropResult) => void;
  updateSortConfig: (newConfig: SortConfig) => void;
  totalAmount: number;
  totalClientsCount: number;
  movedToClientDialog: { client?: interfaceClient; open: boolean };
  setMovedToClientDialog: React.Dispatch<React.SetStateAction<{ client?: interfaceClient; open: boolean }>>;
  handleWinClient: (updatedClient: interfaceClient) => Promise<void>;
  changeClientStatus: (client: interfaceClient, newStatus: CategoriaKanban) => void;
  selectedAdvisorId: string;
  setSelectedAdvisorId: React.Dispatch<React.SetStateAction<string>>;
  addActivityDialog: { client?: interfaceClient; open: boolean };
  setAddActivityDialog: React.Dispatch<React.SetStateAction<{ client?: interfaceClient; open: boolean }>>;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  statusFiltered: 'OWNER' | 'MIRROR';
  setStatusFiltered: React.Dispatch<React.SetStateAction<'OWNER' | 'MIRROR'>>;
}

const KanbanContext = createContext<KanbanContextProps | undefined>(undefined);

export const KanbanProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const kanbanValues = useKanban();

  return (
    <KanbanContext.Provider value={kanbanValues}>
      {children}
    </KanbanContext.Provider>
  );
};

export const useKanbanContext = () => {
  const context = useContext(KanbanContext);
  if (!context) {
    throw new Error('useKanbanContext must be used within a KanbanProvider');
  }
  return context;
};
