import { useState, ChangeEvent, useEffect } from 'react';
import { Grid, TextField, MenuItem, Select, FormControl, InputLabel, Button, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useClientContext } from '../../ClientContext';
import { ORIGINS, interfaceClient } from '../../../../../Interface/interfaceClient';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { NumberFormatCustom } from '../../../../../Helpers/Components';

interface FieldConfig {
  key: keyof interfaceClient;
  label: string;
  type: 'text' | 'number' | 'select' | 'date' | 'money';
  options?: { value: string; label: string }[];
}

const fieldsConfig: FieldConfig[] = [
  { key: 'NomeCompleto', label: 'Nome Completo', type: 'text' },
  { key: 'CodigoCliente', label: 'Código do Cliente', type: 'text' },
  { key: 'Preco', label: 'PL', type: 'money' },
  { key: 'Email', label: 'Email', type: 'text' },
  { key: 'TelefoneContatoPrimario', label: 'Telefone', type: 'text' },
  { key: 'DataNascimento', label: 'Data de Nascimento', type: 'date' },
  {
    key: 'EstadoCivil',
    label: 'Estado Civil',
    type: 'select',
    options: [
      { value: 'Solteiro', label: 'Solteiro(a)' },
      { value: 'Casado', label: 'Casado(a)' },
      { value: 'Divorciado', label: 'Divorciado(a)' },
      { value: 'Viúvo', label: 'Viúvo(a)' },
    ],
  },
  {
    key: 'Sexo',
    label: 'Sexo',
    type: 'select',
    options: [
      { value: 'M', label: 'Masculino' },
      { value: 'F', label: 'Feminino' },
      { value: 'C', label: 'Outros' },
    ],
  },
  { key: 'Profissao', label: 'Profissão', type: 'text' },
  { key: 'Cidade', label: 'Cidade', type: 'text' },
  { key: 'Estado', label: 'Estado', type: 'text' },
  { key: 'Bairro', label: 'Bairro', type: 'text' },
  { key: 'Endereco', label: 'Endereço', type: 'text' },
  { key: 'Numero', label: 'Número', type: 'text' },
  { key: 'CidadeNatal', label: 'Cidade Natal', type: 'text' },
  {
    key: 'Temperatura',
    label: 'Temperatura',
    type: 'select',
    options: [
      { value: 'quente', label: 'Quente' },
      { value: 'morno', label: 'Morno' },
      { value: 'frio', label: 'Frio' },
    ],
  },
  {
    key: 'Origem',
    label: 'Origem',
    type: 'select',
    options: ORIGINS,
  },
  {
    key: 'PotencialDeInvestimento',
    label: 'Faixa de atendimento',
    type: 'select',
    options: [
      { value: 'Assessoria Digital (Até 300k)', label: 'Assessoria Digital (Até 300k)' },
      { value: 'Assessoria Exclusive (300k - 1M)', label: 'Assessoria Exclusive (300k - 1M)' },
      { value: 'Assessoria Premium (1M - 3M)', label: 'Assessoria Premium (1M - 3M)' },
      { value: 'Assessoria Private (A partir de 3M)', label: 'Assessoria Private (A partir de 3M)' },
    ],
  },
  {
    key: 'ReguaRelacionamento',
    label: 'Régua de Relacionamento',
    type: 'select',
    options: [
      { value: 'true', label: 'Sim' },
      { value: 'false', label: 'Não' }
    ],
  },
];

interface ClientEditFormProps {
  handleSave: (editedClient: interfaceClient) => Promise<void>;
  setEditMode: (mode: boolean) => void;
}


export const ClientEditForm = ({ handleSave, setEditMode }: ClientEditFormProps) => {
  const { client } = useClientContext();
  const [editedClient, setEditedClient] = useState(client);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEditedClient(client);
  }, [client]);

  const handleChange = (key: keyof interfaceClient, value: any) => {
    if (key === 'ReguaRelacionamento') {
      setEditedClient({ ...editedClient, [key]: value === 'true' } as interfaceClient);
    } else {
      setEditedClient({ ...editedClient, [key]: value } as interfaceClient);
    }
  };

  if (!editedClient) return <></>;

  return (
    <Grid container>
      <Grid container item spacing={2} xs={12} md={6}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {fieldsConfig.map((field) => (
            <Grid item xs={6} key={field.key}>
              <Box sx={{ minWidth: 120 }}>
                {field.type === 'select' ? (
                  <FormControl fullWidth size="small">
                    <InputLabel>{field.label}</InputLabel>
                    <Select
                      value={editedClient[field.key]}
                      label={field.label}
                      onChange={(e) => handleChange(field.key, e.target.value)}
                    >
                      {field.options?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : field.type === 'date' ? (
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                    <MobileDatePicker
                      label={field.label}
                      value={editedClient[field.key] ?? null}
                      onChange={(newValue: any) => handleChange(field.key, newValue)}
                      renderInput={(params: any) => <TextField
                        fullWidth {...params} size="small"
                      />}
                      inputFormat="dd/MM/yyyy"
                    />
                  </LocalizationProvider>
                ) : field.type === 'text' ? (
                  <TextField
                    fullWidth
                    size="small"
                    type={field.type}
                    label={field.label}
                    value={editedClient[field.key]}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(field.key, e.target.value)}
                  />
                ) : field.type === 'money' ? (
                  <TextField
                    fullWidth
                    size="small"
                    label={field.label}
                    value={editedClient[field.key]}
                    InputProps={{
                      inputComponent: NumberFormatCustom as any,
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleChange(field.key, Number(e.target.value))
                    }
                  />
                ) : field.type === 'number' ? (
                  <TextField
                    fullWidth
                    size="small"
                    type={field.type === 'number' ? 'number' : 'text'}
                    label={field.label}
                    value={editedClient[field.key]}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleChange(field.key, field.type === 'number' ? parseFloat(e.target.value) : e.target.value)
                    }
                  />
                ) : <></>}
              </Box>
            </Grid>
          ))}
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={6} paddingX={2}>
        <TextField
          fullWidth
          size="small"
          type='text'
          label='Bio'
          multiline
          rows={15}
          value={editedClient.Bio}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('Bio', e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Button
          size='small'
          onClick={() => setEditMode(false)}
        >
          Cancelar
        </Button>
        <LoadingButton
          onClick={async () => {
            setLoading(true);
            await handleSave(editedClient as interfaceClient);
            setLoading(false);
          }}
          loading={loading}
          disableElevation
          sx={{ ml: 4 }}
          variant="contained"
          size='small'
        >
          Salvar
        </LoadingButton>
      </Grid>
    </Grid>
  );
};
