import React from "react";
import { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import NumberFormat from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom = React.forwardRef<NumberFormat<any>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        prefix={'R$'}
        decimalSeparator={','}
        thousandSeparator={'.'}
        fixedDecimalScale={true}
        decimalScale={2}
        name={props.name}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
      />
    );
  },
);

const NumberPercentFormatCustom = React.forwardRef<NumberFormat<any>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        suffix={'%'}
        // fixedDecimalScale={true}
        name={props.name}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
      />
    );
  },
);

const ReceitasComponent = ({ receitas, setReceitas, despesa, setDespesas, custos }: any) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(receitas.PotencialSeguro)
  }, [receitas])

  const [custosDependentesTotal, setCustosDependentesTotal] = useState("")
  useEffect(() => {
    let custosDependentes = 0
    custos.map((value: any) => {
      custosDependentes += Number(value.GastoMensal)
    })
    setCustosDependentesTotal(custosDependentes.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))
  }, [custos])

  const onChangeInput = (field: string, event: any) => {
    if (field == "PerfilCliente") {
      receitas[field] = event.target.value
    } else {
      if (isNaN(Number(event.nativeEvent.data))) return;
      if (event.nativeEvent.inputType === "deleteContentBackward" || event.nativeEvent.inputType === "deleteWordBackward") {
        receitas[field] = ""
      } else {
        let newValue = parseFloat((event.target.value.replace("R$", "").replaceAll(".", ""))).toFixed(2)
        receitas[field] = newValue
      }
    }
    setReceitas({ ...receitas })
  }

  const onChangeInputPotencialSeguro = () => {
    setChecked(!checked)
    setReceitas({ ...receitas, PotencialSeguro: !checked })
  }

  // Despesas
  const onChangeInputDespesas = (field: string, value: any) => {
    // The first if is to resolve an error when the accessor fills a value and then clears the entire value
    // The value assign a undefined when this occurs
    if (value == undefined) {
      despesa[field] = ''
    } else if (field == "ContribuicaoDespesaPorcentagem") {
      // For fixed in 100% when the value is greater than 100%
      if (parseFloat(value) > 100) {
        despesa[field] = '100'
      } else {
        despesa[field] = value
      }
    } else if (field == "DespesaMensal") {
      if (isNaN(Number(value.nativeEvent.data))) return;
      if (value.nativeEvent.inputType === "deleteContentBackward" || value.nativeEvent.inputType === "deleteWordBackward") {
        despesa[field] = ""
      } else {
        let newValue = parseFloat((value.target.value.replace("R$", "").replaceAll(".", ""))).toFixed(2)
        despesa[field] = newValue
      }
    } else {
      despesa[field] = value
    }

    setDespesas({ ...despesa })
  }

  const onChangeTipoIRF = (value: string) => {
    despesa.TipoIRF = value
    setDespesas({ ...despesa })
  }

  // Despesas
  const [origemRendaObj, setOrigemRendaObj] = useState<any>({
    AtividadeProfissional: false, Heranca: false,
    Aposentadoria: false, Alugueis: false, Doacao: false, Outras: false
  })

  const changeOrigemRenda = (event: any) => {
    origemRendaObj[event.target.name] = !origemRendaObj[event.target.name]
    setOrigemRendaObj({ ...origemRendaObj })
    receitas[event.target.name] = !receitas[event.target.name]
    setReceitas({ ...receitas })
  }

  const handleCurrencyReceitas = (event: any) => {
    if (Number(event.target.value) > 0) {
      receitas[event.target.name] = Number(event.target.value)
    } else {
      receitas[event.target.name] = null
    }

    setReceitas({ ...receitas })
  }

  const handleCurrencyDespesas = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) > 0) {
      despesa[event.target.name] = Number(event.target.value)
    } else {
      despesa[event.target.name] = null
    }

    setDespesas({ ...despesa })
  }

  const handleContribuicao = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = Number(event.target.value)

    if (value > 100) value = 100;

    if (value > 0) {
      despesa.ContribuicaoDespesaPorcentagem = value
    } else {
      despesa.ContribuicaoDespesaPorcentagem = null
    }

    setDespesas({ ...despesa })
  }

  const handleTipoIRF = (event: React.ChangeEvent<HTMLInputElement>) => {
    despesa.TipoIRF = event.target.value
    setDespesas({ ...despesa })
  }

  return (
    <>
      <Container maxWidth="md" sx={{ padding: 3, backgroundColor: "white", borderRadius: 2, mb: 3 }}>
        <Box component="form"
          sx={{ px: 5, py: 1 }}
          noValidate
          autoComplete="off"
        >
          <Typography variant="h5" mb={2}>
            Renda
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    label="Renda bruta fixa mensal" required fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
                    value={receitas.RendaBrutaMensal || ""}
                    onChange={handleCurrencyReceitas}
                    name="RendaBrutaMensal"
                    InputProps={{
                      inputComponent: NumberFormatCustom as any,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Saldo acumulado do FGTS" fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
                    value={receitas.SaldoAcumuladoFGTS || ""}
                    onChange={handleCurrencyReceitas}
                    name="SaldoAcumuladoFGTS"
                    InputProps={{
                      inputComponent: NumberFormatCustom as any,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ display: { xs: "none", md: "block" } }} md={1} />
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12}>
                  Origem da renda
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    label={"Atividade profissional"}
                    name="AtividadeProfissional"
                    sx={{ color: '#5d5c74' }}
                    control={
                      <Checkbox checked={receitas.AtividadeProfissional} onChange={changeOrigemRenda} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    label={"Herança"}
                    name="Heranca"
                    sx={{ color: '#5d5c74' }}
                    control={
                      <Checkbox checked={receitas.Heranca} onChange={changeOrigemRenda} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    label={"Aposentadoria"}
                    name="Aposentadoria"
                    sx={{ color: '#5d5c74' }}
                    control={
                      <Checkbox checked={receitas.Aposentadoria} onChange={changeOrigemRenda} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    label={"Aluguéis"}
                    name="Alugueis"
                    sx={{ color: '#5d5c74' }}
                    control={
                      <Checkbox checked={receitas.Alugueis} onChange={changeOrigemRenda} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    label={"Doação"}
                    name="Doacao"
                    sx={{ color: '#5d5c74' }}
                    control={
                      <Checkbox checked={receitas.Doacao} onChange={changeOrigemRenda} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    label={"Outras"}
                    name="Outras"
                    sx={{ color: '#5d5c74' }}
                    control={
                      <Checkbox checked={receitas.Outras} onChange={changeOrigemRenda} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
                    }
                  />
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container maxWidth="md" sx={{ padding: 3, backgroundColor: "white", borderRadius: 2, mb: 3 }}>
        <Box component="form"
          sx={{ px: 5, py: 1 }}
          noValidate
          autoComplete="off"
        >
          <Typography variant="h5" mb={2}>
            Despesas
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Despesa" required fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
                value={despesa.DespesaMensal || ""}
                onChange={handleCurrencyDespesas}
                name="DespesaMensal"
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Contribuição nas despesas (porcentagem)" required fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
                value={despesa.ContribuicaoDespesaPorcentagem || ""}
                onChange={handleContribuicao}
                name="ContribuicaoDespesaPorcentagem"
                InputProps={{
                  inputComponent: NumberPercentFormatCustom as any,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ position: "relative" }}>
              <TextField label="Manutenção do padrão de vida (meses)" required fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal" typeof="number"
                onChange={(event) => onChangeInputDespesas('ManutencaoPadraoDeVida', event.target.value)}
                value={despesa.ManutencaoPadraoDeVida || ""}
              />
              <Tooltip sx={{ position: "absolute", right: "4px", top: "7px" }}
                title="Tempo necessário para se manter durante uma incapacidade temporária (Ex. Quebrou o pé)." arrow>
                <HelpOutlineIcon color="primary" fontSize="small" />
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={6} sx={{ position: "relative" }} >
              <TextField label="Restabelecimento de vida (anos)" required fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal" typeof="number"
                onChange={(event) => onChangeInputDespesas('RestabelecimentoVidaMeses', event.target.value)}
                value={despesa.RestabelecimentoVidaMeses || ""}
              />
              <Tooltip sx={{ position: "absolute", right: "4px", top: "7px" }}
                title="Tempo necessário para se reestruturar a partir de uma incapacidade permanente. Tempo padrão: 2 a 5 anos." arrow>
                <HelpOutlineIcon color="primary" fontSize="small" />
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </Container >

      <Container maxWidth="md" sx={{ padding: 3, backgroundColor: "white", borderRadius: 2, mb: 3 }}>
        <Box component="form"
          sx={{ px: 5, py: 1 }}
          noValidate
          autoComplete="off"
        >
          <Typography variant="h5" mb={4}>
            Tipo de imposto de renda
          </Typography>
          <Grid container spacing={2}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={despesa.TipoIRF}
              onChange={handleTipoIRF}
              sx={{ '&.Mui-checked': { color: '#512178' }, width: "100%", textAlign: "center" }}
            >
              <Grid item xs={12} md={4}>
                <FormControlLabel value="SIMPLIFICADA" control={<Radio size="small" sx={{ '&.Mui-checked': { color: '#512178' } }} />} label="Simplificada" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControlLabel value="COMPLETA" control={<Radio size="small" sx={{ '&.Mui-checked': { color: '#512178' } }} />} label="Completa" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControlLabel value="NAOPOSSUI" control={<Radio size="small" sx={{ '&.Mui-checked': { color: '#512178' } }} />} label="Não possui" />
              </Grid>
            </RadioGroup>
          </Grid>
        </Box>
      </Container >
      {/* <form>
                <div className="row container-steps">
                    <h2>Receitas</h2>
                    <div className="input-field col s12 m6 xl5 offset-xl1">
                        <CurrencyInput
                            id="RendaBrutaMensal"
                            name="RendaBrutaMensal"
                            value={receitas.RendaBrutaMensal}
                            groupSeparator="."
                            decimalSeparator=","
                            prefix="R$"
                            onChange={(event) => onChangeInput('RendaBrutaMensal', event)}
                            autoComplete="off"
                        />
                        <label htmlFor="RendaBrutaMensal">Renda bruta mensal *</label>
                    </div>
                    <div className="input-field col s12 m6 xl5">
                        <CurrencyInput
                            id="SaldoAcumuladoFGTS"
                            name="SaldoAcumuladoFGTS"
                            value={receitas.SaldoAcumuladoFGTS}
                            groupSeparator="."
                            decimalSeparator=","
                            prefix="R$"
                            onChange={(event) => onChangeInput('SaldoAcumuladoFGTS', event)}
                            autoComplete="off"
                        />
                        <label htmlFor="SaldoAcumuladoFGTS">Saldo acumulado do FGTS</label>
                    </div>
                </div>
            </form>
            <form>
                <div className="row container-steps">
                    <h2>Despesas </h2>
                    {!(custos.length > 0) ?
                        "" :
                        <h3>A despesa mensal deve ser informada contando com os custos mensais dos dependentes, ou seja, o mínimo de gastos mensais
                            precisa ser <span className="text-bold">{custosDependentesTotal}</span> (soma dos custos informados no Planejamento Familiar).
                        </h3>
                    }
                    <div className="input-field col s12 m6 xl5 offset-xl1">
                        <CurrencyInput
                            id="DespesaMensal"
                            name="DespesaMensal"
                            value={despesa.DespesaMensal}
                            groupSeparator="."
                            decimalSeparator=","
                            prefix="R$"
                            onChange={(event) => onChangeInputDespesas('DespesaMensal', event)}
                            autoComplete="off"
                        />
                        <label htmlFor="DespesaMensal">Despesas fixas (mensal) *</label>
                    </div>
                    <div className="input-field col s12 m6 xl5">
                        <CurrencyInput
                            id="ContribuicaoDespesaPorcentagem"
                            name="ContribuicaoDespesaPorcentagem"
                            value={despesa.ContribuicaoDespesaPorcentagem}
                            maxLength={3}
                            suffix="%"
                            onValueChange={(value) => onChangeInputDespesas('ContribuicaoDespesaPorcentagem', value!)}
                            autoComplete="off"
                        />
                        <label htmlFor="ContribuicaoDespesaPorcentagem">Contribuição nas despesa (porcentagem) *</label>
                    </div>

                    <div className="input-field col s12 m6 xl5 offset-xl1">
                        <input id="ManutencaoPadraoDeVida" autoComplete="off" type="number" name="ManutencaoPadraoDeVida"
                            placeholder="Exemplo: 3 anos" onChange={(event) => onChangeInputDespesas('ManutencaoPadraoDeVida', event.target.value)} value={despesa.ManutencaoPadraoDeVida} />
                        <label htmlFor="ManutencaoPadraoDeVida">Manutenção do padrão de vida (meses) *</label>
                        <div className="tooltip">
                            <ReactTooltip id='test' type="info" effect="solid">{ }</ReactTooltip>
                            <p data-tip='Tempo necessário para se manter durante uma incapacidade temporária (Ex. Quebrou o pé).' data-for='test'>
                                <BsQuestionCircle />
                            </p>
                        </div>
                    </div>
                    <div className="input-field col s12 m6 xl5">
                        <input id="RestabelecimentoVidaMeses" type="number" name="RestabelecimentoVidaMeses"
                            placeholder="Exemplo: 24 meses de liquidez" onChange={(event) => onChangeInputDespesas('RestabelecimentoVidaMeses', event.target.value)} value={despesa.RestabelecimentoVidaMeses} />
                        <label htmlFor="RestabelecimentoVidaMeses">Restabelecimento de vida (anos) *</label>
                        <div className="tooltip">
                            <ReactTooltip id='test' type="info" effect="solid">{ }</ReactTooltip>
                            <p data-tip='Tempo necessário para se reestruturar a partir de uma incapacidade permanente. Tempo padrão: 2 a 5 anos.' data-for='test'>
                                <BsQuestionCircle />
                            </p>
                        </div>
                    </div>
                </div>
            </form>
            <form className="container-steps">
                <div className="row container-steps">
                    <h2>Tipo de imposto de renda</h2>
                    <div className="col s12 space-bottom">
                        <label className="col s12 m4 center-align">
                            <input checked={despesa.TipoIRF === "SIMPLIFICADA"} type="radio"
                                onClick={() => { onChangeTipoIRF("SIMPLIFICADA") }
                                }
                            />
                            <span className="options-tipo-calc">Simplificada</span>
                        </label>
                        <label className="col s12 m4 center-align">
                            <input checked={despesa.TipoIRF === "COMPLETA"} type="radio"
                                onClick={() => { onChangeTipoIRF("COMPLETA") }
                                }
                            />
                            <span className="options-tipo-calc">Completa</span>
                        </label>
                        <label className="col s12 m4 center-align">
                            <input checked={despesa.TipoIRF === "NAOPOSSUI"} type="radio"
                                onClick={() => { onChangeTipoIRF("NAOPOSSUI") }
                                }
                            />
                            <span className="options-tipo-calc">Não possui</span>
                        </label>
                    </div>

                </div>

            </form> */}
    </>
  )
}

export default ReceitasComponent;