import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  FormControl, InputLabel, MenuItem, Select, FormHelperText
} from '@mui/material';
import { ICreateTask } from '../../../Interface/interfaceTask';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { IUser } from '../../../Interface/user';

interface Props {
  open: boolean;
  onClose: () => void;
  createTask: (task: ICreateTask) => void;
  assignUsers: IUser[];
}

export const CreateTaskForm: React.FC<Props> = ({ open, onClose, createTask, assignUsers }) => {
  const [task, setTask] = useState<ICreateTask>({
    titulo: '',
    descricao: '',
    grauUrgencia: 0,
    dataPrazo: null,
    atribuidoId: '',
  });

  const handleSubmit = async () => {
    if (handleValidation()) {
      createTask(task);
      setTask({
        titulo: '',
        descricao: '',
        grauUrgencia: null,
        dataPrazo: null,
        atribuidoId: '',
      });
      onClose();
    }
  };

  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});

  const handleValidation = () => {
    const errors: any = {};
    if (!task.titulo || task.titulo.length < 5) {
      errors['titulo'] = 'O título é obrigatório e deve conter no mínimo 5 caracteres';
    }
    if (!task.descricao || task.descricao.length < 10) {
      errors['descricao'] = 'A descrição é obrigatória e deve conter no mínimo 10 caracteres';
    }
    if (task.grauUrgencia && (task.grauUrgencia < 0 || task.grauUrgencia > 3)) {
      errors['grauUrgencia'] = 'Selecione o grau de prioridade';
    }
    if (!task.dataPrazo) {
      errors['dataPrazo'] = 'A data é obrigatória';
    }
    if (!task.atribuidoId) {
      errors['atribuidoId'] = 'O atribuído é obrigatório';
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  }


  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs' fullWidth>
      <DialogTitle variant='h3'>
        Criar nova tarefa
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} p={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Título"
              size='small'
              value={task.titulo}
              onChange={(e) => setTask({ ...task, titulo: e.target.value })}
              error={!!validationErrors['titulo']}
              helperText={validationErrors['titulo']}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Descrição"
              size='small'
              multiline
              rows={4}
              value={task.descricao}
              onChange={(e) => setTask({ ...task, descricao: e.target.value })}
              error={!!validationErrors['descricao']}
              helperText={validationErrors['descricao']}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth size='small' error={!!validationErrors['grauUrgencia']}>
              <InputLabel id="grau-urgencia-label">Grau de Prioridade</InputLabel>
              <Select
                labelId="grau-urgencia-label"
                id="grau-urgencia"
                value={task.grauUrgencia}
                onChange={(e) => {
                  const value = e.target.value;
                  if (typeof value === 'number') {
                    setTask({ ...task, grauUrgencia: value })
                  }
                }}
                label="Grau de Prioridade"
              >
                <MenuItem value={0}>Baixa</MenuItem>
                <MenuItem value={1}>Média</MenuItem>
                <MenuItem value={2}>Alta</MenuItem>
              </Select>
              <FormHelperText>{validationErrors['grauUrgencia']}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <MobileDatePicker
                label="Data Prazo"
                value={task.dataPrazo}
                onChange={(newValue: any) => {
                  setTask({ ...task, dataPrazo: newValue ? newValue : '' })
                }}
                renderInput={(params: any) => <TextField
                  fullWidth {...params} size="small"
                  error={!!validationErrors['dataPrazo']}
                  helperText={validationErrors['dataPrazo']}
                />}
                inputFormat="dd-MM-yyyy"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth size="small" error={!!validationErrors['atribuidoId']}>
              <InputLabel id="atribuido-id-label">Atribuído</InputLabel>
              <Select
                // error={!!validationErrors['titulo']}

                labelId="atribuido-id-label"
                id="atribuido-id"
                value={task.atribuidoId}
                onChange={(e) => setTask({ ...task, atribuidoId: e.target.value })}
                label="Atribuído"
              >
                {assignUsers.map((user) => (
                  <MenuItem key={user.Id} value={user.Id}>
                    {user.NomeCompleto}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{validationErrors['atribuidoId']}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disableElevation >
          Cancelar
        </Button>
        <Button
          sx={{ width: 130 }}
          onClick={() => handleSubmit()} color="primary" variant='contained'
          disableElevation
        >
          Criar tarefa
        </Button>
      </DialogActions>
    </Dialog>
  );
};