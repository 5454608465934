import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

const Outros = ({ nota, setNota, receitas, setReceitas }: any) => {
  const onChangePotencialSeguro = () => {
    receitas.PotencialSeguro = !receitas.PotencialSeguro
    setReceitas({ ...receitas })
  }

  return <>
    <Container maxWidth="md" sx={{ padding: 3, backgroundColor: "white", borderRadius: 2, mb: 0 }}>
      <Box component="form" autoComplete="off" sx={{ px: 5, py: 1 }}>
        <Box sx={{ mb: 1 }}>
          <Typography variant="h5">
            Outros
          </Typography>
        </Box>
        <TextField label="Notas" fullWidth id="outlined-basic"
          variant="outlined" size="small" margin="normal" multiline rows={4}
          onChange={(e) => setNota(e.target.value)} value={nota}
        />
        <Box
          sx={{
            border: "1px solid #c4c4c4", borderRadius: "4px", textAlign: "center", mt: 3, height: 60,
            display: "flex", justifyContent: "center"
          }}>
          <FormControlLabel
            label={"Enviar cliente para área de seguros?"}
            // fontSize
            sx={{ color: '#5d5c74', fontSize: 3 }}
            control={
              <Checkbox checked={receitas.PotencialSeguro} onChange={onChangePotencialSeguro} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
            }
          />
        </Box>
      </Box>
    </Container>
  </>
}

export default Outros