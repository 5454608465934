import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Card, CardContent, Typography, IconButton, Menu, MenuItem, Grid, Box, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import { INegocioConsorcioRequest } from '../../../Interface/interfaceNegocios';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccountOutlined';
import { formatEstrategiaConsorcio } from '../../../Helpers/formatters';
import { useChangeStatusWithReason } from '../../../Hooks/useChangeStatusWithReason';
import { LoseDialog } from '../KanbanCambio/LoseDialog';
import StorageHelper from '../../../Services/Helpers/StorageHelper';
import { IKanbanList } from '../../../Hooks/useKanbanNegociosConsorcio';

interface Props {
  negocio: INegocioConsorcioRequest;
  index: number;
  kanbanList?: IKanbanList;
  setKanbanList?: React.Dispatch<React.SetStateAction<IKanbanList>>;
}

export const KanbanItemConsorcio: React.FC<Props> = ({ negocio, index, kanbanList, setKanbanList }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { changeStatusWithReason } = useChangeStatusWithReason();
  const [openDialog, setOpenDialog] = useState(false);
  const storageHelper = new StorageHelper();

  const usuarioId = storageHelper.GetUsuarioId();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenProfile = () => {
    const url = `/app/perfil-cliente/${negocio.Negocio.ClienteId}`;
    window.open(url, '_blank', 'noopener,noreferrer');
    handleClose();
  };

  const calculateDaysSinceStart = (startDate: Date) => {
    const currentDate = new Date();
    const timeDiff = currentDate.getTime() - new Date(startDate).getTime();
    return Math.floor(timeDiff / (1000 * 3600 * 24));
  };

  const daysSinceStart = calculateDaysSinceStart(negocio.Negocio.DataInicio);

  const handleOpenLoseDialog = () => {
    setOpenDialog(true);
    handleClose();
  };

  const handleConfirmLose = async (reason: string) => {
    if (kanbanList && setKanbanList) {
      await changeStatusWithReason(
        negocio,
        'PERDEU',
        reason,
        kanbanList,
        setKanbanList,
        {
          lead: 'LEAD',
          r1: 'R1',
          outrasReunioes: 'OUTRAS_REUNIOES',
          aguardandoFechamento: 'AGUARDANDO_FECHAMENTO',
          cliente: 'CLIENTE',
        },
        'Consórcio'
      );
    }
  };

  return (
    <>
      <Draggable draggableId={negocio.Negocio.Id ?? ''} index={index}>
        {(provided) => (
          <Grid item xs={12} mx={2}>
            <Card
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              sx={{ marginBottom: 2, cursor: 'pointer', position: 'relative' }}
            >
              <CardContent>
                <Box display='flex' alignItems='center' mb={1}>
                  <PersonIcon style={{ marginRight: 8 }} />
                  <Typography variant='h6' fontSize={14} maxWidth={'70%'}>{negocio.NomeCliente ? negocio.NomeCliente : '-'}</Typography>
                </Box>

                <Box display='flex' alignItems='center' mb={0.5}>
                  <Typography variant='body1' color='green' fontWeight='bold'>{negocio.Consorcio.ValorFinanceiro ? negocio.Consorcio.ValorFinanceiro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}</Typography>
                </Box>

                <Box display='flex' alignItems='center' mb={0.5}>
                  <Typography variant='body1'>{negocio.Consorcio.Estrategia ? formatEstrategiaConsorcio(negocio.Consorcio.Estrategia) : ''}</Typography>
                </Box>

                <Tooltip title={negocio.NomeVendedor ? 'Vendedor responsável' : 'Assessor responsável'} disableInteractive>
                  <Box display='flex' alignItems='center' mb={0.5}>
                    <SupervisorAccountIcon style={{ marginRight: 8 }} />
                    <Typography variant='body1'>
                      {negocio.NomeVendedor ? negocio.NomeVendedor : negocio.NomeAssessor ?? ''}
                    </Typography>
                  </Box>
                </Tooltip>

                {daysSinceStart >= 0 && <Box display='flex' alignItems='center' mb={1} sx={{ position: 'absolute', bottom: 0 }}>
                  <Typography variant='body2' color='textSecondary'>
                    {`Dias desde o início: ${daysSinceStart} dias`}
                  </Typography>
                </Box>}

                <IconButton
                  sx={{ position: 'absolute', right: -3, top: -3 }}
                  disableRipple
                  onClick={handleClick}
                >
                  <MoreVertIcon fontSize='small' />
                </IconButton>

                <Menu
                  id='long-menu'
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleOpenProfile}>Abrir perfil</MenuItem>
                  {usuarioId === negocio.Negocio.VendedorId && <MenuItem onClick={handleOpenLoseDialog}>Perdeu</MenuItem>}
                </Menu>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Draggable>
      <LoseDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleConfirmLose}
      />
    </>
  );
};