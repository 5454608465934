
import { interfaceClient } from "../../../Interface/interfaceClient"
import { interfaceRevenue } from "../../../Interface/interfaceRevenue"
import ClientesService from "../../../Services/ClientesService"
import StorageHelper from "../../../Services/Helpers/StorageHelper"
import UserService from "../../../Services/UserService"

export const clientRequests = async (clientId: string) => {
  const storageHelper = new StorageHelper();
  const clientesService = new ClientesService()
  const userService = new UserService()

  if (clientId) {
    const assessorId = storageHelper.GetUsuarioId();

    return Promise.all([
      clientesService.BuscarCliente(clientId)
        .then((e: interfaceClient) => {
          return e
        })
        .catch(e => e),

      clientesService.ListaDependentes(clientId)
        .then((e: any) => {
          return e
        })
        .catch(e => e),

      clientesService.ListarProjetos(clientId)
        .then((e: any) => {
          return e
        })
        .catch(e => e),

      clientesService.ListaReceita(clientId)
        .then((e: any) => {
          return e
        })
        .catch(e => {
          if (e instanceof Error) {
            let newRevenue: interfaceRevenue = {
              Id: "", RendaBrutaMensal: null, SaldoAcumuladoFGTS: null, PensaoINSSOutros: null,
              PrevidenciaPrivada: null, OutrasRendasMensais: null, SeguroDeVida: null, AporteMensal: null,
              AporteMensalRecorrencia: null, PerfilCliente: null, PotencialSeguro: null, AtividadeProfissional: null, Heranca: null,
              Aposentadoria: null, Alugueis: null, Doacao: null, Outras: null, JaInveste: null,
              ValorQueInveste: null, InvesteBB: null, InvesteItau: null, InvesteSantander: null,
              InvesteBradesco: null, InvesteBTG: null, InvesteSafra: null, InvesteSicredi: null,
              InvesteXP: null, InvesteRico: null, InvesteClear: null, InvesteBancoDigital: null,
              InvesteOutros: null, InvesteOutrosDescricao: null, ClienteId: clientId
            }
            return newRevenue;
          }
        }),

      clientesService.GetDespesas(clientId)
        .then((e: any) => {
          return e
        }).catch(e => e),

      clientesService.ListarAtivos(clientId)
        .then((e: any) => {
          return e
        }).catch(e => e),

      clientesService.ListarBens(clientId)
        .then((e: any) => {
          return e
        }).catch(e => e),

      clientesService.GetTempoInvestimento(clientId)
        .then((e: any) => {
          return e
        }).catch(e => e),

      clientesService.ListarComentarios(clientId)
        .then((e: any) => {
          return e
        }).catch(e => e),

      userService.GetUserInfos(assessorId)
        .then((e: any) => {
          return e
        }).catch(e => e),
    ]).then((values: any) => {
      return values;
    }).catch((e) => {
    })
  }

}