import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Divider } from '@mui/material';
import { useNavigate } from "react-router-dom";

import { interfaceSchedule } from '../../../Interface/intarfaceSchedule';

export const DialogEvent = ({ open, setOpen, contentInfos }: any) => {
  let navigate = useNavigate();

  let content: interfaceSchedule = contentInfos

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xs">
        <DialogTitle variant="h3" sx={{ pt: 5, px: 5, pb: 2 }}>Tarefa</DialogTitle>
        <DialogContent sx={{ padding: 5 }}>
          <Divider sx={{ mb: 2, borderColor: "#b2b1c330" }} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h5" fontWeight="bold" mt={1}>Nome do cliente:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" fontWeight="normal" mt={1}>
                {content.ClienteNome}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" fontWeight="bold" mt={1}>Data:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" fontWeight="normal" mt={1}>
                {content.DataEvento}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" fontWeight="bold" mt={1}>Título da tarefa:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" fontWeight="normal" mt={1}>
                {content.Title}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" fontWeight="bold" mt={1}>Descrição:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" fontWeight="normal" mt={1}>
                {content.Descricao == "" ? "-" : content.Descricao}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" fontWeight="bold" mt={1}>Terefa concluída:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" fontWeight="normal" mt={1}>
                {content.AtividadeFinalizada ? "Sim" : "Não"}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            navigate(`/app/perfil-cliente/${content.ClienteId}`, { replace: true })
          }}>
            Abrir perfil do cliente
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}