import { ICreateTask, IUpdateTask, IListGroupsTask, interfaceTask, IListGroupsTaskUpdate } from "../Interface/interfaceTask";
import { IUser } from "../Interface/user";
import { AUTH_HEADER } from "./Helpers/Headers";

export default class TaskService {
  private readonly API_URL = process.env.REACT_APP_API_URL;

  private handleErrors(response: Response) {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  }

  public async GetTask(taskId: string): Promise<interfaceTask> {
    return await fetch(`${this.API_URL}/task/${taskId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(err);
      });
  }

  public async GetListCreatedTasks(): Promise<interfaceTask[]> {
    return await fetch(`${this.API_URL}/task/listar-criadas`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(err);
      });
  }

  public async GetListAssignedTasks(): Promise<interfaceTask[]> {
    return await fetch(`${this.API_URL}/task/listar-atribuidas`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(err);
      });
  }

  public async GetListAssignUsers(): Promise<IUser[]> {
    return await fetch(`${this.API_URL}/task/listar-usuarios-atribuir`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(err);
      });
  }

  public async PostTask(task: ICreateTask): Promise<interfaceTask> {
    return await fetch(`${this.API_URL}/task/inserir`, {
      method: 'POST',
      headers: AUTH_HEADER(),
      body: JSON.stringify(task)
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(err);
      });
  }

  public async UpdateTask(task: IUpdateTask): Promise<interfaceTask> {
    return await fetch(`${this.API_URL}/task/atualizar`, {
      method: 'PUT',
      headers: AUTH_HEADER(),
      body: JSON.stringify(task)
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(err);
      });
  }

  public async UpdateStatusTask(taskToUpdate: { taskId: string, status: number }): Promise<interfaceTask> {
    return await fetch(`${this.API_URL}/task/atualizar-status`, {
      method: 'PATCH',
      headers: AUTH_HEADER(),
      body: JSON.stringify(taskToUpdate)
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(err);
      });
  }

  public async PostTaskAddComment(taskToAddComment: { taskId: string, texto: string }): Promise<interfaceTask> {
    return await fetch(`${this.API_URL}/task/adicionar-comentario`, {
      method: 'POST',
      headers: AUTH_HEADER(),
      body: JSON.stringify(taskToAddComment)
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(err);
      });
  }

  public async DeleteTask(taskId: any): Promise<any> {
    return await fetch(`${this.API_URL}/task/deletar/${taskId}`, {
      method: 'DELETE',
      headers: AUTH_HEADER()
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(err);
      });
  }

  public async ListGroups(): Promise<IListGroupsTask[]> {
    return await fetch(`${this.API_URL}/task/listar-grupos`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(err);
      });
  }

  public async UpdateGroup(list: IListGroupsTaskUpdate): Promise<boolean> {
    return await fetch(`${this.API_URL}/task/update-grupo`, {
      method: 'POST',
      headers: AUTH_HEADER(),
      body: JSON.stringify(list)
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(err);
      });
  }
}