import Box from '@mui/material/Box'
import { SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StorageHelper from '../../../Services/Helpers/StorageHelper';
import { Container, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { TaskGroups } from '../../Common/UserManagement/TaskGroups';
import { UserApproval } from '../../Common/UserManagement/UserApproval';
import { ClientMirroring } from '../../Common/UserManagement/ClientsMirror';

export const UserManagementPage = () => {
  const storageHelper = new StorageHelper();

  let navigate = useNavigate()

  useEffect(() => {
    if (storageHelper.GetUsuarioRole() != 'Administrador') {
      navigate('/app/dashboard-pbi');
    }
  }, []);

  const [menuSelected, setMenuSelected] = useState('task-groups');

  const handleChangeMenu = (event: SyntheticEvent, newValue: string) => {
    setMenuSelected(newValue);
  };

  return (
    <Container maxWidth='xl'>
      <Box mt={2}>
        <TabContext value={menuSelected}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChangeMenu}
              centered
            >
              <Tab label='Grupos de Tarefas' value='task-groups' />
              <Tab label='Aprovação de Usuários' value='user-approval' />
              <Tab label='Espelhamento de Clientes' value='client-mirroring' />
            </TabList>
          </Box>
          <TabPanel value='task-groups'><TaskGroups /></TabPanel>
          <TabPanel value='user-approval'><UserApproval /></TabPanel>
          <TabPanel value='client-mirroring'><ClientMirroring /></TabPanel>
        </TabContext>
      </Box>
    </Container >)
}