import React, { useState } from 'react';
import { Button, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Typography, Box, Chip } from '@mui/material';
import Dashboard from "../../../Services/DashboardService";
import { interfaceDadosConsolidadosXP } from '../../../Interface/interfaceDadosConsolidadosXP';
import { enqueueSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';

export const UploadXPFiles = () => {
  const dashboardService = new Dashboard();
  const [dataPreview, setDataPreview] = useState<interfaceDadosConsolidadosXP[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [showAllErrors, setShowAllErrors] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const text = e.target?.result;
          const data = JSON.parse(text as string) as interfaceDadosConsolidadosXP[];
          setDataPreview(data);
        } catch (error) {
          console.error('Falha ao carregar o arquivo', error);
          alert('Erro ao processar o arquivo JSON.');
        }
      };
      reader.readAsText(files[0]);
    }
  };

  const handleUpload = async () => {
    setLoadingUpload(true);

    try {
      await dashboardService.AddXpData(dataPreview);
      setLoadingUpload(false);
      setErrors([]);
      enqueueSnackbar('Dados enviados com sucesso!', { variant: 'success' });
    } catch (error: any) {
      console.error('Erro ao enviar dados:', error);
      setLoadingUpload(false);
      try {
        const parsedError = JSON.parse(error.message);
        setErrors(Array.isArray(parsedError) ? parsedError : [parsedError]);
      } catch {
        setErrors([error.message]);
      }
    }
  };

  return (
    <>
      <div style={{ padding: 20 }}>
        <Typography variant='h4' sx={{ mb: 2 }}>
          Upload de Arquivos
        </Typography>

        {errors.length > 0 && (
          <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
            {errors.slice(0, showAllErrors ? errors.length : 5).map((error, index) => (
              <Chip size='small' key={index} label={error} color="error" />
            ))}
            {errors.length > 5 && !showAllErrors && (
              <Button size="small" onClick={() => setShowAllErrors(true)}>Mais...</Button>
            )}
          </Box>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
          <TextField
            type="file"
            onChange={handleFileChange}
            inputProps={{ accept: 'application/json' }}
            variant="outlined"
          />
          <LoadingButton
            loading={loadingUpload}
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={!dataPreview.length}
          >
            Confirmar e Enviar
          </LoadingButton>
        </Box>

        <TableContainer component={Paper} style={{ marginTop: 20 }}>
          <Table aria-label="Dados Consolidados XP">
            <TableHead>
              <TableRow>
                <TableCell>Squad</TableCell>
                <TableCell align="right">Código Assessor</TableCell>
                <TableCell align="right">Net</TableCell>
                <TableCell align="right">Receita RF</TableCell>
                <TableCell align="right">Receita RV</TableCell>
                <TableCell align="right">Fee Fixo</TableCell>
                <TableCell align="right">Câmbios</TableCell>
                <TableCell align="right">Seguros</TableCell>
                <TableCell align="right">Receita Total</TableCell>
                <TableCell align="right">ROA</TableCell>
                <TableCell align="right">Captação</TableCell>
                <TableCell align="right">NPS Envios</TableCell>
                <TableCell align="right">NPS Respostas</TableCell>
                <TableCell align="right">Taxa de Resposta</TableCell>
                <TableCell align="right">Detratores</TableCell>
                <TableCell align="right">Tempo Zenvia</TableCell>
                <TableCell align="right">Ativações 300K</TableCell>
                <TableCell align="right">Qntd Ligações</TableCell>
                <TableCell align="right">Clientes</TableCell>
                <TableCell align="right">Meta Receita</TableCell>
                <TableCell align="right">Meta Captação</TableCell>
                <TableCell align="right">Ativações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataPreview.length > 0 ? (
                dataPreview.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">{row.Squad}</TableCell>
                    <TableCell align="right">{row.CodigoAssessor}</TableCell>
                    <TableCell align="right">{row.Net}</TableCell>
                    <TableCell align="right">{row.ReceitaRF}</TableCell>
                    <TableCell align="right">{row.ReceitaRV}</TableCell>
                    <TableCell align="right">{row.FeeFixo}</TableCell>
                    <TableCell align="right">{row.Cambios}</TableCell>
                    <TableCell align="right">{row.Seguros}</TableCell>
                    <TableCell align="right">{row.ReceitaTotal}</TableCell>
                    <TableCell align="right">{row.ROA}</TableCell>
                    <TableCell align="right">{row.Captacao}</TableCell>
                    <TableCell align="right">{row.NPSEnvios}</TableCell>
                    <TableCell align="right">{row.NPSRepostas}</TableCell>
                    <TableCell align="right">{row.TaxaDeResposta}</TableCell>
                    <TableCell align="right">{row.Detratores}</TableCell>
                    <TableCell align="right">{row.TempoZenvia}</TableCell>
                    <TableCell align="right">{row.AtivacoesTrezentosK}</TableCell>
                    <TableCell align="right">{row.QntdLigacoes}</TableCell>
                    <TableCell align="right">{row.Clientes}</TableCell>
                    <TableCell align="right">{row.MetaReceita}</TableCell>
                    <TableCell align="right">{row.MetaCaptacao}</TableCell>
                    <TableCell align="right">{row.Ativacoes}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={20} align="center" sx={{ fontSize: 15 }}>Nenhuma informação carregada</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
