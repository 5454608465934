import { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import ClientesService from "../../../../Services/ClientesService"
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Typography from '@mui/material/Typography';

const AdicionarClienteComponent = ({ cliente, setCliente, getInfosClienteFirstTime }: any) => {
  const clientesService = new ClientesService()

  const onChangeInput = (field: string, value: string) => {
    cliente[field] = value
    setCliente({ ...cliente })
  }

  const handleDate = (newValue: Date | null) => {
    cliente.DataNascimento = newValue
    setCliente({ ...cliente })
  }

  const [contatoTipo, setContatoTipo] = useState("movel")
  const [maskContato, setMaskContato] = useState("")
  const onChangeContatoTipo = (value: string) => {
    setContatoTipo(value)
  }

  useEffect(() => {
    if (contatoTipo === "fixo") {
      setMaskContato("(99) 9999-9999")
      setContatoTipo("fixo")
    } else if (contatoTipo === "movel") {
      setMaskContato("(99) 99999-9999")
      setContatoTipo("movel")
    }
  }, [contatoTipo])

  useEffect(() => {
    if (!getInfosClienteFirstTime) return;

    if (cliente.TelefoneContatoPrimario == null) return;

    if (cliente.TelefoneContatoPrimario.length === 14) {
      setMaskContato("(99) 9999-9999")
      setContatoTipo("fixo")
    } else if (cliente.TelefoneContatoPrimario.length === 15) {
      setMaskContato("(99) 99999-9999")
      setContatoTipo("movel")
    }
  }, [getInfosClienteFirstTime])

  const [endereco, setEndereco] = useState<any>({ localidade: "", uf: "", logradouro: "" })
  const [searchCEP, setSearchCEP] = useState(false)

  useEffect(() => {
    if (searchCEP) {
      cliente.Cidade = "..."
      cliente.Estado = "..."
      cliente.Endereco = "..."
      cliente.Bairro = "..."
      setCliente({ ...cliente })

      clientesService.GetInfosCEP(cliente['CEP'])
        .then(e => {
          setEndereco(e)
          setSearchCEP(false)
        })
        .catch(e => {
        })
    }
    // https://viacep.com.br/ws/96077260/json/
  }, [searchCEP])

  useEffect(() => {
    if (endereco.localidade != undefined) {
      cliente.Cidade = endereco.localidade
      cliente.Estado = endereco.uf
      cliente.Endereco = endereco.logradouro
      cliente.Bairro = endereco.bairro
      setCliente({ ...cliente })
    } else {
      cliente.Cidade = ""
      cliente.Estado = ""
      cliente.Endereco = ""
      cliente.Bairro = ""
      setCliente({ ...cliente })
    }
  }, [endereco])

  useEffect(() => {
    // Checking CEP is valid
    const checkCEP = /[0-9]{5}-[0-9]{3}$/
    const CEPValid = checkCEP.test(cliente.CEP)
    if (CEPValid) {
      setSearchCEP(true)
    } else {
      cliente.Cidade = ""
      cliente.Estado = ""
      cliente.Endereco = ""
      cliente.Bairro = ""
    }
  }, [cliente.CEP])

  return (
    <>
      <Container maxWidth="md" sx={{ padding: 3, backgroundColor: "white", borderRadius: 2, mb: 3 }}>
        <Box component="form"
          sx={{ px: 5, py: 1 }}
          noValidate
          autoComplete="off"
        >
          <Typography variant="h5" mb={2}>
            Dados Pessoais
          </Typography>
          <Grid container spacing={2}>
            <Box sx={{ mb: 1 }} />
            <Grid item xs={12} md={6}>
              <TextField label="Nome Completo *" fullWidth variant="outlined" size="small" margin="normal"
                onChange={(event) => onChangeInput('NomeCompleto', event.target.value)} value={cliente.NomeCompleto || ""}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth size="small" margin="normal" >
                <InputLabel id="tipo-contato">Tipo de Contato</InputLabel>
                <Select
                  labelId="tipo-contato"
                  label="Tipo de Contato"
                  onChange={(event) => onChangeContatoTipo(event.target.value)}
                  value={contatoTipo}
                >
                  <MenuItem value={null || ""} disabled>Selecione...</MenuItem>
                  <MenuItem value={"fixo"}>Tel. Fixo</MenuItem>
                  <MenuItem value={"movel"}>Celular</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <InputMask
                autoComplete="disabled"
                mask={maskContato}
                id="TelefoneContatoPrimario"
                type="text"
                name="TelefoneContatoPrimario"
                onChange={(event) => onChangeInput("TelefoneContatoPrimario", event.target.value)} value={cliente.TelefoneContatoPrimario || ""}
              >
                {() => <TextField label="Número" fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal" value={cliente.TelefoneContatoPrimario || ""} />}

              </InputMask>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField label="Profissão *" fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
                onChange={(event) => onChangeInput('Profissao', event.target.value)} value={cliente.Profissao || ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Data de nascimento"
                  format='dd/MM/yyyy'

                  onChange={handleDate}
                  value={cliente.DataNascimento || ""}
                  maxDate={new Date()}
                  slotProps={{
                    textField: {
                      size: 'small',
                      fullWidth: true,
                      margin: 'normal'
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small" margin="normal" >
                <InputLabel id="estado-civil">Estado Civil</InputLabel>
                <Select
                  labelId="estado-civil"
                  onChange={(event) => onChangeInput('EstadoCivil', event.target.value)}
                  value={cliente.EstadoCivil || ""}
                  label="Estado Civil"
                >
                  <MenuItem value={""} disabled>Selecione...</MenuItem>
                  <MenuItem value={"Solteiro"}>Solteiro(a)</MenuItem>
                  <MenuItem value={"Casado"}>Casado(a)</MenuItem>
                  <MenuItem value={"Separado"}>Separado(a)</MenuItem>
                  <MenuItem value={"Divorciado"}>Divorciado(a)</MenuItem>
                  <MenuItem value={"Viúvo"}>Viúvo(a)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small" margin="normal" >
                <InputLabel id="sexo">Como você se identifica? *</InputLabel>
                <Select
                  labelId="sexo"
                  onChange={(event) => onChangeInput('Sexo', event.target.value)}
                  value={cliente.Sexo || " "}
                  label="Como você se identifica? *"
                >
                  {/* <MenuItem disabled value={" "}>Selecione</MenuItem> */}
                  <MenuItem value={" "} disabled>Selecione...</MenuItem>
                  <MenuItem value={"M"}>Masculino</MenuItem>
                  <MenuItem value={"F"}>Feminino</MenuItem>
                  <MenuItem value={"C"}>Outros</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}

export default AdicionarClienteComponent;