import Container from '@mui/material/Container'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import StorageHelper from '../../../../Services/Helpers/StorageHelper';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ClientesService from '../../../../Services/ClientesService';
import Dashboard from '../../../../Services/DashboardService'
import Squads from '../../../../Services/SquadsService'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { SquadsContext } from './Context/SquadsConatext';
import { SquadsComponent } from './Components/SquadsComponent';
import { UsersWithoutSquad } from './Components/UsersWithoutSquad';
import { DialogCreateSquad } from './Components/Dialogs/DialogCreateSquad';
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import AddIcon from '@mui/icons-material/Add';
import { DialogChangeSquad } from './Components/Dialogs/DialogChangeSquad';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export const ControllerSquads = () => {
  const storageHelper = new StorageHelper();
  let navigate = useNavigate();

  useEffect(() => {
    if (storageHelper.GetUsuarioRole() != "Administrador") {
      navigate("/app/dashboard-pbi", { replace: true });
    }
  }, [])

  // Backdrop
  const [openLoadingPage, setOpenLoadingPage] = useState(false)

  // Snackbars
  const [snackbarText, setSnackbarText] = useState("")
  const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false)
  const [openSnackbarFailed, setOpenSnackbarFailed] = useState(false)
  const handleCloseAllBackdrops = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbarSuccess(false)
    setOpenSnackbarFailed(false)
  };

  function openAlertMessageSuccess(message = "") {
    if (message != "") {
      setSnackbarText(message)
      setOpenSnackbarSuccess(true)
    }
  }

  function openAlertMessageFailed(message = "") {
    if (message != "") {
      setSnackbarText(message)
      setOpenSnackbarFailed(true)
    }
  }
  // End Snackbars

  const clientesService = new ClientesService()
  const listarAssessoresService = new Dashboard()
  const squadsService = new Squads()

  const [assessores, setAssessores] = useState<any>([])
  const [assessoresAdminView, setAssessoresAdminView] = useState<any>([])
  const [squads, setSquads] = useState<any>([])
  const [squadsWithAssessores, setSquadsWithAssessores] = useState<any>([])
  const [usersWithoutSquad, setUsersWithoutSquad] = useState<any>([])

  useEffect(() => {
    setOpenLoadingPage(true)

    Promise.all([
      squadsService.ListarParticipantes()
        .then((value) => {
          setAssessores(value)
          return value
        })
        .catch(),

      listarAssessoresService.GetAllUsers()
        .then((value) => {
          setAssessoresAdminView(value)
          return value
        })
        .catch(),

      squadsService.ListarSquads()
        .then((value) => {
          setSquads(value)
          return value
        })
        .catch()
    ])
      .then((value) => {
        // Organizing squads with advisors
        let newSquadWithAssessores: any = []
        value[2].map((squad: any, index: number) => {
          let filteredAssessores = value[0].filter((assessor: any) => assessor.SquadId == squad.Id)
          squad.Assessores = filteredAssessores
          newSquadWithAssessores.push(squad)
        })
        setSquadsWithAssessores(newSquadWithAssessores)

        // Getting the users without squad
        let usersWithout: any = []

        value[1].map((user: any, index: number) => {
          let filteredUsers = value[0].filter((assessor: any) => assessor.UsuarioId == user.Id)
          if (filteredUsers.length == 0) {
            usersWithout.push(user)
          }
        })
        setUsersWithoutSquad(usersWithout)
      })
      .catch()
      .finally(() => setOpenLoadingPage(false))
  }, [])

  // Dialogs
  const [openDialogCreateSquad, setOpenDialogCreateSquad] = useState(false)
  const [openDialogChangeSquad, setOpenDialogChangeSquad] = useState(false)

  const [squadToViewOnDialog, setSquadToViewOnDialog] = useState<any>({})

  const ComponentContainer = (props: any) => {
    return (
      <Grid container spacing={1}
        sx={{ backgroundColor: "#ffffff", borderRadius: 1, px: 3, pt: 1, pb: 2 }}
      >
        {props.children}
      </Grid>
    )
  }

  return (
    <>
      <SquadsContext.Provider value={{
        assessores: assessores,
        setAssessores: setAssessores,
        assessoresAdminView: assessoresAdminView,
        squads: squads,
        setSquads: setSquads,

        squadsWithAssessores: squadsWithAssessores,
        setSquadsWithAssessores: setSquadsWithAssessores,

        // Dialog change squads
        setOpenDialogChangeSquad: setOpenDialogChangeSquad,
        squadToViewOnDialog: squadToViewOnDialog,
        setSquadToViewOnDialog: setSquadToViewOnDialog,

        usersWithoutSquad: usersWithoutSquad,
        setUsersWithoutSquad: setUsersWithoutSquad,

        openAlertMessageSuccess: openAlertMessageSuccess,
        openAlertMessageFailed: openAlertMessageFailed
      }}>
        <Container maxWidth="md">
          <ComponentContainer>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h3" display="inline">
                  Squads
                </Typography>
                <Button sx={{ ml: 2 }} onClick={() => setOpenDialogCreateSquad(true)} disableElevation startIcon={<AddIcon />} size="small" variant="contained" color="secondary">
                  Criar novo Squad
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <SquadsComponent />
            </Grid>
          </ComponentContainer>
          <Box sx={{ my: 5 }} />
          <ComponentContainer>
            <Grid item xs={12}>
              <Typography variant="h4">
                Usuários sem squad
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <UsersWithoutSquad />
            </Grid>
          </ComponentContainer>
        </Container>

        <Snackbar open={openSnackbarFailed} autoHideDuration={6000} onClose={handleCloseAllBackdrops}>
          <Alert onClose={handleCloseAllBackdrops} severity="error" sx={{ fontSize: 14, boxShadow: 3 }}>
            {snackbarText}
          </Alert>
        </Snackbar>

        <Snackbar open={openSnackbarSuccess} autoHideDuration={6000} onClose={handleCloseAllBackdrops}>
          <Alert onClose={handleCloseAllBackdrops} severity="success" sx={{ fontSize: 14, boxShadow: 3 }}>
            {snackbarText}
          </Alert>
        </Snackbar>

        {/* Dialogs */}
        <DialogCreateSquad open={openDialogCreateSquad} setOpen={setOpenDialogCreateSquad} />
        <DialogChangeSquad open={openDialogChangeSquad} setOpen={setOpenDialogChangeSquad} />

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openLoadingPage}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

      </ SquadsContext.Provider>
    </>
  )
}