import moment from "moment";
import { interfaceClient } from "../../../../../Interface/interfaceClient";
import { Grid, ListItem, ListItemText, Typography } from "@mui/material";
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { formatCurrencyBRL } from "../../../../../Helpers/formatters";

interface ClientInfo {
  title: string;
  value: string | undefined;
  formatFunction?: (value: string) => string;
}

const isValidDate = (date: string) => {
  return moment(date, moment.ISO_8601, true).isValid();
}

export const ClientInfoList = ({ client }: { client: interfaceClient }) => {
  const clientInfo: ClientInfo[] = [
    { title: "Nome Completo", value: client.NomeCompleto ?? '-' },
    { title: "Email", value: client.Email ?? '-' },
    { title: "Código do Cliente", value: client.CodigoCliente ?? '-' },
    { title: "Profissão", value: client.Profissao ?? '-' },
    {
      title: "Data de Nascimento",
      value: client.DataNascimento ?? '-',
      formatFunction: (value: string) => isValidDate(value) ? moment(value).format('DD/MM/YYYY') : '-',
    },
    {
      title: 'PL', value: client.Preco?.toString(),
      formatFunction: (value: string) => value ? formatCurrencyBRL(value) : '-',
    },
    { title: "Estado Civil", value: client.EstadoCivil ?? '-' },
    { title: "Celular", value: client.TelefoneContatoPrimario ?? '-' },
    { title: "Cidade", value: client.Cidade ?? '-' },
    { title: "Estado", value: client.Estado ?? '-' },
    { title: "Bairro", value: client.Bairro ?? '-' },
    { title: "Endereço", value: client.Endereco ?? '-' },
    { title: "Número", value: client.Numero?.toString() ?? '-' },
    { title: "Cidade Natal", value: client.CidadeNatal ?? '-' },
    { title: "Temperatura", value: client.Temperatura ?? '-' },
    { title: "Origem", value: client.Origem ?? '-' },
    { title: "Status", value: client.Status ?? '-' },
    {
      title: "UltimaAtividade",
      value: client.UltimaAtividade?.toLocaleString() ?? '-',
      formatFunction: (value: string) => isValidDate(value) ? moment(value).format('DD/MM/YYYY') : '-',
    },
    { title: "Perfil de Investidor", value: client.PerfilInvestidor ?? '-' },
    { title: "PL", value: client.PotencialDeInvestimento ?? '-' },
    {
      title: "Régua de Relacionamento",
      value: client.ReguaRelacionamento !== undefined && client.ReguaRelacionamento !== null
        ? client.ReguaRelacionamento ? 'Sim' : 'Não'
        : '-',
    }
  ];

  return (
    <>
      <Grid container>
        <Grid container item xs={12} md={7}>
          {clientInfo.map((info, index) => (
            <Grid item xs={6} key={index}>
              <ListItem sx={{ p: 0 }}>
                <ListItemText
                  primary={<Typography fontSize={12} color="text.secondary">{info.title}</Typography>}
                  secondary={
                    <Typography sx={{ fontSize: 15, fontWeight: 'bold' }}>
                      {info.formatFunction ? info.formatFunction(info.value ?? '-') : info.value}
                    </Typography>
                  }
                />
              </ListItem>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} md={5} mt={0.7}>
          <Typography fontSize={12} color="text.secondary">Bio</Typography>
          <Typography
            component='pre'
            sx={{
              fontSize: 15, fontWeight: 'bold',
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word'
            }}
          >
            {client.Bio ? client.Bio :
              <>
                <Typography sx={{ color: 'text.secondary', textAlign: 'center', opacity: 0.5, mt: 10 }}>
                  <SentimentDissatisfiedIcon sx={{ fontSize: 50, mb: 1 }} />
                  <br />
                  Nenhuma bio inserida.
                </Typography>
              </>
            }
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};