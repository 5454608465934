const ClienteInformacoesFinanceirasValidation = (receitas: any, despesas: any) => {
    // const [erros, setErros] = useState<string[]>([])
    let errorsList = <any>[]
    let errosCount = 0

    let currencyForAnalysis: number
    // if (isNaN(receitas.SaldoAcumuladoFGTS)) {
    //     receitas.SaldoAcumuladoFGTS = 0.00
    // }
    // if (isNaN(receitas.PensaoINSSOutros)) {
    //     receitas.PensaoINSSOutros = 0.00
    // }
    // if (isNaN(receitas.PrevidenciaPrivada)) {
    //     receitas.PrevidenciaPrivada = 0.00
    // }
    // if (isNaN(receitas.OutrasRendasMensais)) {
    //     receitas.OutrasRendasMensais = 0.00
    // }
    // if (isNaN(receitas.SeguroDeVida)) {
    //     receitas.SeguroDeVida = 0.00
    // }

    currencyForAnalysis = receitas.RendaBrutaMensal
    if (currencyForAnalysis <= 0 || isNaN(currencyForAnalysis)) {
        errorsList.push('A renda bruta mensal deve ser informado.')
        errosCount++
    }
    // currencyForAnalysis = receitas.AporteMensal
    // if (currencyForAnalysis <= 0 || isNaN(currencyForAnalysis)) {
    //     errorsList.push('Aporte mensal deve ser informado.')
    //     errosCount++
    // }
    currencyForAnalysis = receitas.SaldoAcumuladoFGTS
    if (currencyForAnalysis < 0) {
        errorsList.push('Saldo acumulado do FGTS inválido.')
        errosCount++
    }
    // let varForAnalysis = Boolean(receitas.AporteMensalRecorrencia)
    // if (varForAnalysis != true && varForAnalysis != false) {
    //     errorsList.push('A recorrência do aporte deve ser informada.')
    //     errosCount++
    // }
    // currencyForAnalysis = receitas.PensaoINSSOutros
    // if (currencyForAnalysis < 0) {
    //     errorsList.push('A pensão deve ser informada.')
    //     errosCount++
    // }
    // currencyForAnalysis = receitas.PrevidenciaPrivada
    // if (currencyForAnalysis < 0) {
    //     errorsList.push('A previdência privada deve ser informada.')
    //     errosCount++
    // }
    currencyForAnalysis = receitas.OutrasRendasMensais
    if (currencyForAnalysis < 0) {
        errorsList.push('Outras rendas mensais inválida.')
        errosCount++
    }

    // Despesas
    let varForAnalysisDespesas: number

    varForAnalysisDespesas = parseFloat(despesas.DespesaMensal)
    if (varForAnalysisDespesas <= 0 || isNaN(varForAnalysisDespesas)) {
        errorsList.push('Despesa mensal inválida.')
        errosCount++
    }

    varForAnalysisDespesas = parseFloat(despesas.ContribuicaoDespesaPorcentagem)
    if (varForAnalysisDespesas <= 0 || isNaN(varForAnalysisDespesas)) {
        errorsList.push('A porcentagem de contribuição mensal precisa ser informada.')
        errosCount++
    }
    if (despesas.ManutencaoPadraoDeVida! < 0 || isNaN(despesas.ManutencaoPadraoDeVida!)) {
        errorsList.push('Manutenção padrão de vida inválida.')
        errosCount++
    }
    if (despesas.RestabelecimentoVidaMeses! < 0 || isNaN(despesas.RestabelecimentoVidaMeses!)) {
        errorsList.push('Restabelecimento de vida inválido.')
        errosCount++
    }

    return {
        isValid: errosCount === 0,
        errorDescriptions: errorsList
    }

}

export default ClienteInformacoesFinanceirasValidation