import { useEffect, useState } from "react";
import CurrencyInput from 'react-currency-input-field';
import ClientesService from "../../../Services/ClientesService";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import NumberFormat from 'react-number-format';
import * as React from 'react';
import Input from '@mui/material/Input';
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { borderRadius } from "@mui/system";

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumberFormatCustom = React.forwardRef<NumberFormat<any>, CustomProps>(
    function NumberFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={ref}
                prefix={'R$'}
                decimalSeparator={','}
                thousandSeparator={'.'}
                fixedDecimalScale={true}
                decimalScale={2}
                name={props.name}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
            />
        );
    },
);

const AtivosFinanceiros = ({ ativos, setAtivos, cliente, thereIsAtivos, setThereIsAtivos }: any) => {
    const clientesService = new ClientesService()

    const onChangeAtivos = (index: number, field: string, value: string) => {
        ativos[index][field] = value
        setAtivos([...ativos])
    }

    const onChangeCurrency = (index: number, event: any) => {
        if (isNaN(Number(event.nativeEvent.data))) return;
        if (event.nativeEvent.inputType === "deleteContentBackward" || event.nativeEvent.inputType === "deleteWordBackward") {
            ativos[index]['Valor'] = ""
        } else {
            let newValue = parseFloat((event.target.value.replace("R$", "").replaceAll(".", ""))).toFixed(2)
            ativos[index]['Valor'] = newValue
        }
        setAtivos([...ativos])
    }

    const handleCurrencyAtivos = (event: any) => {
        if (Number(event.target.value) > 0) {
            ativos[Number(event.target.name)].Valor = Number(event.target.value)
            setAtivos([...ativos])
        } else {
            ativos[Number(event.target.name)].Valor = null
            setAtivos([...ativos])
        }
    };

    const removeAtivo = (index: number) => {
        if (ativos[index].hasOwnProperty("Id") && ativos[index].Id != "") {
            clientesService.DeletarAtivo(ativos[index].Id)
                .then((e) => { })
                .catch((e) => {
                })
        }

        ativos.splice(index, 1)
        setAtivos([...ativos])
    }

    const addAtivo = () => {
        ativos.push({ Ativo: "", ClienteId: cliente.Id, Valor: null, Comentario: "", Id: "" })
        setAtivos([...ativos])
    }

    const changeThereIsAtivos = () => {
        setThereIsAtivos(!thereIsAtivos)
    }

    const SelectInputBorderLeft = styled(InputBase)(({ theme }) => ({
        '& .MuiInputBase-input': {
            //   backgroundColor: theme.palette.background.paper,
            border: '1px solid #c4c4c4ab',
            borderRadius: "5px 0px 0px 5px",
            padding: '7px 26px 7px 12px',

        },
    }));

    const SelectInputBorderRight = styled(InputBase)(({ theme }) => ({
        '& .MuiInputBase-input': {
            //   backgroundColor: theme.palette.background.paper,
            border: '1px solid #c4c4c4ab',
            borderRadius: "0px 5px 5px 0px",
            padding: '7px 26px 7px 12px',
        },
    }));

    return <>
        {/* Ativos Financeiros */}
        <Container maxWidth="md" sx={{ padding: 3, backgroundColor: "white", borderRadius: 2, mb: 3 }}>
            <Box component="form" autoComplete="off"
                sx={{ px: 5, py: 1 }}
            >
                <Box sx={{ mb: 1 }}>
                    <Typography variant="h5">
                        Ativos financeiros
                    </Typography>
                </Box>
                <FormControlLabel
                    label="Se o seu cliente não possuir ativos financeiros, clique aqui para desativar."
                    labelPlacement="start"
                    sx={{ mb: 1 }}
                    control={
                        <Switch
                            checked={thereIsAtivos} onClick={() => { changeThereIsAtivos() }}
                            size="small"
                        />
                    }
                />
                {!thereIsAtivos ? "" : (
                    <>
                        <Grid container sx={{ mt: 3, mb: 2 }}>
                            <Grid item xs={5} sx={{ textAlign: "center" }}>
                                <Typography variant="h6">
                                    Ativo
                                </Typography>
                            </Grid>
                            <Grid item xs={3} sx={{ textAlign: "center" }}>
                                <Typography variant="h6">
                                    Valor
                                </Typography>
                            </Grid>
                            <Grid item xs={3} sx={{ textAlign: "center" }}>
                                <Typography variant="h6">
                                    Instituição
                                </Typography>
                            </Grid>
                        </Grid>
                        {ativos.map((ativo: any, indexAtivo: number) => {
                            return <Grid container key={indexAtivo} >
                                <Grid item xs={5}>
                                    <FormControl fullWidth>
                                        <Select
                                            value={ativo.Ativo}
                                            onChange={(event) => onChangeAtivos(indexAtivo, 'Ativo', event.target.value)}
                                            fullWidth
                                            input={<SelectInputBorderLeft />}
                                        >
                                            <MenuItem value="Carteira de Investimentos">Carteira de Investimentos</MenuItem>
                                            <MenuItem value="Liquidez">Liquidez</MenuItem>
                                            <MenuItem value="Seguro de Vida">Seguro de vida</MenuItem>
                                            <MenuItem value="Previdência PGBL">Previdência PGBL</MenuItem>
                                            <MenuItem value="Previdência VGBL">Previdência VGBL</MenuItem>
                                            <MenuItem value="FGTS">FGTS</MenuItem>
                                            <MenuItem value="Outros">Outros</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <InputBase
                                            fullWidth
                                            name={String(indexAtivo)}
                                            autoFocus={false}
                                            sx={{
                                                height: "36px",
                                                width: "100%",
                                                borderLeft: 0,
                                                borderRight: 0,
                                                border: '1px solid #c4c4c4ab',
                                                borderRadius: 0,
                                                padding: '5px 26px 5px 12px',
                                            }}
                                            value={ativo.Valor}
                                            inputComponent={NumberFormatCustom as any}
                                            onChange={handleCurrencyAtivos}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <Select
                                        value={ativo.Comentario}
                                        onChange={(event) => onChangeAtivos(indexAtivo, 'Comentario', event.target.value)}
                                        fullWidth size="small" margin="none"
                                        input={<SelectInputBorderRight />}
                                    >
                                        <MenuItem value="Banco do Brasil">Banco do Brasil</MenuItem>
                                        <MenuItem value="Itaú">Itaú</MenuItem>
                                        <MenuItem value="Santander">Santander</MenuItem>
                                        <MenuItem value="Bradesco">Bradesco</MenuItem>
                                        <MenuItem value="BTG Pactual">BTG Pactual</MenuItem>
                                        <MenuItem value="Safra">Safra</MenuItem>
                                        <MenuItem value="Sicredi">Sicredi</MenuItem>
                                        <MenuItem value="Unicred">Unicred</MenuItem>
                                        <MenuItem value="XP">XP</MenuItem>
                                        <MenuItem value="Clear">Clear</MenuItem>
                                        <MenuItem value="Rico">Rico</MenuItem>
                                        <MenuItem value="Banco digital">Banco digital</MenuItem>
                                        <MenuItem value="Outros">Outros</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton aria-label="Example" sx={{ ml: "15px", position: "relative", bottom: "2px" }}>
                                        <DeleteForeverIcon sx={{ color: "#512178" }}
                                            onClick={() => removeAtivo(indexAtivo)}
                                        />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        })}
                        <Box sx={{ width: "100%", textAlign: "center", mt: "30px" }}>
                            <Box component={"hr"} sx={{ border: "1px solid #f0f1f2", position: "relative", top: "24px" }}></Box>
                            <Button variant="contained" size="small" color="secondary" sx={{ backgroundColor: 'secondary' }}
                                onClick={() => addAtivo()} disableElevation
                            >
                                + Adicionar Ativo
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Container>

        {/* <form className="container-steps row">
            <div className="header">
                <h2>Ativos financeiros</h2>
                <div className="switch space-bottom">
                    Se o seu cliente não possuir ativos financeiros, clique aqui para desativar.
                    <label>
                        <input type="checkbox" name="independencia" checked={thereIsAtivos} onClick={() => { changeThereIsAtivos() }} />
                        <span className="lever"></span>
                    </label>
                </div>
                <div className="expenses-dependent">
                    <div className={!thereIsAtivos ? "col s12 hide" : ""}>
                        <div className="col s12 header-expenses">
                            <h4 className="col s5 center-align">Ativo</h4>
                            <h4 className="col s3 center-align">Valor</h4>
                            <h4 className="col s3 center-align">Instituição</h4>
                        </div>
                        {ativos.map((ativo: any, indexAtivo: number) => {
                            return (
                                !(true) ?
                                    <></>
                                    :
                                    <div>
                                        <div className="col s5 input-expenses-dependent">
                                            <select
                                                onChange={(event) => onChangeAtivos(indexAtivo, 'Ativo', event.target.value)} value={ativo.Ativo}
                                                name="ativos"
                                            >
                                                <option value="" disabled selected>Selecione</option>
                                                <option value="Carteira de Investimentos">Carteira de Investimentos</option>
                                                <option value="Liquidez">Liquidez</option>
                                                <option value="Seguro de Vida">Seguro de vida</option>
                                                <option value="Previdência PGBL">Previdência PGBL</option>
                                                <option value="Previdência VGBL">Previdência VGBL</option>
                                                <option value="FGTS">FGTS</option>
                                                <option value="Outros">Outros</option>
                                            </select>
                                        </div>
                                        <div className="input-expenses-dependent col s3">
                                            <CurrencyInput
                                                id="valor"
                                                name="valor"
                                                value={ativo.Valor}
                                                groupSeparator="."
                                                decimalSeparator=","
                                                prefix="R$"
                                                onChange={(event) => onChangeCurrency(indexAtivo, event)}
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className="col s3 input-expenses-dependent">
                                            <select
                                                onChange={(event) => onChangeAtivos(indexAtivo, 'Comentario', event.target.value)} value={ativo.Comentario}
                                                name="comentario"
                                            >
                                                <option value="" disabled selected>Selecione</option>
                                                <option value="Banco do Brasil">Banco do Brasil</option>
                                                <option value="Itaú">Itaú</option>
                                                <option value="Santander">Santander</option>
                                                <option value="Bradesco">Bradesco</option>
                                                <option value="BTG Pactual">BTG Pactual</option>
                                                <option value="Safra">Safra</option>
                                                <option value="Sicredi">Sicredi</option>
                                                <option value="Unicred">Unicred</option>
                                                <option value="XP">XP</option>
                                                <option value="Clear">Clear</option>
                                                <option value="Rico">Rico</option>
                                                <option value="Banco digital">Banco digital</option>
                                                <option value="Outros">Outros</option>
                                            </select>
                                        </div>
                                        <a className="col s1 btn-hover">
                                            <i onClick={() => removeAtivo(indexAtivo)} className="material-icons delete-expense">delete_forever</i>
                                        </a>
                                    </div>
                            )
                        })}

                        <div className="col s12 button-add-expense">
                            <hr className="col s3 m4 xl5 simulated-calc" />
                            <a className="btn col s6 m4 xl2 text-bold" onClick={() => addAtivo()}>
                                + Adicionar Ativo
                            </a>
                            <hr className="col s3 m4 xl5 simulated-calc" />
                        </div>
                    </div>
                </div>
            </div>
        </form> */}
    </>
}

export default AtivosFinanceiros