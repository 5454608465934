import React, { useState, useEffect } from 'react';
import {
  Container, Typography, List, ListItem, ListItemText, IconButton, Dialog, TextField, DialogActions,
  DialogContent, DialogTitle, Button, ListItemSecondaryAction
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

// Definição dos tipos para o grupo
interface Group {
  Id: number;
  GroupName: string;
  Url: string;
}
interface LeaderGroup {
  Id: number;
  GroupName: string;
  Url: string;
  Ordem: number;
  Image: string;
  Nome: string;
}

const API_URL = process.env.REACT_APP_API_URL;

const EditLinksPage: React.FC = () => {
  const [groups, setGroups] = useState<Group[]>([]);
  const [leaderGroups, setLeaderGroups] = useState<LeaderGroup[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentGroup, setCurrentGroup] = useState<Group | null>(null);
  const [currentLeaderGroup, setCurrentLeaderGroup] = useState<LeaderGroup | null>(null);
  const [openLeaderDialog, setOpenLeaderDialog] = useState(false);
  const [isNewGroup, setIsNewGroup] = useState(false);

  useEffect(() => {
    fetchGroups();
    fetchLeaderGroups()
  }, []);

  const fetchGroups = async () => {
    try {
      const response = await fetch(`${API_URL}/api/usergroup`);
      const data = await response.json();
      setGroups(data);
    } catch (error) {
      console.error('Erro ao buscar grupos', error);
    }
  };
  const fetchLeaderGroups = async () => {
    try {
      const response = await fetch(`${API_URL}/api/UserGroup/leaders`);
      const data = await response.json();
      setLeaderGroups(data);
    } catch (error) {
      console.error('Erro ao buscar grupos de líderes', error);
    }
  };
  const handleEditClick = (group: Group) => {
    setCurrentGroup(group);
    setOpenDialog(true);
    setIsNewGroup(false);
  };

  const handleNewGroup = () => {
    setCurrentGroup({ Id: 0, GroupName: '', Url: '' });
    setOpenDialog(true);
    setIsNewGroup(true);
  };
  const handleLeaderGroupEdit = (group: LeaderGroup) => {
    setCurrentLeaderGroup({ ...group });
    setOpenLeaderDialog(true);
  };
  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentGroup) {
      setCurrentGroup({ ...currentGroup, Url: event.target.value });
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentGroup) {
      setCurrentGroup({ ...currentGroup, GroupName: event.target.value });
    }
  };


  const handleCreateLeaderGroup = async () => {
    if (currentLeaderGroup) {
      try {
        const leaderGroupWhitoutId = { Lider: '1', Url: currentLeaderGroup.Url, GroupName: currentLeaderGroup.GroupName, Ordem: currentLeaderGroup.Ordem, Image: currentLeaderGroup.Image };
        const response = await fetch(`${API_URL}/api/UserGroup/leader`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(leaderGroupWhitoutId)
        });
        if (!response.ok) {
          throw new Error('Falha na solicitação de criação');
        }
        const savedGroup = await response.json();
        setLeaderGroups([...leaderGroups, savedGroup]);
        setOpenLeaderDialog(false);
      } catch (error) {
        console.error('Erro ao criar grupo de líderes', error);
      }
    }
  };
  
  const handleUpdateLeaderGroup = async () => {
    if (currentLeaderGroup && currentLeaderGroup.Id) {
      try {
        const response = await fetch(`${API_URL}/api/UserGroup/leader/${currentLeaderGroup.Id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(currentLeaderGroup)
        });        
        if (!response.ok) {
          throw new Error('Falha na solicitação de atualização');
        }
        fetchLeaderGroups();
        setOpenLeaderDialog(false);
      } catch (error) {
        console.error('Erro ao atualizar grupo de líderes', error);
      }
    }
  };

  
  const handleSave = async () => {
    if (currentGroup) {
      try {
        const method = isNewGroup ? 'POST' : 'PUT';
        const response = await fetch(`${API_URL}/api/usergroup${isNewGroup ? '' : `/${currentGroup.Id}`}`, {
          method: method,
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(currentGroup)
        });
        if (!response.ok) {
          throw new Error('Falha na solicitação');
        }
        const savedGroup = await response.json();
        if (isNewGroup) {
          setGroups([...groups, savedGroup]);
        } else {
          setGroups(groups.map(group => group.Id === currentGroup.Id ? savedGroup : group));
        }
        setOpenDialog(false);
      } catch (error) {
        console.error('Erro ao salvar grupo', error);
      }
    }
  };

  const handleDelete = async (Id: number) => {
    try {
      const response = await fetch(`${API_URL}/api/usergroup/${Id}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Falha na solicitação');
      }
      setGroups(groups.filter(group => group.Id !== Id));
      setLeaderGroups(leaderGroups.filter(group => group.Id !== Id));
    } catch (error) {
      console.error('Erro ao deletar grupo', error);
    }
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold' }}>Editar Links das Dashboards para usuários</Typography>
      <Button variant="contained" startIcon={<AddIcon />} onClick={handleNewGroup} sx={{ mb: 2 }}>
        Adicionar Novo Grupo
      </Button>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {groups.map((group) => (
          <ListItem 
            key={group.Id} 
            sx={{ borderBottom: '1px solid #e0e0e0', '&:hover': { backgroundColor: '#f5f5f5' }, display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}
          >
            <ListItemText primary={group.GroupName} />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="edit" onClick={() => handleEditClick(group)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(group.Id)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>Dashboards de Líderes</Typography>
        <Button variant="contained" startIcon={<AddIcon />} onClick={() => { setCurrentLeaderGroup({ Id: 0, GroupName: '', Url: '', Ordem: 0, Image: '', Nome: '' }); setIsNewGroup(true);setOpenLeaderDialog(true) }} sx={{ mb: 2 }}>
          Adicionar Novo Grupo de Líderes
        </Button>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {leaderGroups.map((group) => (
          <ListItem key={group.Id} sx={{ borderBottom: '1px solid #e0e0e0', '&:hover': { backgroundColor: '#f5f5f5' }, display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
            <ListItemText primary={group.GroupName} secondary={group.Nome} />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="edit" onClick={() => handleLeaderGroupEdit(group)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(group.Id)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Dialog open={openLeaderDialog} onClose={() => setOpenLeaderDialog(false)}>
    <DialogTitle>{isNewGroup ? 'Adicionar Novo Grupo' : 'Editar Grupo'}</DialogTitle>
    <DialogContent>
      <TextField
        margin="dense"
        label="Nome do Grupo"
        type="text"
        fullWidth
        variant="outlined"
        value={currentLeaderGroup ? currentLeaderGroup.GroupName : ''}
        onChange={(e) => currentLeaderGroup && setCurrentLeaderGroup({ ...currentLeaderGroup, GroupName: e.target.value })}
        sx={{ mb: 2 }}
      />
            <TextField
        margin="dense"
        label="Nome do relatório"
        type="text"
        fullWidth
        variant="outlined"
        value={currentLeaderGroup ? currentLeaderGroup.Nome : ''}
        onChange={(e) => currentLeaderGroup && setCurrentLeaderGroup({ ...currentLeaderGroup, Nome: e.target.value })}
        sx={{ mb: 2 }}
      />
      <TextField
        margin="dense"
        label="URL do Grupo"
        type="url"
        fullWidth
        variant="outlined"
        value={currentLeaderGroup ? currentLeaderGroup.Url : ''}
        onChange={(e) => currentLeaderGroup && setCurrentLeaderGroup({ ...currentLeaderGroup, Url: e.target.value })}
        sx={{ mb: 2 }}
      />
            <TextField
        margin="dense"
        label="URL da Imagem"
        type="url"
        fullWidth
        variant="outlined"
        value={currentLeaderGroup ? currentLeaderGroup.Image : ''}
        onChange={(e) => currentLeaderGroup && setCurrentLeaderGroup({ ...currentLeaderGroup, Image: e.target.value })}
        sx={{ mb: 2 }}
      />
      <TextField
        margin="dense"
        label="Ordem"
        type="number"
        fullWidth
        variant="outlined"
        value={currentLeaderGroup ? currentLeaderGroup.Ordem : 0}
        onChange={(e) => currentLeaderGroup && setCurrentLeaderGroup({ ...currentLeaderGroup, Ordem: parseInt(e.target.value, 10) })}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setOpenLeaderDialog(false)} color="error">Cancelar</Button>
      <Button onClick={isNewGroup ? handleCreateLeaderGroup : handleUpdateLeaderGroup} variant="contained">
  {isNewGroup ? 'Adicionar' : 'Salvar'}
</Button>    </DialogActions>
  </Dialog>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{isNewGroup ? 'Adicionar Novo Grupo' : 'Editar Link do Grupo'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Nome do Grupo"
            type="text"
            fullWidth
            variant="outlined"
            value={currentGroup ? currentGroup.GroupName : ''}
            onChange={handleNameChange}
            disabled={!isNewGroup}
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            label="URL da"
            type="url"
            fullWidth
            variant="outlined"
            value={currentGroup ? currentGroup.Url : ''}
            onChange={handleUrlChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="error">Cancelar</Button>
          <Button onClick={handleSave} variant="contained">{isNewGroup ? 'Adicionar' : 'Salvar'}</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default EditLinksPage;
