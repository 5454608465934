import { useState } from 'react';
import { Navigate } from "react-router-dom"
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import reuniaoSimplificadaPNG from '../../../../Assets/Images/reuniao-completa.png'
import reuniaoCompletaPNG from '../../../../Assets/Images/reuniao-simplificada.png'

export const DialogNovaReuniaoComponent = (params: any) => {
  const open = params.open
  const setOpen = params.setOpen
  const userId = params.userId

  const [abrirPlanoDeVoo, setAbrirPlanoDeVoo] = useState("")
  const [abrirReuniaoSimplificada, setAbrirReuniaoSimplificada] = useState("")
  if (abrirPlanoDeVoo != '') {
    return <Navigate to={`/app/plano-de-voo/${abrirPlanoDeVoo}`} />
  } else if (abrirReuniaoSimplificada != '') {
    return <Navigate to={`/app/primeira-reuniao/${abrirReuniaoSimplificada}`} />
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm">
        <DialogTitle variant="h3" sx={{ pt: 5, px: 5, pb: 2 }}>Nova reunião</DialogTitle>
        <DialogContent sx={{ padding: 5 }}>
          <Divider sx={{ mb: 2, borderColor: "#b2b1c330" }} />
          {/* <DialogContentText sx={{ mb: 5 }}>
                        <Typography variant="body1">
                            Adicione as informações do seu possível cliente de forma rápida aqui. O cadastro deste lead estará disponível no menu da esquerda da plataforma
                            de acordo com a fase de negócio que você selecionar.
                        </Typography>
                    </DialogContentText> */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box
                maxWidth={240}
                component="button"
                onClick={() => setAbrirReuniaoSimplificada(userId)}
                sx={{
                  border: "1px solid #512178", borderRadius: 2,
                  backgroundColor: "#ffffff",
                  py: 3, px: 6, height: 380,
                  "&:hover": {
                    cursor: "pointer",
                    boxShadow: 2
                  }
                }}>
                <Stack spacing={3} justifyContent="space-evenly" alignItems="center">
                  <img src={reuniaoCompletaPNG} width={140} />
                  <Typography variant="h6" textAlign="center">
                    Reunião Simplificada <br />
                    <span style={{ fontWeight: "bold" }}>
                      <span style={{ textDecoration: "underline", textDecorationColor: "#fffea1" }}>sem</span>  PDF
                    </span>
                  </Typography>
                  <Button size="small" variant="contained" fullWidth>
                    Começar reunião <br />
                    Simplificada
                  </Button>
                </Stack>
              </Box>
              <Typography variant="h6" fontWeight="normal" sx={{ my: 3 }}>
                <span style={{ fontWeight: "bold" }}>Reunião rápida: </span>É uma versão resumida do nosso Plano de Voo mas ela não
                vai gerar o nosso Relatório do Plano de Voo.
              </Typography>
            </Grid>
            {/* <Grid item xs={1} /> */}
            <Grid item xs={6}>
              <Box
                maxWidth={240}
                component="button"
                onClick={() => setAbrirPlanoDeVoo(userId)}
                sx={{
                  border: "1px solid #512178", borderRadius: 2,
                  backgroundColor: "#ffffff",
                  py: 3, px: 6, height: 380,
                  "&:hover": {
                    cursor: "pointer",
                    boxShadow: 2
                  }
                }}>
                <Stack spacing={3} justifyContent="space-evenly" alignItems="center">
                  <img src={reuniaoSimplificadaPNG} width={190} />
                  <Typography variant="h6" textAlign="center">
                    Plano de Voo completo <br />
                    <span style={{ fontWeight: "bold", fontSize: 14 }}>
                      <span style={{ textDecoration: "underline", textDecorationColor: "#fffea1" }}>com</span>  PDF
                    </span>
                  </Typography>
                  <Button size="small" variant="contained" fullWidth>
                    Começar reunião <br />
                    Completa
                  </Button>
                </Stack>
              </Box>
              <Typography variant="h6" fontWeight="normal" sx={{ mt: 3 }}>
                <span style={{ fontWeight: "bold" }}>Plano de voo completo: </span>É o nosso processo completo de reunião e com ele
                você vai conseguir gerar o Relatório de Plano de Voo.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}