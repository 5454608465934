import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { RiThumbDownLine } from 'react-icons/ri';


export const DialogAlterarTarefaComponent = (params: any) => {
  const open = params.open
  const setOpen = params.setOpen

  const [motivoDePerda, setMotivoDePerda] = React.useState('');
  const handleChange = (event: SelectChangeEvent) => {
    setMotivoDePerda(event.target.value as string);

    if (event.target.value == "Não se encaixa no meu perfil de atendimento") {
      setOpenSecondInput(true)
    } else {
      setOpenSecondInput(false)
    }
  };

  // Opção não se encaixa no meu perfil de atendimento
  const [openSecondInput, setOpenSecondInput] = React.useState(false)
  const [faixaDeAtendimento, setFaixaDeAtendimento] = React.useState("")
  const handleChangeFaixDeAtendimento = (event: SelectChangeEvent) => {
    setFaixaDeAtendimento(event.target.value as string);
  };

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs">
        <DialogTitle variant="h3" sx={{ pt: 5, px: 5, pb: 2 }}>Na próxima você consegue!</DialogTitle>
        <DialogContent sx={{ padding: 5 }}>
          <Divider sx={{ mb: 2, borderColor: "#b2b1c330" }} />
          <DialogContentText sx={{ mb: 5 }}>
            <Typography variant="body1">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sint eaque mollitia est, fugit repudiandae.
            </Typography>
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h5" fontWeight="normal" mt={1}>Motivo que perdeu o lead *</Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Select
                  id="demo-simple-select"
                  size="small"
                  displayEmpty
                  value={motivoDePerda}
                  onChange={handleChange}
                >
                  <MenuItem disabled value="">
                    <em>Selecione...</em>
                  </MenuItem>
                  <MenuItem value="Não se encaixa no meu perfil de atendimento">Não se encaixa no meu perfil de atendimento</MenuItem>
                  <MenuItem value="Não tem interesse em investir">Não tem interesse em investir</MenuItem>
                  <MenuItem value="Não tem capital para investir no momento">Não tem capital para investir no momento</MenuItem>
                  <MenuItem value="Não quer trocar de assessoria XP">Não quer trocar de assessoria XP</MenuItem>
                  <MenuItem value="Aguardando capital">Aguardando capital</MenuItem>
                  <MenuItem value="Investe por conta própria">Investe por conta própria</MenuItem>
                  <MenuItem value="Não quer trocar de instituição financeira">Não quer trocar de instituição financeira</MenuItem>
                  <MenuItem value="Experiência ruim XP">Experiência ruim XP</MenuItem>
                  <MenuItem value="Experiência ruim com a MARCO">Experiência ruim com a MARCO</MenuItem>
                  <MenuItem value="Experiência ruim com investimentos">Experiência ruim com investimentos</MenuItem>
                  <MenuItem value="Não atendeu">Não atendeu</MenuItem>
                  <MenuItem value="Não compareceu a reuniões marcadas">Não compareceu a reuniões marcadas</MenuItem>
                  <MenuItem value="Telefone/email não existe">Telefone/email não existe</MenuItem>
                  <MenuItem value="Insegurança sobre a plataforma XP">Insegurança sobre a plataforma XP</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {
              !openSecondInput ? "" :
                <>
                  <Grid item xs={6}>
                    <Typography variant="h5" fontWeight="normal" mt={1}>
                      Qual faixa de atendimento? *
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Select
                        id="demo-simple-select"
                        size="small"
                        displayEmpty
                        value={faixaDeAtendimento}
                        onChange={handleChangeFaixDeAtendimento}
                      >
                        <MenuItem disabled value="">
                          <em>Selecione...</em>
                        </MenuItem>
                        <MenuItem value="Assessoria Digital (Até 300k)">Assessoria Digital (Até 300k)</MenuItem>
                        <MenuItem value="Assessoria Exclusive (300k - 1M)">Assessoria Exclusive (300k - 1M)</MenuItem>
                        <MenuItem value="Assessoria Premium (1M - 3M)">Assessoria Premium (1M - 3M)</MenuItem>
                        <MenuItem value="Assessoria Private (A partir de 3M)">Assessoria Private (A partir de 3M)</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </>
            }
            <Grid item xs={12} textAlign="center" mt={3}>
              <Button sx={{ width: 180 }} endIcon={<RiThumbDownLine />} variant="contained" color="error"
                onClick={() => setOpen(false)}
              >
                Perder
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}