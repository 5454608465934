import { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useUserInfos } from './useUserInfos';
import NegociosService from '../Services/NegociosService';
import { INegocioCreditoRequest } from '../Interface/interfaceNegocios';

interface IKanbanList {
  [key: string]: {
    name: string;
    data: INegocioCreditoRequest[];
  };
}

const statusMapping = {
  LEAD: 'Lead',
  R1: 'R1',
  OUTRAS_REUNIOES: 'Outras Reuniões',
  AGUARDANDO_FECHAMENTO: 'Aguardando Fechamento',
  CLIENTE: 'Cliente',
};

interface AdvisorCreditoContextProps {
  loading: boolean;
  filteredKanbanList: IKanbanList;
}

const AdvisorCreditoContext = createContext<AdvisorCreditoContextProps | undefined>(undefined);

export const AdvisorCreditoProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [kanbanList, setKanbanList] = useState<IKanbanList>({});
  const [loading, setLoading] = useState<boolean>(true);
  const { userInfos } = useUserInfos();
  const negociosService = new NegociosService();

  const fetchNegocios = async () => {
    setLoading(true);
    try {
      const assessorId = userInfos?.Id;
      const negocios = await negociosService.ListNegociosCreditosByAssessorId(assessorId);

      const kanban: IKanbanList = {};

      Object.entries(statusMapping).forEach(([statusKey, statusName]) => {
        kanban[statusKey] = { name: statusName, data: [] };
      });

      negocios.forEach((negocio) => {
        const status = negocio.Negocio.Status;
        if (status && kanban[status]) {
          kanban[status].data.push(negocio);
        }
      });

      setKanbanList(kanban);
    } catch (error) {
      enqueueSnackbar('Erro ao buscar negócios de crédito.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userInfos?.Id) {
      fetchNegocios();
    }
  }, [userInfos?.Id]);

  return (
    <AdvisorCreditoContext.Provider
      value={{
        loading,
        filteredKanbanList: kanbanList,
      }}
    >
      {children}
    </AdvisorCreditoContext.Provider>
  );
};

export const useAdvisorCreditoContext = () => {
  const context = useContext(AdvisorCreditoContext);
  if (!context) {
    throw new Error('useAdvisorCreditoContext must be used within an AdvisorCreditoProvider');
  }
  return context;
};
