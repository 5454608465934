import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import LoginComponent from '../../Common/Login/LoginComponent';
import { useLocation } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import logo from '../../../Assets/Images/logo.png';

const LoginPage = () => {
  const [redirectLogin, setRedirectLogin] = useState(false)

  useEffect(() => {
    setRedirectLogin(false)
  }, [])

  const [openDialogContaCriada, setOpenDialogContraCriada] = useState(false)
  const [openDialogRecuperarSenha, setOpenDialogRecuperarSenha] = useState(false)
  const [openDialogSenhaAlterada, setOpenDialogSenhaAlterada] = useState(false)

  const hash = useLocation().hash
  useEffect(() => {
    switch (hash) {
      case "#criar-conta-sucesso": {
        setOpenDialogContraCriada(true)
        break;
      }
      case "#recuperar-senha-sucesso": {
        setOpenDialogRecuperarSenha(true)
        break;
      }
      case "#senha-alterada": {
        setOpenDialogSenhaAlterada(true)
        break;
      }

    }
  }, [hash])


  const handleClose = () => {
    setOpenDialogContraCriada(false)
    setOpenDialogRecuperarSenha(false)
    setOpenDialogSenhaAlterada(false)
  };

  if (redirectLogin)
    return <Navigate to="/app/dashboard-pbi" />

  return (
    <>
      <Stack sx={{ width: "100vw", height: "100vh" }} direction="row" spacing={0}>
        <Grid container spacing={0}>
          <Grid item xs={12} lg={6}>
            <Container maxWidth="sm" sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <Box sx={{ width: "90%" }}>
                <Box textAlign='center' my={2}>
                  <img src={logo} alt="Logo Marcolab" width={140} />
                </Box>
                <Typography variant="h3" color="primary">
                  Plataforma do Assessor
                </Typography>
                <Typography variant="body1" fontSize={15}>
                  Bem vindo de volta! Por favor acesse sua conta.
                </Typography>
                <LoginComponent />
              </Box>
            </Container>
          </Grid>
          <Grid item lg={6}>
            <Box className="bg-login" sx={{ height: "100%", display: { xs: "none", lg: "block" } }} />
          </Grid>
        </Grid>
      </Stack >

      <Dialog
        open={openDialogContaCriada}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h2>Conta criada com sucesso!</h2>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box>
              Em breve você receberá um e-mail com a confirmação.
              Aguarde seu acesso ser liberado

            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Continuar</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialogRecuperarSenha}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h2>Recuperação de senha</h2>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box>
              Em breve você receberá um e-mail com o link para alterar sua senha.
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Continuar</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialogSenhaAlterada}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h2>Senha alterada!</h2>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box>
              A senha foi alterada com sucesso!
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Continuar</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default LoginPage;