import { interfaceSchedule } from "../../../Interface/intarfaceSchedule";
import StorageHelper from "../../../Services/Helpers/StorageHelper";
import { updateTask, createTask } from "./crudTask"

export async function handleChangeDescriptionTask(task: interfaceSchedule) {
  return updateTask(task);
}

const storageHelper = new StorageHelper();
const assessorId = storageHelper.GetUsuarioId();

export async function handleCreateFirstCallFinished(clientId: string) {
  let newActivitie: interfaceSchedule = {
    DataEvento: new Date(), AtividadeFinalizada: true, Title: "Reagendado", Descricao: "",
    AssessorUsuarioId: assessorId, ClienteId: clientId, Visto: false, DataTermino: null,
    StatusTarefa: "FINISHED", AtividadeFinalizadaData: new Date(), AtividadeTodoDia: false
  }


  return createTask(newActivitie)
}

export async function handleCreateTask(timeToSchedule: number, isAllDay = false, taskToCreate: interfaceSchedule, isPersonalizedDate = false) {
    // Updating the first call to FINISHED
    if (isAllDay) {
        // New contact days
        taskToCreate.AtividadeTodoDia = true

        // Formatting date for tomorrow 7 am
        let dateToday = new Date()
        let dateTomorrow = new Date()
        dateTomorrow.setDate(dateToday.getDate() + timeToSchedule)
        dateTomorrow.setHours(7)
        dateTomorrow.setMinutes(0)
        dateTomorrow.setSeconds(0)

        // newActivitie.DataEvento = formattingDateToISO(dateTomorrow)
        taskToCreate.DataEvento = dateTomorrow

    } else {
        // New contact hours
        taskToCreate.AtividadeTodoDia = false

        let newDate = new Date()
        newDate.setHours(newDate.getHours() + timeToSchedule)

        // newActivitie.DataEvento = formattingDateToISO(newDate)
        taskToCreate.DataEvento = newDate
    }

    try {
        return createTask(taskToCreate)
    } catch (error) {
        console.log(error)
    }
}

export async function handlePostponeFirstCallTask(timeToPostpone: number, isAllDay = false, firstCall: interfaceSchedule) {
    // Handle the new infos to postpone
    if (isAllDay) {
        // Postpone days
        firstCall.AtividadeTodoDia = true

        // Formatting date for tomorrow 7 am
        let dateToday = new Date()
        let dateTomorrow = new Date()
        dateTomorrow.setDate(dateToday.getDate() + timeToPostpone)
        dateTomorrow.setHours(7)
        dateTomorrow.setMinutes(0)
        dateTomorrow.setSeconds(0)

        // newActivitie.DataEvento = formattingDateToISO(dateTomorrow)
        firstCall.DataEvento = dateTomorrow

    } else {
        // Postpone hours
        firstCall.AtividadeTodoDia = false

        let newDate = new Date()
        newDate.setHours(newDate.getHours() + timeToPostpone)

        // newActivitie.DataEvento = formattingDateToISO(newDate)
        firstCall.DataEvento = newDate
    }

    // Rescheduling
    if (firstCall.hasOwnProperty("Id") && firstCall.Id != "") {
        return updateTask(firstCall)
    } else {
        return createTask(firstCall)
    }
}

export async function handlePostponeTask(timeToPostpone: number, isAllDay = false, task: interfaceSchedule) {
    // Handle the new infos to postpone
    if (isAllDay) {
        // Postpone days
        task.AtividadeTodoDia = true

        // Formatting date for tomorrow 7 am
        let dateToday = new Date()
        let dateTomorrow = new Date()
        dateTomorrow.setDate(dateToday.getDate() + timeToPostpone)
        dateTomorrow.setHours(7)
        dateTomorrow.setMinutes(0)
        dateTomorrow.setSeconds(0)

        // newActivitie.DataEvento = formattingDateToISO(dateTomorrow)
        task.DataEvento = dateTomorrow

    } else {
        // Postpone hours
        task.AtividadeTodoDia = false

        let newDate = new Date()
        newDate.setHours(newDate.getHours() + timeToPostpone)

        // newActivitie.DataEvento = formattingDateToISO(newDate)
        task.DataEvento = newDate
    }

    // Rescheduling
    return updateTask(task)
}

export async function handleStatusTask(task: interfaceSchedule, newStatus: "OPEN" | "FINISHED" | "CANCELED") {
    task.StatusTarefa = newStatus

    if (newStatus == "FINISHED" || newStatus == "CANCELED") {
        task.AtividadeFinalizada = true
        task.AtividadeFinalizadaData = new Date()
    } else {
        task.AtividadeFinalizada = false
    }

    return updateTask(task)
}

export async function handleSuccessFirstCallTask(firstCall: interfaceSchedule) {
    // Updating the first call to FINISHED
    firstCall.AtividadeFinalizada = true
    firstCall.AtividadeFinalizadaData = new Date()
    firstCall.StatusTarefa = "FINISHED"

    if (firstCall.hasOwnProperty("Id") && firstCall.Id != "") {
        updateTask(firstCall)
    } else {
        firstCall.DataEvento = new Date()

        try {
            return createTask(firstCall)
        } catch (error) {
            console.log(error)
        }

    }
}