import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Typography, Box, Grid, Divider } from '@mui/material';
import { interfaceClient } from '../../../Interface/interfaceClient';
import { KanbanItem } from './KanbanItem';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

interface Props {
  columnId: string;
  columnName: string;
  clients: interfaceClient[];
  totalAmount: number;
}

export const KanbanColumn: React.FC<Props> = ({ columnId, columnName, clients, totalAmount }) => {
  return (
    <Box width={291} display="flex" flexDirection="column">
      <Grid container alignItems="center">
        <Grid item xs>
          <Box textAlign='center' my={3}>
            <Typography variant="h4" sx={{ color: '#512178' }}>{columnName}</Typography>
            <Typography variant="h6">
              {totalAmount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              {` - ${clients.length} ${clients.length > 1 ? 'negócios' : 'negócio'}`}

            </Typography>
            <Divider sx={{ mt: 3, borderColor: '#00000008' }} />
          </Box>
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ height: '40%', mt: 2.5 }} />
      </Grid>
      <Droppable droppableId={columnId}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} style={{ height: '100%' }}>
            {clients.length === 0 && (
              <Box textAlign='center' my={2} sx={{ opacity: 0.2 }}>
                <TrendingUpIcon sx={{ fontSize: 60, color: '#512178' }} />
                <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                  Aguardando novos desafios!
                </Typography>
              </Box>
            )}
            <Grid container gap={2}>
              {clients.map((client, index) => (
                <KanbanItem key={client.Id} client={client} index={index} />
              ))}
            </Grid>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Box>
  );
};
