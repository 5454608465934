import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { IListUsersGroups } from '../../../../Interface/interfaceUsers';
import UserService from '../../../../Services/UserService';
import { IUser } from '../../../../Interface/user';

const UsersApprovalContext = createContext<UsersApprovalContextType | undefined>(undefined);

interface StateProviderProps {
  children: ReactNode;
}

interface UsersApprovalContextType {
  usersGroupsList: IListUsersGroups | undefined;
  updateUserStatus: (userId: string, blockAction: boolean) => Promise<void>;
  loading: boolean;
}

const UsersApprovalProvider: React.FC<StateProviderProps> = ({ children }) => {
  const userService = new UserService();

  const [usersGroupsList, setUsersGroupsList] = useState<IListUsersGroups>({
    "Ativos": [],
    "Bloqueados": [],
    "Pendentes": []
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    userService.ListUsersGroups()
      .then(groups => {
        setUsersGroupsList(groups);
      })
      .catch((err: Error) => {
        console.error('Error na listagem de grupos de tarefas: ', err);
      })
      .finally(() => setLoading(false))
  }, []);

  const updateUserStatus = async (userId: string, blockAction: boolean) => {
    try {
      if (blockAction) {
        await userService.BlockUser({ Id: userId, Status: true });
      } else {
        await userService.ActiveUser({ Id: userId, Status: true });
        await userService.BlockUser({ Id: userId, Status: false });
      }

      moveUserToGroup(userId, blockAction ? "Bloqueados" : "Ativos");
    } catch (error) {
      console.error('Erro ao atualizar o status do usuário:', error);
    }
  };

  const moveUserToGroup = (userId: string, targetGroup: keyof IListUsersGroups) => {
    setUsersGroupsList(prevGroups => {
      let sourceGroup: keyof IListUsersGroups | null = null;
      let userToMove: IUser | null = null;

      // Encontra o grupo de origem do usuário e o usuário
      for (const group in prevGroups) {
        const foundUser = prevGroups[group as keyof IListUsersGroups].find(user => user.Id === userId);
        if (foundUser) {
          sourceGroup = group as keyof IListUsersGroups;
          userToMove = foundUser;
          break;
        }
      }

      // Se não encontrar o usuário ou seu grupo de origem, retorna os grupos anteriores
      if (!sourceGroup || !userToMove) return prevGroups;

      // Remove o usuário do grupo de origem
      const updatedSourceGroup = prevGroups[sourceGroup].filter(user => user.Id !== userId);

      // Adiciona o usuário ao grupo de destino
      const updatedTargetGroup = [...prevGroups[targetGroup], userToMove];

      return {
        ...prevGroups,
        [sourceGroup]: updatedSourceGroup,
        [targetGroup]: updatedTargetGroup,
      };
    });
  };

  return (
    <UsersApprovalContext.Provider value={{
      usersGroupsList,
      updateUserStatus,
      loading
    }}>
      {children}
    </UsersApprovalContext.Provider>
  );
};

const useUsersApprovalContext = () => {
  const context = useContext(UsersApprovalContext);
  if (context === undefined) {
    throw new Error('useUsersApprovalContext must be used within a StateProvider');
  }
  return context;
};

export { UsersApprovalProvider, useUsersApprovalContext };