import React, { useState } from 'react';
import { TextField, MenuItem, Grid, Typography } from '@mui/material';
import { INegocioConsorcioRequest } from '../../../../../Interface/interfaceNegocios';
import StorageHelper from '../../../../../Services/Helpers/StorageHelper';
import { useParams } from 'react-router-dom';
import { NumberFormatCustom } from '../../../../../Helpers/Components';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';

interface FormConsorcioProps {
  initialData?: INegocioConsorcioRequest;
  onSubmit: (data: INegocioConsorcioRequest) => Promise<void>;
  onClose: () => void;
}

export const FormConsorcio: React.FC<FormConsorcioProps> = ({ initialData, onSubmit, onClose }) => {
  const storageHelper = new StorageHelper();
  const { userId: clientId } = useParams();
  const [loading, setLoading] = useState(false);

  const [negocioConsorcio, setNegocioConsorcio] = useState<INegocioConsorcioRequest>(
    initialData || {
      Negocio: {
        AssessorId: storageHelper.GetUsuarioId(),
        ClienteId: clientId,
        Status: 'LEAD',
        DataInicio: new Date(),
        DataFechamento: undefined,
        VendedorId: '89e331ab-d5ca-411a-966e-0dcd0ada8e16', // Usuário fixo para receber os negócios // User DEV: 0efd9de7-57d7-42d9-bc16-f2ad4eb92b84 USER PROD: 89e331ab-d5ca-411a-966e-0dcd0ada8e16
        ReceitaBruta: 0,
        ReceitaEscritorio: 0,
        Expectativa: 'Alta',
        Descricao: ''
      },
      Consorcio: {
        Estrategia: 'AQUISICAO_PLANEJADA',
        Tipo: '',
        ValorFinanceiro: null,
      }
    }
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    if (name in negocioConsorcio.Negocio) {
      setNegocioConsorcio(prevState => ({
        ...prevState,
        Negocio: {
          ...prevState.Negocio,
          [name]: value
        }
      }));
    } else if (name in negocioConsorcio.Consorcio) {
      setNegocioConsorcio(prevState => ({
        ...prevState,
        Consorcio: {
          ...prevState.Consorcio,
          [name]: value
        }
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      await onSubmit(negocioConsorcio);
      onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            select
            name="Estrategia"
            label="Estratégia"
            value={negocioConsorcio.Consorcio.Estrategia}
            onChange={handleInputChange}
            fullWidth size='small'
            variant="outlined"
          >
            <MenuItem value="AQUISICAO_PLANEJADA">
              Aquisição Planejada
              <Typography ml={1} variant="caption" color="gray" fontSize={10}>(Poupança através do consórcio)</Typography>
            </MenuItem>
            <MenuItem value="OPERACAO_ESTRUTURADA">
              Operação Estruturada
              <Typography ml={1} variant="caption" color="gray" fontSize={10}>(Contemplação rápida e previsível)</Typography>
            </MenuItem>
            <MenuItem value="ALAVANCAGEM_FINANCEIRA">
              Alavancagem Financeira
              <Typography ml={1} variant="caption" color="gray" fontSize={10}>(Consórcio como investimento)</Typography>
            </MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField
            select
            name="Tipo"
            label="Tipo de Consórcio"
            value={negocioConsorcio.Consorcio.Tipo}
            onChange={handleInputChange}
            fullWidth size='small'
            variant="outlined"
          >
            <MenuItem value="Imóvel">Imóvel</MenuItem>
            <MenuItem value="Automóvel">Automóvel</MenuItem>
            <MenuItem value="Outros">Outros</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Valor Financeiro"
            fullWidth size='small'
            variant="outlined"
            autoComplete='off'
            value={negocioConsorcio.Consorcio.ValorFinanceiro}
            onChange={(event) => {
              negocioConsorcio.Consorcio.ValorFinanceiro = Number(event.target.value);
              setNegocioConsorcio({ ...negocioConsorcio });
            }}
            InputProps={{
              inputComponent: NumberFormatCustom as any,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            select
            name="Expectativa"
            label="Expectativa"
            value={negocioConsorcio.Negocio.Expectativa}
            onChange={handleInputChange}
            fullWidth size='small'
            variant="outlined"
          >
            <MenuItem value="Alta">Alta</MenuItem>
            <MenuItem value="Media">Média</MenuItem>
            <MenuItem value="Baixa">Baixa</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="Descricao"
            label="Descrição"
            multiline
            rows={6}
            fullWidth size='small'
            variant="outlined"
            value={negocioConsorcio.Negocio.Descricao}
            inputProps={{
              maxLength: 800
            }}
            onChange={handleInputChange}
            helperText={`${negocioConsorcio.Negocio.Descricao.length}/800 caracteres restantes`}
          />
        </Grid>

        <Grid item xs={12}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            loading={loading}
          >
            {initialData?.Negocio.Id ? 'Salvar Negócio' : 'Criar Negócio de Consórcio'}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
