import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, CircularProgress, Fab, Tooltip } from '@mui/material';

type MyPowerBIReportProps = {
  accessToken: any;
};

const API_URL = process.env.REACT_APP_API_URL;

const MyPowerBIReport: React.FunctionComponent<MyPowerBIReportProps> = ({ accessToken }) => {
  const [reports, setReports] = useState([]);
  const [embedInfo, setEmbedInfo] = useState({ groupId: null, reportId: null });
  const [embedKey, setEmbedKey] = useState(0);

  function extractPowerBIIds(url: any) {
    const regex = /groups\/([^\/]+)\/reports\/([^\/]+)/;
    const matches = url.match(regex);
    if (matches && matches.length === 3) {
      const groupId = matches[1];
      const reportId = matches[2];
      return { groupId, reportId };
    } else {
      console.log('The URL does not contain the expected Power BI identifiers.');
      return { groupId: null, reportId: null };
    }
  }

  const handleBackClick = () => {
    setEmbedInfo({ groupId: null, reportId: null });
    setEmbedKey(prevKey => prevKey + 1);
  };

  const selectReport = (report: any) => {
    const { groupId, reportId } = extractPowerBIIds(report.Url);
    setEmbedInfo({ groupId, reportId });
    setEmbedKey(prevKey => prevKey + 1);
  };

  const embedConfig = {
    type: 'report',
    id: embedInfo.reportId || accessToken.reportId,
    embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${embedInfo.reportId}&groupId=${embedInfo.groupId}`,
    accessToken: accessToken.accessToken,
    tokenType: models.TokenType.Aad,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: true,
    }
  };

  useEffect(() => {
    if (!accessToken.reportId) {
      const loginObject = JSON.parse(localStorage.getItem('login') || '{}');
      const groupName = loginObject ? loginObject.cargo : null;
      fetch(`${API_URL}/api/UserGroup/leaders/groupname/${groupName}`, {
        headers: { 'Content-Type': 'application/json' },
      }).then((res) => res.json()).then((res) => {
        setReports(res);
      }).catch((err: any) => { console.log(err) });
    }
  }, [accessToken]);

  if (!accessToken.accessToken) return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 2, height: 200 }}>
    <CircularProgress size={40} thickness={4} />
  </Box>;

  return (
    <div className="report-container">
      {
        embedInfo.reportId && (
          <Tooltip title='Voltar'>
            <Fab color="primary"
              size='small'
              aria-label="back" style={{ position: 'fixed', bottom: 20, right: 20 }}
              onClick={handleBackClick}>
              <ArrowBackIcon />
            </Fab>
          </Tooltip>
        )
      }

      {
        accessToken.reportId || embedInfo.reportId ? (<PowerBIEmbed
          key={embedKey}
          embedConfig={embedConfig}
          eventHandlers={
            new Map([
              ['loaded', function () { console.log('Report loaded'); }],
              ['rendered', function () { console.log('Report rendered'); }],
              ['error', function (event) { console.error(event); }]
            ])
          }
          cssClassName={'powerbi-report'}
        />) : (<div className="reports">
          {
            reports && reports.length > 0 && reports.sort((a: any, b: any) => a.Ordem - b.Ordem)
              .map((report: any, index) => (
                <div key={index} className='report' onClick={() => selectReport(report)} style={{ height: '250px' }}>
                  <img src={report.Image} alt="Report Icon" style={{ width: '80px', height: '80px' }} />
                  <h2>{report.Nome}</h2>
                </div>
              ))
          }
        </div>)
      }
    </div>
  );
};

export default MyPowerBIReport;
