import { useState } from 'react';
import { useReassignClients } from '../../../Hooks/useReassignClients';
import { Box, Button, Typography, List, ListItem, ListItemText, Alert, TextField } from '@mui/material';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import DownloadIcon from '@mui/icons-material/DownloadOutlined';
import { LoadingButton } from '@mui/lab';

export const ReassignClients = () => {
  const { loading, error, success, failedClients, handleFileUpload } = useReassignClients();
  const [file, setFile] = useState<File | null>(null);

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const onFileUpload = () => {
    if (file) {
      handleFileUpload(file);
    }
  };

  const downloadTemplate = () => {
    const wb = XLSX.utils.book_new();
    const ws_data = [
      ['Conta Xp', 'AssessorDestino'],
      ['123456', '654321'],
      ['789012', '345678']
    ];
    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    XLSX.utils.book_append_sheet(wb, ws, 'Template');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'tombamento.xlsx');
  };

  return (
    <Box sx={{ p: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
        <Typography variant='h4'>
          Tombamento de Clientes
        </Typography>
        <Button startIcon={<DownloadIcon />} variant='outlined' size='small' color='secondary' onClick={downloadTemplate}>
          Baixar Planilha Modelo
        </Button>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
        <TextField
          type='file'
          onChange={onFileChange}
          inputProps={{ accept: '.xlsx, .xls' }}
          variant='outlined'
        />
        <LoadingButton
          loading={loading}
          variant='contained'
          onClick={onFileUpload}
          disabled={!file}
        >
          Confirmar e Enviar
        </LoadingButton>
      </Box>

      {error && (
        <Alert severity='error' sx={{ mt: 2 }}>
          <Typography variant='h5'>
            {error}
          </Typography>
          <List>
            {failedClients.map((clientError, index) => (
              <ListItem key={index}>
                <ListItemText primary={clientError} />
              </ListItem>
            ))}
          </List>
        </Alert>
      )}
      {success && (
        <Alert severity='success' sx={{ mt: 2 }}>
          <Typography variant='h5'>Tombamento concluído com sucesso!</Typography>
        </Alert>
      )}
    </Box>
  );
}