import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid'
import { Divider, FormHelperText } from '@mui/material';
import { RiThumbUpLine } from 'react-icons/ri';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useEffect, useState } from 'react';
import { NumberFormatCustom } from '../../../Helpers/Components';
import { useKanbanContext } from '../../../Hooks/useKanban';
import { LoadingButton } from '@mui/lab';

interface interfaceValues { preco: number | null; codeOfClient: string; motivo: string }

export const DialogWin = () => {
  const { handleWinClient, movedToClientDialog } = useKanbanContext();

  const [newValues, setNewValues] = useState<interfaceValues>({ preco: null, codeOfClient: '', motivo: '' })
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    newValues.preco = null
    newValues.codeOfClient = ''
    newValues.motivo = ''
    setNewValues({ ...newValues })
  }, [movedToClientDialog.open])

  const [errors, setErrors] = useState({
    preco: false,
    codeOfClient: false,
    motivo: false,
  });

  const validate = () => {
    const newErrors = {
      preco: !newValues.preco,
      codeOfClient: newValues.codeOfClient === '',
      motivo: newValues.motivo === '',
    };

    setErrors(newErrors);
    return !newErrors.preco && !newErrors.codeOfClient && !newErrors.motivo;
  };

  if (!movedToClientDialog.client) return <></>;

  return (
    <>
      <Dialog open={movedToClientDialog.open} maxWidth='xs'>
        <DialogTitle variant='h3' sx={{ pt: 5, px: 5, pb: 2 }}>Parabéns!</DialogTitle>
        <DialogContent sx={{ padding: 5 }}>
          <Divider sx={{ mb: 2, borderColor: '#b2b1c330' }} />
          <DialogContentText variant='body1' sx={{ mb: 5 }}>
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                required
                label='PL'
                autoFocus
                fullWidth
                size='small'
                variant='outlined'
                autoComplete='off'
                value={newValues.preco}
                error={errors.preco}
                helperText={errors.preco ? 'Campo obrigatório' : ''}
                onChange={(event) => {
                  newValues.preco = Number(event.target.value)
                  setNewValues({ ...newValues })
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                label='Código do cliente'
                size='small'
                variant='outlined'
                autoComplete='off'
                value={newValues.codeOfClient}
                error={errors.codeOfClient}
                helperText={errors.codeOfClient ? 'Campo obrigatório' : ''}
                onChange={((event) => {
                  const value = event.target.value;

                  if (/^\d*$/.test(value)) {
                    newValues.codeOfClient = value;
                    setNewValues({ ...newValues });
                  }
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={errors.motivo} size='small' required>
                <InputLabel id='motivo-label'>Motivo que virou cliente</InputLabel>
                <Select
                  size='small'
                  labelId='motivo-label'
                  id='motivo-select'
                  label='Motivo que virou cliente'
                  value={newValues.motivo}
                  onChange={(event) => {
                    newValues.motivo = event.target.value
                    setNewValues({ ...newValues })
                  }}
                >
                  <MenuItem disabled value=''>
                    <em>Selecione...</em>
                  </MenuItem>
                  <MenuItem value='Atendimento Marco'>Atendimento Marco</MenuItem>
                  <MenuItem value='Conhecimento do assessor'>Conhecimento do assessor</MenuItem>
                  <MenuItem value='Plataforma XP'>Plataforma XP</MenuItem>
                  <MenuItem value='Plano de voo'>Plano de voo</MenuItem>
                  <MenuItem value='Indicação'>Indicação</MenuItem>
                  <MenuItem value='Melhores rendimentos'>Melhores rendimentos</MenuItem>
                  <MenuItem value='Produtos'>Produtos</MenuItem>
                </Select>
                {
                  errors.motivo && <FormHelperText>Campo obrigatório</FormHelperText>
                }
              </FormControl>
            </Grid>
            <Grid item xs={12} textAlign='center' mt={3}>
              <LoadingButton
                loading={loading}
                sx={{ width: 180 }}
                endIcon={<RiThumbUpLine />}
                variant='contained'
                color='success'
                onClick={() => {
                  if (validate() && movedToClientDialog.client) {
                    const updatedClient = {
                      ...movedToClientDialog.client,
                      Preco: newValues.preco,
                      CodigoCliente: newValues.codeOfClient,
                      MotivoDeGanho: newValues.motivo,
                      MotivoDePausa: '',
                      MotivoDePerda: '',
                      DataCadastro: new Date().toISOString()
                    };

                    setLoading(true);

                    handleWinClient(updatedClient)
                      .finally(() => setLoading(false));

                    setNewValues({ preco: null, codeOfClient: '', motivo: '' });
                  }
                }}
              >
                Ganhar
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog >
    </>
  )
}