const ClienteCustosValidation = (custos: any) => {
    // const [erros, setErros] = useState<string[]>([])
    let errorsList = <any>[]
    let errosCount = 0

    custos.forEach((custo: any, indexCusto: number) => {
        if (!(custo.Descricao.length > 0 && custo.GastoMensal > 0 && custo.Duracao > 0)) {
            errorsList.push(`Custo número ${indexCusto + 1} inválido.`)
            errosCount++
        }
    })

    return {
        isValid: errosCount === 0,
        errorDescriptions: errorsList
    }

}

export default ClienteCustosValidation