import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Table, TableBody, FormControlLabel, TableCell, TableRow, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem, Checkbox, TableHead, CircularProgress, ToggleButtonGroup, ToggleButton } from '@mui/material';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import PersonOffIcon from '@mui/icons-material/PersonOffOutlined';
import { useClientMirroringContext } from './context';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { IClientMirror, IUpdateClientMirror } from '../../../../Interface/interfaceClientMirror';
import { interfaceClient } from '../../../../Interface/interfaceClient';
import ClientMirrorService from '../../../../Services/ClientMirrorService';
import { enqueueSnackbar } from 'notistack';

export const DialogClientsMirror = () => {
  const {
    selectedUser, handleCloseDialog,
    clientsMirror, users,
    selectedUserToUser, setSelectedUserToUser,
    clientsMirrorToUser,
    loadingClientsMirror,
    loadingClientsMirrorToUser,
    resetSelectedUserToUser
  } = useClientMirroringContext();

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const clientMirrorService = new ClientMirrorService();
  const [searchText, setSearchText] = useState('');
  const [statusFiltered, setStatusFiltered] = useState<'CLIENTE' | 'OUTROS'>('CLIENTE');

  const [updateMirror, setUpdateMirror] = useState<IUpdateClientMirror>({
    Adicionados: { AssessorId: '', ClienteIds: [] },
    Removidos: { EspelhamentoIds: [] }
  });

  const handleToggleClient = (client: IClientMirror | interfaceClient, isEspelhado: boolean) => {
    let tempAdicionados = { ...updateMirror.Adicionados };
    let tempRemovidos = { ...updateMirror.Removidos };

    if (isEspelhado) {
      const index = tempRemovidos.EspelhamentoIds.indexOf(client.Id!);
      if (index > -1) {
        tempRemovidos.EspelhamentoIds.splice(index, 1);
      } else {
        tempRemovidos.EspelhamentoIds.push(client.Id!);
        tempAdicionados.ClienteIds = tempAdicionados.ClienteIds.filter(id => id !== client.Id);
      }
    } else {
      const index = tempAdicionados.ClienteIds.indexOf(client.Id!);
      if (index > -1) {
        tempAdicionados.ClienteIds.splice(index, 1);
      } else {
        tempAdicionados.ClienteIds.push(client.Id!);
        tempAdicionados.AssessorId = selectedUser?.Id!;
        tempRemovidos.EspelhamentoIds = tempRemovidos.EspelhamentoIds.filter(id => id !== client.Id);
      }
    }

    setUpdateMirror({
      Adicionados: tempAdicionados,
      Removidos: tempRemovidos
    });

    if (selectedUserToUser) {
      if (updateMirror.Removidos.EspelhamentoIds.length > 0) {
        setSelectAll(false);
      } else if (updateMirror.Adicionados.ClienteIds.length === sortedAndFilteredNaoEspelhadosToUser.length || !sortedAndFilteredNaoEspelhadosToUser.length) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }

    }
  };

  const sortedAndFilteredEspelhados = useMemo(() => {
    const sorted = [...(clientsMirror?.Espelhados || [])].sort((a, b) =>
      (a.Cliente?.NomeCompleto || '').localeCompare(b.Cliente?.NomeCompleto || '')
    );
    return sorted.filter(client =>
      client.Cliente?.NomeCompleto?.toLowerCase().includes(searchText.toLowerCase()) &&
      (statusFiltered === 'CLIENTE' ? client.Cliente?.Status === 'CLIENTE' : client.Cliente?.Status !== 'CLIENTE')
    );
  }, [clientsMirror?.Espelhados, searchText, statusFiltered]);

  const sortedAndFilteredEspelhadosToUser = useMemo(() => {
    const sorted = [...(clientsMirrorToUser?.Espelhados || [])].sort((a, b) =>
      (a.Cliente?.NomeCompleto || '').localeCompare(b.Cliente?.NomeCompleto || '')
    );
    return sorted.filter(client =>
      client.Cliente?.NomeCompleto?.toLowerCase().includes(searchText.toLowerCase()) &&
      (statusFiltered === 'CLIENTE' ? client.Cliente?.Status === 'CLIENTE' : client.Cliente?.Status !== 'CLIENTE')
    );
  }, [clientsMirrorToUser?.Espelhados, searchText, statusFiltered]);

  const sortedAndFilteredNaoEspelhadosToUser = useMemo(() => {
    const sorted = [...(clientsMirrorToUser?.NaoEspelhados || [])].sort((a, b) =>
      (a?.NomeCompleto || '').localeCompare(b?.NomeCompleto || '')
    );
    return sorted.filter(client =>
      client?.NomeCompleto?.toLowerCase().includes(searchText.toLowerCase()) &&
      (statusFiltered === 'CLIENTE' ? client?.Status === 'CLIENTE' : client?.Status !== 'CLIENTE')
    );
  }, [clientsMirrorToUser?.NaoEspelhados, searchText, statusFiltered]);

  const [selectAll, setSelectAll] = useState(false);

  const toggleSelectAll = () => {
    const activatingSelectAll = !selectAll;

    setSelectAll(activatingSelectAll);

    // IDs dos não espelhados
    const naoEspelhadosToUserIds = sortedAndFilteredNaoEspelhadosToUser.map(client => client.Id);

    // IDs dos espelhados
    const espelhadosToUserIds = sortedAndFilteredEspelhadosToUser.map(client => client.Id);

    if (activatingSelectAll) {
      // Se está ativando "Selecionar Tudo", adicione os não espelhados à lista de adição e limpe os removidos
      setUpdateMirror(prevState => ({
        Adicionados: {
          ...prevState.Adicionados,
          AssessorId: selectedUser?.Id,
          ClienteIds: Array.from(new Set([...prevState.Adicionados.ClienteIds, ...naoEspelhadosToUserIds]))
        },
        Removidos: {
          EspelhamentoIds: []
        }
      } as IUpdateClientMirror));
    } else {
      // Se está desativando "Selecionar Tudo", remova os não espelhados da lista de adição
      // e adicione os originalmente espelhados à lista de remoção
      setUpdateMirror(prevState => ({
        Adicionados: {
          ...prevState.Adicionados,
          ClienteIds: prevState.Adicionados.ClienteIds.filter(id => !naoEspelhadosToUserIds.includes(id)),
        },
        Removidos: {
          ...prevState.Removidos,
          EspelhamentoIds: Array.from(new Set([...prevState.Removidos.EspelhamentoIds, ...espelhadosToUserIds]))
        }
      } as IUpdateClientMirror));
    }
  };

  useEffect(() => {
    if (sortedAndFilteredNaoEspelhadosToUser.length === 0) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [clientsMirrorToUser, clientsMirror]);

  const isSaveButtonEnabled = updateMirror.Adicionados.ClienteIds.length > 0 || updateMirror.Removidos.EspelhamentoIds.length > 0;

  const handleResetInfos = () => {
    resetSelectedUserToUser();
    setUpdateMirror({
      Adicionados: { AssessorId: '', ClienteIds: [] },
      Removidos: { EspelhamentoIds: [] }
    });
    setSearchText('');
    setSelectAll(false);
    handleCloseDialog();
  }

  const openClientProfile = (clientId: string) => {
    let url = `/app/perfil-cliente/${clientId}`
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  const handleSaveUpdates = () => {
    let updateInfos: Partial<IUpdateClientMirror> = { ...updateMirror };

    if (updateMirror.Adicionados.ClienteIds.length === 0) {
      delete updateInfos.Adicionados;
    }
    if (updateMirror.Removidos.EspelhamentoIds.length === 0) {
      delete updateInfos.Removidos;
    }

    if (updateMirror.Adicionados.ClienteIds.length === 0 && updateMirror.Removidos.EspelhamentoIds.length === 0) {
      return;
    }

    setLoadingUpdate(true);
    clientMirrorService.UpdateAdvisorClientMirror(updateInfos)
      .then(() => {
        handleResetInfos();
      })
      .catch(err => {
        enqueueSnackbar('Ops... tivemos um problema.', { variant: 'error' });
        console.error(err);
      })
      .finally(() => {
        setLoadingUpdate(false);
      });
  }

  return (
    selectedUser ? (
      <Dialog open={!!selectedUser} onClose={handleCloseDialog} maxWidth='md' fullWidth>
        <DialogTitle component={Typography} variant='h4'>{selectedUser.NomeCompleto}</DialogTitle>
        <DialogContent dividers>
          <Box display='flex'>
            <FormControl size='small'>
              <InputLabel id='select-advisor-label'>Selecione um assessor</InputLabel>
              <Select
                sx={{ width: 200 }}
                labelId='select-advisor-label'
                id='select-advisor'
                value={selectedUserToUser ?? ''}
                label='Selecione um assessor'
                onChange={(event) => setSelectedUserToUser(event.target.value)}
              >
                <MenuItem value='' disabled>
                  <em>Selecione um assessor</em>
                </MenuItem>
                {users && users.length > 0 && users
                  .filter((user) => user.Id !== selectedUser.Id)
                  .map((user) => (
                    <MenuItem key={user.Id} value={user.Id}>
                      {user.NomeCompleto}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
              label='Pesquisar' size='small'
              sx={{ ml: 2 }}
              value={searchText}
              onChange={(event) => setSearchText(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              sx={{ ml: 2 }}
              disabled={searchText === '' && !selectedUserToUser}
              onClick={() => {
                resetSelectedUserToUser();
                setSelectedUserToUser('');
                setSearchText('');
                setSelectAll(false);
              }}>
              Limpar
            </Button>
            <Box flex={1}></Box>
            {
              selectedUserToUser && <FormControlLabel
                label='Selecionar tudo'
                control={<Checkbox
                  size='small'
                  checked={selectAll}
                  onChange={toggleSelectAll}
                  sx={{ ml: 2 }}
                />}
              />
            }
          </Box>

          <ToggleButtonGroup
            size='small'
            color='primary'
            value={statusFiltered}
            exclusive
            onChange={(event, newValue) => newValue ? setStatusFiltered(newValue) : null}
            sx={{ mt: 2 }}
          >
            <ToggleButton value='CLIENTE' sx={{ height: 30 }}>
              Clientes
            </ToggleButton>
            <ToggleButton value='OUTROS' sx={{ height: 30 }}>
              Outros
            </ToggleButton>
          </ToggleButtonGroup>

          <Table aria-label='Client Mirror Groups Table' size='small' sx={{ mt: 2.5 }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '300px' }}>
                  <Typography fontWeight='bold' textAlign='center'>
                    Nome
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight='bold' textAlign='center'>
                    Código
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight='bold' textAlign='center'>
                    Assessor
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight='bold' textAlign='center'>
                    Ações
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            {
              (loadingClientsMirror || loadingClientsMirrorToUser) ?
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                      <Box minHeight={150} display='flex' justifyContent='center' alignItems='center'>
                        <CircularProgress />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody> :
                <TableBody>
                  {
                    !selectedUserToUser && sortedAndFilteredEspelhados.map((client) => (
                      <TableRow key={client.Id}>
                        <TableCell onClick={() => openClientProfile(client.Cliente.Id!)}>
                          <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: 14, cursor: 'pointer' }}>
                            <PersonIcon />
                            <span style={{ marginLeft: '9px', fontSize: '14px', fontWeight: 'bold' }}>
                              {client.Cliente?.NomeCompleto}
                            </span>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ fontSize: 11 }}>{client.Cliente?.CodigoCliente ?? '-'}</TableCell>
                        <TableCell sx={{ fontSize: 11 }}>{client.Assessor?.NomeCompleto ?? '-'}</TableCell>
                        <TableCell padding='checkbox'>
                          <Checkbox
                            checked={!updateMirror.Removidos?.EspelhamentoIds.includes(client.Id)}
                            onChange={() => handleToggleClient(client, true)}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  }
                  {sortedAndFilteredEspelhadosToUser.map((client) => (
                    <TableRow key={client.Id}>
                      <TableCell onClick={() => openClientProfile(client.Cliente.Id!)}>
                        <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: 14, cursor: 'pointer' }}>
                          <PersonIcon />
                          <span style={{ marginLeft: '9px', fontSize: '14px', fontWeight: 'bold' }}>
                            {client.Cliente?.NomeCompleto}
                          </span>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontSize: 11 }}>{client.Cliente?.CodigoCliente ?? '-'}</TableCell>
                      <TableCell sx={{ fontSize: 11 }}>{clientsMirrorToUser?.Assessor.NomeCompleto ?? '-'}</TableCell>
                      <TableCell padding='checkbox'>
                        <Checkbox
                          checked={!updateMirror.Removidos?.EspelhamentoIds.includes(client.Id)}
                          onChange={() => handleToggleClient(client, true)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  {sortedAndFilteredNaoEspelhadosToUser.map((client) => (
                    <TableRow key={client.Id}>
                      <TableCell onClick={() => openClientProfile(client.Id!)}>
                        <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: 14, cursor: 'pointer' }}>
                          <PersonOffIcon />
                          <span style={{ marginLeft: '9px', fontSize: '14px', fontWeight: 'bold' }}>
                            {client?.NomeCompleto ?? '-'}
                          </span>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontSize: 11 }}>{client.CodigoCliente ?? '-'}</TableCell>
                      <TableCell sx={{ fontSize: 11 }}>{clientsMirrorToUser?.Assessor.NomeCompleto ?? '-'}</TableCell>
                      <TableCell padding='checkbox'>
                        <Checkbox
                          checked={updateMirror.Adicionados?.ClienteIds.includes(client.Id!)}
                          onChange={() => handleToggleClient(client, false)} // true, indicando espelhado
                        />
                      </TableCell>
                    </TableRow>
                  ))}

                  {
                    ((!selectedUserToUser && sortedAndFilteredEspelhados.length === 0) || (selectedUserToUser && sortedAndFilteredEspelhadosToUser.length === 0 && sortedAndFilteredNaoEspelhadosToUser.length === 0)) && <TableRow>
                      <TableCell colSpan={4}>
                        <Box minHeight={100} display='flex' alignItems='center' justifyContent='center'>
                          Nenhum cliente encontrado.
                        </Box>
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
            }
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResetInfos} color='secondary' size='small'>Cancelar</Button>
          <LoadingButton
            loading={loadingUpdate}
            disabled={!isSaveButtonEnabled}
            variant='contained' size='small'
            onClick={handleSaveUpdates}
          >
            Salvar
          </LoadingButton>
        </DialogActions>
      </Dialog >
    ) : null
  );
};
