import { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { IListServices, interfaceClient } from '../../../Interface/interfaceClient';
import { useParams } from 'react-router-dom';
import { interfaceRevenue } from '../../../Interface/interfaceRevenue';
import { interfaceTimeline } from '../../../Interface/interfaceTimeline';
import ClientesService from '../../../Services/ClientesService';
import StorageHelper from '../../../Services/Helpers/StorageHelper';
import ScheduleService from '../../../Services/ScheduleService';
import { interfaceSchedule } from '../../../Interface/intarfaceSchedule';
import moment from 'moment';
import { ClientesAtivosEBens } from '../../../Domain/ClientesAtivosEBens';
import { Box, CircularProgress } from '@mui/material';
import { clientRequests } from './requests';
import { formattingDateToISO } from './usefulFunctions';
import { IUser } from '../../../Interface/user';
import UserService from '../../../Services/UserService';
import { interfaceExpenses } from '../../../Interface/interfaceExpenses';

interface ClientContextType {
  client: interfaceClient | null;
  setClient: (client: interfaceClient | null) => void;
  updateClient: (newInfosOfClient: interfaceClient) => Promise<void>;
  listServices: IListServices[];
  timeline: interfaceTimeline[];
  addTimelineItem: (categoria: string, comentario: string) => Promise<void>;
  updateTimelineItem: (timelineId: string, newComment: string) => Promise<void>;
  deleteTimelineItem: (timelineId: string) => Promise<void>;
  activities: interfaceSchedule[];
  setActivities: (activities: interfaceSchedule[]) => void;
  selectedMonthYear: moment.Moment | undefined;
  setSelectedMonthYear: (monthYear: moment.Moment | undefined) => void;
  financialAssets: ClientesAtivosEBens[] | undefined;
  equityAssets: ClientesAtivosEBens[] | undefined;
  advisorOwner: IUser | undefined;
  changeOwner: (newOwnerId: string) => Promise<void>;
  toggleServiceStatus: (servico: IListServices) => Promise<void>;
  clientRevenue: interfaceRevenue | undefined;
  clientExpenses: interfaceExpenses | undefined;
  reloadClientData: () => Promise<void>;
}

const ClientContext = createContext<ClientContextType | undefined>(undefined);

export const ClientProvider = ({ children }: { children: ReactNode }) => {
  const userService = new UserService();
  let clientServices = new ClientesService();
  const scheduleService = new ScheduleService();
  const storageHelper = new StorageHelper();
  const assessorId = storageHelper.GetUsuarioId();
  const [client, setClient] = useState<interfaceClient | null>(null);
  const [listServices, setListServices] = useState<IListServices[]>([]);
  const [financialAssets, setFinancialAssets] = useState<ClientesAtivosEBens[]>();
  const [equityAssets, setEquityAssets] = useState<ClientesAtivosEBens[]>(); // 5
  const [clientRevenue, setClientRevenue] = useState<interfaceRevenue>(); // 6
  const [clientExpenses, setClientExpenses] = useState<interfaceExpenses>();
  const [timeline, setTimeline] = useState<interfaceTimeline[]>([]);
  const [activities, setActivities] = useState<interfaceSchedule[]>([]);
  const [selectedMonthYear, setSelectedMonthYear] = useState<moment.Moment | undefined>();
  const { userId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [advisorOwner, setAdvisorOwner] = useState<IUser>();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const values = await clientRequests(userId ?? '');
      const services = await clientServices.ListServices(userId ?? '');

      if (values[0] && values[0].AssessorUsuarioId) {
        userService.GetUserInfos(values[0].AssessorUsuarioId)
          .then(e => {
            setAdvisorOwner(e);
          })
          .catch(err => {
            console.error(err)
          })
      }

      setListServices(services);
      setClient(values[0]);
      setClientRevenue(values[3]);

      setClientExpenses(values[4]);
      setFinancialAssets(values[5]);
      setEquityAssets(values[6]);
      setTimeline(values[8]);

      const clientActivities = await scheduleService.ListarTarefasCliente(userId);
      setActivities(clientActivities);
    } catch (error) {
      console.error("Erro ao buscar dados do cliente:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const reloadClientData = async () => {
    await fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [userId]);

  const updateClient = async (newInfosOfClient: interfaceClient) => {
    try {
      const updatedClient = await clientServices.UpdateClientes(newInfosOfClient);
      setClient(updatedClient);
    } catch (error) {
      console.error("Erro ao atualizar cliente:", error);
    }
  };

  const addTimelineItem = async (categoria: string, comentario: string) => {
    let newTimelineItem: interfaceTimeline = {
      AssessorUsuarioId: assessorId,
      Categoria: categoria,
      Comentario: comentario,
      ClienteId: client?.Id ?? '',
      DeStatus: "",
      ParaStatus: "",
      CriadoEm: formattingDateToISO(new Date())
    }


    try {
      const addedItem = await clientServices.AddTimeline(newTimelineItem);
      setTimeline([addedItem, ...timeline]);
    } catch (error) {
    }
  };


  const updateTimelineItem = async (timelineId: string, newComment: string) => {
    try {
      await clientServices.UpdateTimeline(timelineId, newComment);
      setTimeline(timeline.map(item => item.Id === timelineId ? { ...item, Comentario: newComment } : item));
    } catch (error) {
      // Handle error
    }
  };

  const deleteTimelineItem = async (timelineId: string) => {
    try {
      await clientServices.DeleteTimeline(timelineId);
      setTimeline(timeline.filter(item => item.Id !== timelineId));
    } catch (error) {
      // Handle error
    }
  };

  const changeOwner = async (newOwnerId: string) => {
    if (client && client.Id) {
      try {
        await clientServices.UpdateOwnerCliente(client?.Id, newOwnerId);
        userService.GetUserInfos(newOwnerId)
          .then(e => {
            setAdvisorOwner(e);
          })
          .catch(err => {
            console.error(err)
          })
      } catch (err) {
        console.error(err)
      }

    }
  }

  const toggleServiceStatus = async (servico: IListServices) => {
    const { ServicoId, ClienteAtivo } = servico;
    const clientId = client?.Id;

    try {
      if (!ClienteAtivo && clientId) {
        // O serviço não está ativo, então ative-o
        const isSuccess = await clientServices.AddClientService({ ServicoId: ServicoId, ClienteId: clientId });
        if (isSuccess) {
          // Atualiza o estado do serviço existente na lista para refletir que agora está ativado
          setListServices(prevServices => prevServices.map(service =>
            service.ServicoId === ServicoId ? { ...service, ClienteAtivo: true, DataAdesao: new Date().toISOString() } : service
          ));
        } else {
          console.error("Não foi possível ativar o serviço.");
        }
      } else if (clientId) {
        // O serviço está ativo, então desative-o
        const isSuccess = await clientServices.DeleteClientService({ ServicoId, ClienteId: clientId });
        if (isSuccess) {
          // Atualiza o estado do serviço existente na lista para refletir que agora está desativado
          setListServices(prevServices => prevServices.map(service =>
            service.ServicoId === ServicoId ? { ...service, ClienteAtivo: false, DataAdesao: null } : service
          ));
        } else {
          console.error("Não foi possível desativar o serviço.");
        }
      }
    } catch (error) {
      console.error("Erro ao alterar o status do serviço:", error);
    }
  };

  return (
    <ClientContext.Provider value={{
      client, setClient, updateClient,
      advisorOwner, changeOwner,
      timeline, addTimelineItem, updateTimelineItem, deleteTimelineItem,
      activities, setActivities,
      selectedMonthYear, setSelectedMonthYear,
      financialAssets,
      equityAssets, listServices,
      toggleServiceStatus,
      clientRevenue,
      clientExpenses,
      reloadClientData
    }}>
      {isLoading ? <LoadingIndicator /> : children}
    </ClientContext.Provider>
  );
};

export const useClientContext = () => {
  const context = useContext(ClientContext);

  if (context === undefined) {
    throw new Error('useClientContext must be used within a ClientProvider');
  }

  return context;
};

const LoadingIndicator = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70vh',
      }}
    >
      <CircularProgress />
    </Box>
  );
};