import { interfaceSchedule } from "../../../Interface/intarfaceSchedule";
import ScheduleService from "../../../Services/ScheduleService";
import { transformDateToUTC } from "../Helpers/transformDateToUTC";

let requestsTasks = new ScheduleService()

export async function createTask(task: interfaceSchedule) {
  delete task.ClienteNome
  delete task.AtividadeSemData
  delete task.Id
  delete task.NewDescriptionTemp

  if (task.DataEvento) task.DataEvento = transformDateToUTC(task.DataEvento!);
  if (task.DataTermino) task.DataTermino = transformDateToUTC(task.DataTermino!);

  return requestsTasks.CreateTask(task)
}

export async function createTaskIfNotExists(task: interfaceSchedule) {
  delete task.ClienteNome
  delete task.AtividadeSemData
  delete task.Id
  delete task.NewDescriptionTemp

  if (task.DataEvento) task.DataEvento = transformDateToUTC(task.DataEvento!);
  if (task.DataTermino) task.DataTermino = transformDateToUTC(task.DataTermino!);

  return requestsTasks.CreateTaskIfNotExists(task)
}

export async function updateTask(task: interfaceSchedule) {
  delete task.ClienteNome
  delete task.AtividadeSemData
  delete task.NewDescriptionTemp

  if (task.DataEvento) task.DataEvento = transformDateToUTC(task.DataEvento!);
  if (task.DataTermino) task.DataTermino = transformDateToUTC(task.DataTermino!);
  if (task.AtividadeFinalizadaData) task.AtividadeFinalizadaData = transformDateToUTC(task.AtividadeFinalizadaData!);

  if (task.Id == "" || !task.Id) {
    throw "Error: task without Id"
  }

  return requestsTasks.UpdateTask(task)
}