import { useState, useEffect, useCallback } from 'react';
import PermissionsService from '../Services/PermissionsService';
import { IUser } from '../Interface/user';
import { enqueueSnackbar } from 'notistack';

export const useInsuranceUsers = () => {
  const [insuranceUsers, setInsuranceUsers] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  const fetchInsuranceUsers = useCallback(async () => {
    setLoading(true);
    try {
      const service = new PermissionsService();
      const users = await service.ListInsuranceUsers();
      setInsuranceUsers(users);
    } catch (err) {
      setError(err);
      enqueueSnackbar('Ops... tivemos um problema ao carregar os usuários de seguros.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (insuranceUsers.length === 0) {
      fetchInsuranceUsers();
    }
  }, [fetchInsuranceUsers, insuranceUsers.length]);

  return {
    insuranceUsers,
    loading,
    error,
    refetch: fetchInsuranceUsers
  };
};