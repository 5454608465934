import { Box, Divider, Grid, ListItem, ListItemText, Tooltip, Typography } from "@mui/material";
import { useClientContext } from "../ClientContext"
import { formatCurrencyBRL } from "../../../../Helpers/formatters";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import LocalAtmIcon from '@mui/icons-material/LocalAtmOutlined';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

export const PatrimonyData = () => {
  const { financialAssets, equityAssets } = useClientContext();

  return <>
    <Grid container>
      <Grid item xs={12} md={5} container justifyContent='center'>
        <Grid item xs={12}>
          <Typography variant="h5" textAlign='center'>
            Ativos Financeiros
          </Typography>
        </Grid>
        {financialAssets && financialAssets.length > 0 ? (
          financialAssets?.map((equityAsset, index) => (
            <Tooltip title={equityAsset.Comentario ? equityAsset.Comentario : ''}>
              <Grid item xs={6} key={index}>
                <Box
                  sx={{
                    py: 1,
                    px: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    boxShadow: 1,
                    borderRadius: 2,
                    textAlign: 'center',
                    m: 1,
                  }}
                >
                  <LocalAtmIcon sx={{ mb: 1, color: 'text.secondary' }} />
                  <ListItem sx={{ p: 0 }}>
                    <ListItemText
                      sx={{ textAlign: 'center' }}
                      primary={<Typography fontSize={12} color="text.secondary">{equityAsset.Ativo}</Typography>}
                      secondary={
                        <Typography sx={{ fontSize: 15, fontWeight: 'bold' }}>
                          {equityAsset.Valor ? formatCurrencyBRL(equityAsset.Valor) : '-'}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Box>
              </Grid>
            </Tooltip>
          ))
        ) : noAssetsMessage}
      </Grid>

      <Grid item xs={2} display='flex' justifyContent='center'>
        <Divider orientation="vertical" />
      </Grid>

      <Grid item xs={12} md={5} container justifyContent='center'>
        <Grid item xs={12}>
          <Typography variant="h5" textAlign='center'>
            Ativos Patrimoniais
          </Typography>
        </Grid>
        {equityAssets && equityAssets.length > 0 ? (
          equityAssets?.map((equityAsset, index) => (
            <Grid item xs={6} key={index}>
              <Box
                sx={{
                  py: 1,
                  px: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  boxShadow: 1,
                  borderRadius: 2,
                  textAlign: 'center',
                  m: 1,
                }}
              >
                <AccountBalanceWalletIcon sx={{ mb: 1, color: 'text.secondary' }} /> {/* Ícone */}
                <ListItem sx={{ p: 0 }}>
                  <ListItemText
                    sx={{ textAlign: 'center' }}
                    primary={<Typography fontSize={12} color="text.secondary">{equityAsset.Ativo}</Typography>}
                    secondary={
                      <Typography sx={{ fontSize: 15, fontWeight: 'bold' }}>
                        {equityAsset.Valor ? formatCurrencyBRL(equityAsset.Valor) : '-'}
                      </Typography>
                    }
                  />
                </ListItem>
              </Box>
            </Grid>
          ))) : noAssetsMessage}

      </Grid>
    </Grid>
  </>
}

export const noAssetsMessage = (
  <Typography sx={{ color: 'text.secondary', textAlign: 'center', opacity: 0.5 }}>
    <SentimentDissatisfiedIcon sx={{ fontSize: 50, mb: 1 }} />
    <br />
    Nenhum ativo cadastrado.
  </Typography>
);