import React, { useEffect, useState } from 'react';
import { TextField, MenuItem, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel, Grid, Autocomplete } from '@mui/material';
import { INegocioCambioRequest } from '../../../../../Interface/interfaceNegocios';
import StorageHelper from '../../../../../Services/Helpers/StorageHelper';
import { useParams } from 'react-router-dom';
import { NumberFormatCustom } from '../../../../../Helpers/Components';
import { LoadingButton } from '@mui/lab';
import UserService from '../../../../../Services/UserService';
import { enqueueSnackbar } from 'notistack';

interface FormCambioProps {
  initialData?: INegocioCambioRequest;
  onSubmit: (data: INegocioCambioRequest) => Promise<void>;
  onClose: () => void;
}

const availableMoedas = ['USD', 'BRL', 'EUR'];

export const FormCambio: React.FC<FormCambioProps> = ({ initialData, onSubmit, onClose }) => {
  const userService = new UserService();
  const [users, setUsers] = useState<{ id: string, label: string }[]>([]);
  const storageHelper = new StorageHelper();
  const { userId: clientId } = useParams();
  const [loading, setLoading] = useState(false);
  const [vendedorError, setVendedorError] = useState<boolean>(false);

  const uid = storageHelper.GetUsuarioId();

  const [negocioCambio, setNegocioCambio] = useState<INegocioCambioRequest>(
    initialData || {
      Negocio: {
        AssessorId: uid,
        ClienteId: clientId,
        Status: 'LEAD',
        DataInicio: new Date(),
        DataFechamento: undefined,
        VendedorId: '',
        ReceitaBruta: 0,
        ReceitaEscritorio: 0,
        Expectativa: 'Alta',
        Descricao: ''
      },
      Cambio: {
        ValorReais: null,
        Moeda: 'USD',
        ValorME: 0,
        TaxaCliente: '',
        Spread: 0,
        Parceiro: '',
        Produto: '',
        PaisParceiro: '',
        PessoaFisica: true,
        SaidaFiscal: true
      }
    }
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    if (name in negocioCambio.Negocio) {
      setNegocioCambio(prevState => ({
        ...prevState,
        Negocio: {
          ...prevState.Negocio,
          [name]: value
        }
      }));
    } else if (name in negocioCambio.Cambio) {
      setNegocioCambio(prevState => ({
        ...prevState,
        Cambio: {
          ...prevState.Cambio,
          [name]: value
        }
      }));
    }
  };

  const handleMoedaChange = (event: any, newValue: string | null) => {
    setNegocioCambio(prevState => ({
      ...prevState,
      Cambio: {
        ...prevState.Cambio,
        Moeda: newValue || ''
      }
    }));
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const booleanValue = value === 'true';

    setNegocioCambio(prevState => ({
      ...prevState,
      Cambio: {
        ...prevState.Cambio,
        [name]: booleanValue
      }
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!negocioCambio.Negocio.VendedorId) {
      setVendedorError(true);
      enqueueSnackbar('Selecione um dono do negócio!', { variant: 'error' });
      return;
    }

    setLoading(true);

    try {
      await onSubmit(negocioCambio);
      onClose();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchUsersByPermission = async () => {
      try {
        const fetchedUsers = await userService.ListUsersByPermissions(['Câmbio', 'AdminCâmbio']);
        const formattedUsers = fetchedUsers.map(user => ({
          id: user.Id,
          label: user.NomeCompleto,
        }));

        setUsers([...formattedUsers]);
      } catch (error) {
        enqueueSnackbar('Erro ao buscar usuários.', { variant: 'error' });
      }
    };

    fetchUsersByPermission()
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {(!initialData?.Negocio.Id || initialData.Negocio.VendedorId === uid) && <Grid item xs={12} textAlign='center' sx={{ mb: 1 }}>
          <Autocomplete
            options={users}
            getOptionLabel={(option) => option.label}
            onChange={(event, newValue) => {
              setNegocioCambio((prevState) => ({
                ...prevState,
                Negocio: {
                  ...prevState.Negocio,
                  VendedorId: newValue ? newValue.id : '',
                },
              }));
              setVendedorError(false);
            }}
            renderInput={(params) => (
              <TextField
                sx={{ width: 200 }}
                {...params}
                label="Dono do Negócio"
                variant="outlined"
                size="small"
                error={vendedorError}
                helperText={vendedorError ? 'Campo obrigatório' : ''}
              />
            )}
            value={users.find(user => user.id === negocioCambio.Negocio.VendedorId) || null}
            noOptionsText="Nenhum usuário encontrado"
          />
        </Grid>}
        <Grid item xs={6}>
          <TextField
            label="Volume Operado"
            fullWidth size='small'
            variant="outlined"
            autoComplete='off'
            value={negocioCambio.Cambio.ValorReais}
            onChange={(event) => {
              negocioCambio.Cambio.ValorReais = Number(event.target.value);
              setNegocioCambio({ ...negocioCambio });
            }}
            InputProps={{
              inputComponent: NumberFormatCustom as any,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <Autocomplete
            freeSolo
            options={availableMoedas}
            value={negocioCambio.Cambio.Moeda}
            onChange={handleMoedaChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Moeda"
                name="Moeda"
                fullWidth size='small'
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            name="PaisParceiro"
            label="País Parceiro"
            fullWidth size='small'
            variant="outlined"
            value={negocioCambio.Cambio.PaisParceiro}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            select
            name="Expectativa"
            label="Expectativa"
            value={negocioCambio.Negocio.Expectativa}
            onChange={handleInputChange}
            fullWidth size='small'
            variant="outlined"
          >
            <MenuItem value="Alta">Alta</MenuItem>
            <MenuItem value="Media">Média</MenuItem>
            <MenuItem value="Baixa">Baixa</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={6} textAlign='center'>
          <FormControl component="fieldset">
            <FormLabel component="legend">É Pessoa Física?</FormLabel>
            <RadioGroup
              row
              name="PessoaFisica"
              value={negocioCambio.Cambio.PessoaFisica.toString()}
              onChange={handleRadioChange}
            >
              <FormControlLabel value="true" control={<Radio />} label="Sim" />
              <FormControlLabel value="false" control={<Radio />} label="Não" />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={6} textAlign='center'>
          <FormControl component="fieldset">
            <FormLabel component="legend">Deu Saída Fiscal?</FormLabel>
            <RadioGroup
              row
              name="SaidaFiscal"
              value={negocioCambio.Cambio.SaidaFiscal.toString()}
              onChange={handleRadioChange}
            >
              <FormControlLabel value="true" control={<Radio />} label="Sim" disabled={!negocioCambio.Cambio.PessoaFisica} />
              <FormControlLabel value="false" control={<Radio />} label="Não" disabled={!negocioCambio.Cambio.PessoaFisica} />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="Descricao"
            label="Descrição"
            multiline
            rows={6}
            fullWidth size='small'
            variant="outlined"
            value={negocioCambio.Negocio.Descricao}
            inputProps={{
              maxLength: 800
            }}
            onChange={handleInputChange}
            helperText={`${negocioCambio.Negocio.Descricao.length}/800 caracteres restantes`}
          />
        </Grid>

        <Grid item xs={12}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            loading={loading}
          >
            {initialData?.Negocio.Id ? 'Salvar Negócio' : 'Criar Negócio de Câmbio'}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};