import React, { useEffect, useState } from 'react';
import {
  TextField,
  MenuItem,
  Grid,
  Autocomplete,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { INegocioCreditoRequest } from '../../../../../Interface/interfaceNegocios';
import StorageHelper from '../../../../../Services/Helpers/StorageHelper';
import { useParams } from 'react-router-dom';
import { NumberFormatCustom } from '../../../../../Helpers/Components';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import UserService from '../../../../../Services/UserService';

interface FormCreditoProps {
  initialData?: INegocioCreditoRequest;
  onSubmit: (data: INegocioCreditoRequest) => Promise<void>;
  onClose: () => void;
}

export const FormCredito: React.FC<FormCreditoProps> = ({
  initialData,
  onSubmit,
  onClose,
}) => {
  const storageHelper = new StorageHelper();
  const userService = new UserService();
  const { userId: clientId } = useParams();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<{ id: string; label: string }[]>([]);
  const [vendedorError, setVendedorError] = useState<boolean>(false);

  const [negocioCredito, setNegocioCredito] = useState<INegocioCreditoRequest>(
    initialData || {
      Negocio: {
        AssessorId: storageHelper.GetUsuarioId(),
        ClienteId: clientId,
        Status: 'LEAD',
        DataInicio: new Date(),
        DataFechamento: undefined,
        VendedorId: '',
        ReceitaBruta: 0,
        ReceitaEscritorio: 0,
        Expectativa: 'Alta',
        Descricao: '',
      },
      Credito: {
        PessoaFisica: false,
        Volume: null,
        Modalidade: '',
      },
    }
  );

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (name in negocioCredito.Negocio) {
      setNegocioCredito((prevState) => ({
        ...prevState,
        Negocio: {
          ...prevState.Negocio,
          [name]: value,
        },
      }));
    } else if (name in negocioCredito.Credito) {
      setNegocioCredito((prevState) => ({
        ...prevState,
        Credito: {
          ...prevState.Credito,
          [name]: value,
        },
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!negocioCredito.Negocio.VendedorId) {
      setVendedorError(true);
      enqueueSnackbar('Selecione um dono do negócio!', { variant: 'error' });
      return;
    }

    setLoading(true);

    try {
      await onSubmit(negocioCredito);
      onClose();
    } catch (error) {
      enqueueSnackbar('Erro ao salvar o negócio de crédito.', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const fetchedUsers = await userService.ListUsersByPermissions([
        'Crédito',
        'AdminCrédito',
      ]);
      const formattedUsers = fetchedUsers.map((user) => ({
        id: user.Id,
        label: user.NomeCompleto,
      }));

      setUsers(formattedUsers);

      if (formattedUsers.length === 1) {
        setNegocioCredito((prevState) => ({
          ...prevState,
          Negocio: {
            ...prevState.Negocio,
            VendedorId: formattedUsers[0].id,
          },
        }));
      }
    } catch (error) {
      enqueueSnackbar('Erro ao buscar usuários.', { variant: 'error' });
    }
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const booleanValue = value === 'true';

    setNegocioCredito(prevState => ({
      ...prevState,
      Credito: {
        ...prevState.Credito,
        [name]: booleanValue
      }
    }));
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12} textAlign='center' sx={{ mb: 1 }}>
          <Autocomplete
            options={users}
            getOptionLabel={(option) => option.label}
            onChange={(event, newValue) => {
              setNegocioCredito((prevState) => ({
                ...prevState,
                Negocio: {
                  ...prevState.Negocio,
                  VendedorId: newValue ? newValue.id : '',
                },
              }));
              setVendedorError(false);
            }}
            renderInput={(params) => (
              <TextField
                sx={{ width: 300 }}
                {...params}
                label='Especialista'
                variant='outlined'
                size='small'
                error={vendedorError}
                helperText={vendedorError ? 'Campo obrigatório' : ''}
              />
            )}
            value={
              users.find(
                (user) => user.id === negocioCredito.Negocio.VendedorId
              ) || null
            }
            noOptionsText='Nenhum usuário encontrado'
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label='Valor financeiro'
            name='Valor financeiro'
            value={negocioCredito.Credito.Volume}
            onChange={(e) =>
              setNegocioCredito((prevState) => ({
                ...prevState,
                Credito: {
                  ...prevState.Credito,
                  Volume: Number(e.target.value),
                },
              }))
            }
            InputProps={{
              inputComponent: NumberFormatCustom as any,
            }}
            fullWidth
            size='small'
            variant='outlined'
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            select
            name='Modalidade'
            label='Modalidade'
            value={negocioCredito.Credito.Modalidade}
            onChange={handleInputChange}
            fullWidth
            size='small'
            variant='outlined'
          >
            <MenuItem value='Financiamento Imobiliário'>Financiamento imobiliário</MenuItem>
            <MenuItem value='Capital de Giro'>Capital de giro</MenuItem>
            <MenuItem value='Liquidez'>Liquidez</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField
            select
            name='Expectativa'
            label='Expectativa'
            value={negocioCredito.Negocio.Expectativa}
            onChange={handleInputChange}
            fullWidth
            size='small'
            variant='outlined'
          >
            <MenuItem value='Alta'>Alta</MenuItem>
            <MenuItem value='Media'>Média</MenuItem>
            <MenuItem value='Baixa'>Baixa</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={6} textAlign='center'>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>É Pessoa Física?</FormLabel>
            <RadioGroup
              row
              name='PessoaFisica'
              value={negocioCredito.Credito.PessoaFisica?.toString()}
              onChange={handleRadioChange}
            >
              <FormControlLabel value='true' control={<Radio />} label='Sim' />
              <FormControlLabel value='false' control={<Radio />} label='Não' />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            name='Descricao'
            label='Descrição'
            multiline
            rows={6}
            fullWidth
            size='small'
            variant='outlined'
            value={negocioCredito.Negocio.Descricao}
            inputProps={{
              maxLength: 800,
            }}
            onChange={handleInputChange}
            helperText={`${negocioCredito.Negocio.Descricao.length}/800 caracteres restantes`}
          />
        </Grid>

        <Grid item xs={12}>
          <LoadingButton
            type='submit'
            variant='contained'
            color='primary'
            fullWidth
            loading={loading}
          >
            {initialData?.Negocio.Id
              ? 'Salvar Negócio'
              : 'Criar Negócio de Crédito'}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
