import { useState } from "react"
import LoginService from "../../../Services/LoginService";
import { AlterarSenhaRequest } from "../../../Domain/DTO/Request/AlterarSenhaRequest";
import { Navigate } from 'react-router';
import Loader from "../Loader";
import { Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const AlterarSenhaComponent = ({ codigo }: any) => {
  const loginService = new LoginService();

  const [erro, setErro] = useState("")
  const [loading, setLoading] = useState<boolean>(false)
  const [sucesso, setSucesso] = useState(false);

  const [alterarSenhaData, setAlterarSenhaData] = useState<AlterarSenhaRequest>({
    Senha: '',
    RepetirSenha: '',
    Codigo: codigo
  });

  const onChangeInput = (campo: any, value: string) => {
    setAlterarSenhaData({ ...alterarSenhaData, [campo]: value })
  }



  const onClickSubmit = () => {
    setLoading(true)

    // Password
    const vryPassword = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    const passwordValid = vryPassword.test(String(alterarSenhaData.Senha));
    // Checking password
    if (!alterarSenhaData.Senha || alterarSenhaData.Senha.trim() == '') {
      setErro('A senha não pode ser vazia.')
      setLoading(false)
    } else if (!alterarSenhaData.RepetirSenha || alterarSenhaData.RepetirSenha.trim() == '') {
      setErro('O repetir senha não pode ser vazio.')
      setLoading(false)
    } else if (alterarSenhaData.RepetirSenha.trim() != '' && (alterarSenhaData.Senha !== alterarSenhaData.RepetirSenha)) {
      setErro('As senhas informadas não coincidem.')
      setLoading(false)
    } else if (passwordValid == false) {
      setErro('A senha deve conter no mínimo oito caracteres, pelo menos uma letra, um número e um caractere especial.')
      setLoading(false)
    } else {
      loginService
        .AlterarSenha(alterarSenhaData)
        .then(() => {
          setSucesso(true)
        })
        .catch((erro: Error) => setErro(''))
        .finally(() => setLoading(false))
    }
  }

  if (sucesso)
    return <Navigate to="/#senha-alterada" />

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} lg={6}>
          <TextField label="Nova senha" fullWidth variant="outlined" margin="dense"
            type='password'
            size="small"
            onChange={(target) => onChangeInput('Senha', target.target.value)}
            value={alterarSenhaData.Senha}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            label="Repetir nova senha" fullWidth variant="outlined" margin="dense"
            type='password'
            size="small"
            onChange={(target) => onChangeInput('RepetirSenha', target.target.value)}
            value={alterarSenhaData.RepetirSenha}
          />
        </Grid>
        <Grid item xs={12} textAlign='center' mb={2}>
          <LoadingButton
            fullWidth
            loading={loading}
            onClick={() => onClickSubmit()}
            variant="contained"
          >
            Criar nova senha
          </LoadingButton>
        </Grid>
      </Grid>

      {/* <div className="row">
        <form>
          <div className="input-field col s12">
            <input id="senha1" type="password" name="senha1" onChange={(target) => onChangeInput('Senha', target.target.value)} />
            <label htmlFor="senha1">Senha</label>
          </div>
          <div className="input-field col s12">
            <input id="senha2" type="password" name="senha2" onChange={(target) => onChangeInput('RepetirSenha', target.target.value)} />
            <label htmlFor="senha2">Repetir Senha</label>
          </div>
          <div className={erro != '' ? "input-erros col s12" : "hide"}>
            <ul>
              <li>
                {erro}
              </li>
            </ul>
          </div>
          <div className="col s12 align-center submit">
            <a className="btn primary" onClick={() => onClickSubmit()}>
              {
                loading
                  ?
                  <Loader />
                  :
                  <>Alterar Senha</>
              }
            </a>
          </div>
        </form>
      </div> */}
    </>
  )
}

export default AlterarSenhaComponent;