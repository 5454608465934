import { AUTH_HEADER } from "./Helpers/Headers";
import { IListAdvisorClientMirror, IListClientsMirror, IUpdateClientMirror } from "../Interface/interfaceClientMirror";

export default class ClientMirrorService {
  private readonly API_URL = process.env.REACT_APP_API_URL;

  private async handleErrors(response: Response): Promise<any> {
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      try {
        return await response.json();
      } catch (error) {
        return null;
      }
    } else {
      return null;
    }
  }

  public async ListClientsMirror(assessorId: string): Promise<IListClientsMirror> {
    return await fetch(`${this.API_URL}/espelhamentoIndividual/${assessorId}/GetClientesEspelhados`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(err);
      });
  }

  public async ListAdvisorClientMirror(assessorId: string, assessorEspelhadoId: string): Promise<IListAdvisorClientMirror> {
    return await fetch(`${this.API_URL}/espelhamentoIndividual/${assessorId}/GetClientesEspelhadosPorAssessor/${assessorEspelhadoId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(err);
      });
  }

  public async UpdateAdvisorClientMirror(updateInfos: Partial<IUpdateClientMirror>): Promise<void> {
    return await fetch(`${this.API_URL}/espelhamentoIndividual/Update`, {
      method: 'POST',
      headers: AUTH_HEADER(),
      body: JSON.stringify(updateInfos)
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(err);
      });
  }
}