import { styled, Tooltip } from '@mui/material';

const PulsingFeature = styled('div')(({ theme }) => ({
  animation: 'pulse 2s infinite',
  fontWeight: 'bold',
  color: theme.palette.secondary.main,
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
      opacity: 1,
    },
    '50%': {
      transform: 'scale(1.3)',
      opacity: 0.7,
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 1,
    },
  },
}));

export const NewFeatureIcon: React.FC<{ title: string }> = ({ title }) => (
  <Tooltip title={<span style={{fontSize: 14}}>{title}</span>} placement="top" arrow>
    <PulsingFeature>
      NEW!
    </PulsingFeature>
  </Tooltip>
);