import React from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Box, Typography, Container, Autocomplete, TextField, Stack } from '@mui/material';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { KanbanColumnCambio } from './KanbanColumn';
import { KanbanSearchInputCambio } from './SearchInput';

interface KanbanBoardCambioProps {
  isDraggable?: boolean;
  loading: boolean;
  onDragEnd?: (result: DropResult) => void;
  filteredKanbanList: any;
  users?: { id: string; label: string }[];
  setUsuarioId?: (id: string | null) => void;
  usuarioId?: string | null;
  isAdminCambio?: boolean;
  showSearchInput?: boolean;
}

export const KanbanBoardCambio: React.FC<KanbanBoardCambioProps> = ({
  isDraggable = true,
  loading,
  onDragEnd,
  filteredKanbanList,
  users,
  setUsuarioId,
  usuarioId,
  isAdminCambio,
  showSearchInput = false,
}) => {
  return (
    <Container maxWidth='xl'>
      <Box my={2} display='flex' flexDirection='column'>
        <Typography variant='h3' my={1}>
          Funil comercial de Câmbio
        </Typography>
        <Stack
          alignItems='center'
          direction='row'
          spacing={6}
          width='max-content'
          sx={{
            px: 5,
            backgroundColor: '#fff',
            borderRadius: 1,
            height: '80px',
            position: 'relative',
          }}
        >
          <Stack direction='row' spacing={1} alignItems='center'>
            <WorkOutlineIcon sx={{ color: '#bcbbce' }} />
            <Typography variant='h5'>
              {Object.values(filteredKanbanList).reduce(
                (acc: number, column: any) => acc + column.data.length,
                0
              )}
            </Typography>
          </Stack>
          {(isAdminCambio && users && setUsuarioId) && (
            <Autocomplete
              size='small'
              options={users}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField {...params} label='Selecione um usuário' variant='outlined' />
              )}
              value={users.find((user) => user.id === usuarioId) || null}
              noOptionsText='Nenhuma opção disponível'
              onChange={(event, newValue) => {
                setUsuarioId(newValue ? newValue.id : null);
              }}
              sx={{ width: 230, mb: 2 }}
            />
          )}
        </Stack>
      </Box>
      <Box
        sx={{
          backgroundColor: '#ffffff',
          borderRadius: 1,
          width: 'max-content',
        }}
      >
        <Box
          sx={{
            pl: 3,
            pt: 3,
          }}
        >
          {showSearchInput && <KanbanSearchInputCambio />}
        </Box>
        <DragDropContext onDragEnd={isDraggable && onDragEnd ? onDragEnd : () => { }}>
          <Box display='flex' overflow='auto' p={2}>
            {Object.entries(filteredKanbanList).map(([status, column]) => {
              const typedColumn = column as { name: string; data: any[] };
              return (
                <KanbanColumnCambio
                  key={status}
                  status={status}
                  columnName={typedColumn.name}
                  negocios={typedColumn.data}
                  loading={loading}
                  isDraggable={isDraggable}
                />
              );
            })}
          </Box>
        </DragDropContext>
      </Box>
    </Container>
  );
};
