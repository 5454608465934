import { useState } from 'react';
import { read, utils } from 'xlsx';
import ClientesService from '../Services/ClientesService';
import { ReassignClientRequest } from '../Domain/DTO/Clients';

export const useReassignClients = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [failedClients, setFailedClients] = useState<string[]>([]);

  const handleFileUpload = async (file: File) => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    setFailedClients([]);

    try {
      const data = await file.arrayBuffer();
      const workbook = read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = utils.sheet_to_json<{ 'Conta Xp': string; 'AssessorDestino': string }>(worksheet);

      const requests: ReassignClientRequest[] = jsonData.map((row) => ({
        codigoCliente: String(row['Conta Xp']).replace(/\D/g, ''), // Remove letras
        codigoAssessor: String(row['AssessorDestino']).replace(/\D/g, ''), // Remove letras
      }));

      await new ClientesService().ReassignClients(requests);

      setSuccess('Tombamento concluído com sucesso!');
    } catch (e: any) {
      if (e.details) {
        setFailedClients(e.details);
        setError(e.message || 'Erro ao processar a solicitação.');
      } else {
        setError(e.message ?? 'Erro desconhecido');
      }
    } finally {
      setLoading(false);
    }
  };

  return { handleFileUpload, loading, error, success, failedClients };
};