import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import ClientMirrorService from '../../../../Services/ClientMirrorService';
import StorageHelper from '../../../../Services/Helpers/StorageHelper';
import { IListAdvisorClientMirror, IListClientsMirror } from '../../../../Interface/interfaceClientMirror';
import { IUser } from '../../../../Interface/user';
import UserService from '../../../../Services/UserService';

const ClientMirroringContext = createContext<ClientMirroringContextType | undefined>(undefined);

interface StateProviderProps {
  children: ReactNode;
}

interface ClientMirroringContextType {
  clientsMirror: IListClientsMirror | undefined;
  clientsMirrorToUser: IListAdvisorClientMirror | undefined;
  selectedUser: IUser | undefined;
  setSelectedUser: (user: IUser | undefined) => void;
  selectedUserToUser: string | undefined;
  setSelectedUserToUser: (userId: string | undefined) => void;
  users: IUser[] | undefined;
  loadingUsers: boolean;
  loadingClientsMirror: boolean;
  loadingClientsMirrorToUser: boolean;
  handleCloseDialog: () => void;
  resetSelectedUserToUser: () => void;
}

const ClientMirroringProvider: React.FC<StateProviderProps> = ({ children }) => {
  const clientMirrorService = new ClientMirrorService();
  const storageHelper = new StorageHelper();
  const assessorId = storageHelper.GetUsuarioId();
  const userService = new UserService();

  const [users, setUsers] = useState<IUser[]>();
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingClientsMirror, setLoadingClientsMirror] = useState(false);
  const [loadingClientsMirrorToUser, setLoadingClientsMirrorToUser] = useState(false);

  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [selectedUserToUser, setSelectedUserToUser] = useState<string>();
  const [clientsMirror, setClientsMirror] = useState<IListClientsMirror>();
  const [clientsMirrorToUser, setClientsMirrorToUser] = useState<IListAdvisorClientMirror>();

  useEffect(() => {
    setLoadingClientsMirror(true);
    clientMirrorService.ListClientsMirror(assessorId)
      .then((response) => {
        setClientsMirror(response);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoadingClientsMirror(false);
      });

    const fetchUsers = async () => {
      setLoadingUsers(true);
      try {
        const users = await userService.GetActiveUsers();
        const sortedUsers = users.sort((a, b) => a.NomeCompleto.localeCompare(b.NomeCompleto));

        setUsers(sortedUsers);
      } catch (err) {
        console.error(err);
      } finally {
        setLoadingUsers(false);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    if (selectedUser && selectedUser.Id) {
      setLoadingClientsMirror(true);
      clientMirrorService.ListClientsMirror(selectedUser?.Id)
        .then((response) => {
          setClientsMirror(response);
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setLoadingClientsMirror(false);
        });
    }
  }, [selectedUser]);

  useEffect(() => {
    if (selectedUser && selectedUser.Id && selectedUserToUser) {
      setLoadingClientsMirrorToUser(true);
      clientMirrorService.ListAdvisorClientMirror(selectedUser.Id, selectedUserToUser)
        .then((response) => {
          setClientsMirrorToUser(response);
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setLoadingClientsMirrorToUser(false);
        });
    }
  }, [selectedUserToUser]);

  const handleCloseDialog = () => {
    setClientsMirror(undefined);
    setClientsMirrorToUser(undefined);
    setSelectedUserToUser(undefined);
    setSelectedUser(undefined);
  }

  const resetSelectedUserToUser = () => {
    setSelectedUserToUser(undefined);
    setClientsMirrorToUser(undefined);
  };

  return (
    <ClientMirroringContext.Provider value={{
      clientsMirror,
      selectedUser,
      setSelectedUser,
      selectedUserToUser,
      setSelectedUserToUser,
      users,
      loadingUsers,
      loadingClientsMirror,
      loadingClientsMirrorToUser,
      handleCloseDialog,
      clientsMirrorToUser,
      resetSelectedUserToUser
    }}>
      {children}
    </ClientMirroringContext.Provider>
  );
};

const useClientMirroringContext = () => {
  const context = useContext(ClientMirroringContext);
  if (context === undefined) {
    throw new Error('useUsersApprovalContext must be used within a StateProvider');
  }
  return context;
};

export { ClientMirroringProvider, useClientMirroringContext };