import { AUTH_HEADER } from "./Helpers/Headers";
import { IListUsersByPermissions, IListUsersGroups, IUpdateStatusUser } from "../Interface/interfaceUsers";
import { IUser } from "../Interface/user";

export default class UserService {
  private readonly API_URL = process.env.REACT_APP_API_URL;

  private async handleErrors(response: Response): Promise<any> {
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      try {
        return await response.json();
      } catch (error) {
        return null;
      }
    } else {
      return null;
    }
  }

  public async GetUserInfos(userId: string): Promise<IUser> {
    return await fetch(`${this.API_URL}/usuario/get?id=${userId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async GetActiveUsers(): Promise<IUser[]> {
    return await fetch(`${this.API_URL}/usuario/listar-usuarios-ativos`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async InserirFunilComercial(action: any): Promise<any> {
    return await fetch(`${this.API_URL}/funil/inserir`, {
      // return await fetch(`${this.API_URL}/?assessorUsuarioId=bc2efd66-8526-4eb7-b4d4-a298757e6df7`, {
      method: 'POST',
      headers: AUTH_HEADER(),
      body: JSON.stringify(action)
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async ListarFunilComercialData(action: any): Promise<any> {
    return await fetch(`${this.API_URL}/funil/listar?acao=${action.action}&data=${action.daysAgo}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data >= 0) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async ListarFunilComercialDataSquad(action: any): Promise<any> {
    return await fetch(`${this.API_URL}/funil/listar-squad?acao=${action.action}&data=${action.daysAgo}&squadId=${action.squadId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data >= 0) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async ListarFunilComercialDataAll(action: any): Promise<any> {
    return await fetch(`${this.API_URL}/funil/listar-data?acao=${action.action}&data=${action.daysAgo}&squadId=${action.squadId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data >= 0) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async ListarFunilComercialDataAssessor(action: any): Promise<any> {
    return await fetch(`${this.API_URL}/funil/listar-assessor?acao=${action.action}&data=${action.daysAgo}&assessorId=${action.assessorId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data >= 0) {
          return { assessorUsuarioId: action.assessorId, count: data, action: action.action };
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async DeletarActionFunilComercial(clienteAndAction: any): Promise<any> {
    return await fetch(`${this.API_URL}/funil/deletar?acao=${clienteAndAction.action}&clienteId=${clienteAndAction.ClienteId}`, {
      method: 'DELETE',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then((data) => {
        if (data >= 0) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async ListUsersGroups(): Promise<IListUsersGroups> {
    return await fetch(`${this.API_URL}/usuario/listar-usuarios-por-status`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(err);
      });
  }

  public async ListUsersByPermissions(permissions: IListUsersByPermissions['Permissions'][]): Promise<IListUsersByPermissions[]> {
    return await fetch(`${this.API_URL}/usuario/listar-usuarios-por-permissoes`, {
      method: 'POST',
      headers: AUTH_HEADER(),
      body: JSON.stringify(permissions)
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(err);
      });
  }

  public async BlockUser(newStatus: IUpdateStatusUser): Promise<void> {
    return await fetch(`${this.API_URL}/usuario/bloquear-usuario`, {
      method: 'PATCH',
      headers: AUTH_HEADER(),
      body: JSON.stringify(newStatus)
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(err);
      });
  }

  public async ActiveUser(newStatus: IUpdateStatusUser): Promise<void> {
    return await fetch(`${this.API_URL}/usuario/atualizar-status`, {
      method: 'PATCH',
      headers: AUTH_HEADER(),
      body: JSON.stringify(newStatus)
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(err);
      });
  }
}