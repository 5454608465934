import React from 'react';
import { KanbanBoardSeguros } from ".";
import { useKanbanNegociosSegurosContext } from "../../../Hooks/useKanbanNegociosSeguro";

export const KanbanToSegurosPage = () => {
  const {
    loading,
    onDragEnd,
    filteredKanbanList,
    users,
    setUsuarioId,
    usuarioId,
    isAdminSeguro,
  } = useKanbanNegociosSegurosContext();

  return <KanbanBoardSeguros
    isDraggable={true}
    loading={loading}
    onDragEnd={onDragEnd}
    filteredKanbanList={filteredKanbanList}
    users={users}
    setUsuarioId={setUsuarioId}
    usuarioId={usuarioId}
    isAdminSeguro={isAdminSeguro}
    showSearchInput={true}
  />;
};
