import UserService from "../../../Services/UserService";
import { useEffect, useState } from "react";
import StorageHelper from "../../../Services/Helpers/StorageHelper";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useNavigate } from "react-router-dom";
import { IUser } from "../../../Interface/user";

export const TopNav = () => {
  const userService = new UserService()
  const [userInfos, setUserInfos] = useState<IUser>()

  const storageHelper = new StorageHelper();
  const assessorId = storageHelper.GetUsuarioId();

  let navigate = useNavigate();

  useEffect(() => {
    let cancel = false;

    userService.GetUserInfos(assessorId)
      .then(e => {
        if (cancel) return;
        setUserInfos(e)
      })
      .catch(e => {
        console.error(e);
      })

    return () => {
      cancel = true
    }
  }, [])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null)

  const handleSignOut = () => {
    setAnchorEl(null);
    storageHelper.Logout();

    navigate("/", { replace: true });
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }} />
      <Typography variant="h5">
        {userInfos ? userInfos.Nome + (userInfos.Sobrenome ? ` ${userInfos.Sobrenome}` : '') : '-'}
      </Typography>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem key="sair" onClick={handleSignOut}>Sair</MenuItem>
      </Menu>
    </>
  )
}