export function transformDateToUTC(dateToTransform: any) {
    let date = new Date(dateToTransform);

    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let hour = date.getHours();
    let minutes = date.getMinutes();

    let newDate = new Date(Date.UTC(year, month, day, hour, minutes));
    return newDate;
}