import { createContext, useContext, useEffect, useState, ReactNode, useMemo } from "react";
import NegociosService from "../Services/NegociosService";
import UserService from "../Services/UserService";
import { enqueueSnackbar } from "notistack";
import { DropResult } from "react-beautiful-dnd";
import StorageHelper from '../Services/Helpers/StorageHelper';
import { INegocio, INegocioSeguroRequest } from "../Interface/interfaceNegocios";
import { usePermissions } from "./usePermissionsUser";
import { createTask } from "../Components/Common/NewProfileClient/crudTask";
import { formattingDateToISO } from "../Components/Common/NewProfileClient/usefulFunctions";
import { interfaceSchedule } from "../Interface/intarfaceSchedule";

export type CategoriaKanbanSeguro = 
  'lead' 
  | 'r1' 
  | 'r2' 
  | 'aguardando' 
  | 'fechado' 
  | 'emAnalise' 
  | 'emitido' 
  | 'pausado';

const statusMapping: Record<CategoriaKanbanSeguro, string> = {
  lead: "LEAD",
  r1: "R1",
  r2: "R2",
  aguardando: "AGUARDANDO",
  fechado: "FECHADO",
  emAnalise: "EM_ANALISE",
  emitido: "EMITIDO",
  pausado: "PAUSADO",
};

const droppableIdToName: Record<CategoriaKanbanSeguro, string> = {
  lead: 'Lead',
  r1: 'R1',
  r2: 'R2',
  aguardando: 'Aguardando',
  fechado: 'Fechado',
  emAnalise: 'Em Análise',
  emitido: 'Emitido',
  pausado: 'Pausado'
};

export interface IKanbanListSeguro {
  lead: { name: string; data: INegocioSeguroRequest[] };
  r1: { name: string; data: INegocioSeguroRequest[] };
  r2: { name: string; data: INegocioSeguroRequest[] };
  aguardando: { name: string; data: INegocioSeguroRequest[] };
  fechado: { name: string; data: INegocioSeguroRequest[] };
  emAnalise: { name: string; data: INegocioSeguroRequest[] };
  emitido: { name: string; data: INegocioSeguroRequest[] };
  pausado: { name: string; data: INegocioSeguroRequest[] };
}

const useKanbanNegociosSeguros = () => {
  const negociosService = new NegociosService();
  const userService = new UserService();
  const storageHelper = new StorageHelper();
  const vendedorId = storageHelper.GetUsuarioId();
  const { permissions } = usePermissions();

  const [kanbanList, setKanbanList] = useState<IKanbanListSeguro>({
    lead: { name: 'Lead', data: [] },
    r1: { name: 'R1', data: [] },
    r2: { name: 'R2', data: [] },
    aguardando: { name: 'Aguardando', data: [] },
    fechado: { name: 'Fechado', data: [] },
    emAnalise: { name: 'Em Análise', data: [] },
    emitido: { name: 'Emitido', data: [] },
    pausado: { name: 'Pausado', data: [] },
  });

  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [usuarioId, setUsuarioId] = useState<string | null>(null);
  const [users, setUsers] = useState<{ id: string, label: string }[]>([]);

  const isAdminSeguro = useMemo(() => {
    return permissions.some(permission => (permission.PermissionName === 'AdminSeguros')) || storageHelper.GetUsuarioRole() === 'Administrador';
  }, [permissions]);

  const fetchUsersByPermission = async () => {
    if (isAdminSeguro) {
      try {
        const fetchedUsers = await userService.ListUsersByPermissions(['Seguros']);
        const formattedUsers = fetchedUsers.map(user => ({
          id: user.Id,
          label: user.NomeCompleto,
        }));
        setUsers([...formattedUsers]);
      } catch (error) {
        enqueueSnackbar('Erro ao buscar usuários.', { variant: 'error' });
      }
    }
  };

  const fetchNegocios = async () => {
    setLoading(true);
    try {
      const negocios = await negociosService.ListNegociosSeguroByVendedorId(usuarioId || vendedorId);

      const kanban: IKanbanListSeguro = {
        lead: { name: 'Lead', data: [] },
        r1: { name: 'R1', data: [] },
        r2: { name: 'R2', data: [] },
        aguardando: { name: 'Aguardando', data: [] },
        fechado: { name: 'Fechado', data: [] },
        emAnalise: { name: 'Em Análise', data: [] },
        emitido: { name: 'Emitido', data: [] },
        pausado: { name: 'Pausado', data: [] },
      };

      negocios.forEach(negocio => {
        const statusKey = Object.keys(statusMapping).find(key => statusMapping[key as keyof typeof statusMapping] === negocio.Negocio.Status);
        if (statusKey) {
          kanban[statusKey as keyof typeof statusMapping].data.push(negocio);
        }
      });

      setKanbanList(kanban);
    } catch (error) {
      enqueueSnackbar('Erro ao buscar negócios de seguros.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNegocios();
  }, [usuarioId]);

  useEffect(() => {
    fetchUsersByPermission();
  }, [isAdminSeguro]);

  const filteredKanbanList = useMemo(() => {
    if (!searchTerm) {
      return Object.fromEntries(
        Object.entries(kanbanList).map(([key, column]) => [
          key,
          {
            ...column,
            data: column.data.sort((a: INegocioSeguroRequest, b: INegocioSeguroRequest) => new Date(b.Negocio.DataInicio).getTime() - new Date(a.Negocio.DataInicio).getTime())
          }
        ])
      ) as IKanbanListSeguro;
    }

    const lowercasedSearchTerm = searchTerm.toLowerCase();

    const filterData = (negocios: INegocioSeguroRequest[]) =>
      negocios
        .filter(negocio =>
          negocio.NomeCliente?.toLowerCase().includes(lowercasedSearchTerm)
        )
        .sort((a, b) => new Date(b.Negocio.DataInicio).getTime() - new Date(a.Negocio.DataInicio).getTime());

    return Object.fromEntries(
      Object.entries(kanbanList).map(([key, column]) => [
        key,
        {
          ...column,
          data: filterData(column.data)
        }
      ])
    ) as IKanbanListSeguro;
  }, [searchTerm, kanbanList]);

  const onDragEnd = async (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!destination || destination.droppableId === source.droppableId) return;

    const startColumnData = kanbanList[source.droppableId as keyof typeof statusMapping].data;
    const finishColumnData = kanbanList[destination.droppableId as keyof typeof statusMapping].data;

    const negocioMoved = startColumnData.find(negocio => negocio.Negocio.Id === draggableId);
    if (!negocioMoved) return;

    if (negocioMoved.Negocio.VendedorId !== vendedorId) {
      enqueueSnackbar('Você não tem permissão para mover este negócio.', { variant: 'error' });
      return;
    }

    const newStatus = statusMapping[destination.droppableId as keyof typeof statusMapping];

    const prevKanbanList = { ...kanbanList };

    setKanbanList(prevState => {
      const newStartColumnData = startColumnData.filter(negocio => negocio.Negocio.Id !== draggableId);
      const updatedNegocioMoved = { ...negocioMoved, Negocio: { ...negocioMoved.Negocio, Status: newStatus } };
      const newFinishColumnData = [updatedNegocioMoved, ...finishColumnData];

      return {
        ...prevState,
        [source.droppableId]: {
          ...prevState[source.droppableId as keyof typeof statusMapping],
          data: newStartColumnData,
        },
        [destination.droppableId]: {
          ...prevState[destination.droppableId as keyof typeof statusMapping],
          data: newFinishColumnData,
        }
      };
    });

    try {
      const updateData: Partial<INegocio> = {
        Id: negocioMoved.Negocio.Id,
        Status: newStatus as INegocio['Status']
      };

      // Se o destino for emitido (final do funil), definimos a DataFechamento
      if (destination.droppableId === 'emitido') {
        updateData.DataFechamento = new Date();
      }

      await negociosService.UpdateNegocio(updateData);

      const now = new Date();
      const descricao = `Movido de ${droppableIdToName[source.droppableId as CategoriaKanbanSeguro]} para ${droppableIdToName[destination.droppableId as CategoriaKanbanSeguro]}.`;

      const newActivity: interfaceSchedule = {
        DataEvento: formattingDateToISO(now),
        AtividadeFinalizada: true,
        Title: 'Movimentação em Seguros',
        Descricao: descricao,
        AssessorUsuarioId: vendedorId,
        ClienteId: negocioMoved.Negocio.ClienteId!,
        Visto: false,
        DataTermino: formattingDateToISO(now),
        StatusTarefa: 'FINISHED',
        AtividadeFinalizadaData: formattingDateToISO(now),
        AtividadeTodoDia: false,
        CriadoPorArea: 'Seguros'
      };

      await createTask(newActivity);
    } catch (error) {
      enqueueSnackbar('Erro ao atualizar status.', { variant: 'error' });

      setKanbanList(prevKanbanList);
    }
  };

  return {
    kanbanList,
    onDragEnd,
    setKanbanList,
    loading,
    fetchNegocios,
    filteredKanbanList,
    setSearchTerm,
    users,
    setUsuarioId,
    usuarioId,
    isAdminSeguro,
  };
};

interface KanbanNegociosSeguroContextProps {
  kanbanList: IKanbanListSeguro;
  setKanbanList: React.Dispatch<React.SetStateAction<IKanbanListSeguro>>;
  onDragEnd: (result: DropResult) => Promise<void>;
  loading: boolean;
  fetchNegocios: () => Promise<void>;
  filteredKanbanList: IKanbanListSeguro;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  users: { id: string, label: string }[];
  setUsuarioId: React.Dispatch<React.SetStateAction<string | null>>;
  usuarioId: string | null;
  isAdminSeguro: boolean;
}

const KanbanNegociosSegurosContext = createContext<KanbanNegociosSeguroContextProps | undefined>(undefined);

export const KanbanNegociosSegurosProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const kanbanNegociosSeguros = useKanbanNegociosSeguros();

  return (
    <KanbanNegociosSegurosContext.Provider value={kanbanNegociosSeguros}>
      {children}
    </KanbanNegociosSegurosContext.Provider>
  );
};

export const useKanbanNegociosSegurosContext = () => {
  const context = useContext(KanbanNegociosSegurosContext);
  if (!context) {
    throw new Error('useKanbanNegociosSegurosContext must be used within a KanbanNegociosSegurosProvider');
  }
  return context;
};
