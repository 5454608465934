import { useEffect, useState, useMemo } from "react";
import { ICreateTask, IUpdateTask, interfaceTask } from "../Interface/interfaceTask";
import TaskService from "../Services/TasksService";
import StorageHelper from "../Services/Helpers/StorageHelper";
import { IUser } from "../Interface/user";

export const useTasks = () => {
  const storageHelper = new StorageHelper();
  const [tasks, setTasks] = useState<interfaceTask[]>([]);
  const [assignUsers, setAssignUsers] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(true);
  const taskService = new TaskService();

  const assessorId = storageHelper.GetUsuarioId();

  const [tasksStatusToShow, setTasksStatusToShow] = useState<(0 | 1 | 2)[]>([0]);
  const [taskUserFilter, setTaskUserFilter] = useState<('created' | 'assigned')[]>(['created', 'assigned']);

  const handleShowTasksByStatus = (statusToShow: (0 | 1 | 2)[]) => {
    setTasksStatusToShow(statusToShow)
  };

  const handleUserFilterChange = (filterTypes: ('created' | 'assigned')[]) => {
    setTaskUserFilter(filterTypes);
  };

  const tasksFiltered = useMemo(() => {
    return tasks.filter(task => {
      const isCreatedByUser = task.CriadorId === assessorId;
      const isAssignedToUser = task.AtribuidoId === assessorId;

      const createdByUserFilter = taskUserFilter.includes('created') && isCreatedByUser;
      const assignedToUserFilter = taskUserFilter.includes('assigned') && isAssignedToUser && !isCreatedByUser;

      return (createdByUserFilter || assignedToUserFilter) && tasksStatusToShow.includes(task.Status);
    });
  }, [tasks, assessorId, taskUserFilter, tasksStatusToShow]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const taskListCreated = await taskService.GetListCreatedTasks();
        const taskListAssigned = await taskService.GetListAssignedTasks();

        // Concatena as listas de tarefas
        const tasks = [...taskListCreated, ...taskListAssigned];

        // Remove tarefas duplicadas pelo ID mantendo apenas a primeira ocorrência
        const tasksMap = new Map(tasks.map((task) => [task.Id, task]));
        const uniqueTasks = Array.from(tasksMap.values());

        // Ordena as tarefas pelo campo DataCriacao
        setTasks(uniqueTasks.sort((a, b) => new Date(a.DataPrazo).getTime() - new Date(b.DataPrazo).getTime()));

        const assignUserList = await taskService.GetListAssignUsers();
        const sortedAssignUserList = assignUserList.sort((a, b) => a.NomeCompleto.trimStart().localeCompare(b.NomeCompleto.trimStart()));
        setAssignUsers(sortedAssignUserList);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const createTask = async (task: ICreateTask): Promise<interfaceTask | undefined> => {
    try {
      const newTask = await taskService.PostTask(task);
      setTasks([...tasks, newTask]);
      return newTask;
    } catch (error) {
      console.log(error);
    }
  };

  const updateTask = async (taskToUpdate: IUpdateTask): Promise<interfaceTask | undefined> => {
    try {
      const updatedTask = await taskService.UpdateTask(taskToUpdate);
      const updatedTasks = [...tasks].map((task) => (task.Id === updatedTask.Id ? updatedTask : task));
      setTasks(updatedTasks);
      return updatedTask;
    } catch (error) {
      console.log(error);
    }
  };


  const updateStatusTask = async (taskId: string, status: number) => {
    try {
      const updatedTask = await taskService.UpdateStatusTask({
        taskId,
        status,
      });
      const updatedTasks = tasks.map((task) =>
        task.Id === updatedTask.Id ? updatedTask : task
      );
      setTasks(updatedTasks);
      return updatedTask;
    } catch (error) {
      console.log(error);
    }
  };

  const deleteTask = async (taskId: string) => {
    try {
      await taskService.DeleteTask(taskId);
      const updatedTasks = [...tasks].filter((task) => task.Id !== taskId);
      setTasks(updatedTasks);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTask = async (taskId: string) => {
    try {
      const task = await taskService.GetTask(taskId);

      if (task.Comentarios.length > 0) {
        task.Comentarios = sortComments(task.Comentarios);
      }

      return task;
    } catch (error) {
      console.log(error);
    }
  };

  const sortComments = (comments: interfaceTask["Comentarios"]) => {
    return [...comments].sort((a, b) => new Date(b.DataCriacao).getTime() - new Date(a.DataCriacao).getTime());
  }

  const addCommentToTask = async (taskId: string, texto: string) => {
    try {
      const updatedTask = await taskService.PostTaskAddComment({
        taskId,
        texto,
      });

      return updatedTask;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    tasks,
    assignUsers,
    loading,
    createTask,
    updateStatusTask,
    deleteTask,
    fetchTask,
    addCommentToTask,
    updateTask,
    handleUserFilterChange,
    taskUserFilter,
    tasksFiltered,
    handleShowTasksByStatus,
    tasksStatusToShow
  };
};
