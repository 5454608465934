import { interfaceSchedule } from "../Interface/intarfaceSchedule";
import { AUTH_HEADER } from "./Helpers/Headers";

// interface AddAtividade {
//     Id?: string;
//     AssessorUsuarioId?: string;
//     ClienteId?: string;
//     DataEvento: Date;
//     Title: string;
//     Descricao: string;
//     Visto: boolean;
//     DataTermino?: Date;
//     StatusAtividade?: boolean;
//     AtividadeFinalizada?: boolean;
// }

export default class ScheduleService {
    private readonly API_URL = process.env.REACT_APP_API_URL;

    public async ListarTarefasCliente(clienteId: any): Promise<interfaceSchedule[]> {
        return await fetch(`${this.API_URL}/tarefas/cliente?clienteId=${clienteId}`, {
            method: 'GET',
            headers: AUTH_HEADER()
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then((data) => {
                if (data) {
                    if (data.length > 0) {
                        data.map((value: interfaceSchedule, index: number) => {
                            data[index].DataEvento = new Date(value.DataEvento + "Z")
                        })
                    }
                    return data;
                } else {
                    throw new Error("Erro")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    public async AtualizarVistoTarefa(tarefaId: any): Promise<interfaceSchedule[]> {
        return await fetch(`${this.API_URL}/tarefas/visto?tarefaId=${tarefaId}`, {
            method: 'POST',
            headers: AUTH_HEADER()
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then(data => {
                if (data) {
                    return data;
                } else {
                    throw new Error("Erro")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    public async AtualizarStatusTarefa(tarefaId: any, statusTarefa: string): Promise<interfaceSchedule[]> {
        return await fetch(`${this.API_URL}/tarefas/status-tarefa?tarefaId=${tarefaId}&statusTarefa=${statusTarefa}`, {
            method: 'PUT',
            headers: AUTH_HEADER()
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then(data => {
                if (data) {
                    return data;
                } else {
                    throw new Error("Erro")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    public async ListarTarefasAssessor(assessorId: any): Promise<any> {
        return await fetch(`${this.API_URL}/tarefas/all?assessorId=${assessorId}`, {
            method: 'GET',
            headers: AUTH_HEADER()
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then(data => {
                if (data) {
                    return data;
                } else {
                    throw new Error("Erro")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    public async UpdateTask(tarefa: any): Promise<any> {
        return await fetch(`${this.API_URL}/tarefas`, {
            method: 'PUT',
            headers: AUTH_HEADER(),
            body: JSON.stringify(tarefa)
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then(data => {
                if (data) {
                    return tarefa;
                } else {
                    throw new Error("Erro")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    public async DeletarTarefa(tarefaId: any): Promise<any> {
        return await fetch(`${this.API_URL}/tarefas?tarefaId=${tarefaId}`, {
            method: 'DELETE',
            headers: AUTH_HEADER()
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then(data => {
                if (data) {
                    return data;
                } else {
                    throw new Error("Erro")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    public async CreateTask(task: interfaceSchedule): Promise<any> {
        return await fetch(`${this.API_URL}/tarefas`, {
            method: 'POST',
            headers: AUTH_HEADER(),
            body: JSON.stringify(task)
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then(data => {
                if (data) {
                    return data;
                } else {
                    throw new Error("Erro")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    };

    public async CreateTaskIfNotExists(task: interfaceSchedule): Promise<any> {
        return await fetch(`${this.API_URL}/tarefas/insert-if-not-exists`, {
            method: 'POST',
            headers: AUTH_HEADER(),
            body: JSON.stringify(task)
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then(data => {
                if (data) {
                    return data;
                } else {
                    throw new Error("Erro")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    }

}