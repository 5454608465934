import { AprovarUsuarioRequest } from "../Domain/DTO/Request/AprovarUsuarioRequest";
import Usuario from "../Domain/Usuario";
import { AUTH_HEADER, DEFAULT_HEADER } from "./Helpers/Headers";
import StorageHelper from "./Helpers/StorageHelper";

export default class LoginService {
    private readonly API_URL = process.env.REACT_APP_API_URL;
    private readonly storageHelper = new StorageHelper();

    public async ListarUsuariosPendentes() : Promise<Usuario[]> {
        return await fetch(`${this.API_URL}/usuario/listar-pendentes`, {
            method: 'GET', 
            headers: AUTH_HEADER(),
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then(data => {
                if (data) {
                    return data;
                }else{
                    throw new Error("Não foi possível buscar os usuários pendentes. Tente novamente mais tarde")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    public async ListarUsuariosAtivos() : Promise<Usuario[]> {
        return await fetch(`${this.API_URL}/usuario/listar-usuarios-ativos`, {
            method: 'GET', 
            headers: AUTH_HEADER(),
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then(data => {
                if (data) {
                    return data;
                }else{
                    throw new Error("Não foi possível buscar os usuários pendentes. Tente novamente mais tarde")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    public async AprovarUsuario(request: AprovarUsuarioRequest): Promise<boolean> {
        return await fetch(`${this.API_URL}/usuario/aprovar-cadastro`, {
            method: 'POST',
            headers: AUTH_HEADER(),
            body: JSON.stringify(request)
        })
            .then(response => {
                if (response.status == 200)
                    return true;

                if (response.status == 404)
                    throw new Error("Ocorreu um erro. Tente novamente");
            })
            .then(data => true)
            .catch(err => {
                throw new Error(err);
            })
    }
}