import { Grid, TextField } from "@mui/material"
import { useClientContext } from "../ClientContext"
import { interfaceTimeline } from "../../../../Interface/interfaceTimeline";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import EventIcon from '@mui/icons-material/Event';
import { Button, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForeverOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import moment from "moment";
import AddIcon from '@mui/icons-material/Add';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import ClientesService from "../../../../Services/ClientesService";
import { formattingDateTimeline } from "../usefulFunctions";

export const ClientNotes = () => {
  const { client, timeline, addTimelineItem, deleteTimelineItem, updateTimelineItem, selectedMonthYear } = useClientContext();
  const clientService = new ClientesService;
  const [loadingAddTimeline, setLoadingAddTimeline] = useState(false);
  const [newTimelineNote, setNewTimelineNote] = useState({ Categoria: 'Nota', Comentario: '' });

  const [timelineToShow, setTimelineToShow] = useState<interfaceTimeline[]>([]);
  useEffect(() => {
    if (timeline.length > 0 && selectedMonthYear) {
      const filteredTimeline = [...timeline].filter((item) => {
        const eventDate = moment(item.CriadoEm);
        return item.Categoria === 'Nota' && eventDate.month() === selectedMonthYear.month() && eventDate.year() === selectedMonthYear.year();
      });

      setTimelineToShow(filteredTimeline.sort((a, b) => new Date(b.CriadoEm!).getTime() - new Date(a.CriadoEm!).getTime()));
    } else if (timeline.length > 0) {
      const filteredTimeline = [...timeline].filter((item) => {
        const eventDate = moment(item.CriadoEm);
        return item.Categoria === 'Nota';
      });

      setTimelineToShow(filteredTimeline);
    }
  }, [timeline, selectedMonthYear]);

  const [editState, setEditState] = useState<{ id: string | null, comment: string }>({ id: null, comment: "" });
  const [loadingSave, setLoadingSave] = useState(false);
  const [deleteDialogState, setDeleteDialogState] = useState({ open: false, deleteItemId: '' });
  const [loadingDelete, setLoadingDelete] = useState(false);

  const handleEditClick = (timelineId: string, currentComment: string) => {
    setEditState({ id: timelineId, comment: currentComment });
  };

  const handleSaveClick = async () => {
    if (editState.id) {
      setLoadingSave(true);
      await updateTimelineItem(editState.id, editState.comment);
      setLoadingSave(false);
      setEditState({ id: null, comment: "" });
    }
  };

  const handleCancelClick = () => {
    setEditState({ id: null, comment: "" });
  };

  const handleDeleteClick = (timelineId: string) => {
    setDeleteDialogState({ open: true, deleteItemId: timelineId });
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogState({ ...deleteDialogState, open: false });
  };

  const handleConfirmDelete = async () => {
    if (deleteDialogState.deleteItemId) {
      setLoadingDelete(true);
      await deleteTimelineItem(deleteDialogState.deleteItemId);
      setLoadingDelete(false);
      setDeleteDialogState({ open: false, deleteItemId: '' });
    }
  }

  return <Grid container item xs={12}>
    <Grid item xs={12} md={6}>
      <TextField
        label="Insira sua nota aqui..."
        value={newTimelineNote.Comentario}
        onChange={(event) => {
          newTimelineNote.Comentario = event.target.value
          setNewTimelineNote({ ...newTimelineNote })
        }}
        rows={4}
        fullWidth
        multiline
      />
      <Box sx={{ textAlign: 'center' }}>
        <LoadingButton loading={loadingAddTimeline} sx={{ mt: 3 }}
          size="small" variant="contained" color="primary"
          startIcon={<AddIcon fontSize='small' />}
          onClick={async () => {
            await addTimelineItem(newTimelineNote.Categoria, newTimelineNote.Comentario);
            setNewTimelineNote({ Categoria: 'Nota', Comentario: '' });
            clientService.UpdateUltimaAtividade(client?.Id);
          }}
        >
          Adicionar nota
        </LoadingButton>
      </Box>
    </Grid>

    <Grid item xs={12} md={6}>
      <Box maxHeight='600px' sx={{ overflowY: 'auto' }}>
        {
          (timelineToShow && timelineToShow.length > 0) ?
            <Timeline position="right">
              {
                timelineToShow.map((timelineItem: any, index: number) => {
                  let categoria = timelineItem.Categoria;
                  let isEditing = editState.id === timelineItem.Id;

                  if (categoria !== 'Nota') return;

                  return <TimelineItem key={index}>
                    <TimelineOppositeContent style={{ flex: 0.01 }} />
                    <TimelineSeparator>
                      <TimelineDot sx={{ backgroundColor: "#d6d5e2", boxShadow: "none" }}>
                        {ChooseIcon(timelineItem.Categoria)}
                      </TimelineDot>
                      {
                        index == timeline.length - 1 ? "" : <TimelineConnector sx={{ backgroundColor: "#b0afc742" }} />
                      }
                    </TimelineSeparator>
                    {
                      timelineItem.Comentario !== "" ?
                        <TimelineContent>
                          <Box display="flex" justifyContent="center" flexDirection="column">
                            <Typography sx={{ whiteSpace: "nowrap" }} variant="h5" fontWeight="bold" color="primary">
                              {timelineItem.Categoria}
                              <Box component="span" sx={{ color: "#b0afc7", ml: 2, fontSize: 11 }}>
                                {formattingDateTimeline(new Date(timelineItem.CriadoEm))}
                                <span style={{ fontWeight: 'normal' }}>
                                  {timelineItem.Assessor?.NomeCompleto ? ` - ${timelineItem.Assessor.NomeCompleto}` : ''}
                                </span>
                              </Box>
                              {
                                !isEditing && <>
                                  <IconButton
                                    size="small" sx={{ ml: 1 }}
                                    onClick={() => handleEditClick(timelineItem.Id, timelineItem.Comentario)}
                                  >
                                    <EditIcon fontSize='small' />
                                  </IconButton>
                                  <IconButton
                                    size="small"
                                    onClick={() => handleDeleteClick(timelineItem.Id)}
                                  >
                                    <DeleteForeverIcon fontSize='small' />
                                  </IconButton>
                                </>
                              }

                            </Typography>
                            {isEditing ? (
                              <>
                                <TextField
                                  fullWidth variant='outlined' sx={{ my: 2 }}
                                  multiline
                                  value={editState.comment}
                                  onChange={(e) => setEditState({ ...editState, comment: e.target.value })}
                                />
                                <Box textAlign='center'>
                                  <Button size='small' onClick={handleCancelClick}>Cancelar</Button>
                                  <LoadingButton sx={{ ml: 2 }} size='small' variant='contained'
                                    loading={loadingSave}
                                    onClick={handleSaveClick}
                                  >
                                    Salvar
                                  </LoadingButton>
                                </Box>
                              </>
                            ) : (
                              <Box>
                                <Typography variant="h5" fontWeight="normal" color="body">
                                  <pre style={{ fontFamily: 'inherit', whiteSpace: 'pre-wrap' }}>
                                    {timelineItem.Comentario}
                                  </pre>
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </TimelineContent>
                        :
                        <TimelineContent>
                          <Box display="flex" justifyContent="center" flexDirection="column">
                            <Typography sx={{ whiteSpace: "nowrap", pt: 1.2 }} variant="h5" fontWeight="bold" color="primary" >
                              {timelineItem.Categoria}
                              <Box component="span" sx={{ color: "#b0afc7", ml: 2, fontSize: 11 }}>
                                {
                                  formattingDateTimeline(new Date(timelineItem.CriadoEm))
                                }
                              </Box>
                            </Typography>
                          </Box>
                        </TimelineContent>
                    }
                  </TimelineItem>
                })
              }
            </Timeline>
            : <Box mt={4}>
              {noAssetsMessage}
            </Box>
        }
      </Box>
    </Grid>

    <Dialog
      open={deleteDialogState.open}
      onClose={handleCloseDeleteDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" variant='h3'>Confirmar exclusão</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" fontSize={17}>
          Tem certeza de que deseja excluir este item da linha do tempo?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size='small' onClick={handleCloseDeleteDialog}>Cancelar</Button>
        <LoadingButton disableElevation size='small' variant='contained'
          loading={loadingDelete}
          onClick={handleConfirmDelete} autoFocus
        >
          Deletar
        </LoadingButton>
      </DialogActions>
    </Dialog>

  </Grid>
}

export const ChooseIcon = (categoria: any) => {
  switch (categoria) {
    case "Agendamento de atividade: Contato":
    case "Agendamento de atividade: Retornar contato":
    case "Agendamento de atividade: Remarcar 1° reunião":
    case "Agendamento de atividade: Remarcar 2° reunião":
    case "Agendamento de atividade: Marcar reunião":
    case "Agendamento de atividade: 1° reunião":
    case "Agendamento de atividade: 2° reunião":
    case "Agendamento de atividade: Reunião":
    case "Agendamento de atividade: Reunião seguro":
    case "Agendamento de atividade: Visita":
    case "Agendamento de atividade: Tarefa":
      return <EventIcon sx={{ width: 18, height: 18, color: "#a5a4bf" }} />
    case "2":
      break;
    default:
      return <WorkOutlineIcon sx={{ width: 18, height: 18, color: "#a5a4bf" }} />
  }
};

const noAssetsMessage = (
  <Typography sx={{ color: 'text.secondary', textAlign: 'center', opacity: 0.5 }}>
    <SentimentDissatisfiedIcon sx={{ fontSize: 50, mb: 1 }} />
    <br />
    Nenhuma nota cadastrada.
  </Typography>
);