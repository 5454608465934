import { Grid, Typography, IconButton, Button, Select, MenuItem, FormControl, InputLabel, Box, CircularProgress } from "@mui/material"
import { useEffect, useMemo, useState } from "react";
import { useClientContext } from "./ClientContext";
import { formatCurrencyBRL } from "../../../Helpers/formatters";
import { FaWhatsapp } from 'react-icons/fa';
import EditIcon from '@mui/icons-material/Edit';
import UserService from "../../../Services/UserService";
import { IUser } from "../../../Interface/user";

export const CommercialsInfos = () => {
  const userService = new UserService();
  const { client, advisorOwner, changeOwner } = useClientContext();
  const [editAdvisorOwner, setEditAdvisorOwner] = useState<{ activeUsers: IUser[], owner?: IUser, openEdit: boolean, searchedUsers: boolean }>({
    activeUsers: [], openEdit: false, searchedUsers: false
  });
  const [loadingUsers, setLoadingUsers] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoadingUsers(true);
      try {
        const users = await userService.GetActiveUsers();
        const sortedUsers = users.sort((a, b) => a.NomeCompleto.localeCompare(b.NomeCompleto));

        const owner = users.find(user => user.Id === client?.AssessorUsuarioId);
        setEditAdvisorOwner(prevState => ({ ...prevState, activeUsers: sortedUsers, searchedUsers: true, owner }));
      } catch (err) {
        console.error(err);
        setEditAdvisorOwner(prevState => ({ ...prevState, searchedUsers: true }));
      } finally {
        setLoadingUsers(false);
      }
    };

    fetchUsers();
  }, [client]);

  function formatPhoneNumber(phoneNumber: string) {
    if (phoneNumber) {
      const cleanedNumber = phoneNumber.replace(/\D+/g, '');
      const countryCode = '55'; // Código do país para o Brasil
      const whatsappNumber = `${countryCode}${cleanedNumber}`;
      return `https://wa.me/${whatsappNumber}`;
    } else {
      return '';
    }
  };

  const whatsappLink = useMemo(() => {
    if (client?.TelefoneContatoPrimario) {
      return formatPhoneNumber(client.TelefoneContatoPrimario);
    } else {
      return '';
    }
  }, [client]);

  return <Grid container item md={5} xs={12}>
    <Grid item xs={12}>
      <Typography variant="h5" fontSize={15} mb={2} textAlign='center'>
        Comerciais
      </Typography>
    </Grid>
    <Grid item container xs={12} spacing={2} mx={3}>
      <Grid item xs={6}>
        <Typography variant='h5' fontWeight='normal'>
          <span style={{ fontWeight: 'bold' }}>
            Nome:
          </span>
          <span style={{ marginLeft: '10px' }}>
            {client?.NomeCompleto}
          </span>
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography variant='h5' fontWeight='normal'>
          <span style={{ fontWeight: 'bold' }}>
            Número da conta:
          </span>
          <span style={{ marginLeft: '10px' }}>
            {client?.CodigoCliente || '-'}
          </span>
        </Typography>
      </Grid>

      {
        (!!client?.Preco && client.Preco > 0) && <Grid item xs={6}>
          <Typography variant='h5' fontWeight='normal'>
            <span style={{ fontWeight: 'bold' }}>
              PL:
            </span>
            <span style={{ marginLeft: '10px' }}>
              {client?.Preco ? formatCurrencyBRL(client?.Preco) : '-'}
            </span>
          </Typography>
        </Grid>
      }

      <Grid item xs={6}>
        <Typography variant='h5' fontWeight='normal'>
          {
            editAdvisorOwner.openEdit ? <>
              <FormControl fullWidth size='small'>
                <InputLabel id='edit-owner-label'>Selecione um Assessor</InputLabel>
                <Select
                  value={editAdvisorOwner.owner?.Id}
                  label='Selecione um Assessor'
                  labelId='edit-owner-label'
                  size='small'
                  onChange={(e) => {
                    const selectedUserId = e.target.value;
                    const selectedUser = editAdvisorOwner.activeUsers.find(user => user.Id === selectedUserId);
                    setEditAdvisorOwner(prevState => ({ ...prevState, owner: selectedUser }));
                  }}
                >
                  {
                    loadingUsers && <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                      <CircularProgress size={24} thickness={4} />
                    </Box>
                  }
                  {editAdvisorOwner.activeUsers.map((user) => (
                    <MenuItem key={user.Id} value={user.Id}>
                      {user.NomeCompleto}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                <Button size='small' color='secondary' onClick={() => setEditAdvisorOwner({ ...editAdvisorOwner, openEdit: false, owner: advisorOwner })}>Cancelar</Button>
                <Button size='small'
                  sx={{ ml: 1 }}
                  variant='outlined'
                  onClick={() => {
                    if (editAdvisorOwner.owner) {
                      changeOwner(editAdvisorOwner.owner.Id);
                      setEditAdvisorOwner({ ...editAdvisorOwner, openEdit: false })
                    };
                  }}
                >
                  Salvar
                </Button>
              </Box>
            </> : <Box display="flex" alignItems="center">
              <Typography variant='h5' component="span" fontWeight='bold'>
                Assessor:
              </Typography>
              {advisorOwner ? (
                <Typography variant='h5' component="span" ml={1} fontWeight='normal'>
                  {`${advisorOwner?.Nome} ${advisorOwner?.Sobrenome}`}
                </Typography>
              ) : (
                <Typography variant='h5' component="span" ml={1}>
                  -
                </Typography>
              )}
              <IconButton size='small' onClick={() => setEditAdvisorOwner({ ...editAdvisorOwner, openEdit: true })} sx={{ ml: 0.5, my: 0, p: 0 }}>
                <EditIcon fontSize='small' style={{ fontSize: 17 }} />
              </IconButton>
            </Box>
          }
        </Typography>
      </Grid>

      {
        !!client?.Idade && client?.Idade > 0 && <Grid item xs={6}>
          <Typography variant='h5' fontWeight='normal'>
            <span style={{ fontWeight: 'bold' }}>
              Idade:
            </span>
            <span style={{ marginLeft: '10px' }}>
              {client?.Idade}
            </span>
          </Typography>
        </Grid>
      }

      {
        client?.Cidade && <Grid item xs={6}>
          <Typography variant='h5' fontWeight='normal'>
            <span style={{ fontWeight: 'bold' }}>
              Cidade:
            </span>
            <span style={{ marginLeft: '10px' }}>
              {client?.Cidade}
            </span>
          </Typography>
        </Grid>
      }

      {
        client?.TelefoneContatoPrimario && (
          <Grid item xs={6}>
            <Box display="flex" alignItems="center">
              <Typography variant='h5' fontWeight='bold'>
                Telefone:
              </Typography>
              <Typography
                component='a'
                variant="h5"
                fontWeight="normal"
                target='_blank'
                href={whatsappLink}
                ml={1}
                style={{ position: 'relative', display: 'flex', alignItems: 'center' }}
              >
                {client.TelefoneContatoPrimario}
                <FaWhatsapp size={19} style={{ marginLeft: 5 }} color='green' />
              </Typography>
            </Box>
          </Grid>
        )
      }
    </Grid>
  </Grid >
}