import React, { useState } from 'react';
import { Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, InputAdornment } from '@mui/material';
import FactCheckIcon from '@mui/icons-material/FactCheckOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { useClientMirroringContext } from './context';
import { DialogClientsMirror } from './Dialog';

export const ClientsMirrorList: React.FC = () => {
  const [searchText, setSearchText] = useState('');

  const { loadingUsers, users, setSelectedUser } = useClientMirroringContext();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredUsers = users?.filter(user =>
    user?.NomeCompleto?.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      <TextField
        label='Pesquisar'
        size='small'
        value={searchText}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <TableContainer>
        <Table aria-label="Clients Mirror Table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '300px' }}>
                <Typography fontWeight='bold' textAlign='center'>
                  Nome
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight='bold' textAlign='center'>
                  Email
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight='bold' textAlign='center'>
                  Código
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingUsers ? (
              <TableRow>
                <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                  <Box minHeight={150} display='flex' justifyContent='center' alignItems='center'>
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            ) : filteredUsers && filteredUsers.length > 0 ? (
              filteredUsers.map((user) => (
                <TableRow
                  key={user.Id}
                  hover
                  onClick={() => setSelectedUser(user)}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <FactCheckIcon fontSize='small' />
                      <span style={{ marginLeft: '9px', fontSize: '14px', fontWeight: 'bold' }}>
                        {user.NomeCompleto}
                      </span>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ fontSize: 11 }}>{user.Email}</TableCell>
                  <TableCell sx={{ fontSize: 11, textAlign: 'center' }}>{user.CodigoAssessor}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                  Nenhum usuário encontrado.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <DialogClientsMirror />
    </>
  );
};