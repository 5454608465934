import { useState } from 'react';
import { Link, Navigate } from 'react-router-dom'
import { CadastroRequest } from '../../../Domain/DTO/Request/CadastroRequest';
import LoginService from '../../../Services/LoginService';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';

interface CadastroData {
  Nome: string;
  Sobrenome: string;
  CodigoAssessor: string;
  DataNascimento: Date | null;
  CPF: string;
  CEP: string;
  Email: string;
  Senha: string;
  RepetirSenha: string;
  ConcordoTermos: boolean;
}

const CriarContaComponent = () => {
  const loginService = new LoginService();

  const [cadastroData, setCadastroData] = useState<CadastroData>({
    Nome: '',
    Sobrenome: '',
    CodigoAssessor: '',
    DataNascimento: null,
    CPF: '',
    CEP: '',
    Email: '',
    Senha: '',
    RepetirSenha: '',
    ConcordoTermos: false
  })
  const [erros, setErros] = useState<String[]>([])
  const [sucesso, setSucesso] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)

  const onChangeInputs = (campo: keyof CadastroData, value: any) => {
    let valorFormatado = value;

    if (campo === 'CPF') {
      valorFormatado = mascaraCPF(value);
    } else if (campo === 'CEP') {
      valorFormatado = mascaraCEP(value);
    }

    setCadastroData((prevState) => ({ ...prevState, [campo]: valorFormatado }));
  };

  const cadastroEValido = () => {
    const errosLista = []

    if (!cadastroData.Nome || cadastroData.Nome.trim() === '')
      errosLista.push('O nome não pode ser vazio');

    if (!cadastroData.Sobrenome || cadastroData.Sobrenome.trim() === '')
      errosLista.push('O Sobrenome não pode ser vazio')

    // if (!cadastroData.CodigoAssessor || cadastroData.CodigoAssessor.trim() === '')
    //   errosLista.push('O Codigo do assessor não pode ser vazio')

    if (!cadastroData.DataNascimento)
      errosLista.push('A data de nascimento não pode ser vazia')

    if (!cadastroData.CPF || cadastroData.CPF.trim() == '')
      errosLista.push('O CPF não pode ser vazio')

    if (!cadastroData.CEP || cadastroData.CEP.trim() == '')
      errosLista.push('O CEP não pode ser vazio')

    // Checking email valid
    const checkingEmail = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailValid = checkingEmail.test(String(cadastroData.Email).toLowerCase());
    if (!emailValid) {
      errosLista.push('Email inválido.')
    }

    // Checking password valid
    const checkingPassword = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    const passwordValid = checkingPassword.test(String(cadastroData.Senha));
    if (!cadastroData.Senha || cadastroData.Senha.trim() == '') {
      errosLista.push('A senha não pode ser vazia.')
    } else if (!cadastroData.RepetirSenha || cadastroData.RepetirSenha.trim() == '') {
      errosLista.push('O repetir senha não pode ser vazio.')
    } else if (cadastroData.RepetirSenha.trim() != '' && (cadastroData.Senha !== cadastroData.RepetirSenha)) {
      errosLista.push('As senhas informadas não coincidem.')
    } else if (passwordValid == false) {
      errosLista.push('A senha deve conter no mínimo oito caracteres, pelo menos uma letra, um número e um caractere especial.')
    }

    if (!cadastroData.ConcordoTermos)
      errosLista.push('Você deve concordar com os termos')

    setErros(errosLista);

    return errosLista.length === 0;
  }

  const registrar = () => {
    setErros([])
    setLoading(true)

    if (!cadastroEValido()) {
      setLoading(false)
      return false
    }


    const cadastroRequest: CadastroRequest = {
      Nome: cadastroData.Nome,
      Sobrenome: cadastroData.Sobrenome,
      Email: cadastroData.Email,
      Senha: cadastroData.Senha,
      CPF: cadastroData.CPF,
      DataNascimento: new Date(),
      CadastroAssessor: {
        Codigo: cadastroData.CodigoAssessor
      },
      UsuarioEndereco: {
        CEP: cadastroData.CEP,
        Municipio: 'Não identificado',
        Estado: 'NI'
      }
    }

    loginService
      .Registrar(cadastroRequest)
      .then((result) => {
        setSucesso(true)
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false))
  }

  const handleDate = (newValue: Date | null) => {
    if (newValue instanceof Date || newValue === null) {
      setCadastroData({ ...cadastroData, DataNascimento: newValue });
    }
  }


  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const mascaraCPF = (valor: string): string => {
    return valor
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  };

  const mascaraCEP = (valor: string): string => {
    return valor
      .replace(/\D/g, '')
      .replace(/^(\d{5})(\d)/, '$1-$2');
  };

  if (sucesso)
    return <Navigate to='/#criar-conta-sucesso' />

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={6}>
          <TextField label='Nome' fullWidth variant='outlined' margin='dense'
            size='small'
            onChange={(target) => onChangeInputs('Nome', target.target.value)}
            value={cadastroData.Nome}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField label='Sobrenome' fullWidth variant='outlined' margin='dense'
            size='small'
            onChange={(target) => onChangeInputs('Sobrenome', target.target.value)}
            value={cadastroData.Sobrenome}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField label='Email' fullWidth variant='outlined' margin='dense'
            size='small'
            onChange={(target) => onChangeInputs('Email', target.target.value)}
            value={cadastroData.Email}
          />
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label='Data de nascimento'
              format='dd/MM/yyyy'
              onChange={handleDate}
              value={cadastroData.DataNascimento}
              slotProps={{
                textField: {
                  size: 'small',
                  fullWidth: true,
                  margin: 'dense'
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <TextField label='Código do Assessor' fullWidth variant='outlined' margin='dense'
            autoComplete='off'
            size='small'
            onChange={(target) => onChangeInputs('CodigoAssessor', target.target.value)}
            value={cadastroData.CodigoAssessor}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label='CPF'
            autoComplete='off'
            fullWidth
            variant='outlined'
            size='small'
            margin='dense'
            value={cadastroData.CPF}
            onChange={(event) => onChangeInputs('CPF', event.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label='CEP'
            fullWidth
            variant='outlined'
            size='small'
            margin='dense'
            autoComplete='off'
            value={cadastroData.CEP}
            onChange={(event) => onChangeInputs('CEP', event.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField label='Senha' fullWidth variant='outlined' margin='dense'
            autoComplete='off'
            size='small' type='password'
            value={cadastroData.Senha}
            onChange={(target) => onChangeInputs('Senha', target.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField label='Confirmação de senha' fullWidth variant='outlined' margin='dense'
            size='small' type='password'
            value={cadastroData.RepetirSenha}
            onChange={(target) => onChangeInputs('RepetirSenha', target.target.value)}
          />
        </Grid>
      </Grid>
      <Box sx={{ my: 4, justifyContent: 'center', display: 'flex' }}>
        <div className='checkbox'>
          <FormGroup>
            <FormControlLabel sx={{ textAlign: 'center' }} checked={cadastroData.ConcordoTermos}
              onClick={() => onChangeInputs('ConcordoTermos', !cadastroData.ConcordoTermos)} control={<Checkbox defaultChecked />}
              label={
                <span>Eu concordo com os <span id='modalTermosAll'><a href='#' onClick={() => setOpen(true)} >termos e condições.</a></span></span>

              } />
          </FormGroup>
        </div>
      </Box>
      <Box sx={{ textAlign: 'center', my: 3 }}>
        <LoadingButton sx={{ width: '90%', textAlign: 'center' }} loading={loading} onClick={() => registrar()} variant='contained'>Criar conta</LoadingButton>
      </Box>

      <Box component={Link} to='/' fontSize={13} textAlign='center' width={1}>
        <Typography>
          Já tem uma conta? <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Faça Login.</span>
        </Typography>
      </Box>

      <div className={erros.length > 0 ? 'input-erros col s12' : 'hide'}>
        <Box sx={{ p: 2, mt: 3 }}>
          <ul>
            {
              erros.map((erro, index) => {
                return <li key={index}>{erro}</li>
              })
            }

          </ul>
        </Box>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle id='scroll-dialog-title'>Subscribe</DialogTitle>
        <DialogContent >
          <DialogContentText
            id='scroll-dialog-description'
            tabIndex={-1}
          >
            <div className='modal-content'>
              <h4 className='center-align'>Termos e Condições</h4>
              <p>O presente termo visa a registrar a manifestação livre, informada e inequívoca pela qual o usuário, neste termo também chamado de Titular, concorda com o tratamento de seus dados pessoais para finalidade específica, em conformidade com a Lei nº 13.709 – Lei Geral de Proteção de Dados Pessoais.</p>
              <p>Ao manifestar sua aceitação com o presente termo, o usuário consente que a empresa Marco Investimentos, CNPJ 32.383.761/0001-66, com sede na Avenida Adolfo Fetter, nº 1304 Sala 03, neste termo denominada Controladora, realize o tratamento de dados pessoais, envolvendo as operações: coleta, utilização, armazenamento e eliminação.
              </p>
              <h5>Dados Pessoais:</h5>
              <p>A Controladora passa a estar habilitada para efetuar o tratamento dos seguintes dados pessoais do usuário:</p>
              <ul>
                <li>- Nome Completo</li>
                <li>- Data de Nascimento</li>
                <li>- CPF</li>
                <li>- CEP</li>
                <li>- Endereço de e-mail</li>
                <li>- Código do Assessor</li>
              </ul>

              <h5>Objetivo do Tratamento dos Dados</h5>
              <p>O tratamento dos dados pessoais listados neste termo tem as seguintes finalidades:</p>
              <ul>
                <li>- Possibilitar que o Assessor se cadastre e utilize a plataforma para fins de desenvolvimento no serviço de assessoria</li>
                <li>- Identificar o usuário</li>
                <li>- Entrar em contato com o usuário</li>
              </ul>

              <h5>Compartilhamento</h5>
              <p>A Marco Investimentos assegura que não realiza o compartilhamento dos dados pessoais do Titular com outros agentes de tratamento de dados. Além disso, sob nenhuma hipótese, comercializa as informações do cliente ou as repassa para terceiros, à exceção de autoridades constituídas, em estrito cumprimento da Lei.</p>

              <h5>Medidas de Segurança:</h5>
              <p>A Controladora se responsabiliza pela manutenção de medidas de segurança, técnicas e administrativas aptas a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito.</p>
              <p>Em conformidade ao art. 48 da Lei nº 13.709, a Controladora comunicará ao Titular e à Autoridade Nacional de Proteção de Dados (ANPD) a ocorrência de incidente de segurança que possa acarretar risco ou dano relevante ao Titular.</p>

              <h5>Direitos do Titular dos Dados</h5>
              <p>O Titular tem direito de obter da Controladora, em relação aos dados por ele tratados, a qualquer momento e mediante requisição:</p>
              <ul>
                <li>I - Confirmação da existência de tratamento;</li>
                <li>II - Acesso aos dados;</li>
                <li>III - Correção de dados incompletos, inexatos ou desatualizados;</li>
                <li>IV - Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na Lei nº 13.709;</li>
                <li>V - Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa e observados os segredos comercial e industrial, de acordo com a regulamentação do órgão controlador; </li>
                <li>VI - Eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas hipóteses previstas no art. 16 da Lei nº 13.709;</li>
                <li>VII - Informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;</li>
                <li>VIII - Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</li>
                <li>IX - Revogação do consentimento, nos termos do § 5º do art. 8º da Lei nº 13.709.</li>
                <li>X – Suporte para reclamação à Autoridade Nacional de Proteção de Dados.</li>
                <li>XI – Revisão das decisões tomadas exclusivamente por sistemas automatizados.</li>
                <li>XII – Concordância com oposição ao tratamento, caso irregular.  </li>
              </ul>

              <h5>Término do Tratamento dos Dados</h5>
              <p>Os Dados Pessoais serão eliminados após 5 (cinco) anos a partir do consentimento ou imediatamente mediante solicitação. </p>
              <p>O Titular poderá solicitar via e-mail ou de forma presencial à Controladora, a qualquer momento, que sejam eliminados os dados pessoais consentidos. O Titular fica ciente de que poderá ser inviável à Controladora continuar o fornecimento de produtos ou serviços ao Titular a partir da eliminação dos dados pessoais.</p>

              <h5>Direito de Revogação do Consentimento</h5>
              <p>O presente consentimento pode ser revogado pelo Titular, a qualquer momento, mediante solicitação via e-mail ou de forma presencial à Controladora.</p>

              <h5>Contato:</h5>
              <p>O titular pode entrar em contato para efetuar qualquer solicitação no e-mail:</p>
              <a href='mailto: dpo@marcoinvestimentos.com.br'>dpo@marcoinvestimentos.com.br</a>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={() => {
            onChangeInputs('ConcordoTermos', true)
            setOpen(false)
          }}>Aceitar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CriarContaComponent;