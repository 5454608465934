import Container from '@mui/material/Container'
import { CalendarComponent } from '../../Common/Atividades/CalendarComponent';

export const AtividadesPage = () => {

    return (
        <>
            <Container maxWidth="lg">
                <CalendarComponent />
            </Container>
        </>
    )
}