import DashboardEmbed from '../../Common/Dashboard/DashboardEmbed';
import Container from '@mui/material/Container';
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { PowerBITokenProvider } from '../../../Helpers/PowerBITokenProvider';
import { msalInstance } from '../../../Config/msalConfig';
import { Box, CircularProgress } from '@mui/material';

const DashboardPBI = (props: any) => {
  const instance = msalInstance;
  const { accounts } = useMsal();
  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    if (instance) {
      instance.initialize()
    }

    const ssoData = localStorage.getItem('loginSSO');
    if (ssoData) {
      const parsedSSOData = JSON.parse(ssoData);
      const expiryTime = new Date(parsedSSOData.expiresOn);
      const currentTime = new Date();
      if (currentTime < expiryTime) {
        console.log('Token is still valid. No need for new SSO call.');
        setAccessToken(parsedSSOData.accessToken);
        return;
      }
    }

    const doSilentSSO = async () => {
      const loginJson = localStorage.getItem("login");
      if (loginJson) {
        const loginObj = JSON.parse(loginJson);
        const email = loginObj.Email;
        const ssoRequest = {
          scopes: ["https://analysis.windows.net/powerbi/api/Report.Read.All"],
          loginHint: email
        };

        try {
          const loginResponse = await instance.ssoSilent(ssoRequest);
          console.log("SSO successful", loginResponse);
          localStorage.setItem("loginSSO", JSON.stringify(loginResponse));
          setAccessToken(loginResponse.accessToken);
        } catch (error) {
          console.error("SSO failed", { error });
          const loginResponse = await instance.loginPopup(ssoRequest);
          console.log("SSO successful", loginResponse);
          localStorage.setItem("loginSSO", JSON.stringify(loginResponse));
          setAccessToken(loginResponse.accessToken);
        }
      }
    };

    doSilentSSO();
  }, [instance, accounts]);

  return (
    <Container maxWidth="xl">
      <div className="content main">
        {
          accessToken ? <PowerBITokenProvider>
            {(token: string) => <DashboardEmbed accessToken={token} />}
          </PowerBITokenProvider> : <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 2, height: 200 }}>
            <CircularProgress size={40} thickness={4} />
          </Box>
        }
      </div>
    </Container>
  );
}

export default DashboardPBI;
