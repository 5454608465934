import React from 'react';
import { KanbanBoardConsorcios } from '../KanbanConsorcio';
import { useAdvisorConsorciosContext } from '../../../Hooks/useAdvisorConsorcioContext';
import { GenericLoading } from '../../GerenicLoading';

export const AdvisorConsorciosKanban: React.FC = () => {
  const { loading, filteredKanbanList } = useAdvisorConsorciosContext();

  if (loading) {
    return <GenericLoading height='50vh' />
  }

  return (
    <KanbanBoardConsorcios
      isDraggable={false}
      loading={loading}
      filteredKanbanList={filteredKanbanList}
    />
  );
};
