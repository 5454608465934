import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import SquadsServices from '../../../../../../Services/SquadsService'
import { SquadsContext } from '../../Context/SquadsConatext';

export const DialogCreateSquad = (params: any) => {
  const open = params.open
  const setOpen = params.setOpen

  // Squads Services
  let squadsServices = new SquadsServices()

  // Context
  const context: any = React.useContext(SquadsContext)

  const [nameToSquad, setNameToSquad] = React.useState("")
  function handleCreateSquad() {
    if (nameToSquad == "") {
      context.openAlertMessageFailed("Preencha algum nome para o squad.")
    } else {
      squadsServices.InserirSquad({ nome: nameToSquad })
        .then(() => {
          window.location.reload()
        })
        .catch(() => {
          context.openAlertMessageFailed("Ops... tivemos algum problema ao inserir o squad.")
        })
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs">
        <DialogTitle variant="h3" sx={{ pt: 5, px: 5, pb: 2 }}>Criar Squad</DialogTitle>
        <DialogContent sx={{ padding: 5 }}>
          <Divider sx={{ mb: 2, borderColor: "#b2b1c330" }} />
          {/* <DialogContentText variant="body1" sx={{ mb: 5 }}>
            Tem certeza que deseja marcar a atividade como <span style={{ fontWeight: "bold" }}>concluída</span>?
          </DialogContentText> */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="component-outlined">Nome do Squad</InputLabel>
                <OutlinedInput
                  id="component-outlined"
                  value={nameToSquad}
                  onChange={(event: any) => {
                    setNameToSquad(event.target.value)
                  }}
                  label="Nome do Squad"
                />
                <FormHelperText >Digite um nome para o Squad</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={6} textAlign="center" mt={3}>
              <Button variant="text" size="small"
                onClick={() => setOpen(false)}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6} textAlign="center" mt={3}>
              <Button variant="contained" color="secondary" size="small"
                onClick={() => {
                  handleCreateSquad()
                }}
              >
                Criar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}