import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface LoseDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (reason: string) => Promise<void>;
}

export const LoseDialog: React.FC<LoseDialogProps> = ({ open, onClose, onConfirm }) => {
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    if (reason.trim()) {
      setLoading(true);
      try {
        await onConfirm(reason);
        setReason('');
        onClose();
      } catch (error) {
        console.error('Erro ao confirmar:', error);
      } finally {
        setLoading(false);
      }
    }
  };


  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle variant='h4'>Motivo da Perda</DialogTitle>
      <DialogContent>
        <DialogContentText>Informe o motivo pelo qual o negócio foi perdido</DialogContentText>
        <TextField
          sx={{ mt: 2 }}
          multiline
          rows={4}
          autoFocus
          label='Motivo'
          fullWidth
          variant='outlined'
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} size='small' disabled={loading}>
          Cancelar
        </Button>
        <LoadingButton
          onClick={handleConfirm}
          variant='contained'
          loading={loading}
          size='small'
          color='error'
        >
          Confirmar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
