import NewResultado from "../../Common/PlanoDeVoo/Resultado/NewResultado"

import Box from '@mui/material/Box';

const PlanoDeVooResultado = (props: any) => {
    return (
        <Box sx={{ pt: 3, px: 7 }}>
            <NewResultado />
        </Box>
    )
}

export default PlanoDeVooResultado;