import { AUTH_HEADER } from "./Helpers/Headers";
import { IUser } from "../Interface/user";
import { IPermissionsUser } from "../Interface/interfacePermissions";

export default class PermissionsService {
  private readonly API_URL = process.env.REACT_APP_API_URL;

  private async handleErrors(response: Response): Promise<any> {
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      try {
        return await response.json();
      } catch (error) {
        return null;
      }
    } else {
      return null;
    }
  }

  public async ListPermissionsUsers(userId: string): Promise<IPermissionsUser[]> {
    return await fetch(`${this.API_URL}/permissions/list-user-permissions/${userId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(err);
      });
  }

  public async ListInsuranceUsers(): Promise<IUser[]> {
    return await fetch(`${this.API_URL}/permissions/list-insurance-users`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(this.handleErrors)
      .catch(err => {
        throw new Error(err);
      });
  }
}