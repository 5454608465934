import { createContext, useContext, useEffect, useState, ReactNode, useMemo } from "react";
import ClientesService from "../Services/ClientesService";
import { interfaceClientSeguros } from "../Interface/interfaceClient";
import { enqueueSnackbar } from "notistack";
import { DropResult } from "react-beautiful-dnd";
import { toUpperSnakeCase } from "../Helpers/formatters";
import StorageHelper from "../Services/Helpers/StorageHelper";
import { createTask } from "../Components/Common/NewProfileClient/crudTask";
import { formattingDateToISO } from "../Components/Common/NewProfileClient/usefulFunctions";
import { interfaceSchedule } from "../Interface/intarfaceSchedule";

export type CategoriaKanbanInsurance = 'lead' | 'r1' | 'r2' | 'aguardando' | 'fechado' | 'emAnalise' | 'emitido' | 'pausado';

const categoriaMapping: Record<CategoriaKanbanInsurance, string> = {
  lead: "LEAD",
  r1: "R1",
  r2: "R2",
  aguardando: "AGUARDANDO",
  fechado: "FECHADO",
  emAnalise: "EM_ANALISE",
  emitido: "EMITIDO",
  pausado: 'PAUSADO'
};

const droppableIdToName: Record<CategoriaKanbanInsurance, string> = {
  lead: 'Lead',
  r1: 'R1',
  r2: 'R2',
  aguardando: 'Aguardando',
  fechado: 'Fechado',
  emAnalise: 'Em Análise',
  emitido: 'Emitido',
  pausado: 'Pausado'
};

export interface IKanbanInsuranceList {
  lead: {
    name: string;
    data: interfaceClientSeguros[];
  };
  r1: {
    name: string;
    data: interfaceClientSeguros[];
  };
  r2: {
    name: string;
    data: interfaceClientSeguros[];
  };
  aguardando: {
    name: string;
    data: interfaceClientSeguros[];
  };
  fechado: {
    name: string;
    data: interfaceClientSeguros[];
  };
  emAnalise: {
    name: string;
    data: interfaceClientSeguros[];
  };
  emitido: {
    name: string;
    data: interfaceClientSeguros[];
  };
  pausado: {
    name: string;
    data: interfaceClientSeguros[];
  }
}

const useKanbanInsurance = () => {
  const clientesService = new ClientesService();
  const storageHelper = new StorageHelper();
  const loggedUserId = storageHelper.GetUsuarioId();

  const [usuarioId, setUsuarioId] = useState<string>(loggedUserId);
  const [kanbanInsuranceList, setKanbanInsuranceList] = useState<IKanbanInsuranceList>({
    lead: {
      name: 'Lead',
      data: []
    },
    r1: {
      name: 'R1',
      data: []
    },
    r2: {
      name: 'R2',
      data: []
    },
    aguardando: {
      name: 'Aguardando',
      data: []
    },
    fechado: {
      name: 'Fechado',
      data: []
    },
    emAnalise: {
      name: 'Em Análise',
      data: []
    },
    emitido: {
      name: 'Emitido',
      data: []
    },
    pausado: {
      name: 'Pausado',
      data: []
    }
  });

  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const fetchClients = async () => {
    try {
      const statusArray = ["LEAD", "R1", "R2", "AGUARDANDO", "FECHADO", "EM_ANALISE", "EMITIDO", "PAUSADO"];
      const clients = await clientesService.ListInsuranceClients(usuarioId, statusArray);

      const updatedKanbanList: IKanbanInsuranceList = {
        lead: { name: 'Lead', data: [] },
        r1: { name: 'R1', data: [] },
        r2: { name: 'R2', data: [] },
        aguardando: { name: 'Aguardando', data: [] },
        fechado: { name: 'Fechado', data: [] },
        emAnalise: { name: 'Em Análise', data: [] },
        emitido: { name: 'Emitido', data: [] },
        pausado: { name: 'Pausado', data: [] }
      };

      clients.forEach(client => {
        const statusKey = Object.keys(categoriaMapping).find(key => categoriaMapping[key as CategoriaKanbanInsurance] === client.StatusSeguros);
        if (statusKey) {
          updatedKanbanList[statusKey as CategoriaKanbanInsurance].data.push(client);
        }
      });

      setKanbanInsuranceList(updatedKanbanList);
    } catch (error) {
      enqueueSnackbar('Erro ao buscar clientes.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClients();
  }, [usuarioId]);

  const onDragEnd = async (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!destination || destination.droppableId === source.droppableId) {
      return;
    }

    const startColumnData = kanbanInsuranceList[source.droppableId as CategoriaKanbanInsurance].data;
    const finishColumnData = kanbanInsuranceList[destination.droppableId as CategoriaKanbanInsurance].data;

    const clientMoved = startColumnData.find(client => client.Id === draggableId);
    if (!clientMoved) {
      enqueueSnackbar('Ops... tivemos um problema.', { variant: 'error' });
      return;
    }

    // Verificação adicional para garantir que o usuário logado é o proprietário do cliente
    if (clientMoved.UsuarioIdSeguros !== loggedUserId) {
      enqueueSnackbar('Você não tem permissão para mover este cliente.', { variant: 'error' });
      return;
    }

    const newStatus = toUpperSnakeCase(destination.droppableId) as interfaceClientSeguros['StatusSeguros'];

    // Atualiza o estado imediatamente
    const newStartColumnData = startColumnData.filter(client => client.Id !== draggableId);
    const updatedClientMoved = { ...clientMoved, StatusSeguros: newStatus };
    const newFinishColumnData = [updatedClientMoved, ...finishColumnData];

    const prevKanbanInsuranceList = { ...kanbanInsuranceList };

    setKanbanInsuranceList(prevState => ({
      ...prevState,
      [source.droppableId]: {
        ...prevState[source.droppableId as CategoriaKanbanInsurance],
        data: newStartColumnData,
      },
      [destination.droppableId]: {
        ...prevState[destination.droppableId as CategoriaKanbanInsurance],
        data: newFinishColumnData,
      }
    }));

    try {
      // Atualização da data de emissão do seguro se o novo status for EMITIDO
      if (newStatus === 'EMITIDO') {
        updatedClientMoved.DataEmissaoSeguros = new Date().toISOString();
      }

      await clientesService.UpdateStatusSeguros(clientMoved.Id!, newStatus);

      const now = new Date();
      const descricao = newStatus === 'EMITIDO'
        ? 'Seguro Emitido'
        : `Movido de ${droppableIdToName[source.droppableId as CategoriaKanbanInsurance]} para ${droppableIdToName[destination.droppableId as CategoriaKanbanInsurance]}.`;

      const newActivity: interfaceSchedule = {
        DataEvento: formattingDateToISO(now),
        AtividadeFinalizada: true,
        Title: 'Movimentação em Seguros',
        Descricao: descricao,
        AssessorUsuarioId: usuarioId,
        ClienteId: clientMoved.Id!,
        Visto: false,
        DataTermino: formattingDateToISO(now),
        StatusTarefa: 'FINISHED',
        AtividadeFinalizadaData: formattingDateToISO(now),
        AtividadeTodoDia: false,
        CriadoPorArea: 'Seguros'
      };

      await createTask(newActivity);
    } catch (error) {
      enqueueSnackbar('Erro ao atualizar status.', { variant: 'error' });
      // Reverte o estado em caso de erro
      setKanbanInsuranceList(prevKanbanInsuranceList);
    }
  };

  const filteredKanbanList = useMemo(() => {
    if (!searchTerm) return kanbanInsuranceList;

    const lowercasedSearchTerm = searchTerm.toLowerCase();

    const filterData = (clients: interfaceClientSeguros[]) =>
      clients.filter(client =>
        client.NomeCompleto?.toLowerCase().includes(lowercasedSearchTerm)
      );

    return Object.fromEntries(
      Object.entries(kanbanInsuranceList).map(([key, column]) => [
        key,
        { ...column, data: filterData(column.data) }
      ])
    ) as IKanbanInsuranceList;
  }, [searchTerm, kanbanInsuranceList]);

  const changeClientStatusWithReason = async (client: interfaceClientSeguros, newStatus: interfaceClientSeguros['StatusSeguros'], reason: string) => {
    if (!newStatus && (!client || client === null || typeof client === 'undefined')) {
      return;
    }

    const oldStatusKey = Object.keys(categoriaMapping).find(key => categoriaMapping[key as CategoriaKanbanInsurance] === client.StatusSeguros) as CategoriaKanbanInsurance;

    if (!oldStatusKey) {
      enqueueSnackbar('Status antigo não encontrado.', { variant: 'error' });
      return;
    }

    const updatedClient = { ...client, StatusSeguros: newStatus };
    const now = new Date();

    // Atualiza o estado imediatamente
    setKanbanInsuranceList(prevState => {
      const newStartColumnData = prevState[oldStatusKey].data.filter(c => c.Id !== client.Id);

      // Se o novo status não estiver no Kanban, apenas remove o cliente da lista
      if (newStatus === 'CANCELADO' || newStatus === 'PERDEU') {
        return {
          ...prevState,
          [oldStatusKey]: {
            ...prevState[oldStatusKey],
            data: newStartColumnData,
          },
        };
      }

      const newStatusKey = Object.keys(categoriaMapping).find(key => categoriaMapping[key as CategoriaKanbanInsurance] === newStatus) as CategoriaKanbanInsurance;

      if (!newStatusKey) {
        enqueueSnackbar('Novo status não encontrado.', { variant: 'error' });
        return prevState;
      }

      const newFinishColumnData = [updatedClient, ...prevState[newStatusKey].data];

      return {
        ...prevState,
        [oldStatusKey]: {
          ...prevState[oldStatusKey],
          data: newStartColumnData,
        },
        [newStatusKey]: {
          ...prevState[newStatusKey],
          data: newFinishColumnData,
        }
      };
    });

    try {
      // Atualização da data de emissão do seguro se o novo status for EMITIDO
      if (newStatus === 'EMITIDO') {
        updatedClient.DataEmissaoSeguros = new Date().toISOString();
      }

      await clientesService.UpdateStatusSeguros(client.Id!, newStatus);

      const newActivity: interfaceSchedule = {
        DataEvento: formattingDateToISO(now),
        AtividadeFinalizada: true,
        Title: 'Movimentação em Seguros',
        Descricao: `Motivo: ${reason}`,
        AssessorUsuarioId: usuarioId,
        ClienteId: client.Id!,
        Visto: false,
        DataTermino: formattingDateToISO(now),
        StatusTarefa: 'FINISHED',
        AtividadeFinalizadaData: formattingDateToISO(now),
        AtividadeTodoDia: false,
        CriadoPorArea: 'Seguros'
      };

      await createTask(newActivity);
    } catch (error) {
      enqueueSnackbar('Erro ao atualizar status.', { variant: 'error' });
      // Reverte o estado em caso de erro
      setKanbanInsuranceList(prevState => {
        const newStartColumnData = prevState[oldStatusKey].data.filter(c => c.Id !== client.Id);
        const newFinishColumnData = [client, ...prevState[oldStatusKey].data];

        return {
          ...prevState,
          [oldStatusKey]: {
            ...prevState[oldStatusKey],
            data: newFinishColumnData,
          },
        };
      });
    }
  };

  return {
    kanbanInsuranceList: filteredKanbanList,
    onDragEnd,
    setKanbanInsuranceList,
    loading,
    setUsuarioId,
    usuarioId,
    setSearchTerm,
    changeClientStatusWithReason
  };
};

interface KanbanInsuranceContextProps {
  kanbanInsuranceList: IKanbanInsuranceList;
  setKanbanInsuranceList: React.Dispatch<React.SetStateAction<IKanbanInsuranceList>>;
  onDragEnd: (result: DropResult) => void;
  loading: boolean;
  setUsuarioId: React.Dispatch<React.SetStateAction<string>>;
  usuarioId: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  changeClientStatusWithReason: (client: interfaceClientSeguros, newStatus: interfaceClientSeguros['StatusSeguros'], reason: string) => Promise<void>;
}

const KanbanInsuranceContext = createContext<KanbanInsuranceContextProps | undefined>(undefined);

export const KanbanInsuranceProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const kanbanInsuranceValues = useKanbanInsurance();

  return (
    <KanbanInsuranceContext.Provider value={kanbanInsuranceValues}>
      {children}
    </KanbanInsuranceContext.Provider>
  );
};

export const useKanbanInsuranceContext = () => {
  const context = useContext(KanbanInsuranceContext);
  if (!context) {
    throw new Error('useKanbanInsuranceContext must be used within a KanbanInsuranceProvider');
  }
  return context;
};