import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Navigate } from "react-router-dom"
import Clientes from "../../../Domain/Clientes";
import { ClientesAtivosEBens } from "../../../Domain/ClientesAtivosEBens";
import { ClientesDependentes } from "../../../Domain/ClientesDependentes";
import { ClientesDespesas } from "../../../Domain/ClientesDespesas";
import { ClientesProjetosVida } from "../../../Domain/ClientesProjetosVida";
import { ClientesIndependencia } from "../../../Domain/ClientesIndependencia"
import { ClientesReceitas } from "../../../Domain/ClientesReceitas";
import { ClientesCompletedSteps } from "../../../Domain/ClientesCompletedSteps";
import ClientesService from "../../../Services/ClientesService";
import startPlanoDeVooImg from '../../../Assets/Images/start-plano-de-voo.png'
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import InfosCliente from "./Step1ClienteInfosComponent"
import Dependentes from "./Step2Dependentes"
import InfosFinanceiras from "./Step3InfosFinanceiras"
import AtivosFinanceiros from "./Step4AtivosFinanceiros"
import Bens from "./Step5Bens"
import Outros from "./Step6Outros"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ClienteDependentesValidation from "../PlanoDeVoo/Steps/Validations/Step3Validation"
import ClienteInformacoesFinanceirasValidation from "../PlanoDeVoo/Steps/Validations/Step4Validation"
import ClienteAtivosFinanceirosValidation from "../PlanoDeVoo/Steps/Validations/Step5Validation"
import ClienteAtivosNaoLiquidaveisValidation from "../PlanoDeVoo/Steps/Validations/Step6Validation"
import { Container, List } from "@mui/material";
import { clienteInfosValidation } from "../PlanoDeVoo/Steps/Validations/Step1Validation";

const PrimeiraReuniao = () => {
    let navigate = useNavigate();

    const clientesService = new ClientesService()

    const { userId } = useParams<any>()
    const [usuarioId, setUsuarioId] = useState<string>('')
    const [idCliente, setIdCliente] = useState<string>('')
    const [erros, setErros] = useState<string[]>([])
    const [loading, setLoading] = useState(false)
    const [passoAtual, setPassoAtual] = useState(1)
    const [cliente, setCliente] = useState<any>(new Clientes())
    const [dependentes, setDependentes] = useState<any>([{ Id: '', NomeCompleto: '', DataNascimento: '2000-01-01', Parentesco: '', ClienteId: '', Idade: null }])
    const [dependentesCustos, setDependentesCustos] = useState<any>([])
    const [projetos, setProjetos] = useState<ClientesProjetosVida[]>([{ Id: '', ClienteId: '', Valor: '', Descricao: '', TempoParaRealizarAnos: NaN }]);
    const [independencia, setIndependencia] = useState<ClientesIndependencia>({ Id: '', ClienteId: '', QntdTempoInvestimento: NaN, QntdTempoVivendoDeRenda: 0 })
    const [receitas, setReceitas] = useState<any>({ Id: '', ClienteId: '', RendaBrutaMensal: undefined, SaldoAcumuladoFGTS: undefined, PensaoINSSOutros: 0, PrevidenciaPrivada: 0, OutrasRendasMensais: 0, SeguroDeVida: 0, AporteMensal: 0, AporteMensalRecorrencia: false, PerfilCliente: '', PotencialSeguro: true, AtividadeProfissional: false, Heranca: false, Aposentadoria: false, Alugueis: false, Doacao: false, Outras: false });
    const [despesas, setDespesas] = useState<any>({ Id: '', ClienteId: '', DespesaMensal: '', ContribuicaoDespesaPorcentagem: 100, ManutencaoPadraoDeVida: 0, RestabelecimentoVidaMeses: 0, TipoIRF: "" });
    const [ativos, setAtivos] = useState<any[]>([{ Id: '', ClienteId: '', Ativo: '', Valor: '', Comentario: '' }])
    const [bens, setBens] = useState<ClientesAtivosEBens[]>([{ Id: '', ClienteId: '', Ativo: '', Valor: '', Comentario: '' }])
    const [completedSteps, setCompletedStep] = useState<ClientesCompletedSteps>({ step1: false, step2: false, step3: false, step4: false, step5: false, step6: false, step7: false });
    const [initialLaodingEnd, setInitialLoadingEnd] = useState(false)
    const [blockFirstStep, setBlockFirstStep] = useState(true)
    const [readyForGenPDF, setReadyForGenPDF] = useState(false)
    const [gerarPDF, setGerarPDF] = useState('')
    const [nota, setNota] = useState('')
    const [openBackdrop, setOpenBackdrop] = useState(false)

    const clienteDependentesValidation = ClienteDependentesValidation
    const clienteInformacoesFinanceirasValidation = ClienteInformacoesFinanceirasValidation
    const clienteAtivosFinanceirosValidation = ClienteAtivosFinanceirosValidation
    const clienteAtivosNaoLiquidaveisValidation = ClienteAtivosNaoLiquidaveisValidation

    const [getInfosClienteFirstTime, setGetInfosClienteFirstTime] = useState(false)
    useEffect(() => {
        let cancel = false

        if (userId) {
            setUsuarioId(userId)
            setOpenBackdrop(true)


            Promise.all([
                clientesService.BuscarCliente(userId)
                    .then((e: any) => {
                        return e
                    })
                    .catch(e => e),

                clientesService.ListaDependentes(userId)
                    .then((e: any) => {
                        return e
                    })
                    .catch(e => e),

                clientesService.ListarProjetos(userId)
                    .then((e: ClientesProjetosVida[]) => {
                        return e
                    })
                    .catch(e => e),

                clientesService.ListaReceita(userId)
                    .then((e: ClientesReceitas) => {
                        return e
                    })
                    .catch(e => e),

                clientesService.GetDespesas(userId)
                    .then((e: ClientesDespesas) => {
                        return e
                    }).catch(e => e),

                clientesService.ListarAtivos(userId)
                    .then((e: ClientesAtivosEBens[]) => {
                        return e
                    }).catch(e => e),

                clientesService.ListarBens(userId)
                    .then((e: ClientesAtivosEBens[]) => {
                        return e
                    }).catch(e => e),

                clientesService.GetIndependenciaFinanceira(userId)
                    .then((e: any) => {
                        return e
                    }).catch(e => e)

            ]).then((values) => {
                if (cancel) return;
                // Cliente
                if (values[0].DataNascimento != null) {
                    let dataNascimento = new Date(values[0].DataNascimento)
                    // dataNascimento.setMonth(dataNascimento.getMonth() + 1)
                    // let month = dataNascimento.getMonth().toString()
                    // if (!(dataNascimento.getMonth() > 9)) {
                    //     month = "0" + month
                    // }
                    // let day = dataNascimento.getDate().toString()
                    // if (!(dataNascimento.getDate() > 9)) {
                    //     day = "0" + day
                    // }
                    // let dataNascimentoText = dataNascimento.getFullYear() + "-" + month + "-" + day
                    // values[0].DataNascimento = dataNascimentoText
                    values[0].DataNascimento = dataNascimento
                }
                setCliente(values[0])
                setInitialLoadingEnd(true)

                // Dependentes
                if (values[1].length > 0) {
                    setDependentes(values[1])
                } else if (values[1].length == 0) {
                    dependentes[0].ClienteId = values[0].Id
                    setDependentes([...dependentes])
                }

                // Projetos
                // if (values[2].length > 0) {
                //     values[2].forEach((value: any, index: any) => {
                //         values[2][index].Valor = parseFloat(value.Valor).toFixed(2)
                //     })
                //     setProjetos(values[2])
                // }

                // Receitas
                if (!(values[3] instanceof Error)) {
                    if (Number(values[3].RendaBrutaMensal) >= 0) {
                        // values[3].RendaBrutaMensal = parseFloat(values[3].RendaBrutaMensal).toFixed(2)
                    }
                    // values[3].AporteMensal = parseFloat(values[3].AporteMensal).toFixed(2)
                    // values[3].SaldoAcumuladoFGTS = parseFloat(values[3].SaldoAcumuladoFGTS).toFixed(2)
                    // values[3].PensaoINSSOutros = parseFloat(values[3].PensaoINSSOutros).toFixed(2)
                    // values[3].OutrasRendasMensais = parseFloat(values[3].OutrasRendasMensais).toFixed(2)
                    setReceitas(values[3])
                }

                if (!(values[4] instanceof Error)) {
                    // values[4].DespesaMensal = parseFloat(values[4].DespesaMensal).toFixed(2)
                    setDespesas(values[4])
                }

                if (values[5].length > 0) {
                    values[5].forEach((value: any, index: any) => {
                        // values[5][index].Valor = parseFloat(value.Valor).toFixed(2)
                    })
                    setAtivos(values[5])
                }

                if (values[6].length > 0) {
                    values[6].forEach((value: any, index: any) => {
                        // values[6][index].Valor = parseFloat(value.Valor).toFixed(2)
                    })
                    setBens(values[6])
                }


                setGetInfosClienteFirstTime(true)
            })
                .catch((e) => {

                })
                .finally(() => {
                    setOpenBackdrop(false)
                })
        } else {
            setInitialLoadingEnd(true)
        }

        return () => {
            cancel = true
        }
    }, [])

    let promisesRequestsForm: any = []
    // Switch 'possui ativos ou bens'
    const [thereIsAtivos, setThereIsAtivos] = useState(true)
    const [thereIsBens, setThereIsBens] = useState(true)

    const onClickSalvar = () => {
        // var elems = document.querySelectorAll('.modal');
        // var instances = M.Modal.init(elems, { endingTop: "3%" });
        setLoading(true)
        try {
            validations()
        } catch (error) {
            console.log(error)
            setLoading(false)
            return
        }

        setErros([])

        atualizarCliente()
        if (cliente.PossuiDependentes) {
            atualizarDependentes()
        }
        atualizarInformacoesFinanceiras()
        if (thereIsAtivos) {
            atualizarAtivos()
        }
        if (thereIsBens) {
            atualizarAtivosNaoLiquidaveis()
        }
        atualizarOutros()

        Promise.all(promisesRequestsForm)
            .then(_ => {
                setLoading(false)
                setOpen(true)
            })
            .catch(() => {
                let error = ["Algum problema ocorreu no Formulário Plano de Voo.", "Por favor, contate a equipe técnica."]
                setErros(error)
                setLoading(false)
            })
    }

    // const [openModalRedirect, setOpenModalRedirect] = useState(false)
    // useEffect(() => {
    //     let closeEnd = () => {
    //         setOpenModalRedirect(false)
    //     }

    //     let el = document.getElementById('modalRedirectToPlanoDeVoo')
    //     let instance = M.Modal.getInstance(el!);
    //     instance.options.onCloseEnd = closeEnd

    //     if (openModalRedirect) {
    //         instance.open()
    //     }
    // }, [openModalRedirect])

    const validations = () => {
        // Cliente Infos
        let clienteInfosIsValid = clienteInfosValidation(cliente)
        if (!clienteInfosIsValid.isValid) {
            setErros(clienteInfosIsValid.errorDescriptions)
            throw ("Error 1")
        }

        // Dependentes
        if (cliente.PossuiDependentes) {
            let clienteDependentesIsValid = clienteDependentesValidation(dependentes)
            if (!clienteDependentesIsValid.isValid) {
                setErros(clienteDependentesIsValid.errorDescriptions)
                throw ("Error 2")
            }
        }

        // Infos Financeiras
        let clienteAtivosFinanceirosValidationIsValid = clienteInformacoesFinanceirasValidation(receitas, despesas)
        if (!clienteAtivosFinanceirosValidationIsValid.isValid) {
            setErros(clienteAtivosFinanceirosValidationIsValid.errorDescriptions)
            throw ("Error 3")
        }

        // Ativos
        if (thereIsAtivos) {
            let clienteInformacoesFinanceirasIsValid = clienteAtivosFinanceirosValidation(ativos)
            if (!clienteInformacoesFinanceirasIsValid.isValid) {
                setErros(clienteInformacoesFinanceirasIsValid.errorDescriptions)
                throw ("Error 4")
            }
        }

        // Bens
        if (thereIsBens) {
            let clienteInformacoesFinanceirasIsValidBens = clienteAtivosNaoLiquidaveisValidation(bens)
            if (!clienteInformacoesFinanceirasIsValidBens.isValid) {
                setErros(clienteInformacoesFinanceirasIsValidBens.errorDescriptions)
                throw ("Error 5")
            }
        }
    }

    const atualizarCliente = () => {
        // let clienteInfosIsValid = clienteInfosValidation(cliente)
        // if (!clienteInfosIsValid.isValid) {
        //     setErros(clienteInfosIsValid.errorDescriptions)
        //     throw ("Error 1")
        // }

        if (cliente.Id == '') {
            // salvarCliente()
        } else if (cliente.Id != '') {
            updateCliente()
        }
    }

    const updateCliente = () => {
        promisesRequestsForm.push(
            clientesService.UpdateClientes(cliente)
                .then((e: any) => { })
                .catch(e => {

                })
        )
    }

    const atualizarDependentes = () => {
        const dependentesNaoCadastrados = dependentes.filter((d: any) => d.Id == '');
        const dependentesCadastradosUpdate = dependentes.filter((d: any) => d.Id != '');

        // let clienteDependentesIsValid = clienteDependentesValidation(dependentes)
        // if (!clienteDependentesIsValid.isValid) {
        //     setErros(clienteDependentesIsValid.errorDescriptions)
        //     throw ("Error 1")
        // }
        // Save dependentes
        if (dependentesNaoCadastrados.length > 0) {
            dependentesNaoCadastrados.forEach((value: any) => {
                value.ClienteId = usuarioId
                delete value.Id
            })
            salvarDependentes(dependentesNaoCadastrados)
        }

        // Update dependentes (cadastrados)
        if (dependentesCadastradosUpdate.length > 0) {
            updateDependentes(dependentesCadastradosUpdate)
        }

        // There is not dependent
        if (dependentesNaoCadastrados.length <= 0 && dependentesCadastradosUpdate.length <= 0) {
            // setCompletedStep(prevState => ({
            //     ...prevState, step3: true
            // }))
        }
    }

    const updateDependentes = (listaDependentes: any) => {
        promisesRequestsForm.push(
            clientesService
                .UpdateDependentesLista(listaDependentes)
                .then(e => { })
                .catch(e => {

                })
        )
    }

    const salvarDependentes = (listaDependentes: ClientesDependentes[]) => {
        promisesRequestsForm.push(
            clientesService.InserirDependentesLista(listaDependentes)
                .then((e) => {
                    e.map(dependente => {
                        for (let i = 0; i < dependentes.length; i++) {
                            if (dependente.NomeCompleto == dependentes[i].NomeCompleto) {
                                dependentes[i].Id = dependente.Id
                                setDependentes([...dependentes])
                            }
                        }
                    })
                })
                .then(() => {
                    // atualizarCustos()
                })
                .catch(e => {

                })
        )
    }

    const atualizarInformacoesFinanceiras = () => {
        // let clienteAtivosFinanceirosValidationIsValid = clienteInformacoesFinanceirasValidation(receitas, despesas)
        // if (!clienteAtivosFinanceirosValidationIsValid.isValid) {
        //     setErros(clienteAtivosFinanceirosValidationIsValid.errorDescriptions)
        //     return;
        // }

        if (!(receitas.SaldoAcumuladoFGTS! >= 0)) {
            receitas.SaldoAcumuladoFGTS = 0
            setReceitas({ ...receitas })
        }
        if (!(receitas.OutrasRendasMensais! >= 0)) {
            receitas.OutrasRendasMensais = 0
            setReceitas({ ...receitas })
        }

        Promise.all([
            atualizarReceitas(),
            atualizarDespesas()
        ]).then(values => {
        }).catch(error => {
            console.log(error)
        })

    }

    const atualizarReceitas = () => {
        if (receitas.Id == '') {
            receitas.ClienteId = usuarioId
            receitas.AporteMensalRecorrencia = Boolean(receitas.AporteMensalRecorrencia)
            salvarReceitas()
        } else if (receitas.Id != '') {
            receitas.ClienteId = usuarioId
            receitas.AporteMensalRecorrencia = Boolean(receitas.AporteMensalRecorrencia)
            updateReceitas();
        } else {
        }
    }

    const updateReceitas = () => {
        promisesRequestsForm.push(
            clientesService.UpdateReceitas(receitas)
                .then(e => {
                    // setReceitas({ ...receitas, Id: e.Id })
                    // nextStep()
                })
                .catch(e => {

                })
                .finally(() => {
                })
        )
    }

    const salvarReceitas = () => {
        delete receitas.Id
        promisesRequestsForm.push(
            clientesService.InserirReceitas(receitas)
                .then(e => {
                    setReceitas({ ...receitas, Id: e.Id })
                    // nextStep()
                })
                .catch(e => {

                })
                .finally(() => {
                })
        )
    }

    const atualizarDespesas = () => {
        if (despesas.Id == '') {
            despesas.ClienteId = usuarioId
            salvarDespesas()
        } else if (despesas.Id != '') {
            updateDespesas()
        } else {
        }
    }

    const updateDespesas = () => {
        promisesRequestsForm.push(
            clientesService.UpdateDepesas(despesas)
                .then(e => {
                    // setDespesas({ ...despesas, Id: e.Id })
                    // nextStep()
                })
                .catch(e => {

                })
                .finally(() => {
                })
        )
    }

    const salvarDespesas = () => {
        delete despesas.Id
        promisesRequestsForm.push(
            clientesService.InserirDepesas(despesas)
                .then(e => {
                    setDespesas({ ...despesas, Id: e.Id })
                    // nextStep()
                })
                .catch(e => {

                })
                .finally(() => {
                })
        )
    }

    const atualizarAtivos = () => {
        // let clienteInformacoesFinanceirasIsValid = clienteAtivosFinanceirosValidation(ativos)
        // if (!clienteInformacoesFinanceirasIsValid.isValid) {
        //     setErros(clienteInformacoesFinanceirasIsValid.errorDescriptions)
        //     return null
        // }

        const ativosNovos = ativos.filter(e => e.Id == '')
        const ativosUpdate = ativos.filter(e => e.Id != '')
        // if (!ativosLiquidaveisIsValid()) {
        //     return null
        // }

        if (ativosNovos.length > 0) {
            ativosNovos.forEach((e) => { e.ClienteId = usuarioId; delete e.Id })
            salvarAtivos(ativosNovos)
        }

        if (ativosUpdate.length > 0) {
            updateAtivos(ativosUpdate)
        }

        if (ativosNovos.length <= 0 && ativosUpdate.length <= 0) {
            setCompletedStep(prevState => ({
                ...prevState, step5: true
            }))
        }

    }

    const updateAtivos = (ativosFiltrados: ClientesAtivosEBens[]) => {
        promisesRequestsForm.push(
            clientesService.UpdateAtivosLista(ativosFiltrados)
                .then(e => {
                    setCompletedStep(prevState => ({ ...prevState, step5: true }))
                })
                .catch(e => {

                })
                .finally(() => {
                })
        )
    }

    const salvarAtivos = (ativosFiltrados: ClientesAtivosEBens[]) => {
        promisesRequestsForm.push(
            clientesService.InserirAtivosLista(ativosFiltrados)
                .then(e => {
                    ativos.forEach(a => {
                        const findAtivo = e.find(r => r.Ativo == a.Ativo && r.Valor == a.Valor)
                        return a.Id = findAtivo?.Id || ''
                    })
                    setAtivos([...ativos])
                    setCompletedStep(prevState => ({ ...prevState, step5: true }))
                })
                .catch(e => {

                })
                .finally(() => {
                })
        )
    }

    const atualizarAtivosNaoLiquidaveis = () => {
        // let clienteInformacoesFinanceirasIsValid = clienteAtivosNaoLiquidaveisValidation(bens)
        // if (!clienteInformacoesFinanceirasIsValid.isValid) {
        //     setErros(clienteInformacoesFinanceirasIsValid.errorDescriptions)
        //     return null
        // }

        const ativosNaoLiquidaveisNovos = bens.filter(e => e.Id == '')
        const ativosNaoLiquidaveisUpdate = bens.filter(e => e.Id != '')

        if (ativosNaoLiquidaveisNovos.length > 0) {
            ativosNaoLiquidaveisNovos.forEach((e) => { e.ClienteId = usuarioId; delete e.Id })
            salvarAtivosNaoLiquidaveis(ativosNaoLiquidaveisNovos)
        }

        if (ativosNaoLiquidaveisUpdate.length > 0) {
            updateAtivosNaoLiquidaveis(ativosNaoLiquidaveisUpdate)
        }

        if (ativosNaoLiquidaveisNovos.length <= 0 && ativosNaoLiquidaveisUpdate.length <= 0) {
            setCompletedStep(prevState => ({
                ...prevState, step6: true
            }))
        }
    }

    const updateAtivosNaoLiquidaveis = (ativosFiltrados: ClientesAtivosEBens[]) => {
        promisesRequestsForm.push(
            clientesService.UpdateAtivosNaoLiquidaveisLista(ativosFiltrados)
                .then(e => {
                    setCompletedStep(prevState => ({ ...prevState, step6: true }))
                })
                .catch(e => {
                })
                .finally(() => {
                })
        )
    }

    const salvarAtivosNaoLiquidaveis = (bensFiltrados: ClientesAtivosEBens[]) => {
        promisesRequestsForm.push(
            clientesService.InserirBensLista(bensFiltrados)
                .then(e => {
                    bens.forEach(a => {
                        const findBem = e.find(r => r.Ativo == a.Ativo && r.Valor == a.Valor)
                        return a.Id = findBem?.Id || ''
                    })
                    setBens([...bens])
                    setCompletedStep(prevState => ({ ...prevState, step6: true }))
                })
                .catch(e => {

                })
                .finally(() => {
                })
        )
    }

    const atualizarOutros = () => {
        // atualizarReceitas()
    }


    // Modal finished reuniao
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const [startPlanoDeVoo, setStartPlanoDeVoo] = useState("")
    const [abrirPerfil, setAbrirPerfil] = useState("")
    if (abrirPerfil != "") {
        return <Navigate to={`/app/perfil-cliente/${abrirPerfil}`} />
    }

    if (startPlanoDeVoo != "") {
        return <Navigate to={`/app/plano-de-voo/${startPlanoDeVoo}`} />
    }



    return (
        <div className="container-passos pr-simplificada">
            {/* <div className="row"> */}
            <div>
                <Container maxWidth="md" sx={{ padding: 1, mb: 1 }}>
                    <Typography variant="h3" textAlign="center">
                        Primeira reunião simplificada
                    </Typography>
                    <Box sx={{ textAlign: "center", mt: 1 }}>
                        <Button variant="text" sx={{ fontSize: 10, fontWeight: "normal" }}
                            onClick={() => {
                                navigate(`/app/perfil-cliente/${userId}`, { replace: true })
                            }}
                        >
                            Deseja retornar para o perfil? Clique aqui.
                        </Button>
                    </Box>
                </Container>
                <InfosCliente cliente={cliente} setCliente={setCliente}
                    getInfosClienteFirstTime={getInfosClienteFirstTime}>
                </InfosCliente>
                <Dependentes
                    dependentes={dependentes} setDependentes={setDependentes}
                    cliente={cliente} setCliente={setCliente}
                ></Dependentes>
                <InfosFinanceiras
                    receitas={receitas} setReceitas={setReceitas}
                    despesas={despesas} setDespesas={setDespesas}
                ></InfosFinanceiras>
                <AtivosFinanceiros
                    ativos={ativos} setAtivos={setAtivos}
                    cliente={cliente} thereIsAtivos={thereIsAtivos} setThereIsAtivos={setThereIsAtivos}
                ></AtivosFinanceiros>
                <Bens
                    bens={bens} setBens={setBens}
                    cliente={cliente} setCliente={setCliente} thereIsBens={thereIsBens} setThereIsBens={setThereIsBens}
                ></Bens>
                <Outros
                    nota={nota} setNota={setNota}
                    receitas={receitas} setReceitas={setReceitas}
                ></Outros>
            </div>

            <Box sx={{
                justifyContent: "center", display: "flex", width: "100%"
            }}>
                <List sx={{ backgroundColor: `${erros.length > 0 ? '#ffcdcd' : 'none'}`, p: 2, borderRadius: 1, m: 3 }}>
                    {erros.map((value) => {
                        return (
                            <ListItem sx={{ m: 0, p: "2px" }}>
                                <ListItemIcon sx={{ minWidth: "35px" }} >
                                    <ErrorOutlineIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="h6">
                                    {value}
                                </Typography>
                            </ListItem>
                        )
                    })}
                </List>
            </Box>

            <Box sx={{ textAlign: "center", mt: 0, mb: 3 }}>
                <LoadingButton
                    onClick={() => onClickSalvar()}
                    loading={loading}
                    variant="contained"
                >
                    Finalizar Reunião Simplificada
                </LoadingButton>
            </Box>

            {/* {Modal Redirect} */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    <Typography variant="h5" my={1}>
                        Agora vamos fazer o Plano de Voo?
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <div className="center-all">
                        <img src={startPlanoDeVooImg} width={250} />
                    </div>
                    <Typography variant="h5" mb={2}>
                        Plano de Voo
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        Adicione as informações do seu possível cliente de forma
                        rápida aqui. O cadastro deste lead estará disponível no menu da esquerda
                        da plataforma de acordo com a fase de negócio que você selecionar.
                    </Typography>
                </DialogContent>
                <DialogActions disableSpacing={true} sx={{ display: "flex", justifyContent: "space-evenly", mb: 2 }}>
                    <Button variant="outlined" onClick={() => setAbrirPerfil(cliente.Id)}>
                        Não quero fazer agora
                    </Button>

                    <Button variant="contained" onClick={() => setStartPlanoDeVoo(cliente.Id)}>
                        Começar Plano de Voo
                    </Button>
                </DialogActions>
            </Dialog>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default PrimeiraReuniao;