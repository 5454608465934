import ControlePassosComponent from "../../Common/PlanoDeVoo/Steps/ControlStepsComponent";

const PlanoDeVooPage = (props: any) => {
    return (
        <>
            <ControlePassosComponent />
        </>
    )
}

export default PlanoDeVooPage;