export default class Usuario {
    constructor() {
        this.Id = '';
        this.Nome = '';
        this.Sobrenome = '';
        this.Email = '';
        this.CPF = '';
        this.DataNascimento = '';
        this.Squad = {
            Nome: "Carregando...",
            Id: ""
        };
        this.SquadAdmin = false;
    }

    Id: string;
    Nome: string;
    NomeCompleto?: string;
    Sobrenome: string;
    Email: string;
    CPF: string;
    DataNascimento: string;
    SquadAdmin: boolean;
    Squad: object;
}