import Box from '@mui/material/Box'
import { NewProfileClient } from '../../Common/NewProfileClient';

const PerfilClientePage = (props: any) => {
  return (
    <Box sx={{ pt: 5 }}>
      <div className="content main">
        {/* <PerfilClienteController /> */}
        <NewProfileClient />
      </div>
    </Box>
  )
}

export default PerfilClientePage;