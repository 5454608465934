import { Grid, styled, Typography, Box, IconButton } from '@mui/material';
import ToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useClientContext } from './ClientContext';
import moment from 'moment';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import 'moment/locale/pt-br';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

moment.locale('pt-br');

export const MenuYear = () => {
  const { activities } = useClientContext();
  const { selectedMonthYear, setSelectedMonthYear } = useClientContext();
  const [viewMonthYear, setViewMonthYear] = useState(selectedMonthYear ?? moment());

  const handleChangeMonth = (event: React.MouseEvent<HTMLElement>, newMonthYear: string) => {
    console.log(event)
    console.log(newMonthYear)
    if (newMonthYear) {
      const [newMonth, newYear] = newMonthYear.split('-');
      const monthIndex = parseInt(moment().month(newMonth).format('M'), 10) - 1;

      const newSelectedDate = moment().set({ 'year': parseInt(newYear, 10), 'month': monthIndex });
      setSelectedMonthYear(newSelectedDate);
    } else {
      setSelectedMonthYear(undefined);
    }
  };

  const handleChangeViewYear = (newValue: Date | null) => {
    if (newValue) {
      setViewMonthYear(moment(newValue));
    }
  };

  const incrementYear = () => setViewMonthYear(viewMonthYear.clone().add(1, 'year'));
  const decrementYear = () => setViewMonthYear(viewMonthYear.clone().subtract(1, 'year'));

  const months = [
    'jan', 'fev', 'mar', 'abr', 'mai', 'jun',
    'jul', 'ago', 'set', 'out', 'nov', 'dez'
  ];

  const activityExistsInMonth = (monthIndex: number, year: number) => {
    return activities.some((activity) => {
      const eventDate = moment(activity.DataEvento);
      return eventDate.month() === monthIndex && eventDate.year() === year && activity.AtividadeFinalizada;
    });
  };

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12} display='flex' justifyContent='center' alignItems='center' gap={1}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
          <IconButton onClick={decrementYear} size='small'>
            <ArrowBackIosNewIcon fontSize='small' />
          </IconButton>
          <MobileDatePicker
            value={viewMonthYear.toDate()}
            onChange={handleChangeViewYear}
            views={['year']}
            renderInput={({ inputRef, inputProps, InputProps }: any) => (
              <Box
                ref={inputRef}
                onClick={(event: any) => {
                  if (inputProps && inputProps.onClick) {
                    inputProps.onClick(event);
                  }
                }}
                sx={{ cursor: 'pointer', display: 'inline-block', textAlign: 'center' }}
              >
                <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                  {moment(viewMonthYear).format('YYYY')}
                </Typography>
              </Box>
            )}
          />
          <IconButton onClick={incrementYear} size='small'>
            <ArrowForwardIosIcon fontSize='small' />
          </IconButton>
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12}>
        <ToggleButtonGroup
          color='primary'
          value={selectedMonthYear ? `${selectedMonthYear.format('MMM')}-${selectedMonthYear.format('YYYY')}` : undefined}
          exclusive

          onChange={handleChangeMonth}
          fullWidth
        >
          {months.map((month, index) => {
            const isFuture = viewMonthYear.year() > moment().year() || (viewMonthYear.year() === moment().year() && index > moment().month());
            const hasActivity = activityExistsInMonth(index, viewMonthYear.year());
            const isSelected = selectedMonthYear ? viewMonthYear.year() === selectedMonthYear.year() && index === selectedMonthYear.month() : false;

            return (
              <CustomToggleButton
                key={month} value={`${month}-${viewMonthYear.format('YYYY')}`} disabled={isFuture}
                selected={isSelected} viewYear={viewMonthYear.year()} monthIndex={index}
              >
                {month}
                {!isFuture && (
                  <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', ml: 1 }}>
                    {hasActivity ? <CheckIcon fontSize="small" /> : <CloseIcon fontSize="small" />}
                  </Box>
                )}
              </CustomToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Grid>

    </Grid>
  );
};

interface CustomToggleButtonProps {
  monthIndex: number;
  viewYear: number;
  selected: boolean;
}

const CustomToggleButton = styled(ToggleButton, {
  shouldForwardProp: (prop) => prop !== 'monthIndex' && prop !== 'viewYear',
})<CustomToggleButtonProps & ToggleButtonProps>(({ theme, monthIndex, viewYear, selected }) => {
  const isCurrentMonth = moment().month() === monthIndex && viewYear === moment().year();
  return {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    ...(isCurrentMonth && !selected && {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      opacity: 0.5,
    }),
  };
});