import React, { createContext, useContext, useState, ReactNode, useEffect, useMemo } from 'react';
import { IListGroupsTask, IListGroupsTaskUpdate } from '../../../../Interface/interfaceTask';
import TaskService from '../../../../Services/TasksService';
import { IUser } from '../../../../Interface/user';

const TaskGroupsContext = createContext<TaskGroupsContextType | undefined>(undefined);

interface StateProviderProps {
  children: ReactNode;
}

interface TaskGroupsContextType {
  updateState: (newState: string) => void;
  taskGroups: IListGroupsTask[] | undefined;
  openDialog: boolean;
  selectedUser: IListGroupsTask | undefined;
  loading: boolean;
  usersIncludedAndNotIncluded: { included: IUser[], notIncluded: IUser[] };
  handleClickOpenDialog: (user: IListGroupsTask) => void;
  handleCloseDialog: () => void;
  updateUserAccess: (updateData: IListGroupsTaskUpdate) => Promise<void>;
}

const TaskGroupsProvider: React.FC<StateProviderProps> = ({ children }) => {
  const taskService = new TaskService();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IListGroupsTask>();
  const [taskGroups, setTaskGroups] = useState<IListGroupsTask[]>();
  const [loading, setLoading] = useState(false);

  const usersIncludedAndNotIncluded = useMemo(() => {
    if (!taskGroups || !selectedUser) return { included: [], notIncluded: [] };

    const accessedUserIds = selectedUser.Acessados.map(user => user.Id);

    const includedUsers = selectedUser.Acessados;

    const notIncludedUsers = taskGroups.filter(group =>
      !accessedUserIds.includes(group.Lider.Id)
    ).map(group => group.Lider);

    return { included: includedUsers, notIncluded: notIncludedUsers };
  }, [selectedUser, taskGroups]);

  useEffect(() => {
    setLoading(true);
    taskService.ListGroups()
      .then(groups => {
        const sortedGroups = groups.sort((a, b) => b.Acessados.length - a.Acessados.length);
        setTaskGroups(sortedGroups);
      })
      .catch((err: Error) => {
        console.error('Error na listagem de grupos de tarefas: ', err);
      })
      .finally(() => setLoading(false))
  }, []);

  const updateState = (newState: string) => {

  };

  const handleClickOpenDialog = (user: IListGroupsTask) => {
    setSelectedUser(user);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const updateUserAccess = async (updateData: IListGroupsTaskUpdate) => {
    setLoading(true);

    try {
      return taskService.UpdateGroup(updateData)
        .then(() => {
          console.log('here')
          setTaskGroups(currentGroups => currentGroups?.map(group => {
            const usersToAdd = updateData.Insert.AcessadosId.map(userIdToAdd => {
              return currentGroups.find(group => group.Lider.Id === userIdToAdd)?.Lider;
            }).filter(user => user) as IUser[];

            const updatedAcessados = group.Acessados.filter(user => !updateData.Delete.AcessadosId.includes(user.Id));

            if (group.Lider.Id === updateData.Insert.LiderId || group.Lider.Id === updateData.Delete.LiderId) {
              return {
                ...group,
                Acessados: [...updatedAcessados, ...usersToAdd]
              };
            }
            return group;
          }));

          setSelectedUser(undefined);
        })

    } catch (error) {
      console.error("Erro ao atualizar o acesso dos usuários", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <TaskGroupsContext.Provider value={{
      updateState,
      taskGroups,
      openDialog,
      selectedUser,
      usersIncludedAndNotIncluded,
      loading,
      handleClickOpenDialog,
      handleCloseDialog,
      updateUserAccess
    }}>
      {children}
    </TaskGroupsContext.Provider>
  );
};

const useTaskGroupsContext = () => {
  const context = useContext(TaskGroupsContext);
  if (context === undefined) {
    throw new Error('useTaskGroupsContext must be used within a StateProvider');
  }
  return context;
};

export { TaskGroupsProvider, useTaskGroupsContext };