import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Box, Card, CardContent, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { interfaceClient } from '../../../Interface/interfaceClient';
import moment from 'moment';
import { useSpring, animated } from 'react-spring';
import WarningIcon from '@mui/icons-material/WarningOutlined';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useKanbanContext } from '../../../Hooks/useKanban';

interface Props {
  client: interfaceClient;
  index: number;
}

export const KanbanItem: React.FC<Props> = ({ client, index }) => {
  const { setAddActivityDialog } = useKanbanContext();

  const draggableId = client.Id ? client.Id : `client-${index}`;

  const calculateDaysSinceLastContact = () => {
    if (client.UltimaAtividade && moment(client.UltimaAtividade).isValid()) {
      const lastActivityDate = moment(client.UltimaAtividade);
      const currentDate = moment();
      return currentDate.diff(lastActivityDate, 'days');
    } else {
      return -1;
    }
  };

  const daysSinceLastContact = calculateDaysSinceLastContact();

  const getColorForDays = (days: number) => {
    if (days < 10) {
      return 'green';
    } else if (days >= 10 && days < 30) {
      return 'orange';
    } else {
      return 'red';
    }
  };

  const daysText = daysSinceLastContact >= 0
    ? (daysSinceLastContact >= 30 ? '30+ dias sem contato' : `${daysSinceLastContact} dias sem contato`)
    : '';
  const color = getColorForDays(daysSinceLastContact);

  const pulse = useSpring({
    from: { transform: 'scale(1)' },
    to: [
      { transform: 'scale(1.5)' },
      { transform: 'scale(1)' }
    ],
    config: { duration: 1000 },
    loop: { reverse: true },
    style: { display: 'inline' }
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Draggable draggableId={draggableId} index={index}>
        {(provided) => (
          <Grid item xs={12} mx={2}>
            <Card
              elevation={2}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              sx={{ position: 'relative' }}
            >
              <CardContent>
                <Box display="flex" alignItems="center">
                  <PersonIcon style={{ marginRight: 8 }} />
                  <Typography variant='h6' fontSize={14}>{client.NomeCompleto}</Typography>
                </Box>
                <Typography variant='body1' fontSize={13} fontWeight='bold' sx={{ my: 1 }}>
                  {client.Preco ? client.Preco.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'R$ -'}
                </Typography>
                <Typography variant="body1" fontWeight='bold' sx={{ color: color, display: 'inline', fontSize: 12 }}>
                  {daysText}
                </Typography>
                {
                  daysSinceLastContact >= 30 && <animated.div style={{ ...pulse, display: 'inline-flex' }}>
                    <WarningIcon fontSize='small' style={{ color: 'orange', position: 'relative', top: 4, marginLeft: 6 }} />
                  </animated.div>
                }
              </CardContent>
              <IconButton
                sx={{ position: 'absolute', right: 0, top: 0 }}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={() => {
                  let url = `/app/perfil-cliente/${client.Id}`
                  window.open(url, '_blank', 'noopener,noreferrer');
                  handleClose();
                }}>Abrir perfil</MenuItem>
                <MenuItem onClick={() => {
                  setAddActivityDialog({ open: true, client: client })
                  handleClose();
                }}>Agendar reunião</MenuItem>
              </Menu>
            </Card>
          </Grid>
        )}
      </Draggable>
    </>
  );
};
