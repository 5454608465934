import React, { useState, useEffect } from 'react';
import {
  Button, Dialog, DialogTitle, DialogContent, Grid, FormControl,
  MenuItem, Select, Divider, DialogContentText, InputLabel
} from '@mui/material';
import { RiThumbDownLine } from 'react-icons/ri';
import { useClientContext } from '../ClientContext';

interface DialogPerdeuComponentProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface InterfaceValues {
  reason: string;
  serviceRange: string;
}

export const Loss: React.FC<DialogPerdeuComponentProps> = ({ open, setOpen }) => {
  const { client, updateClient } = useClientContext();

  const [newValues, setNewValues] = useState<InterfaceValues>({ reason: '', serviceRange: '' });
  const [openSecondInput, setOpenSecondInput] = useState(false);

  useEffect(() => {
    setNewValues({ reason: '', serviceRange: '' });
  }, [open]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs" fullWidth>
      <DialogTitle variant="h3" sx={{ pt: 5, px: 5, pb: 2 }}>Na próxima você consegue!</DialogTitle>
      <DialogContent sx={{ padding: 5 }}>
        <Divider sx={{ mb: 2, borderColor: "#b2b1c330" }} />
        <DialogContentText variant="body1" sx={{ mb: 5 }}>
          Informe o motivo pelo qual o lead foi perdido.
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth size='small'>
              <InputLabel id='reason-loss'>Motivo</InputLabel>
              <Select
                labelId='reason-loss'
                label='Motivo'
                size="small"
                value={newValues.reason}
                onChange={(event) => {
                  const reason = event.target.value;
                  setNewValues(prev => ({ ...prev, reason }));
                  setOpenSecondInput(reason === "Não se encaixa no meu perfil de atendimento");
                }}
              >
                <MenuItem disabled value="">
                  <em>Selecione...</em>
                </MenuItem>
                <MenuItem value="Não se encaixa no meu perfil de atendimento">Não se encaixa no meu perfil de atendimento</MenuItem>
                <MenuItem value="Não tem interesse em investir">Não tem interesse em investir</MenuItem>
                <MenuItem value="Não tem capital para investir no momento">Não tem capital para investir no momento</MenuItem>
                <MenuItem value="Não quer trocar de assessoria XP">Não quer trocar de assessoria XP</MenuItem>
                <MenuItem value="Aguardando capital">Aguardando capital</MenuItem>
                <MenuItem value="Investe por conta própria">Investe por conta própria</MenuItem>
                <MenuItem value="Não quer trocar de instituição financeira">Não quer trocar de instituição financeira</MenuItem>
                <MenuItem value="Experiência ruim XP">Experiência ruim XP</MenuItem>
                <MenuItem value="Experiência ruim com a MARCO">Experiência ruim com a MARCO</MenuItem>
                <MenuItem value="Experiência ruim com investimentos">Experiência ruim com investimentos</MenuItem>
                <MenuItem value="Não atendeu">Não atendeu</MenuItem>
                <MenuItem value="Não compareceu a reuniões marcadas">Não compareceu a reuniões marcadas</MenuItem>
                <MenuItem value="Telefone/email não existe">Telefone/email não existe</MenuItem>
                <MenuItem value="Insegurança sobre a plataforma XP">Insegurança sobre a plataforma XP</MenuItem>
                <MenuItem value="Lead Duplicado">Lead Duplicado</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {openSecondInput && (
            <Grid item xs={12}>
              <FormControl fullWidth size='small'>
                <InputLabel id='select-range'>Faixa de atendimento</InputLabel>
                <Select
                  labelId='select-range'
                  label='Faixa de atendimento'
                  size="small"
                  value={newValues.serviceRange}
                  onChange={(event) => {
                    const serviceRange = event.target.value;
                    setNewValues(prev => ({ ...prev, serviceRange }));
                  }}
                >
                  <MenuItem disabled value="">
                    <em>Selecione...</em>
                  </MenuItem>
                  <MenuItem value="Assessoria Digital (Até 300k)">Assessoria Digital (Até 300k)</MenuItem>
                  <MenuItem value="Assessoria Exclusive (300k - 1M)">Assessoria Exclusive (300k - 1M)</MenuItem>
                  <MenuItem value="Assessoria Premium (1M - 3M)">Assessoria Premium (1M - 3M)</MenuItem>
                  <MenuItem value="Assessoria Private (A partir de 3M)">Assessoria Private (A partir de 3M)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} textAlign="center" mt={3}>
            <Button
              sx={{ width: 180 }}
              endIcon={<RiThumbDownLine />}
              variant="contained"
              color="error"
              onClick={async () => {

                if (client && client.Id) {
                  await updateClient({
                    ...client,
                    PotencialDeInvestimento: newValues.serviceRange,
                    MotivoDePerda: newValues.reason,
                    Status: 'PERDEU'
                  });

                  setNewValues({ reason: '', serviceRange: '' });
                  setOpen(false);
                }


              }}
            >
              Perder
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
