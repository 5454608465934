import { svgAporteInicial } from "../../../../../Assets/Images/img-svg/images-svg"

const ClientePlanejamentoFinanceiroValidation = (independencia: any, clienteInfos: any, correctlyVariables: boolean) => {
    let errorsList = <any>[]
    let errosCount = 0
    // console.log(independencia)
    // debugger
    if (!(independencia.AposentadoriaIdade > 0 && independencia.AposentadoriaIdade < 100)) {
        if (independencia.AtingiuIndependenciaFinanceira) {
            errorsList.push('Idade para aposentadoria inválida.')
        } else {
            errorsList.push('Tempo investindo inválido.')
        }
        errosCount++
    }
    // console.log(!(independencia.AporteInicial >= 0))
    if (!(independencia.AporteInicial >= 0)) {
        errorsList.push('Aporte inicial inválido.')
        errosCount++
    }
    if (!(independencia.ValorParaCalculo >= 0)) {
        if (independencia.AtingiuIndependenciaFinanceira) {
            errorsList.push('Valor para cálculo inválido.')
        } else {
            errorsList.push('Contribuição mensal inválida.')
        }
        errosCount++
    }

    if (independencia.AtingiuIndependenciaFinanceira) {
        if (independencia.CalculoTipo === "OPT41" || independencia.CalculoTipo === "OPT42") {
            if (!(independencia.ValorParaCalculo1 >= 0)) {
                errosCount++
            }
        }

        if (clienteInfos.age > independencia.AposentadoriaIdade) {
            errorsList.push('Idade para aposentadoria é menor do que a idade atual.')
            errosCount++
        }

        if (!correctlyVariables) {
            errorsList.push('O objetivo desejado na Independência Financeira será alcançado antes da idade para aposentadoria. Por favor, preencha as informações corretamente.')
            errosCount++
        }
    }

    return {
        isValid: errosCount === 0,
        errorDescriptions: errorsList
    }
}

export default ClientePlanejamentoFinanceiroValidation