import { useEffect, useState } from "react";
import ClientesService from "../../../Services/ClientesService";
import InputMask from 'react-input-mask'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ptBR } from "date-fns/locale";


const InfosCliente = ({ cliente, setCliente, getInfosClienteFirstTime }: any) => {
  const clientesService = new ClientesService()

  const onChangeInput = (field: any, value: string) => {
    cliente[field] = value
    setCliente({ ...cliente })
  }

  const handleDate = (newValue: Date | null) => {
    cliente.DataNascimento = newValue
    setCliente({ ...cliente })
  }

  const [contatoTipo, setContatoTipo] = useState("movel")
  const [maskContato, setMaskContato] = useState("")
  const onChangeContatoTipo = (value: string) => {
    setContatoTipo(value)
  }

  useEffect(() => {
    if (contatoTipo === "fixo") {
      setMaskContato("(99) 9999-9999")
      setContatoTipo("fixo")
    } else if (contatoTipo === "movel") {
      setMaskContato("(99) 99999-9999")
      setContatoTipo("movel")
    }
  }, [contatoTipo])

  useEffect(() => {
    if (!getInfosClienteFirstTime) return;

    if (cliente.TelefoneContatoPrimario != null && cliente.TelefoneContatoPrimario.length === 14) {
      setMaskContato("(99) 9999-9999")
      setContatoTipo("fixo")
    } else if (cliente.TelefoneContatoPrimario != null && cliente.TelefoneContatoPrimario.length === 15) {
      setMaskContato("(99) 99999-9999")
      setContatoTipo("movel")
    }
  }, [getInfosClienteFirstTime])

  const [endereco, setEndereco] = useState<any>({ localidade: "", uf: "", logradouro: "" })
  const [searchCEP, setSearchCEP] = useState(false)

  useEffect(() => {
    if (searchCEP) {
      cliente.Cidade = "..."
      cliente.Estado = "..."
      cliente.Endereco = "..."
      cliente.Bairro = "..."
      setCliente({ ...cliente })

      clientesService.GetInfosCEP(cliente['CEP'])
        .then(e => {
          if (e.erro === true) M.toast({ html: 'CEP inválido' })
          setEndereco(e)
          setSearchCEP(false)
        })
        .catch(e => {
        })
    }
    // https://viacep.com.br/ws/96077260/json/
  }, [searchCEP])

  useEffect(() => {
    if (endereco.localidade != undefined) {
      cliente.Cidade = endereco.localidade
      cliente.Estado = endereco.uf
      cliente.Endereco = endereco.logradouro
      cliente.Bairro = endereco.bairro
      setCliente({ ...cliente })
    } else {
      cliente.Cidade = ""
      cliente.Estado = ""
      cliente.Endereco = ""
      cliente.Bairro = ""
      setCliente({ ...cliente })
    }
  }, [endereco])

  useEffect(() => {
    // Checking CEP is valid
    const checkCEP = /[0-9]{5}-[0-9]{3}$/
    const CEPValid = checkCEP.test(cliente.CEP)
    if (CEPValid) {
      setSearchCEP(true)
    } else {
      cliente.Cidade = ""
      cliente.Estado = ""
      cliente.Endereco = ""
      cliente.Bairro = ""
    }
  }, [cliente.CEP])

  if (!cliente) {
    return <></>;
  }

  return <>
    <Container maxWidth="md" sx={{ padding: 3, backgroundColor: "white", borderRadius: 2, mb: 3 }}>
      <Box component="form"
        sx={{ px: 5, py: 1 }}
        noValidate
        autoComplete="off"
      >
        <Typography variant="h5" mb={2}>
          Dados Pessoais
        </Typography>
        <Grid container spacing={2}>
          <Box sx={{ mb: 1 }}>
          </Box>
          <Grid item xs={12} md={6}>
            <TextField label="Nome Completo" required fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
              onChange={(event) => onChangeInput('NomeCompleto', event.target.value)} value={cliente.NomeCompleto}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl fullWidth size="small" margin="normal" >
              <InputLabel id="demo-select-small">Tipo de Contato</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-simple-select"
                label="Tipo de Contato"
                onChange={(event) => onChangeContatoTipo(event.target.value)}
                value={contatoTipo || ""}
              >
                <MenuItem value={"fixo"}>Tel. Fixo</MenuItem>
                <MenuItem value={"movel"}>Celular</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <InputMask
              autoComplete="disabled"
              mask={maskContato}
              id="TelefoneContatoPrimario"
              type="text"
              name="TelefoneContatoPrimario"
              onChange={(event) => onChangeInput("TelefoneContatoPrimario", event.target.value)} value={cliente.TelefoneContatoPrimario || ""}
            >
              {() => <TextField label="Número" fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal" value={cliente.TelefoneContatoPrimario || ""} />}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField label="Email *" fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
              onChange={(event) => onChangeInput('Email', event.target.value)} value={cliente.Email || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField label="Profissão *" fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
              onChange={(event) => onChangeInput('Profissao', event.target.value)} value={cliente.Profissao || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputMask
              autoComplete="disabled"
              mask="999.999.999-99"
              id="CPF"
              type="text"
              name="CPF"
              onChange={(event) => onChangeInput('CPF', event.target.value)} value={cliente.CPF || ""}
            >
              {() =>
                <TextField label="CPF *" fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal" value={cliente.CPF || ""} />
              }
            </InputMask>
          </Grid>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
              <DatePicker label="Data de nascimento *"
                value={new Date(cliente.DataNascimento)}
                onChange={handleDate}
                format="dd/MM/yyyy"
                slotProps={{
                  textField: {
                    size: 'small',
                    fullWidth: true,
                    margin: 'normal'
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" margin="normal" >
              <InputLabel id="demo-select-small">Estado Civil</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-simple-select"
                onChange={(event) => onChangeInput('EstadoCivil', event.target.value)}
                value={cliente.EstadoCivil || "selecione"}
                label="Estado Civil"
                defaultValue="selecione"
              >
                <MenuItem disabled key="selecione" value={"selecione"}><em>Selecione...</em></MenuItem>
                <MenuItem key="Solteiro" value={"Solteiro"}>Solteiro(a)</MenuItem>
                <MenuItem key="Casado" value={"Casado"}>Casado(a)</MenuItem>
                <MenuItem key="Separado" value={"Separado"}>Separado(a)</MenuItem>
                <MenuItem key="Divorciado" value={"Divorciado"}>Divorciado(a)</MenuItem>
                <MenuItem key="Viúvo" value={"Viúvo"}>Viúvo(a)</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" margin="normal" >
              <InputLabel id="demo-select-small">Como você se identifica? *</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-simple-select"
                onChange={(event) => onChangeInput('Sexo', event.target.value)}
                value={cliente.Sexo || "selecione"}
                label="Como você se identifica? *"
                defaultValue="selecione"
              >
                <MenuItem key="selecione" disabled value={"selecione"}><em>Selecione...</em></MenuItem>
                <MenuItem key="M" value={"M"}>Masculino</MenuItem>
                <MenuItem key="F" value={"F"}>Feminino</MenuItem>
                <MenuItem key="C" value={"C"}>Outros</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField label="Cidade natal" fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
              onChange={(event) => onChangeInput('CidadeNatal', event.target.value)} value={cliente.CidadeNatal || ""}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
    <Container maxWidth="md" sx={{ padding: 3, backgroundColor: "white", borderRadius: 2, mb: 3 }}>
      <Box component="form"
        sx={{ px: 5, py: 1 }}
        noValidate
        autoComplete="off"
      >
        <Typography variant="h5" mb={2}>
          Residência atual
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <InputMask
              autoComplete="disabled"
              mask="99999-999"
              id="CEP"
              type="text"
              name="CEP"
              onChange={(event) => onChangeInput('CEP', event.target.value)} value={cliente.CEP || ""}
            >
              {() =>
                <TextField label="CEP" fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal" value={cliente.CEP || ""} />
              }
            </InputMask>
          </Grid>
          <Grid item xs={8} md={4}>
            <TextField disabled label="Cidade" fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
              value={cliente.Cidade || ""}
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <TextField disabled label="UF" fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
              value={cliente.Estado || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField disabled label="Endereço" fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
              value={cliente.Endereco || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField label="Número" fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
              onChange={(event) => onChangeInput('Numero', event.target.value)} value={cliente.Numero || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField disabled label="Bairro" fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
              value={cliente.Bairro || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField label="Complemento" fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
              onChange={(event) => onChangeInput('Complemento', event.target.value)} value={cliente.Complemento || ""}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  </>
}

export default InfosCliente