import { useState, useEffect } from 'react';
import SquadsService from '../Services/SquadsService';
import { IUser } from '../Interface/user';
import Dashboard from '../Services/DashboardService';
import StorageHelper from '../Services/Helpers/StorageHelper';

export const useSquadsAndUsers = () => {
  const [squads, setSquads] = useState<any[]>([]);
  const [usersWithoutSquad, setUsersWithoutSquad] = useState<IUser[]>([]);
  const storageHelper = new StorageHelper();

  useEffect(() => {
    if (storageHelper.GetUsuarioRole() !== 'Administrador') return;

    const squadsService = new SquadsService();
    const dashboardService = new Dashboard();

    const fetchData = async () => {
      try {
        const [allParticipants, allUsers, allSquads] = await Promise.all([
          squadsService.ListarParticipantes(),
          dashboardService.GetAllUsers(),
          squadsService.ListarSquads(),
        ]);

        // Organizar squads com seus participantes
        const squadsWithParticipants = allSquads.map((squad: any) => ({
          ...squad,
          Assessores: allParticipants.filter((participant: any) => participant.SquadId === squad.Id),
        }));

        // Identificar usuários sem squad
        const usersWithNoSquad = allUsers.filter((user: IUser) =>
          !allParticipants.some((participant: any) => participant.UsuarioId === user.Id)
        );

        setSquads(squadsWithParticipants);
        setUsersWithoutSquad(usersWithNoSquad);
      } catch (error) {
        console.error("Failed to fetch data for squads and users", error);
      }
    };

    fetchData();
  }, []);
  
  return { squads, usersWithoutSquad };
};