import Clientes from "../Domain/Clientes";
import { ClientesAtivosEBens } from "../Domain/ClientesAtivosEBens";
import { ClientesDependentes } from "../Domain/ClientesDependentes";
import { ClientesIndependencia } from "../Domain/ClientesIndependencia"
import { ClientesDespesas } from "../Domain/ClientesDespesas";
import ClientesPlanejamento from "../Domain/ClientesPlanejamento";
import { ClientesProjetosVida } from "../Domain/ClientesProjetosVida";
import { ClientesReceitas } from "../Domain/ClientesReceitas";
import { AUTH_HEADER } from "./Helpers/Headers";
import { IListServices, interfaceClient, interfaceClientSeguros } from "../Interface/interfaceClient";
import { interfaceRevenue } from "../Interface/interfaceRevenue";
import { IClienteSegurosRequest } from "../Components/Common/NewProfileClient/Dialogs/SendToInsurance";
import { ReassignClientRequest, ReassignClientResponse } from "../Domain/DTO/Clients";

export default class ClientesService {
  private readonly API_URL = process.env.REACT_APP_API_URL;

  public async ListarClientes(assessorId: string, status: string): Promise<interfaceClient[]> {
    return await fetch(`${this.API_URL}/clientes/listar-clientes-status?assessorId=${assessorId}&status=${status}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async ListInsuranceClients(assessorId: string, statusArray: string[]): Promise<interfaceClientSeguros[]> {
    const statusString = statusArray.map(status => `statusSeguros=${status}`).join("&");
    const url = `${this.API_URL}/clientes/listar-clientes-status-seguros?usuarioIdSeguros=${assessorId}&${statusString}`;

    return await fetch(url, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async ListarClientesComFeeFixo(assessorId: string): Promise<interfaceClient[]> {
    return await fetch(`${this.API_URL}/clientes/listar-clientes-com-fee-fixo?assessorId=${assessorId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async ListarClientesDataCadastro(assessorId: string): Promise<interfaceClient[]> {
    const now = new Date();
    now.setDate(now.getDate() - 30);
    const date = now.toISOString();

    return await fetch(`${this.API_URL}/clientes/listar-clientes-data-cadastro?assessorId=${assessorId}&dataCadastro=${date}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async UpdateStatus(clienteId: any, status: any) {
    return await fetch(`${this.API_URL}/clientes/update-cliente-status?clienteId=${clienteId}&status=${status}`, {
      method: 'PUT',
      headers: AUTH_HEADER(),
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async UpdateStatusSeguros(clienteId: string, status: interfaceClient['StatusSeguros']) {
    return await fetch(`${this.API_URL}/clientes/update-cliente-status-seguros?clienteId=${clienteId}&status=${status}`, {
      method: 'PUT',
      headers: AUTH_HEADER(),
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async UpdateOwnerCliente(clienteId: string, assessorId: string) {
    return await fetch(`${this.API_URL}/clientes/alterar-assessor?clienteId=${clienteId}&assessorId=${assessorId}`, {
      method: 'PATCH',
      headers: AUTH_HEADER(),
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async UpdatePreco(clienteId: string, preco: number) {
    return await fetch(`${this.API_URL}/clientes/update-cliente-valor?clienteId=${clienteId}&novoPreco=${preco}`, {
      method: 'PUT',
      headers: AUTH_HEADER(),
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async UpdateUltimaAtividade(clienteId: any) {
    return await fetch(`${this.API_URL}/clientes/update-cliente-ultima-atividade?clienteId=${clienteId}`, {
      method: 'PUT',
      headers: AUTH_HEADER(),
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async ListarLeads(userId: string): Promise<Clientes> {
    return await fetch(`${this.API_URL}/cliente?assessorUsuarioId=${userId}`, {
      // return await fetch(`${this.API_URL}/?assessorUsuarioId=bc2efd66-8526-4eb7-b4d4-a298757e6df7`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async ListarComentarios(clienteId: string): Promise<Clientes> {
    return await fetch(`${this.API_URL}/clientes/listar-comentarios?clienteId=${clienteId}`, {
      // return await fetch(`${this.API_URL}/?assessorUsuarioId=bc2efd66-8526-4eb7-b4d4-a298757e6df7`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async ListServices(clientId: string): Promise<IListServices[]> {
    return await fetch(`${this.API_URL}/servico/listar-cliente-servicos/${clientId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async AddClientService(service: { ServicoId: string, ClienteId: string }): Promise<IListServices[]> {
    console.log(service)
    return await fetch(`${this.API_URL}/servico/salvar-cliente-servico`, {
      method: 'POST',
      headers: AUTH_HEADER(),
      body: JSON.stringify(service)
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async ReassignClients(requests: ReassignClientRequest[]): Promise<ReassignClientResponse> {
    try {
      const response = await fetch(`${this.API_URL}/clientes/reassign-clients`, {
        method: 'POST',
        headers: {
          ...AUTH_HEADER(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requests),
      });

      const jsonResponse = await response.json();

      if (!response.ok) {
        const error = new Error(jsonResponse.message || 'Erro ao processar a solicitação') as Error & { details?: string[] };
        error.details = jsonResponse.details;
        throw error;
      }

      return jsonResponse;
    } catch (error: any) {
      throw error;
    }
  }

  public async DeleteClientService(service: { ServicoId: string, ClienteId: string }): Promise<IListServices[]> {
    return await fetch(`${this.API_URL}/servico/remover-cliente-servico`, {
      method: 'DELETE',
      headers: AUTH_HEADER(),
      body: JSON.stringify(service)
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async AddTimeline(comentario: any): Promise<any> {
    return await fetch(`${this.API_URL}/clientes/inserir-comentario`, {
      method: 'POST',
      headers: AUTH_HEADER(),
      body: JSON.stringify(comentario)
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async UpdateTimeline(timelineId: string, newTimelineComentario: string): Promise<any> {
    const encodedComment = encodeURIComponent(newTimelineComentario);

    return await fetch(`${this.API_URL}/clientes/update-comentario?comentarioId=${timelineId}&newComentario=${encodedComment}`, {
      method: 'PUT',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async DeleteTimeline(timelineId: string): Promise<any> {
    return await fetch(`${this.API_URL}/clientes/deletar-comentario?comentarioId=${timelineId}`, {
      method: 'DELETE',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async BuscarCliente(userId: string): Promise<interfaceClient> {
    return await fetch(`${this.API_URL}/clientes/get-cliente?clienteId=${userId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async ListaPlanejamento(userId: string): Promise<ClientesPlanejamento[]> {
    return await fetch(`${this.API_URL}/clientes/listar-planejamento-educacional?clienteId=${userId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then((data: ClientesPlanejamento[]) => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async ListaDependentes(userId: string): Promise<ClientesDependentes[]> {
    return await fetch(`${this.API_URL}/clientes/listar-dependentes?clienteId=${userId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then((data: ClientesDependentes[]) => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async ListaReceita(userId: string): Promise<ClientesReceitas> {
    return await fetch(`${this.API_URL}/clientes/listar-receita?clienteId=${userId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then((data: ClientesReceitas) => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async GetTempoInvestimento(userId: string): Promise<ClientesIndependencia> {
    return await fetch(`${this.API_URL}/clientes/get-tempo-investimento?clienteId=${userId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then((data: ClientesIndependencia) => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async ListarProjetos(userId: string): Promise<ClientesProjetosVida[]> {
    return await fetch(`${this.API_URL}/clientes/listar-projetos?clienteId=${userId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then((data: ClientesProjetosVida[]) => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async ListarAtivos(userId: string): Promise<ClientesAtivosEBens[]> {
    return await fetch(`${this.API_URL}/clientes/listar-ativos?clienteId=${userId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then((data: ClientesAtivosEBens[]) => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async ListarClientesAssessor(assessorId: string): Promise<interfaceClient[]> {
    const a = AUTH_HEADER()


    return await fetch(`${this.API_URL}/clientes/listar-clientes?assessorId=${assessorId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then((data: interfaceClient[]) => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async ListarBens(userId: string): Promise<ClientesAtivosEBens[]> {
    return await fetch(`${this.API_URL}/clientes/listar-bens?clienteId=${userId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then((data: ClientesAtivosEBens[]) => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async GetDespesas(userId: string): Promise<ClientesDespesas> {
    return await fetch(`${this.API_URL}/clientes/listar-despesas?clienteId=${userId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then((data: ClientesDespesas) => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async ListarCustosDependente(dependenteId: string): Promise<any> {
    return await fetch(`${this.API_URL}/clientes/listar-dependentes-custos?dependenteId=${dependenteId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then((data: ClientesDespesas) => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async InserirCliente(cliente: interfaceClient): Promise<Clientes> {
    return await fetch(`${this.API_URL}/clientes/inserir-cliente`, {
      method: 'POST',
      headers: AUTH_HEADER(),
      body: JSON.stringify(cliente)
    })
      .then(async response => {
        if (response.status == 200) {
          return await response.json()
        }

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async EnviarClienteParaSeguros(cliente: IClienteSegurosRequest): Promise<void> {
    return await fetch(`${this.API_URL}/clientes/enviar-cliente-para-seguros`, {
      method: 'POST',
      headers: AUTH_HEADER(),
      body: JSON.stringify(cliente)
    })
      .then(async response => {
        if (response.ok) {
          return await response.json();
        } else {
          const errorResponse = await response.json();
          throw new Error(errorResponse || 'Erro ao processar a solicitação');
        }
      })
      .catch(err => {
        throw new Error(err.message || 'Erro desconhecido');
      });
  }

  public async UpdateClientes(cliente: interfaceClient): Promise<interfaceClient> {
    return await fetch(`${this.API_URL}/clientes/update-cliente`, {
      method: 'PUT',
      headers: AUTH_HEADER(),
      body: JSON.stringify(cliente)
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return cliente
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async UpdateSubstatus(idCliente: string, newSubstatus: string): Promise<any> {
    return await fetch(`${this.API_URL}/clientes/update-cliente-substatus?clienteId=${idCliente}&subStatus=${newSubstatus}`, {
      method: 'PUT',
      headers: AUTH_HEADER(),
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async InserirPlanejamentoLista(planejamentoLista: ClientesPlanejamento[]): Promise<ClientesPlanejamento> {
    return await fetch(`${this.API_URL}/clientes/inserir-planejamento-lista`, {
      method: 'POST',
      headers: AUTH_HEADER(),
      body: JSON.stringify(planejamentoLista)
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async InserirAtivosLista(ativosLista: ClientesAtivosEBens[]): Promise<ClientesAtivosEBens[]> {
    return await fetch(`${this.API_URL}/clientes/inserir-ativos-lista`, {
      method: 'POST',
      headers: AUTH_HEADER(),
      body: JSON.stringify(ativosLista)
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async UpdateAtivosLista(ativosLista: ClientesAtivosEBens[]): Promise<ClientesAtivosEBens[]> {
    return await fetch(`${this.API_URL}/clientes/update-ativos-lista`, {
      method: 'PUT',
      headers: AUTH_HEADER(),
      body: JSON.stringify(ativosLista)
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async InserirBensLista(bensLista: ClientesAtivosEBens[]): Promise<ClientesAtivosEBens[]> {
    return await fetch(`${this.API_URL}/clientes/inserir-bens-lista`, {
      method: 'POST',
      headers: AUTH_HEADER(),
      body: JSON.stringify(bensLista)
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async UpdateAtivosNaoLiquidaveisLista(ativosLista: ClientesAtivosEBens[]): Promise<ClientesAtivosEBens[]> {
    return await fetch(`${this.API_URL}/clientes/update-bens-lista`, {
      method: 'PUT',
      headers: AUTH_HEADER(),
      body: JSON.stringify(ativosLista)
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async InserirDepesas(despesasLista: ClientesDespesas): Promise<ClientesDespesas> {
    return await fetch(`${this.API_URL}/clientes/inserir-despesas`, {
      method: 'POST',
      headers: AUTH_HEADER(),
      body: JSON.stringify(despesasLista)
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async UpdateDepesas(despesasLista: ClientesDespesas): Promise<ClientesDespesas> {
    return await fetch(`${this.API_URL}/clientes/update-despesa`, {
      method: 'PUT',
      headers: AUTH_HEADER(),
      body: JSON.stringify(despesasLista)
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async InserirReceitas(receitasLista: interfaceRevenue): Promise<ClientesReceitas> {
    return await fetch(`${this.API_URL}/clientes/inserir-receita`, {
      method: 'POST',
      headers: AUTH_HEADER(),
      body: JSON.stringify(receitasLista)
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async InserirIndependenciaFinanceira(independencia: any): Promise<any> {
    return await fetch(`${this.API_URL}/clientes/inserir-independencia-financeira`, {
      method: 'POST',
      headers: AUTH_HEADER(),
      body: JSON.stringify(independencia)
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async GetIndependenciaFinanceira(ClienteId: string): Promise<ClientesDespesas> {
    return await fetch(`${this.API_URL}/clientes/listar-independencia-financeira?clienteId=${ClienteId}`, {
      method: 'GET',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then((data: ClientesDespesas) => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async UpdatePDFGerado(pdf: any): Promise<any> {
    return await fetch(`${this.API_URL}/clientes/update-pdf-gerado?clienteId=${pdf.clienteId}&pdfGerado=${pdf.pdfGerado}`, {
      method: 'PUT',
      headers: AUTH_HEADER(),
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async UpdateIndependenciaFinanceira(independencia: any): Promise<any> {
    return await fetch(`${this.API_URL}/clientes/update-independencia-financeira`, {
      method: 'PUT',
      headers: AUTH_HEADER(),
      body: JSON.stringify(independencia)
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async InserirListaProjeto(projetos: ClientesProjetosVida[]): Promise<ClientesProjetosVida> {
    return await fetch(`${this.API_URL}/clientes/inserir-projeto-lista`, {
      method: 'POST',
      headers: AUTH_HEADER(),
      body: JSON.stringify(projetos)
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async UpdateListaProjeto(projetos: ClientesProjetosVida[]): Promise<ClientesProjetosVida> {
    return await fetch(`${this.API_URL}/clientes/update-projeto-lista`, {
      method: 'PUT',
      headers: AUTH_HEADER(),
      body: JSON.stringify(projetos)
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }


  public async InserirDependentesLista(dependentesLista: ClientesDependentes[]): Promise<ClientesDependentes[]> {
    return await fetch(`${this.API_URL}/clientes/inserir-dependente-lista`, {
      method: 'POST',
      headers: AUTH_HEADER(),
      body: JSON.stringify(dependentesLista)
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async InserirCustoDependente(dependenteCusto: any): Promise<any[]> {
    return await fetch(`${this.API_URL}/clientes/inserir-dependente-custo`, {
      method: 'POST',
      headers: AUTH_HEADER(),
      body: JSON.stringify(dependenteCusto)
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async UpdatePossuiDependentes(clienteId: any, value: boolean): Promise<any> {
    return await fetch(`${this.API_URL}/clientes/update-possui-dependentes?clienteId=${clienteId}&possuiDependentes=${value}`, {
      method: 'PUT',
      headers: AUTH_HEADER(),
      // body: JSON.stringify(update)
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async UpdateDependenteCusto(dependente: any): Promise<any> {
    return await fetch(`${this.API_URL}/clientes/update-dependente-custo`, {
      method: 'PUT',
      headers: AUTH_HEADER(),
      body: JSON.stringify(dependente)
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async UpdateReceitas(receita: interfaceRevenue): Promise<any> {
    return await fetch(`${this.API_URL}/clientes/update-receita`, {
      method: 'PUT',
      headers: AUTH_HEADER(),
      body: JSON.stringify(receita)
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return receita
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async UpdateDependentesLista(dependentesLista: ClientesDependentes[]): Promise<ClientesDependentes[]> {
    return await fetch(`${this.API_URL}/clientes/update-dependente-lista`, {
      method: 'PUT',
      headers: AUTH_HEADER(),
      body: JSON.stringify(dependentesLista)
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async DeletarAtivo(ativoId: string): Promise<ClientesDependentes[]> {
    return await fetch(`${this.API_URL}/clientes/deletar-ativos?ativoId=${ativoId}`, {
      method: 'DELETE',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async DeletarAtivoNaoLiquidavel(ativoId: string): Promise<ClientesDependentes[]> {
    return await fetch(`${this.API_URL}/clientes/deletar-bens?bensId=${ativoId}`, {
      method: 'DELETE',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async DeletarDependente(dependenteId: string): Promise<ClientesDependentes[]> {
    return await fetch(`${this.API_URL}/clientes/deletar-dependente?dependenteId=${dependenteId}`, {
      method: 'DELETE',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async DeletarProjeto(projetoId: string): Promise<ClientesDependentes[]> {
    return await fetch(`${this.API_URL}/clientes/deletar-projeto?projetoId=${projetoId}`, {
      method: 'DELETE',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async DeletarDependenteCusto(dependenteCustoId: string): Promise<any[]> {
    return await fetch(`${this.API_URL}/clientes/deletar-dependente-custo?dependenteCustoId=${dependenteCustoId}`, {
      method: 'DELETE',
      headers: AUTH_HEADER()
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

  public async GetInfosCEP(cep: string) {
    return await fetch(`https://viacep.com.br/ws/${cep}/json/`, {
      method: 'GET',
    })
      .then(async response => {
        if (response.status == 200)
          return await response.json()

        if (response.status == 400)
          await response.json().then(e => {
            throw new Error(e);
          })

        if (response.status == 401)
          await response.json().then(e => {
            throw new Error(e);
          })
      })
      .then(data => {
        if (data) {
          return data;
        } else {
          throw new Error("Erro")
        }
      })
      .catch(err => {
        throw new Error(err);
      })
  }

}

