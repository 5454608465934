import React, { useState } from 'react';
import { Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, InputAdornment } from '@mui/material';
import FactCheckIcon from '@mui/icons-material/FactCheckOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { useTaskGroupsContext } from './context';

export const TaskList: React.FC = () => {
  const { taskGroups, handleClickOpenDialog, loading } = useTaskGroupsContext();
  const [searchText, setSearchText] = useState('');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredTaskGroups = taskGroups?.filter(taskGroup =>
    taskGroup.Lider.NomeCompleto.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      <TextField
        label='Pesquisar'
        size='small'
        value={searchText}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <TableContainer>
        <Table aria-label="Task Groups Table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '300px' }}>
                <Typography fontWeight='bold' textAlign='center'>
                  Nome
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight='bold' textAlign='center'>
                  Tamanho
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                  <Box minHeight={150} display='flex' justifyContent='center' alignItems='center'>
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            ) : filteredTaskGroups && filteredTaskGroups.length > 0 ? (
              filteredTaskGroups.map((taskGroup) => (
                <TableRow
                  key={taskGroup.Lider.Id}
                  hover
                  onClick={() => handleClickOpenDialog(taskGroup)}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <FactCheckIcon fontSize='small' />
                      <span style={{ marginLeft: '9px', fontSize: '14px', fontWeight: 'bold' }}>
                        {taskGroup.Lider.NomeCompleto}
                      </span>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ fontSize: 11 }}>{taskGroup.Acessados.length} membros</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                  Nenhum grupo encontrado.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
