import React, { useEffect, useState, ChangeEvent } from 'react';
import {
  TextField,
  Grid,
  Autocomplete,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Select,
  InputLabel,
  SelectChangeEvent
} from '@mui/material';
import { INegocioSeguroRequest } from '../../../../../Interface/interfaceNegocios';
import StorageHelper from '../../../../../Services/Helpers/StorageHelper';
import { useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import UserService from '../../../../../Services/UserService';
import { NumberFormatCustom } from '../../../../../Helpers/Components';

interface FormSeguroProps {
  initialData?: INegocioSeguroRequest;
  onSubmit: (data: INegocioSeguroRequest) => Promise<void>;
  onClose: () => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const interestOptions = [
  'SegurosDeVida',
  'PlanoDeSaude',
  'PlanejamentoPatrimonial',
  'Outros'
];

const interestLabels: { [key: string]: string } = {
  SegurosDeVida: 'Seguros De Vida',
  PlanoDeSaude: 'Plano de Saúde',
  PlanejamentoPatrimonial: 'Planejamento Patrimonial',
  Outros: 'Outros'
};

export const FormSeguro: React.FC<FormSeguroProps> = ({
  initialData,
  onSubmit,
  onClose
}) => {
  const storageHelper = new StorageHelper();
  const userService = new UserService();
  const { userId: clientId } = useParams();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<{ id: string; label: string }[]>([]);
  const [vendedorError, setVendedorError] = useState<boolean>(false);

  // Iniciando o objeto do negócio de seguro
  const [negocioSeguro, setNegocioSeguro] = useState<INegocioSeguroRequest>(
    initialData || {
      Negocio: {
        AssessorId: storageHelper.GetUsuarioId(),
        ClienteId: clientId,
        Status: 'LEAD',
        DataInicio: new Date(),
        DataFechamento: undefined,
        VendedorId: '',
        ReceitaBruta: 0,
        ReceitaEscritorio: 0,
        Expectativa: 'Alta',
        Descricao: '',
      },
      Seguro: {
        ConfiguracaoFamiliar: '',
        PossuiSeguros: null,
        InteresseReuniaoSegurosDeVida: null,
        InteresseReuniaoPlanoDeSaude: null,
        InteresseReuniaoPlanejamentoPatrimonial: null,
        InteresseReuniaoOutros: null,
        PA: null,
        FormaDePagamento: ''
      },
    }
  );

  const isEditing = Boolean(initialData?.Negocio?.Id);

  // Estado para armazenar os interesses selecionados no select múltiplo
  const [selectedInterests, setSelectedInterests] = useState<string[]>([]);

  // Inicializando os interesses selecionados a partir do initialData (caso exista)
  useEffect(() => {
    if (initialData) {
      const interests: string[] = [];
      if (initialData.Seguro.InteresseReuniaoSegurosDeVida) interests.push('SegurosDeVida');
      if (initialData.Seguro.InteresseReuniaoPlanoDeSaude) interests.push('PlanoDeSaude');
      if (initialData.Seguro.InteresseReuniaoPlanejamentoPatrimonial) interests.push('PlanejamentoPatrimonial');
      if (initialData.Seguro.InteresseReuniaoOutros) interests.push('Outros');
      setSelectedInterests(interests);
    }
  }, [initialData]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (name in negocioSeguro.Negocio) {
      setNegocioSeguro((prevState) => ({
        ...prevState,
        Negocio: {
          ...prevState.Negocio,
          [name]: value,
        },
      }));
    } else if (name in negocioSeguro.Seguro) {
      setNegocioSeguro((prevState) => ({
        ...prevState,
        Seguro: {
          ...prevState.Seguro,
          [name]: value,
        },
      }));
    }
  };

  const handleBooleanChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    const booleanValue = value === 'true';
    setNegocioSeguro((prevState) => ({
      ...prevState,
      Seguro: {
        ...prevState.Seguro,
        [name]: booleanValue,
      },
    }));
  };

  const handleInterestChange = (event: SelectChangeEvent<typeof selectedInterests>) => {
    const {
      target: { value },
    } = event;
    setSelectedInterests(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!negocioSeguro.Negocio.VendedorId) {
      setVendedorError(true);
      enqueueSnackbar('Selecione um especialista!', { variant: 'error' });
      return;
    }

    setLoading(true);

    try {
      // Mapear selectedInterests para os campos booleanos no objeto Seguro
      const updatedSeguro = {
        ...negocioSeguro.Seguro,
        InteresseReuniaoSegurosDeVida: selectedInterests.includes('SegurosDeVida'),
        InteresseReuniaoPlanoDeSaude: selectedInterests.includes('PlanoDeSaude'),
        InteresseReuniaoPlanejamentoPatrimonial: selectedInterests.includes('PlanejamentoPatrimonial'),
        InteresseReuniaoOutros: selectedInterests.includes('Outros')
      };

      const payload: INegocioSeguroRequest = {
        ...negocioSeguro,
        Seguro: updatedSeguro
      };

      await onSubmit(payload);
      onClose();
    } catch (error) {
      enqueueSnackbar('Erro ao salvar o negócio de seguro.', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const fetchedUsers = await userService.ListUsersByPermissions([
        'Seguros',
        'AdminSeguros',
      ]);
      const formattedUsers = fetchedUsers.map((user) => ({
        id: user.Id,
        label: user.NomeCompleto,
      }));

      setUsers(formattedUsers);

      if (formattedUsers.length === 1) {
        setNegocioSeguro((prevState) => ({
          ...prevState,
          Negocio: {
            ...prevState.Negocio,
            VendedorId: formattedUsers[0].id,
          },
        }));
      }
    } catch (error) {
      enqueueSnackbar('Erro ao buscar usuários.', { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12} textAlign='center' sx={{ mb: 1 }}>
          <Autocomplete
            options={users}
            getOptionLabel={(option) => option.label}
            onChange={(event, newValue) => {
              setNegocioSeguro((prevState) => ({
                ...prevState,
                Negocio: {
                  ...prevState.Negocio,
                  VendedorId: newValue ? newValue.id : '',
                },
              }));
              setVendedorError(false);
            }}
            renderInput={(params) => (
              <TextField
                sx={{ width: 300 }}
                {...params}
                label='Life Planner'
                variant='outlined'
                size='small'
                error={vendedorError}
                helperText={vendedorError ? 'Campo obrigatório' : ''}
              />
            )}
            value={
              users.find((user) => user.id === negocioSeguro.Negocio.VendedorId) || null
            }
            noOptionsText='Nenhum usuário encontrado'
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            name='ConfiguracaoFamiliar'
            label='Configuração Familiar'
            fullWidth
            size='small'
            variant='outlined'
            value={negocioSeguro.Seguro.ConfiguracaoFamiliar || ''}
            onChange={handleInputChange}
            multiline
            rows={4}
            inputProps={{
              maxLength: 500
            }}
            helperText={`${negocioSeguro.Seguro.ConfiguracaoFamiliar?.length}/500 caracteres restantes`}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth size='small'>
            <InputLabel id='interests-label'>Interesses</InputLabel>
            <Select
              labelId='interests-label'
              id='interests'
              multiple
              value={selectedInterests}
              onChange={handleInterestChange}
              input={<OutlinedInput label='Interesses' />}
              renderValue={(selected) => selected.map(value => interestLabels[value]).join(', ')}
              MenuProps={MenuProps}
            >
              {interestOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox checked={selectedInterests.indexOf(option) > -1} />
                  <ListItemText primary={interestLabels[option]} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <TextField
            select
            name='Expectativa'
            label='Expectativa'
            value={negocioSeguro.Negocio.Expectativa}
            onChange={handleInputChange}
            fullWidth
            size='small'
            variant='outlined'
          >
            <MenuItem value='Alta'>Alta</MenuItem>
            <MenuItem value='Media'>Média</MenuItem>
            <MenuItem value='Baixa'>Baixa</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} textAlign='center'>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Possui Seguros?</FormLabel>
            <RadioGroup
              row
              name='PossuiSeguros'
              value={
                negocioSeguro.Seguro.PossuiSeguros === null
                  ? ''
                  : negocioSeguro.Seguro.PossuiSeguros?.toString()
              }
              onChange={handleBooleanChange}
            >
              <FormControlLabel value='true' control={<Radio />} label='Sim' />
              <FormControlLabel value='false' control={<Radio />} label='Não' />
            </RadioGroup>
          </FormControl>
        </Grid>

        {isEditing && (
          <>
            <Grid item xs={12} md={6}>
              <TextField
                name='PA'
                label='PA'
                fullWidth
                size='small'
                variant='outlined'
                value={negocioSeguro.Seguro.PA}
                onChange={(e) =>
                  setNegocioSeguro((prevState) => ({
                    ...prevState,
                    Seguro: {
                      ...prevState.Seguro,
                      PA: Number(e.target.value),
                    },
                  }))
                }
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                select
                name='FormaDePagamento'
                label='Forma de Pagamento'
                fullWidth
                size='small'
                variant='outlined'
                value={negocioSeguro.Seguro.FormaDePagamento || ''}
                onChange={handleInputChange}
              >
                <MenuItem value='Mensal'>Mensal</MenuItem>
                <MenuItem value='Anual'>Anual</MenuItem>
                <MenuItem value='Único'>Único</MenuItem>
              </TextField>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <LoadingButton
            type='submit'
            variant='contained'
            color='primary'
            fullWidth
            loading={loading}
          >
            {initialData?.Negocio.Id ? 'Salvar Negócio de Seguro' : 'Criar Negócio de Seguro'}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
