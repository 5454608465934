import Box from '@mui/material/Box'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import StorageHelper from '../../../Services/Helpers/StorageHelper';
import { UsersRegistrationComponent } from '../../Common/Admin/UsersRegistrationComponent'

export const UsersRegistrationPage = () => {
  const storageHelper = new StorageHelper();

  let navigate = useNavigate()

  useEffect(() => {
    if (storageHelper.GetUsuarioRole() != "Administrador") {
      navigate("/app/dashboard-pbi")
    }
  }, [])

  return (
    <Box sx={{ pt: 5 }}>
      <UsersRegistrationComponent />
    </Box>)
}