import { useState } from "react";
import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { NumberFormatCustom } from "../../../Helpers/Components";

const InfosFinanceiras = ({ receitas, setReceitas, despesas, setDespesas }: any) => {
  const onChangeInfosFinanceiras = (field: string, event: any) => {
    if (isNaN(Number(event.nativeEvent.data))) return;
    if (event.nativeEvent.inputType === "deleteContentBackward" || event.nativeEvent.inputType === "deleteWordBackward") {
      receitas[field] = ""
    } else {
      let newValue = parseFloat((event.target.value.replace("R$", "").replaceAll(".", ""))).toFixed(2)
      receitas[field] = newValue
    }

    setReceitas({ ...receitas })
  }

  // Despesas
  const [origemRendaObj, setOrigemRendaObj] = useState<any>({
    AtividadeProfissional: false, Heranca: false,
    Aposentadoria: false, Alugueis: false, Doacao: false, Outras: false
  })

  const changeOrigemRenda = (event: any) => {
    origemRendaObj[event.target.name] = !origemRendaObj[event.target.name]
    setOrigemRendaObj({ ...origemRendaObj })
    receitas[event.target.name] = !receitas[event.target.name]
    setReceitas({ ...receitas })
  }

  const handleCurrencyReceitas = (event: any) => {
    receitas[event.target.name] = Number(event.target.value)
    setReceitas({ ...receitas })
  };


  const handleCurrencyDespesas = (event: React.ChangeEvent<HTMLInputElement>) => {
    despesas[event.target.name] = Number(event.target.value)
    setDespesas({ ...despesas })
  };

  const handleTipoIRF = (event: React.ChangeEvent<HTMLInputElement>) => {
    despesas.TipoIRF = event.target.value
    setDespesas({ ...despesas })
  }

  return <>
    {/* Renda e Despesas */}
    <Container maxWidth="md" sx={{ padding: 3, backgroundColor: "white", borderRadius: 2, mb: 3 }}>
      <Box component="form"
        sx={{ px: 5, py: 1 }}
        noValidate
        autoComplete="off"
      >
        <Typography variant="h5" mb={2}>
          Renda
        </Typography>
        <Grid container spacing={2}>

          <Grid item xs={12} md={5}>
            <TextField
              label="Renda bruta fixa mensal" required fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
              value={receitas.RendaBrutaMensal || ""}
              onChange={handleCurrencyReceitas}
              name="RendaBrutaMensal"
              InputProps={{
                inputComponent: NumberFormatCustom as any,
              }}
            />
          </Grid>
          <Grid item sx={{ display: { xs: "none", md: "block" } }} md={1} />
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12}>
                Origem da renda
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControlLabel
                  label={"Atividade profissional"}
                  name="AtividadeProfissional"
                  sx={{ color: '#5d5c74' }}
                  control={
                    <Checkbox checked={receitas.AtividadeProfissional} onChange={changeOrigemRenda} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  label={"Herança"}
                  name="Heranca"
                  sx={{ color: '#5d5c74' }}
                  control={
                    <Checkbox checked={receitas.Heranca} onChange={changeOrigemRenda} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  label={"Aposentadoria"}
                  name="Aposentadoria"
                  sx={{ color: '#5d5c74' }}
                  control={
                    <Checkbox checked={receitas.Aposentadoria} onChange={changeOrigemRenda} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  label={"Aluguéis"}
                  name="Alugueis"
                  sx={{ color: '#5d5c74' }}
                  control={
                    <Checkbox checked={receitas.Alugueis} onChange={changeOrigemRenda} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  label={"Doação"}
                  name="Doacao"
                  sx={{ color: '#5d5c74' }}
                  control={
                    <Checkbox checked={receitas.Doacao} onChange={changeOrigemRenda} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  label={"Outras"}
                  name="Outras"
                  sx={{ color: '#5d5c74' }}
                  control={
                    <Checkbox checked={receitas.Outras} onChange={changeOrigemRenda} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
                  }
                />
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12}>
            Despesas
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              label="Despesa" required fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
              value={despesas.DespesaMensal}
              onChange={handleCurrencyDespesas}
              name="DespesaMensal"
              InputProps={{
                inputComponent: NumberFormatCustom as any,
              }}
            />
          </Grid>
          <Grid item md={1} />
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12}>
                Tipo de imposto de renda
              </Grid>
              <Grid item xs={12}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={despesas.TipoIRF}
                  onChange={handleTipoIRF}
                  sx={{ '&.Mui-checked': { color: '#512178' } }}
                >
                  <Grid item xs={12} md={4}>
                    <FormControlLabel value="SIMPLIFICADA" control={<Radio size="small" sx={{ '&.Mui-checked': { color: '#512178' } }} />} label="Simplificada" />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControlLabel value="COMPLETA" control={<Radio size="small" sx={{ '&.Mui-checked': { color: '#512178' } }} />} label="Completa" />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControlLabel value="NAOPOSSUI" control={<Radio size="small" sx={{ '&.Mui-checked': { color: '#512178' } }} />} label="Não possui" />
                  </Grid>
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
    {/* <form className="container-steps">
            <div className="row">
                <h2>Renda</h2>
                <div className="col s12 m5">
                    <h3 className="col s<Container maxWidth="lg" sx={{ padding: 3, backgroundColor: "white", borderRadius: 2, mb: 3 }}>
            <Box component="form"
                sx={{ px: 5, py: 1 }}
                noValidate
                autoComplete="off"
            >
                <Typography variant="h5" mb={2}>
                    Renda
                </Typography>
                <Grid container spacing={2}>

                    <Grid item xs={12} md={5}>
                        <TextField
                            label="Renda bruta fixa mensal" required fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
                            value={receitas.RendaBrutaMensal}
                            onChange={handleCurrencyReceitas}
                            name="RendaBrutaMensal"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid item sx={{ display: { xs: "none", md: "block" } }} md={1} />
                    <Grid item xs={12} md={6}>
                        <Grid container>
                            <Grid item xs={12}>
                                Origem da renda
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormControlLabel
                                    label={"Atividade profissional"}
                                    name="AtividadeProfissional"
                                    sx={{ color: '#5d5c74' }}
                                    control={
                                        <Checkbox checked={receitas.AtividadeProfissional} onChange={changeOrigemRenda} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControlLabel
                                    label={"Herança"}
                                    name="Heranca"
                                    sx={{ color: '#5d5c74' }}
                                    control={
                                        <Checkbox checked={receitas.Heranca} onChange={changeOrigemRenda} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControlLabel
                                    label={"Aposentadoria"}
                                    name="Aposentadoria"
                                    sx={{ color: '#5d5c74' }}
                                    control={
                                        <Checkbox checked={receitas.Aposentadoria} onChange={changeOrigemRenda} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControlLabel
                                    label={"Aluguéis"}
                                    name="Alugueis"
                                    sx={{ color: '#5d5c74' }}
                                    control={
                                        <Checkbox checked={receitas.Alugueis} onChange={changeOrigemRenda} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControlLabel
                                    label={"Doação"}
                                    name="Doacao"
                                    sx={{ color: '#5d5c74' }}
                                    control={
                                        <Checkbox checked={receitas.Doacao} onChange={changeOrigemRenda} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControlLabel
                                    label={"Outras"}
                                    name="Outras"
                                    sx={{ color: '#5d5c74' }}
                                    control={
                                        <Checkbox checked={receitas.Outras} onChange={changeOrigemRenda} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
                                    }
                                />
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        Despesas
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            label="Despesa" required fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
                            value={despesas.DespesaMensal}
                            onChange={handleCurrencyDespesas}
                            name="DespesaMensal"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid item md={1} />
                    <Grid item xs={12} md={6}>
                        <Grid container>
                            <Grid item xs={12}>
                                Origem da renda
                            </Grid>
                            <Grid item xs={12}>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={despesas.TipoIRF}
                                    onChange={handleTipoIRF}
                                    sx={{ '&.Mui-checked': { color: '#512178' } }}
                                >
                                    <Grid item xs={12} md={4}>
                                        <FormControlLabel value="SIMPLIFICADA" control={<Radio size="small" sx={{ '&.Mui-checked': { color: '#512178' } }} />} label="Simplificada" />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControlLabel value="COMPLETA" control={<Radio size="small" sx={{ '&.Mui-checked': { color: '#512178' } }} />} label="Completa" />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControlLabel value="NAOPOSSUI" control={<Radio size="small" sx={{ '&.Mui-checked': { color: '#512178' } }} />} label="Não possui" />
                                    </Grid>
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>t-field col s12 m10">
                        <CurrencyInput
                            id="RendaBrutaMensal"
                            name="RendaBrutaMensal"
                            value={receitas.RendaBrutaMensal}
                            placeholder={"Ex: 32.000,00"}
                            groupSeparator="."
                            decimalSeparator=","
                            prefix="R$"
                            onChange={(event) => onChangeInfosFinanceiras('RendaBrutaMensal', event)}
                            autoComplete="off"
                        />
                        <label htmlFor="RendaBrutaMensal">Renda bruta *</label>
                    </div>
                </div>
                <div className="col s12 m7">
                    <h3>Origem da renda</h3>
                    <div className="col col s12 m12">
                        <div className="col s12 m7">
                            <FormControlLabel
                                label={"Atividade profissional"}
                                name="AtividadeProfissional"
                                sx={{ color: '#5d5c74' }}
                                control={
                                    <Checkbox checked={receitas.AtividadeProfissional} onChange={changeOrigemRenda} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
                                }
                            />
                        </div>
                        <div className="col s12 m5">
                            <FormControlLabel
                                label={"Herança"}
                                name="Heranca"
                                sx={{ color: '#5d5c74' }}
                                control={
                                    <Checkbox checked={receitas.Heranca} onChange={changeOrigemRenda} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
                                }
                            />
                        </div>
                        <div className="col s12 m7">
                            <FormControlLabel
                                label={"Aposentadoria"}
                                name="Aposentadoria"
                                sx={{ color: '#5d5c74' }}
                                control={
                                    <Checkbox checked={receitas.Aposentadoria} onChange={changeOrigemRenda} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
                                }
                            />
                        </div>
                        <div className="col s12 m5">
                            <FormControlLabel
                                label={"Aluguéis"}
                                name="Alugueis"
                                sx={{ color: '#5d5c74' }}
                                control={
                                    <Checkbox checked={receitas.Alugueis} onChange={changeOrigemRenda} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
                                }
                            />
                        </div>
                        <div className="col s12 m7">
                            <FormControlLabel
                                label={"Doação"}
                                name="Doacao"
                                sx={{ color: '#5d5c74' }}
                                control={
                                    <Checkbox checked={receitas.Doacao} onChange={changeOrigemRenda} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
                                }
                            />
                        </div>
                        <div className="col s12 m5">
                            <FormControlLabel
                                label={"Outras"}
                                name="Outras"
                                sx={{ color: '#5d5c74' }}
                                control={
                                    <Checkbox checked={receitas.Outras} onChange={changeOrigemRenda} sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
                                }
                            />
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">
                <h2>Despesas</h2>
                <div className="col s12 m5">
                    <div className="input-field col s12 m10">
                        <CurrencyInput
                            id="DespesaMensal"
                            name="DespesaMensal"
                            value={despesas.DespesaMensal}
                            placeholder={"Ex: 25.000,00"}
                            groupSeparator="."
                            decimalSeparator=","
                            prefix="R$"
                            onChange={(event) => onChangeInputDespesas('DespesaMensal', event)}
                            autoComplete="off"
                        />
                        <label htmlFor="RendaBrutaMensal">Despesa *</label>
                    </div>
                </div>
                <div className="col s12 m7" id="origem-renda">
                    <h3>Tipo de imposto de renda</h3>
                    <div className="col s12 space-bottom">
                        <label className="col s6 m4">
                            <input checked={despesas.TipoIRF === "SIMPLIFICADA"} type="radio"
                                onClick={() => { onChangeTipoIRF("SIMPLIFICADA") }
                                }
                            />
                            <span className="options-tipo-calc">Simplificada</span>
                        </label>
                        <label className="col s6 m4">
                            <input checked={despesas.TipoIRF === "COMPLETA"} type="radio"
                                onClick={() => { onChangeTipoIRF("COMPLETA") }
                                }
                            />
                            <span className="options-tipo-calc">Completa</span>
                        </label>
                        <label className="col s6 m4">
                            <input checked={despesas.TipoIRF === "NAOPOSSUI"} type="radio"
                                onClick={() => { onChangeTipoIRF("NAOPOSSUI") }
                                }
                            />
                            <span className="options-tipo-calc">Não possui</span>
                        </label>
                    </div>
                </div>

            </div>
        </form> */}
  </>
}

export default InfosFinanceiras