import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Table, TableBody, TableCell, TableRow, TextField, InputAdornment, Tooltip } from "@mui/material";
import PersonIcon from '@mui/icons-material/PersonOutlined';
import PersonOffIcon from '@mui/icons-material/PersonOffOutlined';
import { useTaskGroupsContext } from "./context";
import SearchIcon from '@mui/icons-material/Search';
import { useMemo, useState } from "react";
import { IListGroupsTaskUpdate } from "../../../../Interface/interfaceTask";
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";
import { IUser } from "../../../../Interface/user";

export const DialogTaskGroups = () => {
  const { openDialog, selectedUser, handleCloseDialog, usersIncludedAndNotIncluded, updateUserAccess } = useTaskGroupsContext();
  const [searchText, setSearchText] = useState('');
  const [usersToAdd, setUsersToAdd] = useState<string[]>([]);
  const [usersToRemove, setUsersToRemove] = useState<string[]>([]);
  const [loadingSave, setLoadingSave] = useState(false);

  const updatedUsers = useMemo(() => {
    const includedUpdated = usersIncludedAndNotIncluded.included.filter(user => !usersToRemove.includes(user.Id))
      .concat(usersIncludedAndNotIncluded.notIncluded.filter(user => usersToAdd.includes(user.Id)))
      .map(user => ({ ...user, pending: usersToAdd.includes(user.Id) }));

    const notIncludedUpdated = usersIncludedAndNotIncluded.notIncluded.filter(user => !usersToAdd.includes(user.Id))
      .concat(usersIncludedAndNotIncluded.included.filter(user => usersToRemove.includes(user.Id)))
      .map(user => ({ ...user, pending: usersToRemove.includes(user.Id) }));

    return { included: includedUpdated, notIncluded: notIncludedUpdated };
  }, [usersIncludedAndNotIncluded, usersToAdd, usersToRemove]);

  const handleAddUser = (userId: string) => {
    setUsersToAdd(prev => {
      const updated = prev.includes(userId) ? prev : [...prev, userId];
      return updated;
    });
    setUsersToRemove(prev => prev.filter(id => id !== userId));
  };

  const handleRemoveUser = (userId: string) => {
    setUsersToRemove(prev => {
      const updated = prev.includes(userId) ? prev : [...prev, userId];
      return updated;
    });
    setUsersToAdd(prev => prev.filter(id => id !== userId));
  };

  const filterUsers = (users: IUser[]) => {
    return users.filter((user) =>
      user.NomeCompleto.toLowerCase().includes(searchText.toLowerCase())
    ).sort((a, b) => a.NomeCompleto.localeCompare(b.NomeCompleto));
  };

  const handleSaveChanges = async () => {
    if (selectedUser) {
      setLoadingSave(true);

      const updateData: IListGroupsTaskUpdate = {
        Insert: {
          LiderId: selectedUser.Lider.Id,
          AcessadosId: usersToAdd
        },
        Delete: {
          LiderId: selectedUser.Lider.Id,
          AcessadosId: usersToRemove
        }
      };

      updateUserAccess(updateData)
        .then(() => {
          handleCloseAndReset();
        })
        .catch(() => {
          enqueueSnackbar('Ops... tivemos um problema.', { variant: 'error' });
        })
        .finally(() => {
          setLoadingSave(false);
        })
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const isUserPendingAddition = (userId: string) => usersToAdd.includes(userId);
  const isUserPendingRemoval = (userId: string) => usersToRemove.includes(userId);

  const handleCloseAndReset = () => {
    handleCloseDialog();
    setUsersToAdd([]);
    setUsersToRemove([]);
    setSearchText('');
  };

  return (
    selectedUser ? (
      <Dialog open={openDialog} onClose={handleCloseAndReset} maxWidth='xs' fullWidth>
        <DialogTitle component={Typography} variant='h4'>{selectedUser.Lider.NomeCompleto}</DialogTitle>
        <DialogContent dividers>
          <TextField
            label='Pesquisar' size='small'
            sx={{ my: 2 }}
            value={searchText}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Table aria-label="Task Groups Table" size='small'>
            <TableBody>
              {filterUsers(updatedUsers.included).map((user) => (
                <TableRow key={user.Id}>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: 14 }}>
                      <PersonIcon />
                      <span style={{ marginLeft: '9px', fontSize: '14px', fontWeight: 'bold' }}>
                        {user.NomeCompleto}
                      </span>
                      {isUserPendingAddition(user.Id) && <Tooltip title='As alterações são temporárias até que você clique em Salvar.'>
                        <InfoIcon fontSize="small" style={{ color: 'orange', marginLeft: '4px' }} />
                      </Tooltip>}
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Button size="small" color="error" onClick={() => handleRemoveUser(user.Id)}>
                      Remover
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              {filterUsers(updatedUsers.notIncluded).map((user) => (
                <TableRow key={user.Id}>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <PersonOffIcon />
                      <span style={{ marginLeft: '9px', fontSize: '14px' }}>
                        {user.NomeCompleto}
                      </span>
                      {isUserPendingRemoval(user.Id) && <Tooltip title='As alterações são temporárias até que você clique em Salvar.'>
                        <InfoIcon fontSize="small" style={{ color: 'orange', marginLeft: '4px' }} />
                      </Tooltip>}
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Button size="small" color="primary" onClick={() => handleAddUser(user.Id)}>
                      Adicionar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={loadingSave} onClick={handleSaveChanges} variant='contained' size='small'>Salvar</LoadingButton>
          <Button onClick={handleCloseAndReset} color='secondary' size='small'>Fechar</Button>
        </DialogActions>
      </Dialog >
    ) : null
  );
};
