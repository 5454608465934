import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import NumberFormat from 'react-number-format';
import DeleteIcon from '@mui/icons-material/Delete';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom = React.forwardRef<NumberFormat<any>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        prefix={'R$'}
        decimalSeparator={','}
        thousandSeparator={'.'}
        fixedDecimalScale={true}
        decimalScale={2}
        name={props.name}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
      />
    );
  },
);

const BensNaoLiquidaveisComponent = ({ bensNaoLiquidaveis, setBensNaoLiquidaveis, addBem, removerBem }: any) => {

  const onChangeInput = (index: number, field: string, value: string) => {
    bensNaoLiquidaveis[index][field] = value
    setBensNaoLiquidaveis([...bensNaoLiquidaveis])
  }

  const handleCurrencyAtivosImobilizados = (event: any) => {
    if (Number(event.target.value) > 0) {
      bensNaoLiquidaveis[Number(event.target.name)].Valor = Number(event.target.value)
      setBensNaoLiquidaveis([...bensNaoLiquidaveis])
    } else {
      bensNaoLiquidaveis[Number(event.target.name)].Valor = null
      setBensNaoLiquidaveis([...bensNaoLiquidaveis])
    }
  };

  return (
    <>
      {!(bensNaoLiquidaveis.length == 0) ? "" :
        <Container maxWidth="md" sx={{ padding: 3, pt: 1, backgroundColor: "white", borderRadius: 2, mb: 3 }}>
          <Box component="form" autoComplete="off"
            sx={{ px: 5, py: 1 }}
          >
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <Box component={"hr"} sx={{ border: "1px solid #f0f1f2", position: "relative", top: "24px" }}></Box>
              <Button variant="contained" size="small" color="secondary" sx={{ backgroundColor: 'secondary' }}
                onClick={() => addBem()} disableElevation
              >
                + Adicionar ativo imobilizado
              </Button>
            </Box>
          </Box>
        </Container>
      }
      {bensNaoLiquidaveis.map((ativo: any, indexAtivo: number) => {
        return (
          <Container key={indexAtivo} maxWidth="md" sx={{ padding: 3, backgroundColor: "white", borderRadius: 2, mb: 3 }}>
            <Box component="form" autoComplete="off"
              sx={{ px: 5, py: 1 }}
            >
              <Grid container columnSpacing={3} rowSpacing={1}>
                <>
                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Typography variant="h5" mb={2}>
                          Ativo imobilizado {indexAtivo + 1}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Button onClick={() => removerBem(indexAtivo)} size="small" variant="contained" sx={{ backgroundColor: "error.main", fontWeight: "500" }} startIcon={<DeleteIcon />}>
                          Remover Ativo {indexAtivo + 1}
                        </Button>
                      </Grid>
                    </Grid>

                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth size="small" margin="normal">
                      <InputLabel id="demo-select-small">Ativo</InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-simple-select"
                        onChange={(event) => onChangeInput(indexAtivo, 'Ativo', event.target.value)}
                        value={ativo.Ativo}
                        label="Ativo"

                      >
                        <MenuItem value="Casa">Casa</MenuItem>
                        <MenuItem value="Apartamento">Apartamento</MenuItem>
                        <MenuItem value="Sítio">Sítio</MenuItem>
                        <MenuItem value="Terras">Terras</MenuItem>
                        <MenuItem value="Automóvel">Automóvel</MenuItem>
                        <MenuItem value="Outros">Outros</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Valor" required fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
                      value={ativo.Valor}
                      name={String(indexAtivo)}
                      onChange={handleCurrencyAtivosImobilizados}
                      InputProps={{
                        inputComponent: NumberFormatCustom as any,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Comentario" fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
                      value={ativo.Comentario}
                      onChange={(event) => onChangeInput(indexAtivo, 'Comentario', event.target.value)}
                    />
                  </Grid>
                </>
              </Grid>

              {!(indexAtivo === bensNaoLiquidaveis.length - 1) ? "" :
                <Box sx={{ width: "100%", textAlign: "center", mt: "30px" }}>
                  <Box component={"hr"} sx={{ border: "1px solid #f0f1f2", position: "relative", top: "24px" }}></Box>
                  <Button variant="contained" size="small" color="secondary" sx={{ backgroundColor: 'secondary' }}
                    onClick={() => addBem()} disableElevation
                  >
                    + Adicionar ativo
                  </Button>
                </Box>
              }


            </Box>
          </Container>
        )
      })}
    </>
  )
}

export default BensNaoLiquidaveisComponent;