import { SquadsResponse } from "../Domain/DTO/Response/SquadsResponse";
import { AUTH_HEADER, DEFAULT_HEADER } from "./Helpers/Headers";
import StorageHelper from "./Helpers/StorageHelper";


export default class SquadsService {
    private readonly API_URL = process.env.REACT_APP_API_URL;
    private readonly storageHelper = new StorageHelper();

    /**
     * Squads
     */
    public async ListarParticipantes(): Promise<any> {
        return await fetch(`${this.API_URL}/squads/listar-e-participantes`, {
            method: 'GET',
            headers: AUTH_HEADER()
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then(data => {
                if (data) {
                    return data;
                } else {
                    throw new Error("Erro")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    public async ListarSquads(): Promise<SquadsResponse[]> {
        return await fetch(`${this.API_URL}/squads/listar`, {
            method: 'GET',
            headers: AUTH_HEADER()
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then(data => {
                if (data) {
                    return data;
                } else {
                    throw new Error("Erro")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    public async InserirSquad(squad: { nome: string }): Promise<any> {
        return await fetch(`${this.API_URL}/squads/inserir-squad`, {
            // return await fetch(`${this.API_URL}/?assessorUsuarioId=bc2efd66-8526-4eb7-b4d4-a298757e6df7`, {
            method: 'POST',
            headers: AUTH_HEADER(),
            body: JSON.stringify(squad)
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then(data => {
                if (data) {
                    return data;
                } else {
                    throw new Error("Erro")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    public async DeleteSquad(idSquad: string): Promise<any> {
        return await fetch(`${this.API_URL}/squads/deletar-squad`, {
            method: 'DELETE',
            headers: AUTH_HEADER(),
            body: JSON.stringify({ Id: idSquad })
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then(data => {
                if (data) {
                    return data;
                } else {
                    throw new Error("Erro")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    /**
     * Advisors
     */

    public async InserirParticipante(user: { SquadId: string, UsuarioId: string, SquadAdmin: boolean }): Promise<any> {
        return await fetch(`${this.API_URL}/squads/inserir-participante`, {
            // return await fetch(`${this.API_URL}/?assessorUsuarioId=bc2efd66-8526-4eb7-b4d4-a298757e6df7`, {
            method: 'POST',
            headers: AUTH_HEADER(),
            body: JSON.stringify(user)
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then(data => {
                if (data) {
                    return data;
                } else {
                    throw new Error("Erro")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    public async UpdateParticipante(newInfos: { Id: string, SquadId: string, UsuarioId: string, SquadAdmin: boolean }) {
        return await fetch(`${this.API_URL}/squads/update-participante`, {
            method: 'PUT',
            headers: AUTH_HEADER(),
            body: JSON.stringify(newInfos)
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then(data => {
                if (data) {
                    return data;
                } else {
                    throw new Error("Erro")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    public async DeleteParticipante(idParticipante: string): Promise<any> {
        return await fetch(`${this.API_URL}/squads/delete-participante?squadParticipanteId=${idParticipante}`, {
            method: 'DELETE',
            headers: AUTH_HEADER(),
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then(data => {
                if (data) {
                    return data;
                } else {
                    throw new Error("Erro")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    }

}