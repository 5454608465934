import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography'
import { SquadsContext } from '../../Context/SquadsConatext';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import SquadsServices from '../../../../../../Services/SquadsService'
import Tooltip from '@mui/material/Tooltip';

export const DialogChangeSquad = (params: any) => {
  const open = params.open
  const setOpen = params.setOpen

  // Squads Services
  let squadsServices = new SquadsServices()

  // Context
  const context: any = React.useContext(SquadsContext)
  let squads = context.squads
  let assessores = context.assessores
  let squadsWithAssessores = context.squadsWithAssessores
  let setSquadsWithAssessores = context.setSquadsWithAssessores
  let usersWithoutSquad = context.usersWithoutSquad
  let setUsersWithoutSquad = context.setUsersWithoutSquad
  let squadToViewOnDialog = context.squadToViewOnDialog

  function handleDeleteParticipante(assessor: any) {
    squadsServices.DeleteParticipante(assessor.Id)
      .then((value) => {

        let indexOfSquad = squadsWithAssessores.findIndex((value: any) => {
          return value.Id === assessor.SquadId
        })

        squadsWithAssessores[indexOfSquad].Assessores.map((value: any, index: number) => {
          if (value.Id === assessor.Id) {
            squadsWithAssessores[indexOfSquad].Assessores.splice(index, 1)
          }
        })
        setSquadsWithAssessores([...squadsWithAssessores])

        // Insert deleted assessor in users without squad
        setUsersWithoutSquad([assessor.Usuario, ...usersWithoutSquad])

        context.openAlertMessageSuccess("Assessor excluído do Squad com sucesso!")
      })
      .catch(() => {
        context.openAlertMessageFailed("Ops... não foi possível excluir o assessor do Squad.")
      })
  }

  function handleDeleteSquad() {
    if (squadToViewOnDialog.Assessores.length > 0) {
      context.openAlertMessageFailed("Primeiro exclua todos os assessores.")
      return;
    }
    squadsServices.DeleteSquad(squadToViewOnDialog.Id)
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        context.openAlertMessageFailed("Ops... não foi possível excluir o Squad.")
      })
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs">
        <DialogTitle variant="h3" sx={{ pt: 5, px: 5, pb: 2 }}>
          Integrantes do squad
        </DialogTitle>
        <DialogContent sx={{ padding: 5 }}>
          <Divider sx={{ mb: 2, borderColor: "#b2b1c330" }} />
          {/* <DialogContentText variant="body1" sx={{ mb: 5 }}>
            Tem certeza que deseja marcar a atividade como <span style={{ fontWeight: "bold" }}>concluída</span>?
          </DialogContentText> */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={1} sx={{ overflowY: "auto", maxHeight: 400 }}>
                {
                  squadToViewOnDialog.hasOwnProperty("Assessores") ?
                    squadToViewOnDialog.Assessores.map((value: any) => {
                      return (
                        <Grid item xs={12}>
                          <Grid container spacing={1} justifyContent="space-between">
                            <Grid item xs={10}>
                              <Typography variant="h5">
                                {`${value.Usuario.Nome} ${value.Usuario.Sobrenome}`}
                              </Typography>
                              <Typography variant="body2">
                                {`${value.Usuario.Email}`}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton
                                onClick={() => {
                                  handleDeleteParticipante(value)
                                }}
                                aria-label="delete" size="small">
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    }) : <></>
                }
              </Grid>
            </Grid>


            <Grid item xs={6} textAlign="center" mt={3}>
              <Button variant="text" size="small"
                onClick={() => setOpen(false)}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6} textAlign="center" mt={3}>
              <Tooltip title="Para excluir o squad primeiro você precisa excluir os assessores que estão nele.">
                <Button variant="contained" color="error" size="small" disableElevation
                  disabled={squadToViewOnDialog.hasOwnProperty("Assessores") && squadToViewOnDialog.Assessores.length > 0 ? true : false}
                  onClick={() => {
                    handleDeleteSquad()
                  }}
                >
                  Deletar
                </Button>
              </Tooltip>


            </Grid>
            {/* <Grid item xs={4} textAlign="center" mt={3}>
              <Button variant="contained" color="success"
                onClick={() => {
                  setOpen(false)
                  // handleStatus("FINISHED")
                }}
              >
                Confirmar
              </Button>
            </Grid> */}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}