import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid'
import { Divider, InputLabel } from '@mui/material';
import { RiThumbUpLine } from 'react-icons/ri';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NumberFormat from 'react-number-format';
import { useClientContext } from '../ClientContext';
import { forwardRef, useEffect, useState } from 'react';

interface WinProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface interfaceValues { price: number | null; codeOfClient: string; reason: string };

export const Win: React.FC<WinProps> = ({ open, setOpen }) => {
  const { client, updateClient } = useClientContext();

  const [newValues, setNewValues] = useState<interfaceValues>({ price: client?.Preco || null, codeOfClient: '', reason: '' });

  useEffect(() => {
    newValues.price = client?.Preco ?? null;
    newValues.codeOfClient = client?.CodigoCliente ?? '';
    newValues.reason = '';
    setNewValues({ ...newValues });
  }, [client])

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth='xs'>
        <DialogTitle variant='h3' sx={{ pt: 5, px: 5, pb: 2 }}>Parabéns!</DialogTitle>
        <DialogContent sx={{ padding: 5 }}>
          <Divider sx={{ mb: 2, borderColor: '#b2b1c330' }} />
          <DialogContentText variant='body1' sx={{ mb: 5 }}>
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label='PL'
                autoFocus
                required
                fullWidth
                size='small'
                variant='outlined'
                autoComplete='off'
                value={newValues.price}
                onChange={(event) => {
                  newValues.price = Number(event.target.value);
                  setNewValues({ ...newValues });
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='Código do cliente' required
                fullWidth
                size='small'
                variant='outlined'
                autoComplete='off'
                value={newValues.codeOfClient}
                onChange={((event) => {
                  const value = event.target.value;

                  if (/^\d*$/.test(value)) {
                    newValues.codeOfClient = value;
                    setNewValues({ ...newValues });
                  }
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth size='small'>
                <InputLabel id='reason-win'>Motivo que virou cliente</InputLabel>
                <Select
                  labelId='reason-win'
                  label='Motivo que virou cliente'
                  size='small'
                  value={newValues.reason}
                  onChange={(event) => {
                    newValues.reason = event.target.value;
                    setNewValues({ ...newValues });
                  }}
                >
                  <MenuItem disabled value=''>
                    <em>Selecione...</em>
                  </MenuItem>
                  <MenuItem value='Atendimento Marco'>Atendimento Marco</MenuItem>
                  <MenuItem value='Conhecimento do assessor'>Conhecimento do assessor</MenuItem>
                  <MenuItem value='Plataforma XP'>Plataforma XP</MenuItem>
                  <MenuItem value='Plano de voo'>Plano de voo</MenuItem>
                  <MenuItem value='Indicação'>Indicação</MenuItem>
                  <MenuItem value='Melhores rendimentos'>Melhores rendimentos</MenuItem>
                  <MenuItem value='Produtos'>Produtos</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} textAlign='center' mt={3}>
              <Button sx={{ width: 180 }} endIcon={<RiThumbUpLine />} variant='contained' color='success'
                onClick={async () => {
                  if (client && client.Id) {
                    await updateClient({
                      ...client,
                      Preco: newValues.price,
                      CodigoCliente: newValues.codeOfClient,
                      MotivoDeGanho: newValues.reason,
                      Status: 'CLIENTE',
                      DataCadastro: new Date().toISOString()
                    });

                    newValues.price = null;
                    newValues.codeOfClient = '';
                    newValues.reason = '';
                    setNewValues({ ...newValues });

                    setOpen(false);
                  }

                }}
              >
                Ganhar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom = forwardRef<NumberFormat<any>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        prefix={'R$'}
        decimalSeparator={','}
        thousandSeparator={'.'}
        fixedDecimalScale={true}
        decimalScale={2}
        name={props.name}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
      />
    );
  },
);