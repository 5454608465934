import { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useUserInfos } from './useUserInfos';
import NegociosService from '../Services/NegociosService';
import { INegocioSeguroRequest } from '../Interface/interfaceNegocios';

// Ajuste do mapeamento de status para o funil de seguros
const statusMapping: Record<string, string> = {
  LEAD: 'Lead',
  R1: 'R1',
  R2: 'R2',
  AGUARDANDO: 'Aguardando',
  FECHADO: 'Fechado',
  EM_ANALISE: 'Em Análise',
  EMITIDO: 'Emitido',
  PAUSADO: 'Pausado',
};

interface IKanbanList {
  [key: string]: {
    name: string;
    data: INegocioSeguroRequest[];
  };
}

interface AdvisorSegurosContextProps {
  loading: boolean;
  filteredKanbanList: IKanbanList;
}

const AdvisorSegurosContext = createContext<AdvisorSegurosContextProps | undefined>(undefined);

export const AdvisorSegurosProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [kanbanList, setKanbanList] = useState<IKanbanList>({});
  const [loading, setLoading] = useState<boolean>(true);
  const { userInfos } = useUserInfos();
  const negociosService = new NegociosService();

  const fetchNegocios = async () => {
    setLoading(true);
    try {
      const assessorId = userInfos?.Id;
      if (!assessorId) {
        setLoading(false);
        return;
      }

      // Chamada do método para listar negócios de seguros por assessor
      const negocios = await negociosService.ListNegociosSegurosByAssessorId(assessorId);

      const kanban: IKanbanList = {};

      Object.entries(statusMapping).forEach(([statusKey, statusName]) => {
        kanban[statusKey] = { name: statusName, data: [] };
      });

      negocios.forEach((negocio) => {
        const status = negocio.Negocio.Status;
        if (status && kanban[status]) {
          kanban[status].data.push(negocio);
        }
      });

      setKanbanList(kanban);
    } catch (error) {
      enqueueSnackbar('Erro ao buscar negócios de seguros.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userInfos?.Id) {
      fetchNegocios();
    }
  }, [userInfos?.Id]);

  return (
    <AdvisorSegurosContext.Provider
      value={{
        loading,
        filteredKanbanList: kanbanList,
      }}
    >
      {children}
    </AdvisorSegurosContext.Provider>
  );
};

export const useAdvisorSegurosContext = () => {
  const context = useContext(AdvisorSegurosContext);
  if (!context) {
    throw new Error('useAdvisorSegurosContext must be used within an AdvisorSegurosProvider');
  }
  return context;
};
