import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { DrawerHeader, AppBar, Drawer } from "./DrawerConfig";
import { Outlet } from 'react-router-dom';
import { TopNav } from '../../Common/Navigation/TopNav';
import logo from '../../../Assets/Images/logo-marcolab.png'
import { PermissionsProvider } from '../../../Hooks/usePermissionsUser';
import { SideBarComponentV2 } from '../../Common/Navigation/SidebarComponentV2';

const AppPage = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <PermissionsProvider>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar style={{ background: "#ffffff", color: "#bcbccb", boxShadow: "0px 5px 20px 1px rgba(119, 119, 119, 0.05)" }} position="fixed" open={open}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <TopNav />
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}
            PaperProps={{
              sx: {
                backgroundColor: "secondary.main",
                color: "red",
                "&::-webkit-scrollbar": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#1e1e2f",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#4a4a61",
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#5b5b77",
                },
              },
            }}
          >
            <DrawerHeader>
              <Box component="a" href="/app/dashboard-pbi" sx={{ maxWidth: "160px" }}>
                <img src={logo} alt='Logo Marcolab' width={150} />
              </Box>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon sx={{ color: 'white' }} /> : <ChevronLeftIcon sx={{ color: 'white' }} />}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <SideBarComponentV2 open={open} />
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1 }}>
            <DrawerHeader />
            <Box sx={{ backgroundColor: "#f0f0f7" }}>
              <Outlet />
            </Box>
          </Box>
        </Box >
      </PermissionsProvider>
    </>
  )
}

export default AppPage;