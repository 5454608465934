import { DataGrid, GridColDef, GridValueFormatterParams, ptBR } from '@mui/x-data-grid'
import React, { useMemo } from 'react';

export interface DataGridComponentProps {
  clientes: any[];
  loading: boolean;
  columns: ('NomeCompleto' | 'Origem' | 'Temperatura' | 'Preco' | 'UltimaAtividade' |
    'PotencialDeInvestimento' | 'SubStatus' | 'TelefoneContatoPrimario' | 'CodigoCliente' |
    'MotivoDePerda')[];
}

const DataGridComponent: React.FC<DataGridComponentProps> = ({ clientes, columns, loading }) => {
  const formatterColorTemperatura = (value: string) => {
    switch (value) {
      case 'Quente': {
        return 'green';
      }
      case 'Morno': {
        return '#7D9F7D ';
      }
      case 'Frio': {
        return '#856F97';
      }
      default: {
        return '';
      }
    }
  }

  const formatterAtividade = (value: any) => {
    if (value.value == null) {
      return '-'
    }
    let createDate = new Date(value.value)
    createDate.setHours(createDate.getHours() - 3)
    let dateNow = new Date()
    let difference = dateNow.getTime() - createDate.getTime()
    let days = Math.floor(difference / (1000 * 3600 * 24));

    if (dateNow.getDate() - createDate.getDate() == 1) {
      if ((dateNow.getMonth() == createDate.getMonth()) && (dateNow.getFullYear() == createDate.getFullYear())) {
        days = 1
      }
    }
    return days
  }

  const formatterPreco = (value: any) => {
    let v = value.value
    if (!(v > 0)) v = 0
    return (
      v.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    )
  }

  const buildColumns = useMemo(() => {
    const result: GridColDef[] = []

    columns.forEach(v => {
      if (v === 'NomeCompleto') {
        result.push({
          field: 'NomeCompleto',
          headerName: 'Nome',
          headerAlign: "center",
          cellClassName: "cell-class",
          flex: 1,
          renderHeader: () => {
            return <strong>Nome</strong>
          },
          renderCell: (params: any) => {
            return <strong>{params.value}</strong>
          }
        })
      } else if (v === 'Origem') {
        result.push({
          field: 'Origem',
          headerName: 'Origem',
          type: 'text',
          headerAlign: "center",
          align: "center",
          flex: 1,
          cellClassName: "cell-class",
          renderHeader: () => {
            return <strong>Origem</strong>
          },
          renderCell: (params: any) => {
            return <span>{params.value}</span>
          }
        })
      } else if (v === 'Temperatura') {
        result.push({
          field: 'Temperatura',
          headerName: 'Temperatura',
          type: 'text',
          headerAlign: "center",
          align: "center",
          flex: 1,
          cellClassName: "cell-class",
          renderHeader: () => {
            return <strong>Temperatura</strong>
          },
          renderCell: (params: any) => {
            return <strong style={{ color: formatterColorTemperatura(params.value) }}>{params.value ? params.value : '-'}</strong>
          }
        })
      } else if (v === 'Preco') {
        result.push({
          field: 'Preco',
          headerName: 'Valor',
          type: 'number',
          headerAlign: "center",
          align: "center",
          flex: 1,
          cellClassName: "cell-class",
          valueFormatter: (params: GridValueFormatterParams<any>) => formatterPreco(params),
          renderCell: (params: any) => {
            return <>R$&nbsp;{params.formattedValue}</>
          },
          renderHeader: () => {
            return <strong>Patrimônio</strong>
          }
        })
      } else if (v === 'UltimaAtividade') {
        result.push({
          field: 'UltimaAtividade',
          headerName: 'Atividade',
          cellClassName: "cell-class",
          headerAlign: "center",
          align: "center",
          flex: 1,
          valueFormatter: (params: GridValueFormatterParams<any>) => formatterAtividade(params),
          renderCell: (params: any) => {
            // Format value
            let lastActivityDay = Number(params.formattedValue)
            let textToShow = ""
            if (lastActivityDay == 0 || lastActivityDay == 1) {
              textToShow = lastActivityDay + " dia"
            } else if (lastActivityDay > 1) {
              textToShow = lastActivityDay + " dias"
            }


            // Insert the color
            let colorToText = ""

            if (lastActivityDay >= 0 && lastActivityDay < 5) {
              colorToText = "#b2b1ff"
            } else if (lastActivityDay < 10) {
              colorToText = "#ffae55"
            } else {
              colorToText = "#ffa4a4 "
            }

            return <>
              <span style={{ fontWeight: "bold", color: colorToText }}>{textToShow}</span>
            </>
          },
          renderHeader: () => {
            return <strong>Última atualização</strong>
          }
        })
      } else if (v === 'PotencialDeInvestimento') {
        result.push({
          field: 'PotencialDeInvestimento',
          headerName: 'Potencial de Investimento',
          type: 'text',
          headerAlign: "center",
          align: "center",
          flex: 1,
          cellClassName: "cell-class",
          renderHeader: () => {
            return <strong>Potencial de Investimento</strong>
          },
          renderCell: (params: any) => {
            return <span>{params.value ? params.value : '-'}</span>
          }
        })
      } else if (v === 'SubStatus') {
        result.push({
          field: 'SubStatus',
          headerName: 'Status',
          cellClassName: "cell-class",
          headerAlign: "center",
          align: "center",
          flex: 1,
          renderHeader: () => {
            return <strong>Status</strong>
          },
          renderCell: (value: any) => {
            if (value.row.SubStatus != null && value.row.SubStatus != "") {
              return <>{value.row.SubStatus}</>
            } else {
              return <>Qualificação</>
            }
          }
        })
      } else if (v === 'CodigoCliente') {
        result.push({
          field: 'CodigoCliente',
          headerName: 'Código do Cliente',
          cellClassName: "cell-class",
          headerAlign: "center",
          align: "center",
          flex: 1,
          renderHeader: () => {
            return <strong>Código do Cliente</strong>
          },
          renderCell: (params: any) => <span>{params.value ? params.value : '-'}</span>
        })
      } else if (v === 'TelefoneContatoPrimario') {
        result.push({
          field: 'TelefoneContatoPrimario',
          headerName: 'Telefone',
          cellClassName: "cell-class",
          headerAlign: "center",
          align: "center",
          flex: 1,
          renderHeader: () => {
            return <strong>Contato</strong>
          },
          renderCell: (params: any) => <span>{params.value ? params.value : '-'}</span>
        })
      } else if (v === 'MotivoDePerda') {
        result.push({
          field: 'MotivoDePerda',
          headerName: 'Motivo de Perda',
          cellClassName: "cell-class",
          headerAlign: "center",
          align: "center",
          flex: 1,
          renderHeader: () => {
            return <strong>Motivo de Perda</strong>
          },
          renderCell: (params: any) => <span>{params.value ? params.value : '-'}</span>
        })
      }
    })

    return result;
  }, [columns])


  return <DataGrid
    sx={{
      fontSize: 15,
      border: "none",
      "& .MuiDataGrid-cell": {
        borderBottom: "1px solid #d1aeed1f"
      },
      "& .MuiDataGrid-cell:focus": {
        outline: "none"
      }
    }}

    onCellClick={(param) => {
      let url = `/app/perfil-cliente/${param.id}`
      window.open(url, '_blank', 'noopener,noreferrer');
    }}

    loading={loading}
    checkboxSelection={false}
    rows={clientes}
    columns={buildColumns}
    pageSize={20}
    rowsPerPageOptions={[20]}
    getRowId={(row: any) => row.Id}
    autoPageSize={true}
    autoHeight={true}
    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
    componentsProps={{
      toolbar: {
        showQuickFilter: true,
        quickFilterProps: { debounceMs: 500 },
      },
    }}
  />
}

export default DataGridComponent;