import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Box, Typography, Grid, Divider, Skeleton } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { INegocioSeguroRequest } from '../../../Interface/interfaceNegocios';
import { KanbanItemSeguros } from './KanbanItem';
import { IKanbanListSeguro } from '../../../Hooks/useKanbanNegociosSeguro';

interface Props {
  status: string;
  columnName: string;
  negocios: INegocioSeguroRequest[];
  loading: boolean;
  isDraggable?: boolean;
  kanbanList?: IKanbanListSeguro;
  setKanbanList?: React.Dispatch<React.SetStateAction<IKanbanListSeguro>>;
}

export const KanbanColumnSeguros: React.FC<Props> = ({
  status,
  columnName,
  negocios,
  loading,
  isDraggable = true,
  kanbanList,
  setKanbanList

}) => {
  const totalPA = negocios.reduce(
    (sum, negocio) => sum + (negocio.Seguro.PA ?? 0),
    0
  );

  const totalNegocios = negocios.length;

  // Gerar esqueletos caso esteja carregando
  const skeletonCount = Math.floor(Math.random() * 5) + 2;

  return (
    <Box width={242.7} display="flex" flexDirection="column">
      <Grid container alignItems="center">
        <Grid item xs>
          <Box textAlign='center' my={3}>
            <Typography variant="h4" sx={{ color: '#512178' }}>{columnName}</Typography>
            <Typography variant="h6">
              {totalPA.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              {` - ${totalNegocios} ${totalNegocios > 1 ? 'negócios' : 'negócio'}`}
            </Typography>
            <Divider sx={{ mt: 3, borderColor: '#00000008' }} />
          </Box>
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ height: '40%', mt: 2.5 }} />
      </Grid>
      <Droppable droppableId={status} isDropDisabled={!isDraggable}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} style={{ height: '100%' }}>
            {loading ? (
              <Grid container gap={2}>
                {Array.from({ length: skeletonCount }).map((_, index) => (
                  <Grid item xs={12} mx={2} key={index}>
                    <Skeleton variant="rectangular" height={100} sx={{ borderRadius: '5px' }} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <>
                {negocios.length === 0 && (
                  <Box textAlign='center' my={2} sx={{ opacity: 0.2 }}>
                    <TrendingUpIcon sx={{ fontSize: 60, color: '#512178' }} />
                    <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                      Nenhum negócio disponível
                    </Typography>
                  </Box>
                )}
                <Grid container gap={2}>
                  {negocios.map((negocio, index) => (
                    <KanbanItemSeguros
                      key={negocio.Negocio.Id}
                      negocio={negocio}
                      index={index}
                      kanbanList={kanbanList}
                      setKanbanList={setKanbanList}
                    />
                  ))}
                </Grid>
              </>
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Box>
  );
};
