import Checkbox from '@mui/material/Checkbox';
import ClientesService from "../../../Services/ClientesService";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import { NumberFormatCustom } from '../../../Helpers/Components';

const BensComponent = ({ bens, setBens, cliente, setCliente, thereIsBens, setThereIsBens }: any) => {
  const clientesService = new ClientesService()

  const onChangeBens = (index: number, field: string, value: string) => {
    bens[index][field] = value
    setBens([...bens])
  }

  const onChangeCurrency = (index: number, event: any) => {
    if (isNaN(Number(event.nativeEvent.data))) return;
    if (event.nativeEvent.inputType === "deleteContentBackward" || event.nativeEvent.inputType === "deleteWordBackward") {
      bens[index]['Valor'] = ""
    } else {
      let newValue = parseFloat((event.target.value.replace("R$", "").replaceAll(".", ""))).toFixed(2)
      bens[index]['Valor'] = newValue
    }
    setBens([...bens])
  }

  const addBens = () => {
    bens.push({ Ativo: "", ClienteId: cliente.Id, Valor: null, Comentario: "", Id: "" })
    setBens([...bens])
  }

  const handleCurrencyAtivos = (event: any) => {
    if (Number(event.target.value) > 0) {
      bens[Number(event.target.name)].Valor = Number(event.target.value)
      setBens([...bens])
    } else {
      bens[Number(event.target.name)].Valor = null
      setBens([...bens])
    }
  };

  const handleComentario = (event: any) => {
    bens[Number(event.target.name)].Comentario = event.target.value
    setBens([...bens])
  };

  const removeBens = (index: number) => {
    if (bens[index].hasOwnProperty("Id") && bens[index].Id != "") {
      clientesService.DeletarAtivoNaoLiquidavel(bens[index].Id)
        .then((e) => { })
        .catch((e) => {
        })
    }

    bens.splice(index, 1)
    setBens([...bens])
  }

  const changeThereIsBens = () => {
    setThereIsBens(!thereIsBens)
  }

  const changeAdministradoraDeBens = () => {
    cliente.PossuiAdministradoraBens = !cliente.PossuiAdministradoraBens
    setCliente({ ...cliente })
  }

  const SelectInputBorderRight = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
      //   backgroundColor: theme.palette.background.paper,
      border: '1px solid #c4c4c4ab',
      borderRadius: "0px 5px 5px 0px",
      padding: '7px 26px 7px 12px',
    },
  }));
  const SelectInputBorderLeft = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
      //   backgroundColor: theme.palette.background.paper,
      border: '1px solid #c4c4c4ab',
      borderRadius: "5px 0px 0px 5px",
      padding: '7px 26px 7px 12px',
    },
  }));

  return <>
    <Container maxWidth="md" sx={{ padding: 3, backgroundColor: "white", borderRadius: 2, mb: 3 }}>
      <Box component="form" autoComplete="off" sx={{ px: 5, py: 1 }}>
        <Box sx={{ mb: 1 }}>
          <Typography variant="h5">
            Bens e patrimônio
          </Typography>
        </Box>
        <FormControlLabel
          label="Se o seu cliente não possuir bens e patrimônios, clique aqui para desativar."
          labelPlacement="start"
          sx={{ mb: 1 }}
          control={
            <Switch
              checked={thereIsBens} onClick={() => { changeThereIsBens() }}
              value={thereIsBens || false}
              size="small"
            />
          }
        />
        {!thereIsBens ? "" : (
          <>
            <Divider sx={{ my: 2 }} />
            <FormControlLabel
              label={"Cliente possui administradora de bens?"}
              name="AtividadeProfissional"
              sx={{ color: '#5d5c74', display: "block", ml: 1 }}
              value={cliente.PossuiAdministradoraBens || false}
              control={
                <Checkbox checked={cliente.PossuiAdministradoraBens || false} value={cliente.PossuiAdministradoraBens || false} onChange={changeAdministradoraDeBens}
                  sx={{ color: '#512178', '&.Mui-checked': { color: '#512178', }, }} />
              }
            />
            <Divider sx={{ my: 2 }} />
            <Grid container sx={{ mt: 3, mb: 2 }}>
              <Grid item xs={5} sx={{ textAlign: "center" }}>
                <Typography variant="h6">
                  Bem e/ou patrimônio
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ textAlign: "center" }}>
                <Typography variant="h6">
                  Valor
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ textAlign: "center" }}>
                <Typography variant="h6">
                  Comentário
                </Typography>
              </Grid>
            </Grid>

            {bens.map((bem: any, indexBem: number) => {
              return <Grid container key={indexBem}>
                <Grid item xs={5}>
                  <FormControl fullWidth>
                    <Select
                      value={bem.Ativo || "Selecione"}
                      onChange={(event) => onChangeBens(indexBem, 'Ativo', event.target.value)}
                      fullWidth
                      input={<SelectInputBorderLeft />}
                      defaultValue="Selecione"
                    >
                      <MenuItem key="Selecione" value="Selecione"><em>Selecione...</em></MenuItem>
                      <MenuItem key="Casa" value="Casa">Casa</MenuItem>
                      <MenuItem key="Apartamento" value="Apartamento">Apartamento</MenuItem>
                      <MenuItem key="Sítio" value="Sítio">Sítio</MenuItem>
                      <MenuItem key="Terras" value="Terras">Terras</MenuItem>
                      <MenuItem key="Automóvel" value="Automóvel">Automóvel</MenuItem>
                      <MenuItem key="Outros" value="Outros">Outros</MenuItem>
                    </Select>
                  </FormControl>

                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputBase
                      fullWidth
                      name={String(indexBem)}
                      autoFocus={false}
                      sx={{
                        height: "36px",
                        width: "100%",
                        borderLeft: 0,
                        borderRight: 0,
                        border: '1px solid #c4c4c4ab',
                        borderRadius: 0,
                        padding: '5px 26px 5px 12px',
                      }}
                      value={bem.Valor || ""}
                      inputComponent={NumberFormatCustom as any}
                      onChange={handleCurrencyAtivos}
                    />

                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <FormControl fullWidth>
                      <InputBase
                        fullWidth
                        name={String(indexBem)}
                        autoFocus={false}
                        sx={{
                          height: "36px",
                          width: "100%",
                          borderLeft: 0,
                          borderRight: 0,
                          border: '1px solid #c4c4c4ab',
                          borderRadius: "0px 5px 5px 0px",
                          padding: '5px 26px 5px 12px',
                        }}
                        value={bem.Comentario || ""}
                        onChange={handleComentario}
                      />

                    </FormControl>
                    {/* <Select
                                                value={bem.Comentario}
                                                onChange={(event) => onChangeBens(indexBem, 'Comentario', event.target.value)}
                                                fullWidth size="small" margin="none"
                                                input={<SelectInputBorderRight />}
                                            >
                                                <MenuItem value="Banco do Brasil">Banco do Brasil</MenuItem>
                                                <MenuItem value="Itaú">Itaú</MenuItem>
                                                <MenuItem value="Santander">Santander</MenuItem>
                                                <MenuItem value="Bradesco">Bradesco</MenuItem>
                                                <MenuItem value="BTG Pactual">BTG Pactual</MenuItem>
                                                <MenuItem value="Safra">Safra</MenuItem>
                                                <MenuItem value="Sicredi">Sicredi</MenuItem>
                                                <MenuItem value="Unicred">Unicred</MenuItem>
                                                <MenuItem value="XP">XP</MenuItem>
                                                <MenuItem value="Clear">Clear</MenuItem>
                                                <MenuItem value="Rico">Rico</MenuItem>
                                                <MenuItem value="Banco digital">Banco digital</MenuItem>
                                                <MenuItem value="Outros">Outros</MenuItem>
                                            </Select> */}
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <IconButton aria-label="Example" sx={{ ml: "15px", position: "relative", bottom: "2px" }}>
                    <DeleteForeverIcon sx={{ color: "#512178" }}
                      onClick={() => removeBens(indexBem)}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            })}
            <Box sx={{ width: "100%", textAlign: "center", mt: "30px" }}>
              <Box component={"hr"} sx={{ border: "1px solid #f0f1f2", position: "relative", top: "24px" }}></Box>
              <Button variant="contained" size="small" color="secondary" sx={{ backgroundColor: 'secondary' }}
                onClick={() => addBens()} disableElevation
              >
                + Adicionar Ativo
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Container>
  </>

}

export default BensComponent;