import { interfaceDadosConsolidadosXP } from "../Interface/interfaceDadosConsolidadosXP";
import { AUTH_HEADER } from "./Helpers/Headers";

export default class Dashboard {
    private readonly API_URL = process.env.REACT_APP_API_URL;

    public async GetInfosDash(assessorId: string, dataBusca: string) {
        return await fetch(`${this.API_URL}/dadosConsolidadosXP/assessor?assessorId=${assessorId}&dataBusca=${dataBusca}`, {
            method: 'GET',
            headers: AUTH_HEADER()
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then(data => {
                if (data) {
                    return data;
                } else {
                    throw new Error("Erro")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    public async GetInfosDashLider(assessorId: string, dataBusca: string) {
        return await fetch(`${this.API_URL}/dadosConsolidadosXP/assessor-squad?assessorId=${assessorId}&dataBusca=${dataBusca}`, {
            method: 'GET',
            headers: AUTH_HEADER()
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then(data => {
                if (data) {
                    return data;
                } else {
                    throw new Error("Erro")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    public async GetInfosAll(assessorId: string, dataBusca: string) {
        return await fetch(`${this.API_URL}/dadosconsolidadosxp/buscar`, {
            method: 'GET',
            headers: AUTH_HEADER()
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then(data => {
                if (data) {
                    return data;
                } else {
                    throw new Error("Erro")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    public async GetAllUsers() {
        return await fetch(`${this.API_URL}/usuario/listar-usuarios-ativos`, {
            method: 'GET',
            headers: AUTH_HEADER()
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then(data => {
                if (data) {
                    return data;
                } else {
                    throw new Error("Erro")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    public async ListarSquadsParticipantes() {
        return await fetch(`${this.API_URL}/squads/listar-e-participantes`, {
            method: 'GET',
            headers: AUTH_HEADER()
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then(data => {
                if (data) {
                    return data;
                } else {
                    throw new Error("Erro")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    public async ListarSquads() {
        return await fetch(`${this.API_URL}/squads/listar`, {
            method: 'GET',
            headers: AUTH_HEADER()
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then(data => {
                if (data) {
                    return data;
                } else {
                    throw new Error("Erro")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    public async ListarDetalhes() {
        return await fetch(`${this.API_URL}/dadosConsolidadosXP/detalhes`, {
            method: 'GET',
            headers: AUTH_HEADER()
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then(data => {
                if (data) {
                    return data;
                } else {
                    throw new Error("Erro")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    public async AddXpData(data: interfaceDadosConsolidadosXP[]) {
        return fetch(`${this.API_URL}/dadosConsolidadosXP/inserir`, {
            method: 'POST',
            headers: AUTH_HEADER(),
            body: JSON.stringify(data)
        })
            .then(async response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    const errorResponse = await response.json();
                    throw new Error(JSON.stringify(errorResponse));
                }
            })
            .catch(err => {
                throw new Error(err.message);
            });
    }
}