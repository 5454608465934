import { CircularProgress, InputAdornment, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from "react";
import { useKanbanNegociosContext } from "../../../Hooks/useKanbanNegocios";

export const KanbanSearchInputCambio = () => {
  const {
    setSearchTerm
  } = useKanbanNegociosContext();

  const [search, setSearch] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    setIsSearching(true);
    const timeoutId = setTimeout(() => {
      setSearchTerm(search);
      setIsSearching(false);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
      setIsSearching(false);
    };
  }, [search]);

  return <TextField
    label='Pesquisar'
    sx={{ width: 250 }}
    autoComplete='off'
    size='small'
    value={search}
    onChange={(event) => setSearch(event.target.value)}
    InputProps={{
      startAdornment: (
        <InputAdornment position='start'>
          <SearchIcon />
        </InputAdornment>
      ),
      endAdornment: isSearching && (
        <InputAdornment position='end'>
          <CircularProgress size={15} />
        </InputAdornment>
      )
    }}
  />;
}
