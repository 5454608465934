import { Link, Navigate } from "react-router-dom"
import { useState } from "react";
import LoginService from "../../../Services/LoginService";
import { RecuperarSenhaRequest } from "../../../Domain/DTO/Request/RecuperarSenhaRequest";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';

const RecuperarSenhaComponent = () => {
    const loginService = new LoginService();
    const [recuperarSenhaData, setRecuperarSenhaData] = useState<RecuperarSenhaRequest>({ Email: '' })
    const [sucesso, setSucesso] = useState(false);
    const [loading, setLoading] = useState<boolean>(false)


    const onChangeInput = (Email: string) => {
        setRecuperarSenhaData({ Email })
    }

    const [erro, setErro] = useState("")
    const [loginValido, setLoginValido] = useState(false)
    const [invalidArguments, setInvalidArguments] = useState({ validEmail: false })

    // Checking email and password
    const onClickSubmit = () => {
        setLoading(true)
        const checkingEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const emailValid = checkingEmail.test(String(recuperarSenhaData.Email).toLowerCase());

        if (!emailValid) {
            setErro("Email inválido.")
            setLoading(false)
            setInvalidArguments({ validEmail: true })
        } else {
            setInvalidArguments({ validEmail: false })
            loginService
                .RecuperarSenha(recuperarSenhaData)
                .then(r => setSucesso(true))
                .catch((erro: Error) => setErro(''))
                .finally(() => setLoading(false))
        }
    }

    if (sucesso)
        return <Navigate to="/#recuperar-senha-sucesso" />

    return (
        <>
            <TextField label="Email" fullWidth id="outlined-basic" variant="outlined" margin="normal"
                sx={{ mt: 4 }}
                error={invalidArguments.validEmail}
                helperText={invalidArguments.validEmail ? "Email inválido." : ""}
                onChange={(target) => onChangeInput(target.target.value)}
                value={recuperarSenhaData.Email}
            />
            <Box sx={{ textAlign: "center", my: 3 }}>
                <LoadingButton sx={{ width: "90%", textAlign: "center" }} loading={loading} onClick={() => onClickSubmit()} variant="contained">Recuperar senha</LoadingButton>
            </Box>
            <Stack direction="row" spacing={0} justifyContent="space-around" textAlign="center" alignItems="center">
                <small>
                    <Link to="/">Voltar</Link>
                </small>
            </Stack>
            {/* <div className="row">
                <form>
                    <div className="input-field col s12">
                        <input id="email" type="text" name="email" onChange={(target) => onChangeInput(target.target.value)} />
                        <label htmlFor="email">E-mail</label>
                    </div>

                    <div className={erro != '' ? "input-erros col s12" : "hide"}>
                        <ul>
                            <li>
                                {erro}
                            </li>
                        </ul>
                    </div>

                    <div className="col s12 submit">
                        <a className="btn primary" onClick={() => onClickSubmit()}>
                            {
                                loading
                                    ?
                                    <Loader />
                                    :
                                    <>Recuperar Senha</>
                            }
                        </a>
                    </div>

                    <div className="col s12">
                        <small>
                            <Link to="/">Voltar</Link>
                        </small>
                    </div>
                </form>
            </div> */}
        </>
    );
}

export default RecuperarSenhaComponent;