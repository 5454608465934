import PrimeiraReuniao from "../../Common/PrimeiraReuniao/PrimeiraReuniaoComponent";
import Box from '@mui/material/Box'

const PrimeiraReuniaoPage = (props: any) => {
    return (
        <Box sx={{ pt: 5, px: 7 }}>
            <PrimeiraReuniao />
        </Box>
    )
}

export default PrimeiraReuniaoPage;