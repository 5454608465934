import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import timeGridPlugin from '@fullcalendar/timegrid';
import ptLocale from '@fullcalendar/core/locales/pt-br';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import Box from '@mui/material/Box'
import ScheduleService from '../../../Services/ScheduleService';
import { useEffect, useState } from 'react';
import StorageHelper from "../../../Services/Helpers/StorageHelper"
import { interfaceSchedule } from '../../../Interface/intarfaceSchedule';
import { DialogEvent } from './DialogEvent';

const scheduleService = new ScheduleService()

export const CalendarComponent = () => {
  const storageHelper = new StorageHelper();
  const assessorId = storageHelper.GetUsuarioId();

  const [tasks, setTasks] = useState<interfaceSchedule[]>([])

  useEffect(() => {
    scheduleService.ListarTarefasAssessor(assessorId)
      .then((values) => {
        if (values && values.length > 0) {
          const filteredTasks = values.filter((task: interfaceSchedule) => task.StatusTarefa !== "CANCELED");
          setTasks(filteredTasks);
        }
      })
      .catch((err: any) => {
        console.error("Error ao buscar Tarefas", err);
      })
  }, [assessorId]);

  interface interfaceEvents { id: string, title: string, date: Date, color: string }
  const [events, setEvents] = useState<interfaceEvents[]>([])
  useEffect(() => {
    if (tasks.length > 0) {
      let newEvent: any = []

      tasks.map((value: interfaceSchedule) => {
        let event: any = {}

        event.id = value.Id

        let nameArray = value.ClienteNome!.split(" ")
        // console.log(nameFiltered)
        let name = nameArray[0]
        if (nameArray[1] != undefined && nameArray[1] != null) {
          name = name + " " + nameArray[1].substring(0, 6)
        }

        if (value.Title == "Reagendado") {
          value.Title = "1° contato reagendado"
        }
        event.title = value.Title + " - " + name

        // Color
        if (value.AtividadeFinalizada) {
          event.color = "#ebebeb"
          event.borderColor = "#ebebeb"
          event.textColor = "#525252"
          event.title = event.title
        } else {
          event.color = "#512178"
        }
        event.success = true

        // Event date
        let date = new Date(value.DataEvento!)
        date.setHours(date.getHours() - 3)
        event.date = date

        // All day
        if (value.AtividadeTodoDia) {
          event.allDay = true
        }


        event = { ...event, value }

        newEvent.push(event)
      })

      setEvents(newEvent)
    }
  }, [tasks])

  const [openDialogEvent, setOpenDialogEvent] = useState(false)
  const [contentOfDialog, setContentOfDialog] = useState<any>({})

  return <>
    <Box sx={{ backgroundColor: "#ffffff", p: 4, m: 3, minHeight: 700, borderRadius: 2 }}>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrap5Plugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          start: 'prev,next today',
          center: 'title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay'
        }}
        weekends={true}
        locale={ptLocale}
        events={events}
        eventClick={(eventInfo: any) => {
          setOpenDialogEvent(true)
          setContentOfDialog(eventInfo.event._def.extendedProps.value)
        }}
      />
    </Box>

    <DialogEvent open={openDialogEvent} setOpen={setOpenDialogEvent} contentInfos={contentOfDialog} />
  </>
}