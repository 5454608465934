import React, { useState, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Grid,
  Avatar,
  Tooltip,
  Divider, FormControl, InputLabel, MenuItem, Select, IconButton, FormHelperText
} from '@mui/material';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { IUpdateTask, interfaceTask } from '../../../Interface/interfaceTask';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineConnector from '@mui/lab/TimelineConnector';
import { NoContent } from '../NoCotent';
import AssignmentIcon from '@mui/icons-material/AssignmentOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForeverOutlined';
import StorageHelper from '../../../Services/Helpers/StorageHelper';
import LoadingButton from '@mui/lab/LoadingButton';
import PlaylistRemoveOutlinedIcon from '@mui/icons-material/PlaylistRemoveOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';

interface Props {
  open: boolean;
  onClose: () => void;
  task: interfaceTask;
  addCommentToTask: (taskId: string, texto: string) => any;
  updateTask: (task: IUpdateTask, handleCancelEditing: any) => any;
  deleteTask: (taskId: string) => any;
  updateStatusTask: (taskId: string, status: number) => any;
}

export const TaskDialog: React.FC<Props> = ({ open, onClose, task, addCommentToTask, updateTask, deleteTask, updateStatusTask }) => {
  const [editing, setEditing] = useState(false);
  const [titulo, setTitulo] = useState(task.Titulo);
  const [descricao, setDescricao] = useState(task.Descricao);
  const [dataPrazo, setDataPrazo] = useState(task.DataPrazo);
  const [grauUrgencia, setGrauUrgencia] = useState(task.GrauUrgencia);
  const [newComment, setNewComment] = useState("");
  const [loadingAddNewComment, setLoadingAddNewComment] = useState(false);

  const storageHelper = new StorageHelper();
  const assessorId = storageHelper.GetUsuarioId();

  const userOwnerTask = useMemo(() => {
    if (assessorId === task.CriadorId) {
      return true;
    }

    return false;
  }, [task, assessorId])

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancelEditing = () => {
    setEditing(false);
    setTitulo(task.Titulo);
    setDescricao(task.Descricao);
    setDataPrazo(task.DataPrazo);
    setGrauUrgencia(task.GrauUrgencia);
  };

  const handleSave = async () => {
    if (handleValidation()) {
      const updatedTask: IUpdateTask = {
        titulo: titulo,
        descricao: descricao,
        dataPrazo: dataPrazo,
        grauUrgencia: grauUrgencia,
        taskId: task.Id
      };

      try {
        await updateTask(updatedTask, handleCancelEditing);
      } catch (error) {
        console.log(error);
      }
    }

  };

  const handleTituloChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitulo(event.target.value);
  };

  const handleDescricaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescricao(event.target.value);
  };

  const handleGrauUrgenciaChange = (event: any) => {
    setGrauUrgencia(Number(event.target.value));
  };

  const handleNewComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewComment(event.target.value);
  };

  const formatarData = (data: string) => {
    const dataObj = new Date(data);
    const dia = String(dataObj.getDate()).padStart(2, '0');
    const mes = String(dataObj.getMonth() + 1).padStart(2, '0'); // os meses são indexados a partir do zero
    const ano = dataObj.getFullYear();
    const hora = String(dataObj.getHours() - 3).padStart(2, '0');
    const minuto = String(dataObj.getMinutes()).padStart(2, '0');

    return `${dia}/${mes}/${ano} ${hora}:${minuto}`;
  }

  const writeDate = (date: string) => {
    const dataObj = new Date(date);
    const dia = String(dataObj.getDate()).padStart(2, '0');
    const mes = String(dataObj.getMonth() + 1).padStart(2, '0'); // os meses são indexados a partir do zero
    const ano = dataObj.getFullYear();

    return `${dia}/${mes}/${ano}`;
  }

  const writeGrauUrgencia = (grauUrgencia: number) => {
    switch (grauUrgencia) {
      case 0:
        return 'Baixa';
      case 1:
        return 'Média';
      case 2:
        return 'Alta';
      default:
        return '';
    }
  }

  const [openDialogDeleteTask, setOpenDialogDeleteTask] = useState(false);

  const handleCancelDeleteTask = () => {
    setOpenDialogDeleteTask(false);
  };

  const handleConfirmDeleteTask = () => {
    deleteTask(task.Id);
    setOpenDialogDeleteTask(false);
  };


  const [anchorActions, setAnchorActions] = React.useState<null | HTMLElement>(null);
  const openActions = Boolean(anchorActions);
  const handleOpenActions = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorActions(event.currentTarget);
  };
  const handleCloseActions = () => {
    setAnchorActions(null);
  };

  const writeStatus = () => {
    switch (task.Status) {
      case (1):
        return ' (tarefa concluída)';
      case (2):
        return ' (tarefa cancelada)'
      default:
        return '';
    }
  }

  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});

  const handleValidation = () => {
    const errors: any = {};
    if (!titulo && titulo.length < 6) {
      errors['titulo'] = 'O título é obrigatório e deve conter no mínimo 5 caracteres';
    }
    if (!descricao && descricao.length < 10) {
      errors['descricao'] = 'A descrição é obrigatória e deve conter no mínimo 10 caracteres';
    }
    if (grauUrgencia && (grauUrgencia < 0 || grauUrgencia > 3)) {
      errors['grauUrgencia'] = 'Selecione o grau de prioridade';
    }
    if (!dataPrazo) {
      errors['dataPrazo'] = 'A data é obrigatória';
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle variant='h3' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 1 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AssignmentIcon style={{ marginRight: 5 }} />
            Tarefa &nbsp; <del>{writeStatus()}</del>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleOpenActions}
                color='primary'
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorActions}
                open={openActions}
                onClose={handleCloseActions}
              >
                <MenuItem key={'concluir-tarefa'} onClick={() => {
                  updateStatusTask(task.Id, 1);
                  handleCloseActions();
                }}>
                  <PlaylistAddCheckOutlinedIcon fontSize='small' sx={{ mr: 1 }} />
                  Concluir tarefa
                </MenuItem>
                <MenuItem key={'concluir-tarefa'} onClick={() => {
                  updateStatusTask(task.Id, 2);
                  handleCloseActions();
                }}>
                  <PlaylistRemoveOutlinedIcon fontSize='small' sx={{ mr: 1 }} />
                  Cancelar tarefa
                </MenuItem>
              </Menu>
            </div>
            {
              userOwnerTask && <Tooltip title='Deseja excluir esta tarefa?'>
                <IconButton
                  onClick={() => setOpenDialogDeleteTask(true)}
                >
                  <DeleteForeverIcon color='primary' />
                </IconButton>
              </Tooltip>
            }

          </div>

        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Typography variant="h4" fontSize={17}>Informações:</Typography>
              <List dense>
                <ListItem sx={{ minHeight: 70 }}>
                  <ListItemText
                    sx={{ minWidth: 130 }}
                    primary={<span style={{ fontSize: 17 }}>Título</span>}
                    secondary={!editing ? <span style={{ fontSize: 16 }}>{task.Titulo}</span> : null}
                  />
                  {editing && (
                    <TextField
                      fullWidth
                      size='small'
                      value={titulo}
                      onChange={handleTituloChange}
                      variant="outlined"
                      error={!!validationErrors['titulo']}
                      helperText={validationErrors['titulo']}
                    />
                  )}
                </ListItem>
                <ListItem sx={{ minHeight: 70 }}>
                  <ListItemText
                    sx={{ minWidth: 130 }}
                    primary={<span style={{ fontSize: 17 }}>Descrição</span>}
                    secondary={!editing ? <span style={{ fontSize: 16 }}>{task.Descricao}</span> : null}
                  />
                  {editing && (
                    <TextField
                      fullWidth
                      size='small'
                      value={descricao}
                      onChange={handleDescricaoChange}
                      variant="outlined"
                      error={!!validationErrors['descricao']}
                      helperText={validationErrors['descricao']}
                    />
                  )}
                </ListItem>
                <ListItem sx={{ minHeight: 70 }}>
                  <ListItemText
                    sx={{ minWidth: 130 }}
                    primary={<span style={{ fontSize: 17 }}>Prazo de entrega</span>}
                    secondary={!editing ? <span style={{ fontSize: 16 }}>{writeDate(task.DataPrazo)}</span> : null}
                  />
                  {editing && (
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                      <MobileDatePicker
                        label="Data Prazo"
                        value={dataPrazo}
                        onChange={(newValue: any) => {
                          setDataPrazo(newValue ? newValue : '')
                        }}
                        renderInput={(params: any) => <TextField
                          fullWidth {...params} size="small"
                          error={!!validationErrors['dataPrazo']}
                          helperText={validationErrors['dataPrazo']}
                        />}
                        inputFormat="dd-MM-yyyy"
                      />
                    </LocalizationProvider>
                  )}
                </ListItem>
                <ListItem sx={{ minHeight: 70 }}>
                  <ListItemText
                    sx={{ minWidth: 130 }}
                    primary={<span style={{ fontSize: 17 }}>Grau de Prioridade</span>}
                    secondary={!editing ? <span style={{ fontSize: 16 }}>{writeGrauUrgencia(task.GrauUrgencia)}</span> : null}
                  />
                  {editing && (
                    <><FormControl fullWidth size='small' error={!!validationErrors['grauUrgencia']}>
                      <InputLabel id="grau-urgencia-label">Grau de Prioridade</InputLabel>
                      <Select
                        labelId="grau-urgencia-label"
                        id="grau-urgencia"
                        value={grauUrgencia}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (typeof value === 'number') {
                            handleGrauUrgenciaChange(e)
                          }
                        }}
                        label="Grau de Urgência"
                      >
                        <MenuItem value={0}>Baixa</MenuItem>
                        <MenuItem value={1}>Média</MenuItem>
                        <MenuItem value={2}>Alta</MenuItem>
                      </Select>
                      <FormHelperText>{validationErrors['grauUrgencia']}</FormHelperText>
                    </FormControl></>
                  )}
                </ListItem>
                {!editing && (
                  <ListItem>
                    <ListItemText
                      sx={{ minWidth: 130 }}
                      primary={<span style={{ fontSize: 17 }}>Responsável</span>}
                      secondary={!editing ? <span style={{ fontSize: 16 }}>{task.Atribuido.NomeCompleto}</span> : null}
                    />
                  </ListItem>
                )}
                {!editing && !userOwnerTask && (
                  <ListItem>
                    <ListItemText
                      sx={{ minWidth: 130 }}
                      primary={<span style={{ fontSize: 17 }}>Quem criou a tarefa?</span>}
                      secondary={!editing ? <span style={{ fontSize: 16 }}>{task.Criador.NomeCompleto}</span> : null}
                    />
                  </ListItem>
                )}

              </List>
              <div style={{ textAlign: 'center' }}>
                {
                  !editing && userOwnerTask && <Button
                    onClick={() => {
                      handleEdit();
                    }}
                    size='small'
                    color="secondary"
                    disableElevation
                    startIcon={<EditIcon />}
                  >
                    Editar informações
                  </Button>
                }
                {
                  editing && userOwnerTask && <div style={{ marginTop: 20 }}>
                    <Button
                      onClick={() => {
                        handleCancelEditing();
                      }}
                      size='small'
                      disableElevation
                      sx={{ mr: 4 }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      onClick={() => {
                        handleSave();
                      }}
                      size='small'
                      variant='contained'
                    >
                      Salvar
                    </Button>
                  </div>
                }

              </div>
            </Grid>
            <Divider orientation='vertical' flexItem sx={{ mr: 5 }} />
            <Grid item xs={12} md={6}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h4" fontSize={17}>Comentários:</Typography>
                {
                  !(task.Comentarios.length > 0) && <NoContent message='Ainda não foi adicionado nenhum comentário' />
                }
                {task.Comentarios.length > 0 && (
                  <Box sx={{ flex: 1, overflowY: 'auto', maxHeight: 340, mb: 3 }}>
                    <Timeline >
                      {task.Comentarios.sort((a, b) => b.DataCriacao.localeCompare(a.DataCriacao)).map((comentario, index) => (
                        <TimelineItem key={index} position={comentario.UsuarioId === task.AtribuidoId ? "left" : "right"}>
                          <TimelineOppositeContent
                            sx={{ mt: 1.4 }}
                          >
                            <Typography color="text.secondary" fontSize={12}>{formatarData(comentario.DataCriacao)}</Typography>
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot sx={{ backgroundColor: comentario.UsuarioId === task.AtribuidoId ? '#512178' : '' }}>
                              <Avatar
                                sx={{
                                  width: 20, height: 20,
                                  bgcolor: 'transparent'
                                }}
                              >
                                {comentario.UsuarioNome[0]}
                              </Avatar>
                            </TimelineDot>
                            {task.Comentarios.length !== index + 1 && <TimelineConnector />}
                          </TimelineSeparator>
                          <TimelineContent>
                            {comentario.Texto.length > 100 ?
                              <Tooltip title={<span style={{ fontSize: 14 }}>{comentario.Texto}</span>} arrow>
                                <Typography fontWeight='bold'>
                                  {comentario.Texto.slice(0, 100) + '...'}
                                </Typography>
                              </Tooltip>
                              :
                              <Typography fontWeight='bold'>
                                {comentario.Texto}
                              </Typography>
                            }
                            <Typography variant="caption">{comentario.UsuarioNome}</Typography>
                          </TimelineContent>
                        </TimelineItem>
                      ))}
                    </Timeline>
                  </Box>
                )}
                <Box display='flex' alignItems='flex-end' height={1}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 2, gap: 10, width: '100%' }}>
                    <TextField sx={{ maxWidth: 250 }}
                      fullWidth
                      label="Adicionar Comentário" variant="outlined" size='small'
                      value={newComment}
                      onChange={handleNewComment}
                      multiline
                      rows={2}
                    />
                    <LoadingButton
                      onClick={() => {
                        setLoadingAddNewComment(true);
                        addCommentToTask(task.Id, newComment)
                          .finally(() => setLoadingAddNewComment(false))
                        setNewComment("");
                      }}
                      variant='contained' size='small'
                      color="primary"
                      loading={loadingAddNewComment}
                    >
                      Adicionar Comentário
                    </LoadingButton>
                  </div>
                </Box>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialogDeleteTask} onClose={handleCancelDeleteTask}>
        <DialogTitle variant='h4'>Confirmar exclusão</DialogTitle>
        <DialogContent>
          Tem certeza que deseja excluir a tarefa "{task.Titulo}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDeleteTask}>Cancelar</Button>
          <Button onClick={handleConfirmDeleteTask} autoFocus variant='contained' size='small'>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};