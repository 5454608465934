import { CircularProgress } from "@mui/material";

interface GenericLoadingProps {
  height?: string;
  overlay?: boolean;
  size?: 'small' | 'medium' | 'large';
}

export const GenericLoading: React.FC<GenericLoadingProps> = ({
  height = '100vh',
  overlay = false,
  size = 'medium'
}) => {
  const getSize = () => {
    switch (size) {
      case 'small':
        return 30;
      case 'large':
        return 50;
      default:
        return 40;
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: overlay ? '100%' : height,
        position: overlay ? 'absolute' : 'static',
        top: overlay ? 0 : 'auto',
        left: overlay ? 0 : 'auto',
        width: overlay ? '100%' : 'auto',
        zIndex: overlay ? 5000 : 'auto',
      }}
    >
      <CircularProgress size={getSize()} />
    </div>
  );
};
