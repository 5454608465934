import { useEffect, useMemo, useState } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Box, Divider, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { BsPersonCircle } from 'react-icons/bs';
import { BsCalendarEvent } from 'react-icons/bs'
import { BiCalendar } from 'react-icons/bi'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import { interfaceSchedule } from '../../../../Interface/intarfaceSchedule';
import { useClientContext } from '../ClientContext';
import moment from 'moment';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { handleChangeDescriptionTask, handleStatusTask } from '../handleTasks';
import { formattingDateToISO } from '../usefulFunctions';
import { DialogTarefaConcluidaComponent } from '../Dialogs/TarefaConcluida';
import { DialogTarefaNaoConcluidaComponent } from '../Dialogs/TarefaNaoConcluida';
import { DialogAlterarTarefaComponent } from '../Dialogs/AlterarTarefa';
import { enqueueSnackbar } from 'notistack';
import EditIcon from '@mui/icons-material/Edit';
import StorageHelper from '../../../../Services/Helpers/StorageHelper';
import { IPermissionsUser } from '../../../../Interface/interfacePermissions';
import { usePermissions } from '../../../../Hooks/usePermissionsUser';

export const ScheduledActivities = () => {
  const storageHelper = new StorageHelper();
  const loggedUserId = storageHelper.GetUsuarioId();
  const { permissions } = usePermissions();
  const { activities, setActivities, selectedMonthYear, client } = useClientContext();
  const [filteredActivities, setFilteredActivities] = useState<interfaceSchedule[]>([]);
  const [tempDescriptions, setTempDescriptions] = useState<{ [id: string]: string }>({});
  const [tempTitles, setTempTitles] = useState<{ [id: string]: string }>({});
  const [showEdit, setShowEdit] = useState<{ [id: string]: boolean }>({});
  const [loading, setLoading] = useState(false);
  const [newActivitie, setNewActivitie] = useState<Partial<interfaceSchedule>>({
    DataEvento: '',
    AtividadeFinalizada: false,
    AtividadeFinalizadaData: null as Date | null,
  });

  useEffect(() => {
    const filtered = activities.filter(activity => {
      const activityDate = moment(activity.DataEvento);
      return selectedMonthYear ? activityDate.month() === selectedMonthYear.month() && activityDate.year() === selectedMonthYear.year() : true;
    });

    setFilteredActivities(filtered);
  }, [activities, selectedMonthYear]);

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [openTarefaConcluidaDialog, setOpenTarefaConcluidaDialog] = useState(false)
  const [openTarefaNaoConcluidaDialog, setOpenTarefaNaoConcluidaDialog] = useState(false)
  const [openAlterarTarefa, setOpenAlterarTarefa] = useState(false)

  const [idOfActivitieToChangeStatus, setIdOfActivitieToChangeStatus] = useState('')

  function handleStatus(newStatus: 'OPEN' | 'FINISHED' | 'CANCELED') {
    setOpenBackdrop(true);

    // Encontre a atividade pelo ID
    const activityToUpdate = activities.find(activity => activity.Id === idOfActivitieToChangeStatus);

    if (!activityToUpdate) {
      console.error('Atividade não encontrada');
      setOpenBackdrop(false);
      return;
    }

    handleStatusTask(activityToUpdate, newStatus)
      .then((updatedActivity) => {
        // Atualize o estado com a atividade atualizada
        setActivities(activities.map(activity => activity.Id === idOfActivitieToChangeStatus ? updatedActivity : activity));
      })
      .catch((err) => {
        console.error('Erro ao atualizar atividade', err);
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  }

  const handleDescriptionChange = (activityId: string, newDescription: string) => {
    setTempDescriptions(prev => ({ ...prev, [activityId]: newDescription }));
  };

  const handleTitleChange = (activityId: string, newTitle: string) => {
    setTempTitles(prev => ({ ...prev, [activityId]: newTitle }));
  };

  const handleSaveChanges = async (activityId: string) => {
    setLoading(true);
    const activityIndex = activities.findIndex(activity => activity.Id === activityId);
    if (activityIndex !== -1) {
      const activity = activities[activityIndex];
      const updatedActivity: interfaceSchedule = {
        ...activity,
        Descricao: tempDescriptions[activityId] || activity.Descricao,
        Title: tempTitles[activityId] || activity.Title,
        DataEvento: newActivitie.DataEvento!
      };

      try {
        handleChangeDescriptionTask(updatedActivity)
          .then((result) => {
            const newActivities = activities.map(a => a.Id === activityId ? result : a);
            setActivities(newActivities);
            setShowEdit(prev => ({ ...prev, [activityId]: false }));
          })
          .catch(() => {
            enqueueSnackbar('Ops... tivemos um problema.', { variant: 'error' });
          })
          .finally(() => setLoading(false));
      } catch (error) {
        console.error('Erro ao salvar alterações: ', error);
        setLoading(false);
      }
    }
  };

  const scheduledActivities = filteredActivities.filter((activity: interfaceSchedule) => !activity.AtividadeFinalizadaData)
    .sort((a, b) => {
      const dateA = moment(a.DataEvento);
      const dateB = moment(b.DataEvento);

      return dateA.diff(dateB);
    });

  const completedActivities = filteredActivities.filter((activity: interfaceSchedule) => activity.AtividadeFinalizadaData && activity.StatusTarefa !== 'CANCELED')
    .sort((a, b) => {
      const dateA = moment(a.DataEvento);
      const dateB = moment(b.DataEvento);

      return dateB.diff(dateA);
    });

  const chooseChipColorByArea = (activity: interfaceSchedule) => {
    switch (activity.CriadoPorArea) {
      case 'Seguros':
        return 'info';
      case 'Câmbio':
        return 'success';
      case 'Consórcio':
        return 'secondary';
      default:
        return 'primary'
    }
  }

  const showOptionAporte = useMemo(() => {
    const isInsuranceUser = permissions.some((v: IPermissionsUser) => (v.PermissionName === 'Câmbio' || v.PermissionName === 'AdminCâmbio'));
    const isAdvisorClientOwner = client?.AssessorUsuarioId === loggedUserId;

    return isAdvisorClientOwner || !isInsuranceUser;

  }, [client, permissions, loggedUserId]);

  return <>
    <Card variant='outlined' sx={{ px: '6%', pt: '10px', border: 'none' }}>
      <CardHeader
        avatar={
          <BsCalendarEvent />
        }
        title='Atividades agendadas'
        titleTypographyProps={{ fontSize: 14 }}
      >
        <BsPersonCircle />
      </CardHeader>
      <CardContent sx={{ p: 0 }}>
        <Grid container spacing={3}>
          {
            (scheduledActivities && scheduledActivities.length > 0) ? scheduledActivities.map((activity: interfaceSchedule, index: number) => {
              if (!activity.Id) return <></>;

              /**
               * Formatting date event
               */
              function padTo2Digits(num: number) {
                return num.toString().padStart(2, '0');
              }

              let formatedDate = { date: '-', hour: '-' }

              if (!activity.AtividadeSemData) {
                let date = new Date(activity.DataEvento!);

                // Adiciona 3 horas à data
                date.setHours(date.getHours() + 3);

                let day = padTo2Digits(date.getDate());
                let month = padTo2Digits(date.getMonth() + 1);
                let year = date.getFullYear();

                let hour = padTo2Digits(date.getHours());
                let minutes = padTo2Digits(date.getMinutes());

                formatedDate.date = day + '/' + month + '/' + year;
                formatedDate.hour = hour + ':' + minutes;
              }


              if (activity.AtividadeFinalizada) return <></>;
              if (activity.Title === 'Reagendado') return <></>;

              return (
                <Grid item xs={12} key={index} container justifyContent='center'>
                  <Grid container item spacing={1} alignItems='center' sx={{ maxWidth: 350, px: 1, pb: 2 }}>
                    <Grid item xl={5} xs={12} textAlign='center'>
                      <Tooltip title={activity.Title} placement='top'>
                        <Chip
                          color={chooseChipColorByArea(activity)}
                          size='small' label={activity.Title}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xl={7} xs={12} display='flex' justifyContent='space-evenly'>
                      <Stack direction='row' spacing={0.5} alignItems='center'>
                        <Avatar variant='rounded' sx={{ bgcolor: '#edd8fe', height: 17, width: 17 }}>
                          <BiCalendar size={12} color='#9672b3' />
                        </Avatar>
                        <Typography variant='h6' fontSize={12} color='primary'>
                          {formatedDate.date}
                        </Typography>
                      </Stack>
                      <Stack direction='row' spacing={0.5} alignItems='center'>
                        <Avatar variant='rounded' sx={{ bgcolor: '#edd8fe', height: 17, width: 17 }}>
                          <AccessTimeIcon sx={{ fontSize: 12, color: '#9672b3' }} />
                        </Avatar>
                        <Typography variant='h6' fontSize={12} color='primary'>
                          {
                            activity.AtividadeTodoDia ?
                              'todo dia' : formatedDate.hour
                          }
                        </Typography>
                      </Stack>
                    </Grid>
                    {
                      !showEdit[activity.Id] && <Grid item xs={12}>
                        <Typography variant='body1' fontWeight='normal' color='body' fontSize={15}>
                          <pre style={{ fontFamily: 'inherit', whiteSpace: 'pre-wrap' }}>
                            {activity.Descricao}
                          </pre>
                        </Typography>
                      </Grid>
                    }

                    {
                      showEdit[activity.Id] ? (
                        <Grid container item xs={12} spacing={2} mt={0}>
                          <Grid item xs={6}>
                            <TextField
                              id='datetime-local'
                              type='datetime-local'
                              label='Data e hora'
                              size='small'
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={newActivitie.DataEvento ? formattingDateToISO(new Date(newActivitie.DataEvento)) : null}
                              onChange={(event) => {
                                if (new Date(event.target.value).getTime() < new Date().getTime()) {
                                  newActivitie.AtividadeFinalizada = true
                                  newActivitie.AtividadeFinalizadaData = new Date()
                                } else {
                                  newActivitie.AtividadeFinalizada = false
                                  newActivitie.AtividadeFinalizadaData = null
                                }

                                newActivitie.DataEvento = event.target.value;
                                setNewActivitie({ ...newActivitie });
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth size='small'>
                              <InputLabel id='select-title-label'>Categoria</InputLabel>
                              <Select
                                labelId='select-title-label'
                                label='Categoria'
                                value={tempTitles[activity.Id!] || activity.Title || ''}
                                onChange={(e) => handleTitleChange(activity.Id!, e.target.value as string)}
                              >
                                <MenuItem value='Contato'>Contato</MenuItem>
                                <MenuItem value='Tentativa de Contato'>Tentativa de Contato</MenuItem>
                                <MenuItem value='Marcar reunião'>Marcar reunião</MenuItem>
                                {client?.Status === 'LEAD' && (
                                  <MenuItem value='1° reunião'>1° reunião</MenuItem>
                                )}
                                {client?.Status === 'R1' && (
                                  <MenuItem value='2° reunião'>2° reunião</MenuItem>
                                )}
                                <MenuItem value='Reunião'>Reunião</MenuItem>
                                <MenuItem value='Reunião seguro'>Reunião seguro</MenuItem>
                                <MenuItem value='Reunião câmbio'>Reunião câmbio</MenuItem>
                                <MenuItem value='Tarefa'>Tarefa</MenuItem>
                                {showOptionAporte && <MenuItem value='Aporte'>Aporte</MenuItem>}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              size='small'
                              multiline
                              rows={6}
                              fullWidth
                              inputProps={{
                                maxLength: 500
                              }}
                              label='Descrição da atividade'
                              helperText={`${tempDescriptions[activity.Id].length}/500 caracteres restantes`}
                              InputLabelProps={{ shrink: true }}
                              value={tempDescriptions[activity.Id] || activity.Descricao}
                              onChange={(event) => {
                                const newValue = event.target.value;

                                if (newValue.length <= 500) {
                                  handleDescriptionChange(activity.Id!, newValue)
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Stack spacing={0} justifyContent='center' direction='row'>
                              <Button
                                variant='text'
                                sx={{ width: '100px', mr: 1 }}
                                size='small'
                                onClick={() => setShowEdit(prev => ({ ...prev, [activity.Id!]: false }))}
                              >
                                Cancelar
                              </Button>
                              <LoadingButton
                                loading={loading}
                                sx={{ width: '100px' }}
                                size='small'
                                variant='contained'
                                color='primary'
                                onClick={() => handleSaveChanges(activity.Id!)}
                              >
                                Salvar
                              </LoadingButton>
                            </Stack>
                          </Grid>
                        </Grid>
                      ) : (
                        <></>
                      )
                    }

                    {!showEdit[activity.Id] && <Grid item xs={12}>
                      <Stack direction='row' justifyContent='center' spacing={2}>
                        <IconButton
                          onClick={() => {
                            setOpenTarefaConcluidaDialog(true)
                            setIdOfActivitieToChangeStatus(activity.Id ?? '')
                          }}
                          size='small' sx={{ color: '#a5a4bf', '&:hover': { color: '#34c38f' } }}
                          aria-label='add an alarm'>
                          <Tooltip placement='top' title='Marcar atividade como concluída com sucesso'>
                            <CheckCircleOutlineIcon fontSize='inherit' />
                          </Tooltip>
                        </IconButton>

                        <IconButton onClick={() => {
                          setOpenTarefaNaoConcluidaDialog(true)
                          setIdOfActivitieToChangeStatus(activity.Id ?? '')
                        }} size='small' sx={{ color: '#a5a4bf' }}
                          aria-label='add an alarm'>
                          <Tooltip placement='top' title='Marcar atividade como não concluída'>
                            <CancelIcon fontSize='inherit' />
                          </Tooltip>
                        </IconButton>

                        <Tooltip title={activity.AssessorUsuarioId === loggedUserId ? '' : 'Você não é o criador desta atividade.'} placement='top'>
                          <Box sx={{ display: 'inline-flex' }}>
                            <IconButton
                              disabled={activity.AssessorUsuarioId !== loggedUserId}
                              onClick={() => {
                                setTempDescriptions({ ...tempDescriptions, [activity.Id!]: tempDescriptions[activity.Id!] || activity.Descricao || '' });
                                setTempTitles({ ...tempTitles, [activity.Id!]: tempTitles[activity.Id!] || activity.Title || '' });
                                setNewActivitie({ ...newActivitie, DataEvento: activity.DataEvento })
                                setShowEdit({ ...showEdit, [activity.Id!]: true });
                              }}
                              size='small' sx={{ color: '#a5a4bf' }}
                            >
                              <Tooltip placement='top' title='Editar'>
                                <EditIcon fontSize='inherit' />
                              </Tooltip>
                            </IconButton>
                          </Box>
                        </Tooltip>

                      </Stack>
                    </Grid>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              )
            }) : <Grid item xs={12} textAlign='center'>
              {noAssetsMessage}
            </Grid>
          }
        </Grid>
      </CardContent>
    </Card>

    <Card variant='outlined' sx={{ px: '6%', pt: '10px', border: 'none', boxShadow: '0px 5px 20px 1px rgba(119, 119, 119, 0.05)' }}>
      <CardHeader
        avatar={
          <BsCalendarEvent />
        }
        title='Atividades concluídas'
        titleTypographyProps={{ fontSize: 14 }}
      >
        <BsPersonCircle />
      </CardHeader>
      <CardContent sx={{ p: 0 }}>
        <Grid container spacing={3}>

          {
            (completedActivities && completedActivities.length > 0) ? completedActivities.map((activity: interfaceSchedule, index: number) => {
              /**
               * Formatting date event
               */
              function padTo2Digits(num: number) {
                return num.toString().padStart(2, '0');
              }

              let formatedDate = { date: '-', hour: '-' }

              if (!activity.AtividadeSemData) {
                let date = new Date(activity.DataEvento!)

                date.setHours(date.getHours() + 3);

                let day = padTo2Digits(date.getDate())
                let month = padTo2Digits(date.getMonth() + 1)
                let year = date.getFullYear()

                let hour = padTo2Digits(date.getHours())
                let minutes = padTo2Digits(date.getMinutes())

                formatedDate.date = day + '/' + month + '/' + year
                formatedDate.hour = hour + ':' + minutes
              }

              const validCategories = [
                'Contato',
                'Tentativa de Contato',
                'Marcar reunião',
                '1° reunião',
                '2° reunião',
                'Reunião',
                'Reunião seguro',
                'Reunião câmbio',
                'Tarefa',
                'Aporte'
              ];

              const editing = showEdit[activity.Id!];

              return (
                <Grid item xs={12} key={index} container justifyContent='center'>
                  <Grid container item spacing={1} alignItems='center' sx={{ maxWidth: 350, px: 1, pb: 2 }}>
                    <Grid item xl={5} xs={12} textAlign='center'>
                      <Tooltip title={activity.Title} placement='top'>
                        <Chip color={chooseChipColorByArea(activity)} size='small' label={activity.Title} />
                      </Tooltip>
                    </Grid>
                    <Grid item xl={7} xs={12} display='flex' justifyContent='space-evenly'>
                      <Stack direction='row' spacing={0.5} alignItems='center'>
                        <Avatar variant='rounded' sx={{ bgcolor: '#edd8fe', height: 17, width: 17 }}>
                          <BiCalendar size={12} color='#9672b3' />
                        </Avatar>
                        <Typography variant='h6' fontSize={12} color='primary'>
                          {formatedDate.date}
                        </Typography>
                      </Stack>
                      <Stack direction='row' spacing={0.5} alignItems='center'>
                        <Avatar variant='rounded' sx={{ bgcolor: '#edd8fe', height: 17, width: 17 }}>
                          <AccessTimeIcon sx={{ fontSize: 12, color: '#9672b3' }} />
                        </Avatar>
                        <Typography variant='h6' fontSize={12} color='primary'>
                          {
                            activity.AtividadeTodoDia ?
                              'todo dia' : formatedDate.hour
                          }
                        </Typography>
                      </Stack>
                    </Grid>
                    {editing ? (
                      <Grid container xs={12} spacing={2} mt={1}>
                        {validCategories.includes(activity.Title) && <Grid item xs={12}>
                          <FormControl fullWidth size="small">
                            <InputLabel id='select-title-label-completed'>Categoria</InputLabel>
                            <Select
                              labelId='select-title-label-completed'
                              label='Categoria'
                              value={tempTitles[activity.Id!] || activity.Title}
                              onChange={(e) => handleTitleChange(activity.Id!, e.target.value as string)}
                            >
                              <MenuItem value='Contato'>Contato</MenuItem>
                              <MenuItem value='Tentativa de Contato'>Tentativa de Contato</MenuItem>
                              <MenuItem value='Marcar reunião'>Marcar reunião</MenuItem>
                              {client?.Status === 'LEAD' && (
                                <MenuItem value='1° reunião'>1° reunião</MenuItem>
                              )}
                              {client?.Status === 'R1' && (
                                <MenuItem value='2° reunião'>2° reunião</MenuItem>
                              )}
                              <MenuItem value='Reunião'>Reunião</MenuItem>
                              <MenuItem value='Reunião seguro'>Reunião seguro</MenuItem>
                              <MenuItem value='Reunião câmbio'>Reunião câmbio</MenuItem>
                              <MenuItem value='Tarefa'>Tarefa</MenuItem>
                              {showOptionAporte && <MenuItem value='Aporte'>Aporte</MenuItem>}
                            </Select>
                          </FormControl>
                        </Grid>
                        }
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Descrição"
                            multiline
                            rows={3}
                            value={tempDescriptions[activity.Id!] || activity.Descricao}
                            onChange={(event) => {
                              const newValue = event.target.value;

                              if (newValue.length <= 500) {
                                handleDescriptionChange(activity.Id!, newValue)
                              }
                            }}
                            sx={{ mb: 2 }}
                            inputProps={{
                              maxLength: 500
                            }}
                            helperText={`${tempDescriptions[activity.Id!].length}/500 caracteres restantes`}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Stack direction="row" justifyContent="center" spacing={0}>
                            <Button
                              size="small"
                              variant="text"
                              sx={{ mr: 1 }}
                              onClick={() => setShowEdit(prev => ({ ...prev, [activity.Id!]: false }))}
                            >
                              Cancelar
                            </Button>
                            <LoadingButton
                              size="small"
                              variant="contained"
                              loading={loading}
                              onClick={() => handleSaveChanges(activity.Id!)}
                            >
                              Salvar
                            </LoadingButton>
                          </Stack>
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={12}>
                          <Typography variant='body1' fontWeight='normal' color='body' fontSize={15}>
                            <pre style={{ fontFamily: 'inherit', whiteSpace: 'pre-wrap' }}>
                              {activity.Descricao}
                            </pre>
                          </Typography>
                        </Grid>
                        {activity.AssessorUsuarioId !== loggedUserId && (
                          <Grid item xs={12}>
                            <Typography variant='body2' textAlign='center' fontSize={11} color='textSecondary'>
                              {activity.AssessorInfos?.NomeCompleto}
                            </Typography>
                          </Grid>
                        )}
                        {!showEdit[activity.Id!] && <Grid item xs={12}>
                          <Stack direction='row' justifyContent='center' spacing={2}>
                            <Tooltip title={activity.AssessorUsuarioId === loggedUserId ? '' : 'Você não é o criador desta atividade.'} placement='top'>
                              <Box sx={{ display: 'inline-flex' }}>
                                <IconButton
                                  disabled={activity.AssessorUsuarioId !== loggedUserId}
                                  onClick={() => {
                                    setTempDescriptions({ ...tempDescriptions, [activity.Id!]: tempDescriptions[activity.Id!] || activity.Descricao || '' });
                                    setTempTitles({ ...tempTitles, [activity.Id!]: tempTitles[activity.Id!] || activity.Title || '' });
                                    setNewActivitie({ ...newActivitie, DataEvento: activity.DataEvento })
                                    setShowEdit({ ...showEdit, [activity.Id!]: true });
                                  }}
                                  size='small' sx={{ color: '#a5a4bf' }}
                                >
                                  <Tooltip placement='top' title='Editar'>
                                    <EditIcon fontSize='inherit' />
                                  </Tooltip>
                                </IconButton>
                              </Box>
                            </Tooltip>
                          </Stack>
                        </Grid>}
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              )
            })
              :
              <Grid item xs={12} textAlign='center'>
                {noAssetsMessage}
              </Grid>
          }

        </Grid>
      </CardContent>
    </Card >

    <DialogTarefaConcluidaComponent open={openTarefaConcluidaDialog} setOpen={setOpenTarefaConcluidaDialog} handleStatus={handleStatus} />
    <DialogTarefaNaoConcluidaComponent open={openTarefaNaoConcluidaDialog} setOpen={setOpenTarefaNaoConcluidaDialog} handleStatus={handleStatus} />
    <DialogAlterarTarefaComponent open={openAlterarTarefa} setOpen={setOpenAlterarTarefa} />

    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={openBackdrop}
    >
      <CircularProgress color='inherit' />
    </Backdrop>
  </>
}

export const noAssetsMessage = (
  <Typography sx={{ color: 'text.secondary', textAlign: 'center', opacity: 0.5 }}>
    <SentimentDissatisfiedIcon sx={{ fontSize: 50, mb: 1 }} />
    <br />
    Nenhuma atividade cadastrada.
  </Typography>
);