import React from 'react';
import { useAdvisorCambioContext } from '../../../Hooks/useAdvisorCambioContext';
import { KanbanBoardCambio } from '../KanbanCambio';
import { GenericLoading } from '../../GerenicLoading';

export const AdvisorCambioKanban: React.FC = () => {
  const { loading, filteredKanbanList } = useAdvisorCambioContext();

  if (loading) {
    return <GenericLoading height='50vh' />
  }

  return (
    <KanbanBoardCambio
      isDraggable={false}
      loading={loading}
      filteredKanbanList={filteredKanbanList}
    />
  );
};
