import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import { RiThumbUpLine } from 'react-icons/ri';
import { RiThumbDownLine } from 'react-icons/ri';
import { AiOutlineHourglass } from 'react-icons/ai';
import ReplayIcon from '@mui/icons-material/Replay';
import { useClientContext } from './ClientContext';
import { interfaceClient } from '../../../Interface/interfaceClient';
import { useState } from 'react';
import { Win } from './Dialogs/Win';
import { Loss } from './Dialogs/Loss';

export const ChangeStatus = () => {
  const { client, updateClient } = useClientContext();
  const [openWinDialog, setOpenWinDialog] = useState(false);
  const [openLossDialog, setOpenLossDialog] = useState(false);

  const changeStatus = (newStatus: interfaceClient['Status']) => {
    if (client && client.Id) {
      updateClient({ ...client, Status: newStatus });
    }
  }

  let textOfStatus: string = ""

  switch (client?.Status ?? '') {
    case "CLIENTE":
      textOfStatus = "Cliente"
      break;
    case "PERDEU":
      textOfStatus = "Perdeu"
      break;
    case "PAUSADO":
      textOfStatus = "Pausado"
      break;
    default:
      textOfStatus = "..."
  }

  function writeColorOfBackgroundStatus(status: string) {
    switch (status) {
      case "CLIENTE":
        return "success.main"
      case "PERDEU":
        return "error.main"
      case "PAUSADO":
        return "#A5A4BF"
      default:
        return ""
    }
  }

  const IconForStatus = ({ status }: any) => {
    switch (status) {
      case "CLIENTE":
        return <RiThumbUpLine style={{ fontSize: 17 }} />
      case "PERDEU":
        return <RiThumbDownLine style={{ fontSize: 17 }} />
      case "PAUSADO":
        return <AiOutlineHourglass style={{ fontSize: 17 }} />
      default:
        return <></>
    }
  }

  const ComponentStatus = () => {
    return <>
      <Box>
        <ButtonGroup fullWidth
          variant="text"
          sx={{
            backgroundColor: "#ffffff",
            color: "#000000"
          }}
          size="small"
        >
          <Button
            endIcon={<IconForStatus status={client?.Status} />}
            style={{ borderRight: "2px solid #f0f0f7", color: '#f3f2ff' }}
            size='small'
            sx={{
              fontWeight: 'bold', px: 3,
              backgroundColor: writeColorOfBackgroundStatus(client?.Status ?? '')
            }}
            disabled
          >
            {textOfStatus}
          </Button>
          <Button
            onClick={() => {
              client?.Status == "PERDEU" ? changeStatus("LEAD") : changeStatus("OTHERS_MEETING");
            }}
            endIcon={< ReplayIcon style={{ fontSize: 15 }} />}
            sx={{ fontWeight: "normal", px: 2 }}
            size='small'
          >
            reabrir
          </Button>
        </ButtonGroup>
      </Box>
    </>
  }

  return <>
    {
      client?.Status === 'LEAD' || client?.Status === 'R1' || client?.Status === 'OTHERS_MEETING' || client?.Status === 'WAITING_CLIENT' ?
        <Box>
          <ButtonGroup
            variant="text"
            sx={{
              backgroundColor: "#ffffff",
              color: "#000000",
            }}
          >
            <Button
              onClick={() => {
                setOpenWinDialog(true);
              }}
              sx={{ fontWeight: "normal", color: 'success.main', px: 3 }}
              fullWidth
              size='small'
              startIcon={<RiThumbUpLine fontSize='small' />}
              style={{ borderRight: "2px solid #f0f0f7", }}
            >
              Ganhar
            </Button>
            <Button
              onClick={() => {
                setOpenLossDialog(true);
              }}
              size='small'
              fullWidth
              startIcon={<RiThumbDownLine fontSize='small' />}
              sx={{ fontWeight: "normal", color: 'error.main', px: 3 }}
            >
              Perder
            </Button>
          </ButtonGroup>
        </Box> :
        <>
          {
            <ComponentStatus />
          }
        </>
    }

    <Win open={openWinDialog} setOpen={setOpenWinDialog} />
    <Loss open={openLossDialog} setOpen={setOpenLossDialog} />
  </>
}