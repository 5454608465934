import { Grid } from "@mui/material"
import { ScheduledActivities } from "./ScheduledActivities";
import { ScheduleActivity } from "./ScheduleActivity";

export const ClientActivities = () => {
  return <Grid container item xs={12}>
    <Grid item xs={12} md={6}>
      <ScheduleActivity />
    </Grid>
    <Grid item xs={12} md={6}>
      <ScheduledActivities />
    </Grid>
  </Grid>
}
