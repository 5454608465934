import { DataGrid, GridColDef, ptBR } from '@mui/x-data-grid';
import { interfaceTask } from '../../../Interface/interfaceTask';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

interface Props {
  tasks: interfaceTask[];
  loading: boolean;
  openDialogTask: (taskId: string) => void;
}

const useStyles = makeStyles({
  cursorPointer: {
    cursor: 'pointer',
  },
});

const TaskTable = ({ tasks, loading, openDialogTask }: Props) => {
  const classes = useStyles();

  const columns: GridColDef[] = [
    {
      field: 'Titulo',
      headerName: 'Título',
      minWidth: 300,
      flex: 1,
      headerAlign: "center",
      renderHeader: (value) => {
        return <strong>{value.colDef.headerName}</strong>
      },
    },
    {
      field: 'GrauUrgencia',
      headerName: 'Nível de prioridade',
      headerAlign: "center",
      // width: 400,
      flex: 1,
      align: "center",
      renderHeader: (value) => {
        return <strong>{value.colDef.headerName}</strong>
      },
      valueGetter: (params) => {
        const grauUrgencia = params.row.GrauUrgencia;
        switch (grauUrgencia) {
          case 0:
            return 'Baixa';
          case 1:
            return 'Média';
          case 2:
            return 'Alta';
          default:
            return '';
        }
      },
    },
    {
      field: 'DataPrazo', headerName: 'Data prazo', flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: (value) => {
        return <strong>{value.colDef.headerName}</strong>
      },
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear().toString()}`
      }
    },
    {
      field: 'Atribuido.NomeCompleto',
      headerName: 'Responsável',
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: (value) => {
        return <strong>{value.colDef.headerName}</strong>
      },
      valueGetter: (params) => {
        return params.row.Atribuido?.NomeCompleto || '';
      },
    },
    {
      field: 'Criador.NomeCompleto',
      headerName: 'Criador',
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: (value) => {
        return <strong>{value.colDef.headerName}</strong>
      },
      valueGetter: (params) => {
        return params.row.Criador?.NomeCompleto || '';
      },
    },
    {
      field: 'Status',
      headerName: 'Status',
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: (value) => {
        return <strong>{value.colDef.headerName}</strong>
      },
      valueGetter: (params) => {
        const prazo = new Date(params.row.DataPrazo);
        prazo.setHours(0, 0, 0, 0);
        const hoje = new Date();
        hoje.setHours(0, 0, 0, 0);
        const status = params.row.Status;

        if (status === 0 && prazo.getTime() !== hoje.getTime() && hoje > prazo) {
          return 'Atrasada';
        } else {
          switch (status) {
            case 0:
              return 'Em aberto';
            case 1:
              return 'Concluída';
            case 2:
              return 'Cancelada';
            default:
              return '';
          }
        }
      },
      renderCell: (params) => {
        switch (params.value) {
          case 'Em aberto':
            return <span style={{ color: '#512178' }}>{params.value}</span>
          case 'Concluída':
            return <span style={{ color: 'green' }}>{params.value}</span>
          case 'Atrasada':
            return <span style={{ color: 'red' }}>{params.value}</span>
          case 'Cancelada':
            return <span><del>{params.value}</del></span>
          default:
            return '';
        }
      }
    },
  ];

  return (
    <Box minWidth={1000}>
      <DataGrid
        sx={{
          fontSize: 15,
          border: "none",
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid #d1aeed1f"
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none"
          }
        }}

        onCellClick={(param) => {
          if (param.id) {
            openDialogTask(param.id as string);
          }
        }}

        getRowClassName={(params) => {
          return classes.cursorPointer;
        }}
        loading={loading}
        getRowId={(row) => row.Id}
        rows={tasks}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        autoPageSize={true}
        autoHeight={true}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        disableSelectionOnClick
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </Box>
  );
};

export default TaskTable;
