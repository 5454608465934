import { useUsersApprovalContext } from './context'
import { Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, InputAdornment, IconButton, Button, Tooltip } from '@mui/material';
import FactCheckIcon from '@mui/icons-material/FactCheckOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { IListUsersGroups } from '../../../../Interface/interfaceUsers';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

export const UsersApprovalList = () => {
  const { updateUserStatus, usersGroupsList, loading } = useUsersApprovalContext();
  const [searchText, setSearchText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<keyof IListUsersGroups>('Pendentes');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: keyof IListUsersGroups,
  ) => {
    if (newAlignment) {
      setSelectedStatus(newAlignment);
    };
  };

  const filteredUsers = usersGroupsList ? usersGroupsList[selectedStatus]
    .filter(user => user.NomeCompleto.toLowerCase().includes(searchText.toLowerCase()))
    .sort((a, b) => a.NomeCompleto.localeCompare(b.NomeCompleto)) : [];

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <TextField
          label='Pesquisar'
          size='small'
          value={searchText}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <ToggleButtonGroup
          color='primary'
          size='small'
          value={selectedStatus}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton value='Pendentes'>Pendentes</ToggleButton>
          <ToggleButton value='Bloqueados'>Bloqueados</ToggleButton>
          <ToggleButton value='Ativos'>Ativos</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <TableContainer>
        <Table aria-label='Task Groups Table'>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography fontWeight='bold' textAlign='center'>
                  Nome
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight='bold' textAlign='center'>
                  Email
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight='bold' textAlign='center'>
                  Último Login
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight='bold' textAlign='center'>
                  Data de Inscrição
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight='bold' textAlign='center'>
                  Ações
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                  <Box minHeight={150} display='flex' justifyContent='center' alignItems='center'>
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            ) : filteredUsers && filteredUsers.length > 0 ? (
              filteredUsers.map((user) => (
                <TableRow
                  key={user.Id}
                  hover
                >
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <FactCheckIcon fontSize='small' />
                      <span style={{ marginLeft: '9px', fontSize: '14px', fontWeight: 'bold' }}>
                        {user.NomeCompleto}
                      </span>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ fontSize: 11 }}>{user.Email}</TableCell>
                  <TableCell sx={{ fontSize: 11, textAlign: 'center' }}>{user.UltimoLogin ? moment(user.UltimoLogin).format('DD/MM/YYYY') : '-'}</TableCell>
                  <TableCell sx={{ fontSize: 11, textAlign: 'center' }}>{user.CriadoEm ? moment(user.CriadoEm).format('DD/MM/YYYY') : '-'}</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>
                    {
                      selectedStatus === 'Pendentes' && <>
                        <Tooltip title='Ativar Usuário'>
                          <IconButton onClick={() => updateUserStatus(user.Id, false)}>
                            <CheckIcon fontSize='small' />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Rejeitar Usuário'>
                          <IconButton onClick={() => updateUserStatus(user.Id, true)}>
                            <CloseIcon fontSize='small' />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                    {
                      selectedStatus === 'Bloqueados' && <>
                        <Button onClick={() => updateUserStatus(user.Id, false)}
                          size='small' variant='contained'
                        >
                          Ativar
                        </Button>
                      </>
                    }
                    {
                      selectedStatus === 'Ativos' && <>
                        <Button onClick={() => updateUserStatus(user.Id, true)}
                          size='small' variant='contained' color='error'
                        >
                          Bloquear
                        </Button>
                      </>
                    }
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} style={{ textAlign: 'center', height: 125 }}>
                  Nenhum usuário encontrado.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer >
    </>
  );
}