import { useEffect, useState } from 'react';
import { msalInstance } from '../Config/msalConfig';
import { useMsal } from '@azure/msal-react';
const API_URL = process.env.REACT_APP_API_URL;

export const PowerBITokenProvider = ({ children }: any) => {
  const { accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const [reportId, setReportId] = useState('');
  const [groupId, setGroupId] = useState('');

  function extractPowerBIIds(url: any) {
    const regex = /groups\/([^\/]+)\/reports\/([^\/]+)/;
    const matches = url.match(regex);
    if (matches && matches.length === 3) {
      const groupId = matches[1];
      const reportId = matches[2];
      return { groupId, reportId };
    } else {
      console.log('The URL does not contain the expected Power BI identifiers.');
      return { groupId: null, reportId: null };
    }
  }

  useEffect(() => {
    if (!msalInstance) {
      console.error("A instância MSAL não está inicializada.");
      return;
    }

    msalInstance.initialize()
    // const instance = msalInstance;

    const acquireToken = async () => {
      const ssoData = localStorage.getItem('loginSSO');

      try {
        if (ssoData) {
          const parsedSSOData = JSON.parse(ssoData);

          const expiryTime = new Date(parsedSSOData.expiresOn);
          const currentTime = new Date();
          if (currentTime < expiryTime) {
            setAccessToken(parsedSSOData.accessToken ?? '');
            return;
          }

          // const account = instance.getAllAccounts()[0];

          // if (!account) {
          //   throw new Error("Nenhuma conta está disponível para adquirir o token.");
          // }
          // const response: any = await instance.acquireTokenSilent({
          //   scopes: ["https://analysis.windows.net/powerbi/api/.default"],
          //   account: account
          // });
          // setAccessToken(response.accessToken);
          return;
        }
      } catch (error) {
        console.error("Erro ao adquirir o token:", error);
      }
    };

    const fetchGroupData = async () => {
      const loginObject = JSON.parse(localStorage.getItem('login') || '{}');
      const groupName = loginObject ? loginObject.cargo : null;

      if (groupName) {
        try {
          const response = await fetch(`${API_URL}/api/UserGroup/groupname/${groupName}`);
          if (response.ok) {
            const data = await response.json();
            if (data.length > 1) {
              return;
            }
            const { Url } = data[0];
            const { groupId, reportId } = extractPowerBIIds(Url);
            setGroupId(groupId);
            setReportId(reportId);
          } else {
            console.error('HTTP Error: ' + response.status);
          }
        } catch (error) {
          console.error('Fetching error:', error);
        }
      }
    };

    acquireToken();
    fetchGroupData();
  }, [msalInstance, accounts]);

  return children({ accessToken, reportId, groupId });
};