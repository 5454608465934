import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { createTask } from '../Components/Common/NewProfileClient/crudTask';
import { formattingDateToISO } from '../Components/Common/NewProfileClient/usefulFunctions';
import { interfaceSchedule } from '../Interface/intarfaceSchedule';
import { INegocioCreditoRequest, INegocio, INegocioSeguroRequest, INegocioCambioRequest, INegocioConsorcioRequest } from '../Interface/interfaceNegocios';
import NegociosService from '../Services/NegociosService';
import StorageHelper from '../Services/Helpers/StorageHelper';
import { formatStatusNegocios } from '../Helpers/formatters';

export const useChangeStatusWithReason = () => {
  const [loading, setLoading] = useState(false);
  const negociosService = new NegociosService();
  const storageHelper = new StorageHelper();

  const vendedorId = storageHelper.GetUsuarioId();

  const changeStatusWithReason = async (
    negocio: INegocioCreditoRequest | INegocioSeguroRequest | INegocioCambioRequest | INegocioConsorcioRequest,
    newStatus: INegocio['Status'],
    reason: string,
    kanbanList: any,
    setKanbanList: React.Dispatch<React.SetStateAction<any>>,
    statusMapping: Record<string, string>,
    funnelName: 'Seguros' | 'Câmbio' | 'Consórcio' | 'Crédito'
  ) => {
    if (!negocio || !negocio.Negocio || !negocio.Negocio.Id) return;

    if (newStatus !== 'PERDEU' && newStatus !== 'CANCELADO') {
      enqueueSnackbar('Status inválido. Apenas PERDEU ou CANCELADO são permitidos.', { variant: 'warning' });
      return;
    }

    const oldStatusKey = Object.keys(statusMapping).find(
      key => statusMapping[key] === negocio.Negocio.Status
    );

    if (!oldStatusKey) {
      enqueueSnackbar('Status antigo não encontrado no Kanban.', { variant: 'error' });
      return;
    }

    const prevKanbanList = JSON.parse(JSON.stringify(kanbanList)); // Clona o estado atual

    setLoading(true);

    try {
      // Atualiza o status no backend
      await negociosService.UpdateNegocio({ Id: negocio.Negocio.Id, Status: newStatus });

      const now = new Date();
      const descricao = `Negócio marcado como ${formatStatusNegocios(newStatus)}. Motivo: ${reason}`;

      const newActivity: interfaceSchedule = {
        DataEvento: formattingDateToISO(now),
        AtividadeFinalizada: true,
        Title: `Movimentação em ${funnelName}`,
        Descricao: descricao,
        AssessorUsuarioId: vendedorId,
        ClienteId: negocio.Negocio.ClienteId!,
        Visto: false,
        DataTermino: formattingDateToISO(now),
        StatusTarefa: 'FINISHED',
        AtividadeFinalizadaData: formattingDateToISO(now),
        AtividadeTodoDia: false,
        CriadoPorArea: 'Crédito'
      };

      await createTask(newActivity);

      // Atualiza o estado do Kanban **após** sucesso em todas as requisições
      setKanbanList((prevState: any) => {
        const newStartColumnData = prevState[oldStatusKey].data.filter(
          (n: any) => n.Negocio.Id !== negocio.Negocio.Id
        );

        return {
          ...prevState,
          [oldStatusKey]: {
            ...prevState[oldStatusKey],
            data: newStartColumnData,
          }
        };
      });

      enqueueSnackbar(`Negócio marcado como ${formatStatusNegocios(newStatus)} com sucesso!`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Erro ao atualizar o status do negócio.', { variant: 'error' });
      setKanbanList(prevKanbanList); // Reverte em caso de erro
    } finally {
      setLoading(false);
    }
  };

  return { changeStatusWithReason, loading };
};
