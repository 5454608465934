import StorageHelper from "./StorageHelper";

const storageHelper = new StorageHelper()

export const AUTH_HEADER = () => {
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${storageHelper.GetJWT()}`
    }
}

export const DEFAULT_HEADER = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}