import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import { Box, Button, Divider, Typography } from '@mui/material';
import { CommercialsInfos } from './CommercialsInfos';
import { ObjectiveSteps } from './ObjectiveSteps';
import { ClientProvider } from './ClientContext';
import { MenuViews } from './MenuViews.tsx';
import { MenuYear } from './MenuYear';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { ChangeStatus } from './ChangeStatus';
import { DialogNovaReuniaoComponent } from './Dialogs/DialogNovaReuniaoComponent';
import AddIcon from '@mui/icons-material/Add';
import { DialogNovoNegocioComponent } from './Dialogs/NovoNegocio';
import { NegociosProvider } from '../../../Hooks/useNegocios';

export const NewProfileClient = () => {
  const { userId } = useParams();
  const [openNovaReuniaoDialog, setOpenNovaReuniaoDialog] = useState(false);
  const [openNovoNegocioDialog, setOpenNovoNegocioDialog] = useState(false);

  return (
    <>
      <ClientProvider>
        <Container maxWidth='lg' sx={{ mb: 10 }}>
          <Grid container gap={3}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <Typography variant='h4' >Informações do Cliente</Typography>

                <Box sx={{ display: 'flex', gap: 3 }}>
                  <ChangeStatus />
                  <Button
                    onClick={() => setOpenNovaReuniaoDialog(true)}
                    variant='contained' size='small'
                    disableElevation
                  >
                    + Nova reunião (Formulário)
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid container item xs={12} gap={3}
              sx={{ backgroundColor: 'white', p: 2 }}
            >
              <CommercialsInfos />
              <Grid item xs={1} justifyContent='center' display='flex'>
                <Divider orientation='vertical' />
              </Grid>
              <ObjectiveSteps />
            </Grid>

            <Grid item container xs={12}>
              <MenuYear />
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <Button
                  variant='contained' size='small'
                  startIcon={<AddIcon />}
                  onClick={() => setOpenNovoNegocioDialog(true)}
                >
                  Novo Negócio
                </Button>
              </Box>
            </Grid>

            <NegociosProvider>
              <Grid item container xs={12} gap={3}
                sx={{ backgroundColor: 'white', p: 2 }}
              >
                <MenuViews />
              </Grid>
              <DialogNovoNegocioComponent open={openNovoNegocioDialog} onClose={() => setOpenNovoNegocioDialog(false)} />
            </NegociosProvider>
          </Grid>
        </Container >
      </ClientProvider>

      <DialogNovaReuniaoComponent open={openNovaReuniaoDialog} setOpen={setOpenNovaReuniaoDialog} userId={userId} />
    </>
  )
}