import { Container, Paper } from "@mui/material"
import { UsersApprovalProvider } from "./context"
import { UsersApprovalList } from "./UsersList"

export const UserApproval = () => {

  return <>
    <UsersApprovalProvider>
      <Container maxWidth='md'>
        <Paper elevation={0} sx={{ padding: 3 }}>
          <UsersApprovalList />
        </Paper>
      </Container>
    </UsersApprovalProvider>
  </>
}