import React, { createContext, useContext, useState, useEffect, useCallback, ReactNode } from 'react';
import PermissionsService from '../Services/PermissionsService';
import { IPermissionsUser } from '../Interface/interfacePermissions';
import { enqueueSnackbar } from 'notistack';
import StorageHelper from '../Services/Helpers/StorageHelper';

interface PermissionsContextProps {
  permissions: IPermissionsUser[];
  loading: boolean;
  error: any;
  refetch: () => void;
}

const PermissionsContext = createContext<PermissionsContextProps | undefined>(undefined);

const PermissionsProvider = ({ children }: { children: ReactNode }) => {
  const storageHelper = new StorageHelper();
  const userId = storageHelper.GetUsuarioId();

  const [permissions, setPermissions] = useState<IPermissionsUser[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  const fetchInsuranceUsers = useCallback(async () => {
    if (!userId) return;

    setLoading(true);
    try {
      const service = new PermissionsService();
      const users = await service.ListPermissionsUsers(userId);
      setPermissions(users);
    } catch (err) {
      setError(err);
      enqueueSnackbar('Ops... tivemos um problema ao carregar as permissões de usuário.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    if (permissions.length === 0) {
      fetchInsuranceUsers();
    }
  }, [fetchInsuranceUsers, permissions.length]);

  return (
    <PermissionsContext.Provider value={{ permissions, loading, error, refetch: fetchInsuranceUsers }}>
      {children}
    </PermissionsContext.Provider>
  );
};

const usePermissions = (): PermissionsContextProps => {
  const context = useContext(PermissionsContext);
  if (context === undefined) {
    throw new Error('usePermissions must be used within a PermissionsProvider');
  }
  return context;
};

export { PermissionsProvider, usePermissions };