import { LoginResponse } from "../../Domain/DTO/Response/LoginResponse";

var ls = require('local-storage')

export default class StorageHelper {

    public SalvarLogin(response: LoginResponse) {
        ls.set('login', response)
    }

    public GetJWT() {
        const user = ls.get('login')

        return user?.JWT
    }

    public GetUsuarioRole() {
        const user = ls.get('login')

        return user?.Role
    }

    public GetUsuarioId() {
        const user = ls.get('login')

        return user?.Id
    }

    public GetDataExpiracaoToken() {
        const user = ls.get('login')

        return user?.DataExpiracao
    }

    public Logout() {
        ls.remove('login');
        ls.remove('loginSSO');
    }
}

