import { Box, Container } from "@mui/material";

const SolicitacoesPage = (props: any) => {
  return (
    <>
      <Container maxWidth="xl">
        <Box sx={{
          width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center",
          mt: 5
        }}>
          <iframe src="https://portal.pipefy.com/32a605dd-a56b-4c55-bbed-c240baebd357?embedded=true" title="Pipefy"
            style={{ width: "80%", height: "80vh", border: "none" }}
          ></iframe>
        </Box>
      </Container>
    </>
  )
}

export default SolicitacoesPage;