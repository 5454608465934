const camposValidacao = {
    NomeCompleto: true,
    CPF: false,
    DataNascimento: true,
    Email: false,
    AposentadoriaAnos: false,
    EstadoCivil: true,
    Profissao: true,
    Sexo: false,
    EnderecoCEP: false,
};

export const clienteInfosValidation = (cliente: any) => {
    // const [erros, setErros] = useState<string[]>([])
    let errorsList = <any>[]
    let errosCount = 0

    if (camposValidacao.NomeCompleto && (cliente.NomeCompleto.trim() == '' || cliente.NomeCompleto.length < 3 || cliente.NomeCompleto.length > 150)) {
        errorsList.push('Nome obrigatório.')
        errosCount++
    }

    // Checking date is valid with REGEX
    const checkingCPF = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/
    const CPFValid = checkingCPF.test(cliente.CPF)
    if (camposValidacao.CPF && (cliente.CPF != null && cliente.CPF.trim() != '')) {
        // The CPFValid regex is inside because it is not mandatory
        if (!CPFValid) {
            errorsList.push('CPF inválido.')
            errosCount++
        }
    }
    // Checking date is valid
    if (camposValidacao.DataNascimento && cliente.DataNascimento != null) {
        const today = new Date()
        const clienteBirthDate = new Date(cliente.DataNascimento)
        const [monthToday, dayToday, yearToday] = [today.getMonth() + 1, today.getDate(), today.getFullYear()];
        const [monthCliente, dayCliente, yearCliente] = [clienteBirthDate.getMonth() + 1, clienteBirthDate.getDate() + 1, clienteBirthDate.getFullYear()]

        if (cliente.DataNascimento == null || cliente.DataNascimento == "Invalid Date") {
            errorsList.push('A data de nascimento deve ser informada.')
            errosCount++
        } else if (yearCliente > yearToday) {
            errorsList.push('Data de nascimento inválida. 0')
            errosCount++
        } else if (yearToday == yearCliente && monthCliente > monthToday) {
            errorsList.push('Data de nascimento inválida. 1')
            errosCount++
        } else if (yearToday == yearCliente && monthCliente == monthToday && dayCliente > dayToday) {
            errorsList.push('Data de nascimento inválida. 2')
            errosCount++
        }
    } else if (camposValidacao.DataNascimento) {
        errorsList.push('A data de nascimento deve ser informada.')
        errosCount++
    }


    // Checking email is valid
    const checkingEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailValid = checkingEmail.test(String(cliente.Email).toLowerCase())
    if (camposValidacao.Email && (!emailValid || cliente.Email.length < 3 || cliente.Email.length > 100)) {
        errorsList.push('Email obrigatório.')
        errosCount++
    }

    if (camposValidacao.AposentadoriaAnos && cliente.AposentadoriaAnos != null) {
        if (!isNaN(cliente.AposentadoriaAnos) || cliente.AposentadoriaAnos == 0) {
            if (cliente.AposentadoriaAnos <= 0 || isNaN(cliente.AposentadoriaAnos)) {
                errorsList.push('A idade para aposentadoria deve ser informada.')
                errosCount++
            }
        }
    }

    if (camposValidacao.EstadoCivil && (cliente.EstadoCivil != null && cliente.EstadoCivil != '')) {
        if (cliente.EstadoCivil.trim() == '' || cliente.EstadoCivil.length > 50) {
            errorsList.push('Estado Civil inválido.')
            errosCount++
        }
    }

    if (camposValidacao.Profissao && (cliente.Profissao == null || cliente.Profissao.trim() == '' || cliente.Profissao.length > 60)) {
        errorsList.push('Profissão inválida.')
        errosCount++
    }

    if (camposValidacao.Sexo && !(cliente.Sexo != null && cliente.Sexo != '' && cliente.Sexo != ' ')) {
        errorsList.push('O gênero deve ser informado.')
        errosCount++
    }

    // Checking CEP is valid
    const checkCEP = /[0-9]{5}-[0-9]{3}$/
    const CEPValid = checkCEP.test(cliente.EnderecoCEP)
    if (camposValidacao.EnderecoCEP && (cliente.EnderecoCEP != null && cliente.EnderecoCEP != '')) {
        if (!CEPValid) {
            errorsList.push('CEP inválido.')
            errosCount++
        }
    }

    return {
        isValid: errosCount === 0,
        errorDescriptions: errorsList
    }

}