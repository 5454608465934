import React from "react"
import { SquadsContext } from "../Context/SquadsConatext"
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip';
import EditIcon from '@mui/icons-material/Edit';

export const SquadsComponent = () => {
  // Context
  const context: any = React.useContext(SquadsContext)
  let squads = context.squads
  let assessores = context.assessores
  let squadsWithAssessores = context.squadsWithAssessores
  let setSquadToViewOnDialog = context.setSquadToViewOnDialog
  let setOpenDialogChangeSquad = context.setOpenDialogChangeSquad

  function handleOpenSquad(squad: any) {
    setSquadToViewOnDialog(squad)
    setOpenDialogChangeSquad(true)
  }

  return <>
    <Grid container spacing={2} justifyContent="space-evenly" sx={{ pt: 1 }}>
      {
        squadsWithAssessores.map((value: { Id: string, Nome: string, Assessores: any }, index: number) => {
          return (
            <Grid item key={value.Id}>
              <Chip
                sx={{ fontWeight: "bold" }}
                label={`${value.Nome} (${value.Assessores.length})`}
                // onDelete={handleOpenSquad}
                onClick={() => handleOpenSquad(value)}
                deleteIcon={<EditIcon style={{ fontSize: 18 }} />}
              />
            </Grid>
          )
        })
      }
    </Grid>

  </>
}