import React, { useMemo, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Autocomplete, Box, Button, ButtonGroup, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { SortConfig, useKanbanContext } from '../../../Hooks/useKanban';
import { KanbanColumn } from './KanbanColumn';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Container, Stack } from '@mui/material';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { useUserInfos } from '../../../Hooks/useUserInfos';
import { enqueueSnackbar } from 'notistack';
import { DialogWin } from './DialogWin';
import { DialogAddActivity } from './DialogAddActivity';
import { KanbanSearchInput } from './SearchInput';
import StorageHelper from '../../../Services/Helpers/StorageHelper';
import { useSquadsAndUsers } from '../../../Hooks/useActiveUsersSquad';

export const KanbanBoard: React.FC = () => {
  const [sortCriteria, setSortCriteria] = useState<SortConfig>({ key: 'UltimaAtividade', direction: 'descending' });
  const { userInfos, squadMembers } = useUserInfos();
  const storageHelper = new StorageHelper();

  const {
    kanbanList,
    loading,
    onDragEnd,
    updateSortConfig,
    totalAmount,
    totalClientsCount,
    selectedAdvisorId,
    setSelectedAdvisorId,
    setStatusFiltered,
    statusFiltered
  } = useKanbanContext();
  const { squads, usersWithoutSquad } = useSquadsAndUsers();

  const isAdmin = useMemo(() => {
    return storageHelper.GetUsuarioRole();
  }, []);

  const isSquadAdmin = useMemo(() => {
    setSelectedAdvisorId(userInfos.Id)

    if (userInfos?.SquadAdmin) {
      return true;
    } else {
      return false;
    }
  }, [userInfos])

  const toggleSort = (key: 'Preco' | 'UltimaAtividade' | 'NomeCompleto') => {
    let isAscending: boolean;

    if (sortCriteria.key !== key) {
      isAscending = true;
    } else {
      isAscending = sortCriteria.direction === 'ascending';
    }

    setSortCriteria({
      key,
      direction: isAscending ? 'descending' : 'ascending'
    });
    updateSortConfig({
      key,
      direction: isAscending ? 'descending' : 'ascending'
    });
  };

  const onDragEndModified = (result: DropResult) => {
    if (selectedAdvisorId !== userInfos.Id) {
      enqueueSnackbar('Movimentação bloqueada: você não pode mover itens de outro assessor.', { variant: 'warning' })
      return;
    }

    onDragEnd(result);
  };

  const generateAutocompleteOptions = () => {
    let options: any = [];

    if (isAdmin) {
      squads.forEach((squad) => {
        options = options.concat(squad.Assessores.map((assessor: any) => ({
          label: `${assessor.Usuario.NomeCompleto} ${assessor.SquadAdmin ? '(líder)' : ''}`,
          id: assessor.Usuario.Id,
          subheader: squad.Nome,
        })));
      });

      if (usersWithoutSquad && usersWithoutSquad.length > 0) {
        options = options.concat(usersWithoutSquad.map((user) => ({
          label: user.NomeCompleto,
          id: user.Id,
          subheader: 'Usuários sem Squad',
        })));
      }
    } else {
      options = squadMembers.map((advisor: any) => ({
        label: advisor.Usuario.NomeCompleto,
        id: advisor.Usuario.Id
      }));
    }

    return options;
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
        <Box textAlign="center">
          <CircularProgress size={60} sx={{ color: '#512178' }} />
          <Typography variant="h6" sx={{ mt: 2, color: '#512178' }}>
            Carregando...
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Container maxWidth='xl'>
      <Box my={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">Funil comercial</Typography>
        <Stack justifyContent="center" alignItems="center" direction="row" spacing={6}
          sx={{
            px: 5,
            backgroundColor: "#fff",
            borderRadius: 1,
            height: "80px",
            position: 'relative',
          }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <WorkOutlineIcon sx={{ color: "#bcbbce" }} />
            <Typography variant="h5">{totalClientsCount}</Typography>
          </Stack>
          <Typography variant="h5" color="#5acea4">{totalAmount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Typography>
          {isSquadAdmin && !isAdmin && (
            <FormControl fullWidth size='small'>
              <InputLabel id="select-advisor-label">Selecione um assessor</InputLabel>
              <Select
                sx={{ width: 150 }}
                labelId="select-advisor-label"
                id="select-advisor"
                value={selectedAdvisorId}
                label="Selecione um assessor"
                onChange={(event) => setSelectedAdvisorId(event.target.value)}
              >
                <MenuItem value="">
                  <em>Selecione um assessor</em>
                </MenuItem>
                {squadMembers.map((advisor: any) => (
                  <MenuItem key={advisor.Usuario.Id} value={advisor.Usuario.Id} sx={{ fontWeight: userInfos.Id === advisor.Usuario.Id ? 'bold' : 'normal' }}>
                    {advisor.Usuario.NomeCompleto}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {
            isAdmin && (
              <Autocomplete
                size="small"
                options={generateAutocompleteOptions()}
                groupBy={(option) => option.subheader}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label="Selecione um assessor" variant="outlined" />
                )}
                value={generateAutocompleteOptions().find((option: any) => option.id === selectedAdvisorId) || null}
                noOptionsText='Nenhuma opção disponível'
                onChange={(event, newValue) => {
                  setSelectedAdvisorId(newValue ? newValue.id : '');
                }}
                sx={{ width: 230 }}
              />
            )
          }
        </Stack>
      </Box>
      <Box sx={{
        backgroundColor: "#ffffff",
        borderRadius: 1, width: 'max-content'
      }}>
        <Box
          sx={{
            pl: 3,
            pt: 3,
          }}
        >
          <KanbanSearchInput />
          <ButtonGroup
            variant="contained"
            size='small'
            disableElevation
            sx={{
              ml: 5,
              '& .MuiButton-root': {
                border: '1px solid rgba(0, 0, 0, 0.12)',
              },
              '& .MuiButton-root:not(:last-of-type)': {
                borderRight: 'none',
              },
              borderRadius: '4px',
              overflow: 'hidden',
            }}
          >
            <Button
              onClick={() => toggleSort('UltimaAtividade')}
              variant={sortCriteria.key === 'UltimaAtividade' ? 'contained' : 'outlined'}
              endIcon={sortCriteria.key === 'UltimaAtividade' ? (sortCriteria.direction === 'ascending' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />) : null}
            >
              Último contato
            </Button>
            <Button
              onClick={() => toggleSort('Preco')}
              variant={sortCriteria.key === 'Preco' ? 'contained' : 'outlined'}
              endIcon={sortCriteria.key === 'Preco' ? (sortCriteria.direction === 'ascending' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />) : null}
            >
              PL
            </Button>
            <Button
              onClick={() => toggleSort('NomeCompleto')}
              variant={sortCriteria.key === 'NomeCompleto' ? 'contained' : 'outlined'}
              endIcon={sortCriteria.key === 'NomeCompleto' ? (sortCriteria.direction === 'ascending' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />) : null}
            >
              Ordem alfabética
            </Button>
          </ButtonGroup>
          <ToggleButtonGroup
            size='small'
            color='primary'
            value={statusFiltered}
            disabled={loading}
            exclusive
            onChange={(_, newValue) => newValue ? setStatusFiltered(newValue) : null}
            sx={{ ml: 3 }}
          >
            <ToggleButton value='OWNER' sx={{ height: 30 }}>
              Portfólio próprio
            </ToggleButton>
            <ToggleButton value='MIRROR' sx={{ height: 30 }}>
              Portfólio espelhado
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <DragDropContext onDragEnd={onDragEndModified}>
          <Box display="flex" overflow="auto" p={2}>
            {Object.entries(kanbanList).map(([columnId, column]) => {
              const clients = column.data;
              return (
                <KanbanColumn
                  key={columnId}
                  columnId={columnId}
                  columnName={column.name}
                  clients={clients}
                  totalAmount={column.amountMoney}
                />
              );
            })}
          </Box>
        </DragDropContext>
      </Box>

      <DialogWin />
      <DialogAddActivity />
    </Container>
  );
};