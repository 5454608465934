
// 👇️ format as "YYYY-MM-DD hh:mm:ss"
export function formattingDateToISO(date: Date) {
  // ✅ Format using reusable function
  function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    'T' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      // padTo2Digits(date.getSeconds()),
    ].join(':')
  );
}

export function formattingDateTimeline(date: any) {
  date = new Date(date);

  let monthString = date.toLocaleString('pt-BR', { month: "short" });
  // Remove the . at the end
  monthString = monthString.substring(0, monthString.length - 1);
  // Uppercase the first letter
  monthString = monthString.charAt(0).toUpperCase() + monthString.slice(1);

  let dayString = ("0" + date.getDate()).slice(-2);
  let yearString = date.getFullYear();
  let hourString = ("0" + date.getHours()).slice(-2);
  let minutesString = ("0" + date.getMinutes()).slice(-2);

  // Result
  let newDateString = `${dayString} ${monthString}, ${yearString} ${hourString}:${minutesString}`;

  return newDateString;
}