import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import EventIcon from '@mui/icons-material/Event';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { ButtonBaseProps, Dialog, DialogContent, DialogTitle, FormControl, FormLabel } from '@mui/material';
import { interfaceSchedule } from '../../../Interface/intarfaceSchedule';
import StorageHelper from '../../../Services/Helpers/StorageHelper';
import ClientesService from '../../../Services/ClientesService';
import { interfaceTimeline } from '../../../Interface/interfaceTimeline';
import { useKanbanContext } from '../../../Hooks/useKanban';
import { useEffect, useRef, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { createTask } from '../NewProfileClient/crudTask';
import { formattingDateToISO } from '../NewProfileClient/usefulFunctions';

const ButtonStyled = styled(Button)({
  borderColor: '#0000003b', fontWeight: 'normal',
  '&:hover': {
    // border: '1px solid #0000003b',
  },

  '&:disabled': {
    border: '1px solid #0000003b',
    color: '#512178',
    borderColor: '#0000003b', fontWeight: 'normal',
  }
})

export const DialogAddActivity = () => {
  const { addActivityDialog, setAddActivityDialog, changeClientStatus } = useKanbanContext();

  const clientesService = new ClientesService;

  const storageHelper = new StorageHelper();

  const assessorId = storageHelper.GetUsuarioId();

  const [loading, setLoading] = useState(false);

  const [newActivitie, setNewActivitie] = useState<interfaceSchedule>({
    DataEvento: null, AtividadeFinalizada: false, Title: '', Descricao: '',
    AssessorUsuarioId: assessorId, ClienteId: addActivityDialog.client?.Id ?? '', Visto: false, DataTermino: null,
    StatusTarefa: 'OPEN', AtividadeFinalizadaData: null, AtividadeTodoDia: false
  } as interfaceSchedule)

  const handleAddTitle = (type: string) => {
    newActivitie.Title = type
    setNewActivitie({ ...newActivitie })

    setButtonsToSetDate([...buttonsToSetDate])
  }

  // Menu options contact and meeting
  const [openSelectContato, setOpenSelectContato] = useState(false);
  const anchorRefSelectContato = useRef<HTMLButtonElement>(null);

  const [openSelectReuniao, setOpenSelectReuniao] = useState(false);
  const anchorRefSelectReuniao = useRef<HTMLButtonElement>(null);

  const handleOpenMenu = (menu: string) => {
    if (menu == 'CONTATO') {
      setOpenSelectContato(true)
    } else {
      setOpenSelectReuniao(true)
    }
  };

  const handleCloseMenu = () => {
    setOpenSelectContato(false)
    setOpenSelectReuniao(false)
  };

  interface buttonsToSetDateInterface {
    name: string, isActivated: boolean, action: Function
  }

  const [buttonsToSetDate, setButtonsToSetDate] = useState<buttonsToSetDateInterface[]>([
    {
      name: 'Agora (atividade concluída)',
      isActivated: false,
      action: (index: number) => {
        // Action in date
        let newDate = new Date()

        newActivitie.Title = newActivitie.Title
        newActivitie.DataEvento = formattingDateToISO(newDate)
        newActivitie.AtividadeTodoDia = false
        newActivitie.AtividadeFinalizada = true
        newActivitie.AtividadeFinalizadaData = formattingDateToISO(newDate)
        setNewActivitie({ ...newActivitie })

        // Setting just this button with is activated
        buttonsToSetDate.map((buttonInfos, i) => {
          if (index == i) {
            buttonsToSetDate[i].isActivated = true
          } else {
            buttonsToSetDate[i].isActivated = false
          }
        })
        setButtonsToSetDate([...buttonsToSetDate])
      }
    },
    {
      name: 'Personalizar data',
      isActivated: false,
      action: (index: number) => {
        // Setting the date with active
        newActivitie.DataEvento = formattingDateToISO(new Date())
        newActivitie.AtividadeTodoDia = false

        setNewActivitie({ ...newActivitie })

        // Setting just this button with is activated
        buttonsToSetDate.map((buttonInfos, i) => {
          if (index == i) {
            buttonsToSetDate[i].isActivated = true
          } else {
            buttonsToSetDate[i].isActivated = false
          }
        })
        setButtonsToSetDate([...buttonsToSetDate])
      }
    },
    {
      name: 'Sem data',
      isActivated: false,
      action: (index: number) => {
        // Setting activitie to all day
        newActivitie.AtividadeSemData = true
        newActivitie.DataEvento = null
        newActivitie.AtividadeFinalizada = false
        setNewActivitie({ ...newActivitie })

        // Setting just this button with is activated
        buttonsToSetDate.map((buttonInfos, i) => {
          if (index == i) {
            buttonsToSetDate[i].isActivated = true
          } else {
            buttonsToSetDate[i].isActivated = false
          }
        })
        setButtonsToSetDate([...buttonsToSetDate])
      }
    },
  ])

  interface ButtonToChooseDateInterface extends ButtonBaseProps {
    variantText: 'text' | 'outlined' | 'contained';
  }
  const ButtonToChooseDate: React.FC<ButtonToChooseDateInterface> = ({ children, onClick, variantText }) => {
    return <Button variant={variantText} size='small' disableElevation sx={{ fontWeight: 'normal' }}
      onClick={onClick}
    >
      {children}
    </Button>
  }

  // Option to personalized date
  const [personalizedDate, setPersonalizedDate] = useState(false)

  useEffect(() => {
    if (buttonsToSetDate[1].isActivated) {
      setPersonalizedDate(true)
    } else {
      setPersonalizedDate(false)
    }
  }, [buttonsToSetDate])

  function writeDate(date: any) {
    if (!date) return;

    let dateOfActivitie = new Date(date)

    if (!(dateOfActivitie instanceof Date)) return '...';

    let monthString = dateOfActivitie.toLocaleString('pt-BR', { month: 'short' })
    // Pulling out . in the endc
    monthString = monthString.substring(0, monthString.length - 1);
    // Uppercase the first letter
    monthString = monthString.charAt(0).toUpperCase() + monthString.slice(1)

    let dayString = ('0' + dateOfActivitie.getDate()).slice(-2)
    let hourString = ('0' + dateOfActivitie.getHours()).slice(-2)
    let minutesString = ('0' + dateOfActivitie.getMinutes()).slice(-2)

    // 25/jun | 17:00h - Quarta-feira
    const weekday = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];

    let day = weekday[dateOfActivitie.getDay()];

    let newDateString = ''
    // Result
    if (newActivitie.AtividadeTodoDia) {
      newDateString = dayString + '/' + monthString + ' - ' + day
    } else {
      newDateString = dayString + '/' + monthString + '  |  ' + hourString + ':' + minutesString + ' - ' + day
    }

    return newDateString
  }

  function clearNewActivitie() {
    // Clear last infos
    newActivitie.Title = '';
    newActivitie.Descricao = '';
    newActivitie.DataEvento = null;
    newActivitie.ClienteId = '';

    setNewActivitie({ ...newActivitie });
  }

  function handleCreateActivitie() {
    if (!addActivityDialog.client || !addActivityDialog.client.Id) return;

    setLoading(true);

    createTask({ ...newActivitie, ClienteId: addActivityDialog.client.Id })
      .then(() => {
        // Create timeline
        let newTimeline: interfaceTimeline = {
          AssessorUsuarioId: assessorId,
          Categoria: `Atividade agendada - ${newActivitie.Title}`,
          Comentario: newActivitie.Descricao,
          ClienteId: addActivityDialog.client?.Id,
          DeStatus: '',
          ParaStatus: '',
          CriadoEm: formattingDateToISO(new Date())
        }

        if (!addActivityDialog.client) return;


        if (addActivityDialog.client.Status === 'LEAD' && newActivitie.Title === '1° reunião') {
          changeClientStatus(addActivityDialog.client, 'r1');
        } else if ((addActivityDialog.client.Status === 'LEAD' || addActivityDialog.client.Status === 'R1') && (newActivitie.Title !== 'Contato' && newActivitie.Title !== 'Marcar reunião')) {
          changeClientStatus(addActivityDialog.client, 'othersMeeting');
        }

        clientesService.AddTimeline(newTimeline)
          .finally(() => {
            setAddActivityDialog({ open: false });
            setLoading(false);
            clearNewActivitie();
          })
      })
  }

  if (!addActivityDialog.client) return <></>;

  return (
    <>
      <Dialog
        open={addActivityDialog.open}
        onClose={() => { setAddActivityDialog({ open: false }); clearNewActivitie(); }}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle variant='h4'>
          Agendar reunião
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h5' fontWeight='normal' mb={2} style={{ position: 'relative' }}>
                Agendar uma atividade:
                {!newActivitie.Title ? <></> :
                  <Chip style={{ marginLeft: '12px' }} label={newActivitie.Title} size='small'
                    onDelete={() => {
                      // Clear infos
                      clearNewActivitie()

                      buttonsToSetDate.map((value, index) => {
                        buttonsToSetDate[index].isActivated = false
                      })
                      setButtonsToSetDate([...buttonsToSetDate])
                    }}
                    sx={{ fontWeight: 'normal' }} />
                }
              </Typography>
              <Box textAlign='center' sx={{ mt: 4, mb: 1 }}>
                <ButtonGroup variant='outlined' aria-label='outlined button group'>
                  <ButtonStyled
                    endIcon={<ArrowDropDownIcon />}
                    disableTouchRipple

                    onMouseEnter={() => handleOpenMenu('CONTATO')}
                    onMouseLeave={() => handleCloseMenu()}
                    ref={anchorRefSelectContato}
                  >
                    Contato
                    <Popper
                      open={openSelectContato}
                      anchorEl={anchorRefSelectContato.current}
                      role={undefined}
                      placement='bottom'
                      transition
                      sx={{ zIndex: 10001 }}
                    >
                      {({ TransitionProps }) => (
                        <Grow
                          {...TransitionProps}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={() => { }}>
                              <MenuList>
                                <MenuItem onClick={() => {
                                  handleAddTitle('Contato')
                                  handleCloseMenu()
                                }}>
                                  Contato
                                </MenuItem>
                                <MenuItem onClick={() => {
                                  handleAddTitle('Marcar reunião')
                                  handleCloseMenu()
                                }}>
                                  Marcar reunião
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </ButtonStyled>

                  <ButtonStyled
                    endIcon={<ArrowDropDownIcon />}
                    disableTouchRipple

                    onMouseEnter={() => handleOpenMenu('REUNIAO')}
                    onMouseLeave={() => handleCloseMenu()}
                    ref={anchorRefSelectReuniao}
                  >
                    Reunião
                    <Popper
                      open={openSelectReuniao}
                      anchorEl={anchorRefSelectReuniao.current}
                      role={undefined}
                      placement='bottom'
                      transition
                      sx={{ zIndex: 10001 }}
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}

                        >
                          <Paper>
                            <ClickAwayListener onClickAway={() => { }}>
                              <MenuList>
                                {
                                  addActivityDialog.client?.Status === 'LEAD' && <MenuItem onClick={() => {
                                    handleAddTitle('1° reunião')
                                    handleCloseMenu()
                                  }}>
                                    1° reunião
                                  </MenuItem>
                                }

                                {
                                  addActivityDialog.client?.Status === 'R1' && <MenuItem onClick={() => {
                                    handleAddTitle('2° reunião')
                                    handleCloseMenu()
                                  }}>
                                    2° reunião
                                  </MenuItem>
                                }
                                <MenuItem onClick={() => {
                                  handleAddTitle('Reunião')
                                  handleCloseMenu()
                                }}>
                                  Reunião
                                </MenuItem>
                                <MenuItem onClick={() => {
                                  handleAddTitle('Reunião seguro')
                                  handleCloseMenu()
                                }}>
                                  Reunião seguro
                                </MenuItem>
                                <MenuItem onClick={() => {
                                  handleAddTitle('Reunião câmbio')
                                  handleCloseMenu()
                                }}>
                                  Reunião câmbio
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </ButtonStyled>
                </ButtonGroup>
              </Box>
            </Grid>
            {newActivitie.Title == '' ? <></> :
              <>
                <Grid item xs={12}>
                  <Stack direction='row' spacing={0.5} alignItems='center'>
                    <Typography variant='body1'>
                      Selecione o tempo:
                    </Typography>

                    {
                      newActivitie.DataEvento ? <Chip label={writeDate(newActivitie.DataEvento)} size='small' sx={{ fontWeight: 'normal' }}
                        onDelete={() => {
                          newActivitie.DataEvento = null
                          setNewActivitie({ ...newActivitie })

                          buttonsToSetDate.map((value, index) => {
                            buttonsToSetDate[index].isActivated = false
                          })
                          setButtonsToSetDate([...buttonsToSetDate])
                        }}
                      /> : <></>
                    }
                  </Stack>

                </Grid>

                <Grid item xs={12}
                  sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center' }}
                >
                  {
                    buttonsToSetDate.map(((buttonInfos, index) => {
                      return <ButtonToChooseDate key={index} variantText={buttonsToSetDate[index].isActivated ? 'contained' : 'outlined'} onClick={() => buttonsToSetDate[index].action(index)}>
                        {buttonInfos.name}
                      </ButtonToChooseDate>
                    }))
                  }
                </Grid>

                {
                  personalizedDate ? <Grid item xs={12} textAlign='center'>
                    <Typography variant='body1'>
                      Data e hora:
                    </Typography>
                    <TextField
                      id='datetime-local'
                      type='datetime-local'
                      size='small'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={newActivitie.DataEvento}
                      onChange={(event) => {
                        if (new Date(event.target.value).getTime() < new Date().getTime()) {
                          newActivitie.AtividadeFinalizada = true
                          newActivitie.AtividadeFinalizadaData = new Date()
                        } else {
                          newActivitie.AtividadeFinalizada = false
                          newActivitie.AtividadeFinalizadaData = null
                        }

                        newActivitie.DataEvento = event.target.value
                        setNewActivitie({ ...newActivitie })
                      }}
                    />
                  </Grid> : <></>
                }

                {
                  buttonsToSetDate.filter((obj) => obj.isActivated ? true : false).length > 0 ? <Grid item xs={12}>
                    <FormControl variant='outlined' fullWidth>
                      <FormLabel>Comentário</FormLabel>
                      <TextField
                        multiline
                        rows={2}
                        fullWidth
                        value={newActivitie.Descricao}
                        onChange={(event) => {
                          newActivitie.Descricao = event.target.value
                          setNewActivitie({ ...newActivitie })
                        }}
                      />
                    </FormControl>
                  </Grid> : <></>

                }

                <Grid item xs={12}>
                  <Box textAlign='center' mt={2}>
                    <LoadingButton
                      loading={loading}
                      onClick={() => {
                        handleCreateActivitie()
                      }}
                      disabled={buttonsToSetDate.filter((obj) => obj.isActivated ? true : false).length > 0 ? false : true}
                      disableElevation
                      endIcon={<EventIcon />} variant='contained' color='secondary' size='small'>
                      {buttonsToSetDate[2].isActivated || buttonsToSetDate[0].isActivated ? 'Criar ' : 'Agendar '} atividade
                    </LoadingButton>
                  </Box>
                </Grid>
              </>
            }
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}