import React, { useMemo, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material';
import { KanbanColumn } from './KanbanColumn';
import { Container, Stack } from '@mui/material';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { KanbanSearchInput } from './SearchInput';
import StorageHelper from '../../../Services/Helpers/StorageHelper';
import { useInsuranceUsers } from '../../../Hooks/useInsuranceUsers';
import { useKanbanInsuranceContext } from '../../../Hooks/useKanbanInsurance';
import { usePermissions } from '../../../Hooks/usePermissionsUser';

export const KanbanInsuranceBoard: React.FC = () => {
  const storageHelper = new StorageHelper();

  const { insuranceUsers, loading: loadingUsers } = useInsuranceUsers();
  const {
    kanbanInsuranceList,
    loading,
    onDragEnd,
    setUsuarioId,
    usuarioId,
  } = useKanbanInsuranceContext();
  const { permissions } = usePermissions();

  const isAdmin = useMemo(() => {
    const userRole = storageHelper.GetUsuarioRole();

    return permissions.some(permission => permission.PermissionName === 'AdminSeguros') || userRole === 'Administrador';
  }, [permissions, storageHelper]);

  const onDragEndModified = (result: DropResult) => {
    onDragEnd(result);
  };

  const generateAutocompleteOptions = () => {
    return insuranceUsers.map((user) => ({
      label: user.NomeCompleto,
      id: user.Id,
    }));
  };

  if (loading || loadingUsers) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
        <Box textAlign="center">
          <CircularProgress size={60} sx={{ color: '#512178' }} />
          <Typography variant="h6" sx={{ mt: 2, color: '#512178' }}>
            Carregando...
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Container maxWidth='xl'>
      <Box my={2} display="flex" flexDirection='column'>
        <Typography variant="h3" my={1}>Funil comercial Seguros</Typography>
        <Stack alignItems="center" direction="row" spacing={6} width='max-content'
          sx={{
            px: 5,
            backgroundColor: "#fff",
            borderRadius: 1,
            height: "80px",
            position: 'relative',
          }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <WorkOutlineIcon sx={{ color: "#bcbbce" }} />
            <Typography variant="h5">{Object.values(kanbanInsuranceList).reduce((acc, column) => acc + column.data.length, 0)}</Typography>
          </Stack>
          {isAdmin && (
            <Autocomplete
              size="small"
              options={generateAutocompleteOptions()}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField {...params} label="Selecione um usuário" variant="outlined" />
              )}
              value={generateAutocompleteOptions().find((option: any) => option.id === usuarioId) || null}
              noOptionsText='Nenhuma opção disponível'
              onChange={(event, newValue) => {
                setUsuarioId(newValue ? newValue.id : '');
              }}
              sx={{ width: 230 }}
            />
          )}
        </Stack>
      </Box>
      <Box sx={{
        backgroundColor: "#ffffff",
        borderRadius: 1, width: 'max-content',
      }}>
        <Box
          sx={{
            pl: 3,
            pt: 3,
          }}
        >
          <KanbanSearchInput />
        </Box>
        <DragDropContext onDragEnd={onDragEndModified}>
          <Box display="flex" overflow="auto" p={2}>
            {Object.entries(kanbanInsuranceList).map(([columnId, column]) => {
              const clients = column.data;
              return (
                <KanbanColumn
                  key={columnId}
                  columnId={columnId}
                  columnName={column.name}
                  clients={clients}
                />
              );
            })}
          </Box>
        </DragDropContext>
      </Box>
    </Container>
  );
};