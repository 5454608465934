import { useEffect, useState, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Navigate } from "react-router-dom"
import { ClientesAtivosEBens } from "../../../../Domain/ClientesAtivosEBens";
import { ClientesDependentes } from "../../../../Domain/ClientesDependentes";
import { ClientesDespesas } from "../../../../Domain/ClientesDespesas";
import { ClientesProjetosVida } from "../../../../Domain/ClientesProjetosVida";
import { ClientesIndependencia } from "../../../../Domain/ClientesIndependencia"
import { ClientesReceitas } from "../../../../Domain/ClientesReceitas";
import ClientesService from "../../../../Services/ClientesService";
import AdicionarClienteComponent from "./Step1ClienteInfosComponent";
import AtivosLiquidaveisComponent from "./Step5AtivosFinanceirosComponent";
import BensNaoLiquidaveisComponent from "./Step6AtivosNaoLiquidaveisComponent";
import ProjetosComponent from "./Step2PlanejamentoFinanceiroProjetosComponent";
import ReceitasComponent from "./Step4InfosFinanceirasComponent";
import StorageHelper from "../../../../Services/Helpers/StorageHelper";
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import PersonIcon from '@mui/icons-material/Person';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { clienteInfosValidation } from "./Validations/Step1Validation"
import ClientePlanejamentoFinanceiroValidation from "./Validations/Step2Validation"
import ClienteDependentesValidation from "./Validations/Step3Validation"
import ClienteInformacoesFinanceirasValidation from "./Validations/Step4Validation"
import ClienteAtivosFinanceirosValidation from "./Validations/Step5Validation"
import ClienteAtivosNaoLiquidaveisValidation from "./Validations/Step6Validation"
import ClienteCustosValidation from "./Validations/Step3_1Validation"

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { interfaceClient } from "../../../../Interface/interfaceClient";
import { interfaceRevenue } from "../../../../Interface/interfaceRevenue";


const ControlePassosComponent = () => {
  let navigate = useNavigate();

  const [openSnackbarFailed, setOpenSnackbarFailed] = useState(false)
  const [textSnackbarFailed, setTextSnackbarFailed] = useState("")
  const handleCloseAllBackdrops = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbarFailed(false)
    setTextSnackbarFailed("")
  };

  function openSnackbarFailedFunc(text: string) {
    setTextSnackbarFailed(text)
    setOpenSnackbarFailed(true)
  }


  const clientesService = new ClientesService()
  const { userId } = useParams<any>()
  const [usuarioId, setUsuarioId] = useState<string>('')
  const [idCliente, setIdCliente] = useState<string>('')
  const [erros, setErros] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const [passoAtual, setPassoAtual] = useState(1)
  const [cliente, setCliente] = useState<interfaceClient>({} as interfaceClient)
  const [dependentes, setDependentes] = useState<any>([])
  const [dependentesCustos, setDependentesCustos] = useState<any>([])
  const [projetos, setProjetos] = useState<ClientesProjetosVida[]>([]);
  const [independencia, setIndependencia] = useState<ClientesIndependencia>({ Id: '', ClienteId: '', QntdTempoInvestimento: NaN, QntdTempoVivendoDeRenda: 0 })
  const [receitas, setReceitas] = useState<interfaceRevenue>(
    {
      Id: "", RendaBrutaMensal: null, SaldoAcumuladoFGTS: null, PensaoINSSOutros: null,
      PrevidenciaPrivada: null, OutrasRendasMensais: 0, SeguroDeVida: null, AporteMensal: null,
      AporteMensalRecorrencia: null, PerfilCliente: null, PotencialSeguro: false, AtividadeProfissional: null, Heranca: null,
      Aposentadoria: null, Alugueis: null, Doacao: null, Outras: null, JaInveste: null,
      ValorQueInveste: null, InvesteBB: null, InvesteItau: null, InvesteSantander: null,
      InvesteBradesco: null, InvesteBTG: null, InvesteSafra: null, InvesteSicredi: null,
      InvesteXP: null, InvesteRico: null, InvesteClear: null, InvesteBancoDigital: null,
      InvesteOutros: null, InvesteOutrosDescricao: null, ClienteId: ""
    }
  );
  const [despesas, setDespesas] = useState<ClientesDespesas>({ Id: '', ClienteId: '', DespesaMensal: '', ContribuicaoDespesaPorcentagem: '', ManutencaoPadraoDeVida: NaN, RestabelecimentoVidaMeses: NaN, TipoIRF: "" });
  const [ativos, setAtivos] = useState<ClientesAtivosEBens[]>([{ Id: '', ClienteId: '', Ativo: '', Valor: '', Comentario: '' }])
  const [bens, setBens] = useState<ClientesAtivosEBens[]>([{ Id: '', ClienteId: '', Ativo: '', Valor: '', Comentario: '' }])
  const [initialLaodingEnd, setInitialLoadingEnd] = useState(false)
  const [blockFirstStep, setBlockFirstStep] = useState(true)
  const [readyForGenPDF, setReadyForGenPDF] = useState(false)
  const [gerarPDF, setGerarPDF] = useState('')
  const [independenciaFinanceiraValues, setIndependenciaFinanceiraValues] = useState<any>({
    AtingiuIndependenciaFinanceira: true, AposentadoriaTipo: "VITALICIA", AposentadoriaPrazo: undefined,
    AposentadoriaIdade: undefined, AporteInicial: undefined, CalculoTipo: "OPT1", ValorParaCalculo: undefined, ValorParaCalculo2: undefined,
    RentabilidadeAnual: 13, RentabilidadeAnualIndependencia: 6, InflacaoAnual: 6, ClienteId: "", Id: ""
  })
  const [resultadosIndependencia, setResultadosIndependencia] = useState({
    reservaAcumulada: 0, reservaAcumuladaHoje: 0,
    rendaMensal: 0, rendaMensalHoje: 0, rendaMensalTemp: 0, rendaMensalHojeTemp: 0,
    contribuicaoMensal: 0, rentabilidadeAnual: 0, aporteInicial: 0
  })
  const [showDashFT, setShowDashFT] = useState(false)
  const [openBackdrop, setOpenBackdrop] = useState(false)

  const clienteDependentesValidation = ClienteDependentesValidation
  const clienteInformacoesFinanceirasValidation = ClienteInformacoesFinanceirasValidation
  const clienteAtivosFinanceirosValidation = ClienteAtivosFinanceirosValidation
  const clienteAtivosNaoLiquidaveisValidation = ClienteAtivosNaoLiquidaveisValidation

  const [getInfosClienteFirstTime, setGetInfosClienteFirstTime] = useState(false)
  useEffect(() => {
    if (userId) {
      setUsuarioId(userId)
      setOpenBackdrop(true)

      Promise.all([
        clientesService.BuscarCliente(userId)
          .then((e: any) => {
            return e
          })
          .catch(e => e),

        clientesService.ListaDependentes(userId)
          .then((e: any) => {
            return e
          })
          .catch(e => e),

        clientesService.ListarProjetos(userId)
          .then((e: ClientesProjetosVida[]) => {
            return e
          })
          .catch(e => e),

        clientesService.ListaReceita(userId)
          .then((e: ClientesReceitas) => {
            return e
          })
          .catch(e => e),

        clientesService.GetDespesas(userId)
          .then((e: ClientesDespesas) => {
            return e
          }).catch(e => e),

        clientesService.ListarAtivos(userId)
          .then((e: ClientesAtivosEBens[]) => {
            return e
          }).catch(e => e),

        clientesService.ListarBens(userId)
          .then((e: ClientesAtivosEBens[]) => {
            return e
          }).catch(e => e),

        clientesService.GetIndependenciaFinanceira(userId)
          .then((e: any) => {
            return e
          }).catch(e => e)

      ]).then((values) => {
        // Cliente
        if (values[0].DataNascimento != null) {
          let dataNascimento = new Date(values[0].DataNascimento)
          // dataNascimento.setMonth(dataNascimento.getMonth() + 1)
          // let month = dataNascimento.getMonth().toString()
          // if (!(dataNascimento.getMonth() > 9)) {
          //     month = "0" + month
          // }
          // let day = dataNascimento.getDate().toString()
          // if (!(dataNascimento.getDate() > 9)) {
          //     day = "0" + day
          // }
          // let dataNascimentoText = dataNascimento.getFullYear() + "-" + month + "-" + day
          // values[0].DataNascimento = dataNascimentoText
          values[0].DataNascimento = dataNascimento
        }
        setCliente(values[0])
        setInitialLoadingEnd(true)

        // Projetos
        if (values[2].length > 0) {
          values[2].forEach((value: any, index: any) => {
            // values[2][index].Valor = parseFloat(value.Valor).toFixed(2)
          })
          setProjetos(values[2])
        }

        // Receitas
        if (!(values[3] instanceof Error)) {
          // values[3].RendaBrutaMensal = parseFloat(values[3].RendaBrutaMensal).toFixed(2)
          // values[3].AporteMensal = parseFloat(values[3].AporteMensal).toFixed(2)
          // values[3].SaldoAcumuladoFGTS = parseFloat(values[3].SaldoAcumuladoFGTS).toFixed(2)
          // values[3].PensaoINSSOutros = parseFloat(values[3].PensaoINSSOutros).toFixed(2)
          // values[3].OutrasRendasMensais = parseFloat(values[3].OutrasRendasMensais).toFixed(2)
          setReceitas(values[3])
        }

        if (!(values[4] instanceof Error)) {
          // values[4].DespesaMensal = parseFloat(values[4].DespesaMensal).toFixed(2)
          setDespesas(values[4])
        }

        if (values[5].length > 0) {
          values[5].forEach((value: any, index: any) => {
            // values[5][index].Valor = parseFloat(value.Valor).toFixed(2)
          })
          setAtivos(values[5])
        }

        if (values[6].length > 0) {
          values[6].forEach((value: any, index: any) => {
            // values[6][index].Valor = parseFloat(value.Valor).toFixed(2)
          })
          setBens(values[6])
        }

        if (!(values[7] instanceof Error)) {
          // if (values[7].AporteInicial > 0) values[7].AporteInicial = values[7].AporteInicial.toFixed(2);
          // if (values[7].ValorParaCalculo > 0) values[7].ValorParaCalculo = values[7].ValorParaCalculo.toFixed(2);
          // if (values[7].ValorParaCalculo2 > 0) values[7].ValorParaCalculo2 = values[7].ValorParaCalculo2.toFixed(2);
          setIndependenciaFinanceiraValues(values[7])
        }

        setGetInfosClienteFirstTime(true)
      })
        .catch((e) => {
          // MsetOpenBackdrop(true).toast({ html: 'Algum problema ocorreu!' })
        })
        .finally(() => {
          setOpenBackdrop(false)
        })
    } else {
      setInitialLoadingEnd(true)
    }
  }, [])

  // Setting steps in true of false in the first time
  const [variablesFilledCorrectly, setVariablesFilledCorrectly] = useState(false)

  useEffect(() => {
    if (!getInfosClienteFirstTime) return;

    if (cliente.PDFGerado) {
      // setCompletedStep({ step1: true, step2: true, step3: true, step4: true, step5: true, step6: true, step7: true })
      setCompleted([true, true, true, true, true])
    }

    // Verify Cliente infos
    let clienteInfosIsValid = clienteInfosValidation(cliente)
    if (clienteInfosIsValid.isValid) {
      // setCompletedStep(prevState => ({ ...prevState, step1: true }))
      // setCompleted(prevState => ({ ...prevState, 0: true }))
      completed[0] = true
      setCompleted([...completed])
    }

    // Verify Projetos and Planejamento Financeiro
    let planejamentoFinaceiroIsValid = ClientePlanejamentoFinanceiroValidation(independenciaFinanceiraValues, clienteInfos, variablesFilledCorrectly)
    if (planejamentoFinaceiroIsValid.isValid && projetosIsValid()) {
      // setCompletedStep(prevState => ({ ...prevState, step2: true }))
      // setCompleted(prevState => ({ ...prevState, 1: true }))
      completed[1] = true
      setCompleted([...completed])
    }

    // Verify Ativos Financeiros
    let clienteAtivosFinanceirosIsValid = clienteAtivosFinanceirosValidation(ativos)
    if (clienteAtivosFinanceirosIsValid.isValid) {
      // setCompletedStep(prevState => ({ ...prevState, step5: true }))
      // setCompleted(prevState => ({ ...prevState, 4: true }))
      completed[2] = true
      setCompleted([...completed])
    }

    // Verify Ativos Nao Liquidaveis
    let clienteAtivosNaoLiquidaveisIsValid = clienteAtivosNaoLiquidaveisValidation(bens)
    if (clienteAtivosNaoLiquidaveisIsValid.isValid) {
      // setCompletedStep(prevState => ({ ...prevState, step6: true }))
      // setCompleted(prevState => ({ ...prevState, 5: true }))
      completed[3] = true
      setCompleted([...completed])
    }

    // Verify Receitas and Despesas
    let clientesInformacoesFinanceirasIsValid = clienteInformacoesFinanceirasValidation(receitas, despesas)
    if (clientesInformacoesFinanceirasIsValid.isValid) {
      // setCompletedStep(prevState => ({ ...prevState, step4: true }))
      // setCompleted(prevState => ({ ...prevState, 3: true }))
      completed[4] = true
      setCompleted([...completed])
    }
  }, [getInfosClienteFirstTime])

  useEffect(() => {
    if (cliente.AssessorUsuarioId != '') {
      setBlockFirstStep(false)
    } else {
      setPassoAtual(1)
    }
  }, [cliente.AssessorUsuarioId])

  const projetosIsValid = (): boolean => {
    let errosCount: number = 0
    setErros([]);
    let currencyForAnalysis: number

    projetos.forEach((projeto: any) => {
      if (projeto.Descricao.trim() == '' || projeto.Descricao.length < 3 || projeto.Descricao.length > 150) {
        setErros(oldArray => [...oldArray, 'A descrição está inválida.'])
        errosCount++
      }
      // currencyForAnalysis = parseFloat(projeto.Valor.replace(",", "."))

      if (projeto.Valor <= 0 || isNaN(projeto.Valor)) {
        setErros(oldArray => [...oldArray, 'O valor deve ser informado.'])
        errosCount++
      }
      if (projeto.TempoParaRealizarAnos <= 0 || isNaN(projeto.TempoParaRealizarAnos)) {
        setErros(oldArray => [...oldArray, 'O tempo para realização do projeto deve ser informado.'])
        errosCount++
      }
    })
    return errosCount === 0;
  }

  // Função para verificar a idade do dependente
  function getAge(dateString: Date) {
    var today = new Date();
    var birthDate = new Date(dateString);
    birthDate.setDate(birthDate.getDate() + 1)
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const [clienteInfos, setClienteInfos] = useState({ name: "", age: 0 })
  useEffect(() => {
    clienteInfos.age = getAge(new Date(cliente.DataNascimento!))
    clienteInfos.name = cliente.NomeCompleto!
    setClienteInfos({ ...clienteInfos })
  }, [cliente.DataNascimento, cliente.NomeCompleto])

  /**
   * Start Actions POST and PUT
   */

  const onClickSalvar = () => {
    if (loading) return;

    setLoading(true)

    switch (activeStep) {
      case 0:
        try {
          atualizarCliente()

          completed[activeStep] = true;
          setCompleted([...completed]);

          handleNext();
        } catch (e) {
          setLoading(false)

          completed[activeStep] = true
          setCompleted([...completed])
        }
        break

      case 1:
        if (!showDashFT && independenciaFinanceiraValues.AtingiuIndependenciaFinanceira) {
          // M.toast({ html: 'Por favor, simule o cálculo da Independência Financeira antes de salvar as informações.' })
          // setOpenSnackbarFailed(true)
          openSnackbarFailedFunc("Por favor, simule o cálculo da Independência Financeira antes de salvar as informações.")
          setLoading(false)
          return;
        }

        try {
          atualizarCliente()
          atualizarPlanejamentoFinanceiro()
          atualizarProjetos()

          completed[activeStep] = true;
          setCompleted([...completed]);

          handleNext();
        } catch (e) {
          setLoading(false)
          // setCompletedStep(prevState => ({ ...prevState, step2: false }))

          completed[activeStep] = true;
          setCompleted([...completed]);
        }
        break

      case 2:
        atualizarAtivos()
        break
      case 3:
        atualizarAtivosNaoLiquidaveis()
        break

      case 4:
        atualizarInformacoesFinanceiras()
        break
    }
  }

  const atualizarCliente = () => {
    let clienteInfosIsValid = clienteInfosValidation(cliente)
    if (!clienteInfosIsValid.isValid) {
      setErros(clienteInfosIsValid.errorDescriptions)
      throw ("Error 1")
    }

    if (cliente.Id == '') {
      salvarCliente()
    } else if (cliente.Id != '') {
      updateCliente()
    }
  }

  const updateCliente = () => {
    clientesService.UpdateClientes(cliente)
      .then((e: any) => { })
      .catch(e => {
        
      })
  }

  const salvarCliente = () => {
    var storageHelper = new StorageHelper()
    cliente.AssessorUsuarioId = storageHelper.GetUsuarioId()

    delete cliente.Id
    clientesService.InserirCliente(cliente)
      .then((e: any) => {
        cliente.Id = e.Id
        setUsuarioId(e.Id)
        setIdCliente(e.Id)
        setBlockFirstStep(false)
      })
      .catch(e => {
        
      })

  }

  const atualizarCustos = () => {
    const custosNaoCadastrados = dependentesCustos.filter((d: any) => d.Id == "");
    const custosCadastradosUpdate = dependentesCustos.filter((d: any) => d.Id != "");

    // Validation of Custos
    let clienteCustosIsValid = ClienteCustosValidation(dependentesCustos)
    if (!clienteCustosIsValid.isValid) {
      setErros(clienteCustosIsValid.errorDescriptions)
      throw ("Error dependentes custos.")
    }

    // Save custos
    if (custosNaoCadastrados.length > 0) {
      custosNaoCadastrados.map((custo: any) => {
        if (custo.hasOwnProperty("ClientesDependenteId") && custo.ClientesDependenteId != "") {
          delete custo.Id
          salvarCusto(custo)
        }
      })

    }

    // Update custos (cadastrados)
    if (custosCadastradosUpdate.length > 0) {
      custosCadastradosUpdate.map((custo: any) => {
        updateCusto(custo)
      })
    }
  }

  const salvarCusto = (custo: any) => {
    clientesService.InserirCustoDependente(custo)
      .then((custoReturn: any) => {
        /*
        - Iterating over all costs to compare registered and comparing 
          with the returned cost to insert the specific Id
        - This is necessary because a request is sent for each cost that was entered, 
          so we don't know the order in which the values will be returned.
        */
        dependentesCustos.map((custo: any, indexCusto: number) => {
          let comparisonDescricao = custo.Descricao === custoReturn.Descricao
          let comparisonDuracao = custo.Duracao === custoReturn.Duracao
          let comparisonGastoMensal = custo.GastoMensal === custoReturn.GastoMensal
          let resultComparison = comparisonDescricao && comparisonDuracao && comparisonGastoMensal
          if (resultComparison) {
            dependentesCustos[indexCusto].Id = custoReturn.Id
            setDependentesCustos([...dependentesCustos])
          }
        })
        // Object.assign(dependentesCustos[dependentesCustos.length - 1], { Id: custoReturn.Id })
        // setDependentesCustos([...dependentesCustos])
      })
      .catch(() => {
      })
  }

  const updateCusto = (custo: any) => {
    clientesService.UpdateDependenteCusto(custo)
      .then(() => {
      })
      .catch(() => {
      })
  }

  const atualizarDependentes = () => {
    const dependentesNaoCadastrados = dependentes.filter((d: any) => d.Id == '');
    const dependentesCadastradosUpdate = dependentes.filter((d: any) => d.Id != '');
    // Validation of dependentes
    let clienteDependentesIsValid = clienteDependentesValidation(dependentes)
    if (!clienteDependentesIsValid.isValid) {
      setErros(clienteDependentesIsValid.errorDescriptions)
      throw ("Error 1")
    }

    // Save dependentes
    if (dependentesNaoCadastrados.length > 0) {
      dependentesNaoCadastrados.forEach((value: any) => {
        value.ClienteId = usuarioId
        delete value.Id
      })
      setLoading(true)
      salvarDependentes(dependentesNaoCadastrados)
    }

    // Update dependentes (cadastrados)
    if (dependentesCadastradosUpdate.length > 0) {
      setLoading(true)
      updateDependentes(dependentesCadastradosUpdate)
    }

    // There is not dependent
    if (dependentesNaoCadastrados.length <= 0 && dependentesCadastradosUpdate.length <= 0) {
      // setCompletedStep(prevState => ({
      //     ...prevState, step3: true
      // }))
    }
  }

  const updateDependentes = (listaDependentes: any) => {
    clientesService
      .UpdateDependentesLista(listaDependentes)
      .then(e => { })
      .catch(e => {
        
      })
  }

  const salvarDependentes = (listaDependentes: ClientesDependentes[]) => {
    clientesService.InserirDependentesLista(listaDependentes)
      .then((e) => {
        e.map(dependente => {
          for (let i = 0; i < dependentes.length; i++) {
            if (dependente.NomeCompleto == dependentes[i].NomeCompleto) {
              dependentes[i].Id = dependente.Id
              setDependentes([...dependentes])
            }
          }
        })
      })
      .then(() => {
        atualizarCustos()
      })
      .catch(e => {
        
      })
  }

  // useEffect(() => {
  //     if (withoutIndependencia == true) {
  //         setWithoutIndependencia(false)
  //         atualizarProjetos()
  //         nextStep()
  //     }
  // }, [withoutIndependencia])
  const atualizarProjetos = () => {
    const projetosNaoCadastrados = projetos.filter(d => d.Id == '')
    const projetosUpdate = projetos.filter(d => d.Id != '')

    if (!projetosIsValid()) {
      throw ("Error 3")
    }

    if (projetosNaoCadastrados.length > 0 && projetosIsValid()) {
      projetosNaoCadastrados.forEach((e) => { e.ClienteId = usuarioId; delete e.Id })
      salvarProjetos(projetosNaoCadastrados)
    }

    if (projetosUpdate.length > 0 && projetosIsValid()) {
      UpdateProjetos(projetosUpdate)
    }
  }

  const UpdateProjetos = (projetosFiltrados: ClientesProjetosVida[]) => {
    clientesService.UpdateListaProjeto(projetosFiltrados)
      .then(e => { }).catch(e => e)
  }

  const salvarProjetos = (projetosFiltrados: ClientesProjetosVida[]) => {
    clientesService.InserirListaProjeto(projetosFiltrados)
      .then((e: any) => {
        e.map((projeto: any) => {
          for (let i = 0; i < projetos.length; i++) {
            if (projeto.Descricao == projetos[i].Descricao) {
              projetos[i].Id = projeto.Id
            }
          }
        })
        setProjetos([...projetos])
      })
      .catch(e => {
        
      })
  }

  const atualizarPlanejamentoFinanceiro = () => {
    // Analisando Independencia Financeira
    // if (!independenciaFinanceiraValues.AtingiuIndependenciaFinanceira) return null
    if (independenciaFinanceiraValues.AtingiuIndependenciaFinanceira) {
      let planejamentoFinaceiroIsValid = ClientePlanejamentoFinanceiroValidation(independenciaFinanceiraValues, clienteInfos, variablesFilledCorrectly)
      if (!planejamentoFinaceiroIsValid.isValid) {
        setErros(planejamentoFinaceiroIsValid.errorDescriptions)
        throw ("Error 2")
      }
    } else if (projetos.length > 0) {
      let planejamentoFinaceiroIsValid = ClientePlanejamentoFinanceiroValidation(independenciaFinanceiraValues, clienteInfos, variablesFilledCorrectly)
      if (!planejamentoFinaceiroIsValid.isValid) {
        setErros(planejamentoFinaceiroIsValid.errorDescriptions)
        throw ("Error 2")
      }
    }

    if (independenciaFinanceiraValues.Id == "") {
      salvarIndependenciaFinanceira()
    } else {
      updateIndependenciaFinanceira()
    }
  }

  const salvarIndependenciaFinanceira = () => {
    delete independenciaFinanceiraValues.Id
    independenciaFinanceiraValues.ClienteId = usuarioId
    clientesService.InserirIndependenciaFinanceira(independenciaFinanceiraValues)
      .then(e => {
        independenciaFinanceiraValues.Id = e.Id
        setIndependenciaFinanceiraValues({ ...independenciaFinanceiraValues })
      })
      .catch(e => {
        
      })
  }

  const updateIndependenciaFinanceira = () => {
    clientesService.UpdateIndependenciaFinanceira(independenciaFinanceiraValues)
      .then(e => {
        // setReceitas({ ...receitas, Id: e.Id })
      })
      .catch(e => {
        
      })
  }

  const atualizarInformacoesFinanceiras = () => {
    let clienteAtivosFinanceirosValidationIsValid = clienteInformacoesFinanceirasValidation(receitas, despesas)
    if (!clienteAtivosFinanceirosValidationIsValid.isValid) {
      setLoading(false)
      setErros(clienteAtivosFinanceirosValidationIsValid.errorDescriptions)
      return;
    }

    if (!(receitas.SaldoAcumuladoFGTS! >= 0)) {
      receitas.SaldoAcumuladoFGTS = 0
      setReceitas({ ...receitas })
    }
    if (!(receitas.OutrasRendasMensais! >= 0)) {
      receitas.OutrasRendasMensais = 0
      setReceitas({ ...receitas })
    }

    Promise.all([
      atualizarReceitas(),
      atualizarDespesas()
    ]).then(values => {
      setLoading(false)

      completed[activeStep] = true;
      setCompleted([...completed]);

      handleNext();
    }).catch(error => {
      setLoading(false)
      console.log(error)

      completed[activeStep] = true;
      setCompleted([...completed]);
    })

  }

  const atualizarReceitas = () => {
    if (receitas.Id == '') {
      receitas.ClienteId = usuarioId
      receitas.AporteMensalRecorrencia = Boolean(receitas.AporteMensalRecorrencia)
      salvarReceitas()
    } else if (receitas.Id != '') {
      receitas.ClienteId = usuarioId
      receitas.AporteMensalRecorrencia = Boolean(receitas.AporteMensalRecorrencia)
      updateReceitas();
    } else {
      setLoading(false)
    }
  }

  const updateReceitas = () => {
    clientesService.UpdateReceitas(receitas)
      .then(e => {
        // setReceitas({ ...receitas, Id: e.Id })
        nextStep()
      })
      .catch(e => {
        
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const salvarReceitas = () => {
    delete receitas.Id
    clientesService.InserirReceitas(receitas)
      .then(e => {
        setReceitas({ ...receitas, Id: e.Id })
        nextStep()
      })
      .catch(e => {
        
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const atualizarDespesas = () => {
    if (despesas.Id == '') {
      despesas.ClienteId = usuarioId
      salvarDespesas()
    } else if (despesas.Id != '') {
      updateDespesas()
    } else {
      setLoading(false);
    }
  }

  const updateDespesas = () => {
    clientesService.UpdateDepesas(despesas)
      .then(e => {
        // setDespesas({ ...despesas, Id: e.Id })
        nextStep()
      })
      .catch(e => {
        
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const salvarDespesas = () => {
    delete despesas.Id
    clientesService.InserirDepesas(despesas)
      .then(e => {
        setDespesas({ ...despesas, Id: e.Id })
        nextStep()
      })
      .catch(e => {
        
      })
      .finally(() => {
        setLoading(false)
      })

  }

  const atualizarAtivos = () => {
    let clienteInformacoesFinanceirasIsValid = clienteAtivosFinanceirosValidation(ativos)
    if (!clienteInformacoesFinanceirasIsValid.isValid) {
      setLoading(false)
      setErros(clienteInformacoesFinanceirasIsValid.errorDescriptions)
      return null
    }

    const ativosNovos = ativos.filter(e => e.Id == '')
    const ativosUpdate = ativos.filter(e => e.Id != '')
    // if (!ativosLiquidaveisIsValid()) {
    //     setLoading(false)
    //     return null
    // }

    if (ativosNovos.length > 0) {
      ativosNovos.forEach((e) => { e.ClienteId = usuarioId; delete e.Id })
      salvarAtivos(ativosNovos)

      completed[activeStep] = true;
      setCompleted([...completed]);

      handleNext();
    }

    if (ativosUpdate.length > 0) {
      updateAtivos(ativosUpdate)

      completed[activeStep] = true;
      setCompleted([...completed]);

      handleNext();
    }

    if (ativosNovos.length <= 0 && ativosUpdate.length <= 0) {
      completed[activeStep] = true;
      setCompleted([...completed]);

      handleNext();

      setLoading(false)
      nextStep()
    }

  }

  const updateAtivos = (ativosFiltrados: ClientesAtivosEBens[]) => {
    setLoading(true)
    clientesService.UpdateAtivosLista(ativosFiltrados)
      .then(e => {
        completed[activeStep] = true;
        setCompleted([...completed]);
        nextStep()
      })
      .catch(e => {
        
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const salvarAtivos = (ativosFiltrados: ClientesAtivosEBens[]) => {
    setLoading(true)
    clientesService.InserirAtivosLista(ativosFiltrados)
      .then(e => {
        ativos.forEach(a => {
          const findAtivo = e.find(r => r.Ativo == a.Ativo && r.Valor == a.Valor)
          return a.Id = findAtivo?.Id || ''
        })
        setAtivos([...ativos])
        completed[activeStep] = true;
        setCompleted([...completed]);
        nextStep()
      })
      .catch(e => {
        
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const atualizarAtivosNaoLiquidaveis = () => {
    let clienteInformacoesFinanceirasIsValid = clienteAtivosNaoLiquidaveisValidation(bens)
    if (!clienteInformacoesFinanceirasIsValid.isValid) {
      setLoading(false)
      setErros(clienteInformacoesFinanceirasIsValid.errorDescriptions)
      return null
    }

    const ativosNaoLiquidaveisNovos = bens.filter(e => e.Id == '')
    const ativosNaoLiquidaveisUpdate = bens.filter(e => e.Id != '')

    if (ativosNaoLiquidaveisNovos.length > 0) {
      ativosNaoLiquidaveisNovos.forEach((e) => { e.ClienteId = usuarioId; delete e.Id })
      salvarAtivosNaoLiquidaveis(ativosNaoLiquidaveisNovos)

      completed[activeStep] = true;
      setCompleted([...completed]);

      handleNext();
    }

    if (ativosNaoLiquidaveisUpdate.length > 0) {
      updateAtivosNaoLiquidaveis(ativosNaoLiquidaveisUpdate)

      completed[activeStep] = true;
      setCompleted([...completed]);

      handleNext();
    }

    if (ativosNaoLiquidaveisNovos.length <= 0 && ativosNaoLiquidaveisUpdate.length <= 0) {
      completed[activeStep] = true;
      setCompleted([...completed]);

      handleNext();
      setLoading(false)
      nextStep()
    }
  }

  const updateAtivosNaoLiquidaveis = (ativosFiltrados: ClientesAtivosEBens[]) => {
    setLoading(true)
    clientesService.UpdateAtivosNaoLiquidaveisLista(ativosFiltrados)
      .then(e => {
        completed[activeStep] = true;
        setCompleted([...completed]);
        nextStep()
      })
      .catch(e => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const salvarAtivosNaoLiquidaveis = (bensFiltrados: ClientesAtivosEBens[]) => {
    setLoading(true)
    clientesService.InserirBensLista(bensFiltrados)
      .then(e => {
        bens.forEach(a => {
          const findBem = e.find(r => r.Ativo == a.Ativo && r.Valor == a.Valor)
          return a.Id = findBem?.Id || ''
        })
        setBens([...bens])

        completed[activeStep] = true;
        setCompleted([...completed]);

        nextStep()
      })
      .catch(e => {
        
      })
      .finally(() => {
        setLoading(false)
      })

  }

  const atualizarOutros = () => {
    atualizarReceitas()

    completed[activeStep] = true;
    setCompleted([...completed]);

    handleNext();
  }

  /**
   * End Actions GET and UPDATE
   */






  const onClickAddProjetos = () => {
    setProjetos([...projetos, { Id: '', ClienteId: '', Valor: '', Descricao: '', TempoParaRealizarAnos: 0 }])
  }

  const onClickRemoveProjetos = (index: number) => {
    const projetosFiltrados = [...projetos]
    var projetoId = projetosFiltrados[index].Id

    if (projetoId && projetoId != '')
      clientesService.DeletarProjeto(projetoId)

    projetosFiltrados.splice(index, 1)
    setProjetos(projetosFiltrados)
  }

  const onClickAddAtivosLiquidaveis = () => {
    setAtivos([...ativos, { Id: '', ClienteId: '', Ativo: '', Valor: '', Comentario: '' }])
  }

  const onClickAddBensLiquidaveis = () => {
    setBens([...bens, { Id: '', ClienteId: '', Ativo: '', Valor: '', Comentario: '' }])
  }

  const onClickRemoveAtivo = (index: number) => {
    const ativosFiltrados = [...ativos]

    var ativoId = ativosFiltrados[index].Id

    if (ativoId && ativoId != '')
      clientesService.DeletarAtivo(ativoId)

    ativosFiltrados.splice(index, 1)
    setAtivos(ativosFiltrados)
  }

  const onClickRemoveBem = (index: number) => {
    const bensFiltrados = [...bens]
    var bemId = bensFiltrados[index].Id

    if (bemId && bemId != '')
      clientesService.DeletarAtivoNaoLiquidavel(bemId)

    bensFiltrados.splice(index, 1)
    setBens(bensFiltrados)
  }

  const onClickTrocarPasso = (passo: number) => {
    /**
     * I finish here
     * - Achar uma logica para sempre que ser clicado em uma, analizar se pode ser possivel alterar o passo ou nao
     * _ ANALISES IMPORTANTES
     *      Nao deixar passar do step1 sem preencher pelo menos o nome completo
     */
    // onClickSalvar()

    if (blockFirstStep == true) {
      onClickSalvar()
    } else {
      setErros([])
      setPassoAtual(passo)
    }
  }

  const nextStep = () => {
    setLoading(false)
    window.scroll({ top: 150, behavior: 'smooth' })
    let nextStep = passoAtual + 1

    if (nextStep > 7) {
      nextStep = 1
      setPassoAtual(nextStep)
    } else {
      setPassoAtual(nextStep)
    }
  }

  const handleGerarPDF = (clienteId: any) => {
    if (clienteId != '' || clienteId != undefined) {
      // updateReceitas()
      updateReceitas()
      setGerarPDF(clienteId)
    } else {
      setGerarPDF(idCliente)
    }
  }

  const [abrirPerfil, setAbrirPerfil] = useState('')
  const handleAbrirPerfil = (clienteId: any) => {
    if (clienteId != '' || clienteId != undefined) {
      setAbrirPerfil(clienteId)
    } else {
      setAbrirPerfil(idCliente)
    }
  }

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<boolean[]>([false, false, false, false, false]);

  useEffect(() => {
    // Verificando se já é possível gerar o PDF
    // let existFalse = Object.values(completedSteps).find(step => step == false)
    let existFalse = completed.filter((value) => value == false)
    if (existFalse.length == 0) {
      setReadyForGenPDF(true)
    }
  }, [completed])

  if (abrirPerfil != '') {
    return <Navigate to={`/app/perfil-cliente/${abrirPerfil}`} />
  }

  if (gerarPDF != '') {
    return <Navigate to={`/app/plano-de-voo-resultado/${gerarPDF}`} />
  }

  const steps = [
    'Dados Pessoais',
    'Planejamento Financeiro',
    // 'Planejamento Familiar',
    'Ativos Financeiros',
    'Ativos Imobilizados',
    'Informações Financeiras',
    // 'Outros',
  ];

  const totalSteps = () => {
    return steps.length;
  };

  const stepsCompleted = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return stepsCompleted() === totalSteps();
  };

  const handleNext = () => {
    setLoading(false)
    const newActiveStep =
      isLastStep() ?
        0
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };


  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ backgroundColor: "#ffffff", pt: 3, display: "flex", justifyContent: "center" }}>
          <Box maxWidth="900px">
            <Typography variant="h4" textAlign="center">
              Plano de Voo
            </Typography>
            <Box sx={{ textAlign: "center", mb: 2 }}>
              <Button variant="text" sx={{ fontSize: 10, fontWeight: "normal" }}
                onClick={() => {
                  navigate(`/app/perfil-cliente/${userId}`, { replace: true })
                }}
              >
                Deseja retornar para o perfil? Clique aqui.
              </Button>
            </Box>
            <Box pb={3}>
              <Stepper alternativeLabel nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                  <Step key={label} completed={completed[index]}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                      <Typography variant={"h6"} sx={{ mt: 2, mb: 1 }}>
                        {label}
                      </Typography>
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>
        </Box>
        <Box sx={{ my: 3 }}>
          <Fragment>
            {
              !readyForGenPDF ? "" :
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="h5">Resultado pronto para ser gerado!</Typography>
                  <Button sx={{ my: 3, mr: 3 }} onClick={() => handleGerarPDF(cliente.Id)} variant="contained" endIcon={<PictureAsPdfIcon />}>
                    Gerar PDF
                  </Button>
                  <Button onClick={() => handleAbrirPerfil(cliente.Id)} variant="outlined" endIcon={<PersonIcon />}>
                    Abrir perfil
                  </Button>
                </Box>
            }
            {
              activeStep == 0 ? <AdicionarClienteComponent cliente={cliente} setCliente={setCliente} getInfosClienteFirstTime={getInfosClienteFirstTime} /> : ""
            }
            {
              activeStep == 1 ? <ProjetosComponent variablesFilledCorrectly={variablesFilledCorrectly} setVariablesFilledCorrectly={setVariablesFilledCorrectly} cliente={cliente} setCliente={setCliente} projetos={projetos} setProjetos={setProjetos} addProjeto={onClickAddProjetos}
                removeProjeto={onClickRemoveProjetos} independencia={independencia} setIndependencia={setIndependencia}
                dadosCliente={cliente} independenciaFinanceiraValues={independenciaFinanceiraValues}
                setIndependenciaFinanceiraValues={setIndependenciaFinanceiraValues}
                resultadosIndependencia={resultadosIndependencia} setResultadosIndependencia={setResultadosIndependencia} setShowDashFT={setShowDashFT} /> : ""
            }
            {
              activeStep == 2 ? <AtivosLiquidaveisComponent ativosLiquidaveis={ativos} setAtivosLiquidaveis={setAtivos} addAtivo={onClickAddAtivosLiquidaveis} removerAtivo={onClickRemoveAtivo} /> : ""
            }
            {
              activeStep == 3 ? <BensNaoLiquidaveisComponent bensNaoLiquidaveis={bens} setBensNaoLiquidaveis={setAtivos} addBem={onClickAddBensLiquidaveis} removerBem={onClickRemoveBem} /> : ""
            }
            {
              activeStep == 4 ? <ReceitasComponent receitas={receitas} setReceitas={setReceitas} despesa={despesas} setDespesas={setDespesas} custos={dependentesCustos} /> : ""
            }
            <Box sx={{
              justifyContent: "center", display: "flex", width: "100%"
            }}>
              <List sx={{ backgroundColor: `${erros.length > 0 ? '#ffcdcd' : 'none'}`, p: 2, borderRadius: 1 }}>
                {erros.map((value) => {
                  return (
                    <ListItem sx={{ m: 0, p: "2px" }}>
                      <ListItemIcon sx={{ minWidth: "35px" }} >
                        <ErrorOutlineIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="h6">
                        {value}
                      </Typography>
                    </ListItem>
                  )
                })}
              </List>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: "center", my: 2 }}>
              <Stack direction="row" justifyContent="space-evenly" width={1} maxWidth="900px">
                <Button variant="outlined"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Voltar
                </Button>
                <LoadingButton
                  onClick={() => onClickSalvar()}
                  loading={loading}
                  variant="contained"
                >
                  Salvar e avançar etapa
                </LoadingButton>
              </Stack>
            </Box>
          </Fragment>
        </Box>
      </Box>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar open={openSnackbarFailed} autoHideDuration={6000} onClose={handleCloseAllBackdrops}>
        <Alert onClose={handleCloseAllBackdrops} severity="error" sx={{ fontSize: 14, boxShadow: 3 }}>
          {textSnackbarFailed}
        </Alert>
      </Snackbar>
    </>
  )
}

export default ControlePassosComponent;