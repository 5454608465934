// authConfig.js
import { PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: "b0513c4e-2267-4f91-b945-f8127e271a13", // Substitua pelo seu Application (client) ID
        authority: "https://login.microsoftonline.com/35f1299d-3e6b-4ef4-a48b-c9cd3df7bf80", // Substitua pelo seu Directory (tenant) ID
        redirectUri: `${window.location.origin}/blank.html` // Substitua pela URL de redirecionamento do seu aplicativo
    }
};

export const msalInstance = new PublicClientApplication(msalConfig);
