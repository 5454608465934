import RecuperarSenhaComponent from '../../Common/Login/RecuperarSenhaComponent';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import logo from '../../../Assets/Images/logo.png';
import { Container } from '@mui/material';

const RecuperarSenhaPage = () => {
  return (
    <>
      <Stack sx={{ width: "100vw", height: "100vh" }} direction="row" spacing={0}>
        <Grid container spacing={0}>
          <Grid item xs={12} lg={6}>
            <Container maxWidth="sm" sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <Box sx={{ width: "90%" }}>
                <Box textAlign='center' my={2}>
                  <img src={logo} alt="Logo Marcolab" width={140} />
                </Box>
                <Typography variant="h3" color="primary">
                  Esqueci minha senha
                </Typography>
                <Typography variant="body1" fontSize={15}>
                  Digite seu e-mail e enviaremos um link de redefinição de senha.
                </Typography>
                <RecuperarSenhaComponent />
              </Box>
            </Container>
          </Grid>
          <Grid item lg={6}>
            <Box className="bg-login" sx={{ height: "100%", display: { xs: "none", lg: "block" } }} />
          </Grid>
        </Grid>
      </Stack >
    </>
  )
}

export default RecuperarSenhaPage;