import { useEffect, useState } from "react";
import * as SVGImages from "../../../../Assets/Images/img-svg/images-svg"
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import CalculateIcon from '@mui/icons-material/Calculate';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import NumberFormat from 'react-number-format';
import * as React from 'react';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom = React.forwardRef<NumberFormat<any>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        prefix={'R$'}
        decimalSeparator={','}
        thousandSeparator={'.'}
        fixedDecimalScale={true}
        decimalScale={2}
        name={props.name}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
      />
    );
  },
);



const ProjetosComponent = ({ cliente, setCliente, projetos, setProjetos, addProjeto,
  removeProjeto, independencia, setIndependencia, dadosCliente,
  independenciaFinanceiraValues, setIndependenciaFinanceiraValues,
  resultadosIndependencia, setResultadosIndependencia, setVariablesFilledCorrectly, setShowDashFT }: any
) => {
  const [openSnackbarFailed, setOpenSnackbarFailed] = useState(false)
  const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false)
  const handleCloseAllBackdrops = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setTextToSnackbar("")
    setOpenSnackbarFailed(false)
    setOpenSnackbarSuccess(false)
  };

  const [textToSnackbar, setTextToSnackbar] = useState("")
  function callSnackbarFailed(text: string) {
    setTextToSnackbar(text)
    setOpenSnackbarFailed(true)
  }
  function callSnackbarSuccess(text: string) {
    setTextToSnackbar(text)
    setOpenSnackbarSuccess(true)
  }

  // Função para verificar a idade do dependente
  function getAge(dateString: Date) {
    if (dateString == null) return null;

    var today = new Date();
    var birthDate = new Date(dateString);
    birthDate.setDate(birthDate.getDate() + 1)
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const [perfilCliente, setPerfilCliente] = useState<any>({ MomentoDeVida: "acumulo", nivelDeRisco: 1, nivelDeRiscoText: "Nível de risco baixo" })
  useEffect(() => {
    if (cliente.PerfilInvestidor === "1") {
      perfilCliente.nivelDeRiscoText = "Nível de risco baixo"
    } else if (cliente.PerfilInvestidor === "2") {
      perfilCliente.nivelDeRiscoText = "Nível de risco moderado"
    } else if (cliente.PerfilInvestidor === "3") {
      perfilCliente.nivelDeRiscoText = "Nível de risco alto"
    }
    setPerfilCliente({ ...perfilCliente })
  }, [cliente.PerfilInvestidor, perfilCliente.nivelDeRisco])
  const onChangePerfil = (field: string, value: string) => {
    perfilCliente[field] = value
    setPerfilCliente({ ...perfilCliente })

    cliente.MomentoDeVida = value
    setCliente({ ...cliente })
  }

  function changeSlider(event: any) {
    switch (event.target.value) {
      case (1):
        perfilCliente.nivelDeRisco = "1"
        setPerfilCliente({ ...perfilCliente })

        cliente.PerfilInvestidor = "1"
        setCliente({ ...cliente })
        break;
      case (2):
        perfilCliente.nivelDeRisco = "2"
        setPerfilCliente({ ...perfilCliente })

        cliente.PerfilInvestidor = "2"
        setCliente({ ...cliente })
        break;
      case (3):
        perfilCliente.nivelDeRisco = "3"
        setPerfilCliente({ ...perfilCliente })

        cliente.PerfilInvestidor = "3"
        setCliente({ ...cliente })
        break;
    }
  }

  const SliderFunc = () => {
    return (
      <Box sx={{ width: 300 }}>
        <Slider
          aria-label="Temperature"
          value={Number(cliente.PerfilInvestidor)}
          onChange={changeSlider}
          step={1}
          marks
          min={1}
          max={3}
        />
      </Box>
    );
  }

  const [clienteInfos, setClienteInfos] = useState<any>({ name: "", age: 0 })
  useEffect(() => {
    clienteInfos.age = getAge(dadosCliente.DataNascimento)
    clienteInfos.name = dadosCliente.NomeCompleto
    setClienteInfos({ ...clienteInfos })
  }, [dadosCliente.DataNascimento, dadosCliente.NomeCompleto])

  const onChangeInput = (index: number, field: string, value: any) => {
    projetos[index][field] = value
    setProjetos([...projetos])
  }

  // Ativar ou desativar os inputs para independencia
  const changeThereIsIndependencia = () => {
    independenciaFinanceiraValues.AtingiuIndependenciaFinanceira = !independenciaFinanceiraValues.AtingiuIndependenciaFinanceira
    setIndependenciaFinanceiraValues({ ...independenciaFinanceiraValues })
  }

  // Valores para calculos da independencia financeira
  const [showDashsFirstTime, setShowDashsFirstTime] = useState(false)
  const [allVariablesFilled, setAllVariablesFilled] = useState(false)
  useEffect(() => {
    let var1 = independenciaFinanceiraValues.AposentadoriaIdade >= 0
    let var2 = independenciaFinanceiraValues.AporteInicial >= 0
    let var3 = independenciaFinanceiraValues["ValorParaCalculo"] >= 0
    // let var3 = false
    // switch (independenciaFinanceiraValues.CalculoTipo) {
    //     case "OPT1":
    //         var3 = independenciaFinanceiraValues["valorParaCalculo1"] >= 0
    //         break
    //     case "OPT2":
    //         var3 = independenciaFinanceiraValues["valorParaCalculo2"] >= 0
    //         break
    //     case "OPT3":
    //         var3 = independenciaFinanceiraValues["valorParaCalculo3"] >= 0
    //         break
    // }

    if (var1 && var2 && var3) {
      setAllVariablesFilled(true)
    } else {
      setAllVariablesFilled(false)
    }
  }, [independenciaFinanceiraValues])

  const changeIndependenciaFinanceiraValues = (field: string, value: any) => {

    if (value === "TEMPORARIA") {
      callSnackbarFailed("Esta opção está temporariamente indisponível.")
      return;
    }

    if (value === "OPT41") {
      callSnackbarFailed("Esta opção está temporariamente indisponível.")
      return;
    }

    // if (isNaN(value) && !(typeof (value) === "string" || value == null)) value = undefined
    // if (!value && field === "AposentadoriaIdade") value = undefined
    if (field === "AporteInicial" || field === "ValorParaCalculo") {
      if (isNaN(Number(value.nativeEvent.data))) return;
      if (value.nativeEvent.inputType === "deleteContentBackward" || value.nativeEvent.inputType === "deleteWordBackward") {
        independenciaFinanceiraValues[field] = ""
      } else {
        let newValue = parseFloat((value.target.value.replace("R$", "").replaceAll(".", ""))).toFixed(2)
        independenciaFinanceiraValues[field] = newValue
      }
    } else {
      if (field === "AposentadoriaIdade" && value > 120) {
        value = 120
      }
      if (value === 0) {
        independenciaFinanceiraValues[field] = undefined
      } else {
        independenciaFinanceiraValues[field] = value
      }
    }

    setIndependenciaFinanceiraValues({ ...independenciaFinanceiraValues })
  }

  const callTipoCalculo = () => {
    if (cliente.DataNascimento == null) {
      callSnackbarFailed("Por favor, preencha a data de nascimento na etapa anterior.")
      return;
    }

    if (!allVariablesFilled) {
      callSnackbarFailed("Por favor, preencha todos os campos obrigatórios.")
      return;
    }

    if (clienteInfos.age > independenciaFinanceiraValues.AposentadoriaIdade) {
      callSnackbarFailed("A idade para aposentadoria é menor do que a idade atual.")
      return;
    }

    if (showDashsFirstTime) {
      callSnackbarSuccess("Resultado atualizado.")

    } else {
      callSnackbarSuccess("Resultado gerado.")
    }

    switch (independenciaFinanceiraValues.CalculoTipo) {
      case ("OPT1"):
        calcularMontanteAcumulado()
        break
      case ("OPT2"):
        calcularContribuicaoMensal()
        break
      case ("OPT3"):
        calcularContribuicaoMensal()
        break
      case ("OPT4"):
        calcularRentabilidade()
        break
    }
  }

  const calcularMontanteAcumulado = () => {
    if (!allVariablesFilled) {
      callSnackbarFailed("Por favor, preencha todos os campos obrigatórios.")
      return null
    }

    let aporteInicial = Number(independenciaFinanceiraValues.AporteInicial)
    let rentabilidadeAnual = independenciaFinanceiraValues.RentabilidadeAnual / 100
    let rentabilidadeAnualIndependencia = independenciaFinanceiraValues.RentabilidadeAnualIndependencia / 100
    let rentabilidadeMensal = ((1 + rentabilidadeAnual) ** (1 / 12)) - 1
    let rentabilidadeMensalIndependencia = ((1 + rentabilidadeAnualIndependencia) ** (1 / 12)) - 1
    let tempoInvestindo = (independenciaFinanceiraValues.AposentadoriaIdade - clienteInfos.age) * 12 // 25 => Idade do cliente
    let inflacao = independenciaFinanceiraValues.InflacaoAnual / 100
    // Variavel informada
    let montanteAtual = aporteInicial

    // Fazendo os aportes no montante e aumentando o valor do aporte mensalmente, ajustado pela inflacao
    let aporteMensal = Number(independenciaFinanceiraValues.ValorParaCalculo)
    for (let i = 1; i <= tempoInvestindo; i++) {
      montanteAtual += montanteAtual * rentabilidadeMensal

      // Aporte
      montanteAtual += aporteMensal

      // Aumento do aporte
      if (i % 12 == 0 && i < tempoInvestindo) {
        aporteMensal += aporteMensal * inflacao
      }
    }
    let fatorInflacao = (1 + inflacao) ** ((tempoInvestindo / 12))

    // Valor montate atual
    resultadosIndependencia.reservaAcumulada = montanteAtual
    // Valor montante atual hoje
    let montanteAtualHoje = montanteAtual / fatorInflacao
    resultadosIndependencia.reservaAcumuladaHoje = montanteAtualHoje

    // Calculando rentabilidades
    let rentabilidadeMensalVitalicia = 0

    if (independenciaFinanceiraValues.AposentadoriaTipo === "VITALICIA") {
      rentabilidadeMensalVitalicia = montanteAtual * rentabilidadeMensalIndependencia
    }

    // Setting Renda Mensal
    let rendaMensal = montanteAtual * rentabilidadeMensalIndependencia
    resultadosIndependencia.rendaMensal = rendaMensal
    resultadosIndependencia.rendaMensalHoje = rendaMensal / fatorInflacao

    if (independenciaFinanceiraValues.AposentadoriaTipo === "TEMPORARIA") {
      // Setting Renda Mensal TEMPORARIA
      var montanteAcumulado = resultadosIndependencia.reservaAcumulada
      var prazo = independenciaFinanceiraValues.AposentadoriaPrazo * 12


      let montanteAcumuladoLoop = montanteAcumulado
      var valorParaSacar = montanteAcumulado / prazo

      let loopIndependenciaTemporaria = () => {
        for (let i = 0; i < prazo; i++) {
          montanteAcumuladoLoop += montanteAcumuladoLoop * rentabilidadeMensalIndependencia
          montanteAcumuladoLoop -= valorParaSacar
        }

        if (montanteAcumuladoLoop > 50) {
          if (montanteAcumuladoLoop > 50000) {
            valorParaSacar += valorParaSacar * 0.005
          } else if (montanteAcumuladoLoop > 5000) {
            valorParaSacar += valorParaSacar * 0.0005
          } else {
            valorParaSacar += valorParaSacar * 0.0000005
          }

          // Resetting value
          montanteAcumuladoLoop = montanteAcumulado
          // Call again
          loopIndependenciaTemporaria()
        }

        // return montanteNoMes
      }

      loopIndependenciaTemporaria()

      resultadosIndependencia.rendaMensalTemp = valorParaSacar
      resultadosIndependencia.rendaMensalHojeTemp = valorParaSacar / fatorInflacao
    }

    // Setting Values
    resultadosIndependencia.contribuicaoMensal = Number(independenciaFinanceiraValues.ValorParaCalculo)
    resultadosIndependencia.rentabilidadeAnual = independenciaFinanceiraValues.RentabilidadeAnual
    resultadosIndependencia.aporteInicial = Number(independenciaFinanceiraValues.AporteInicial)

    setVariablesFilledCorrectly(true)

    setResultadosIndependencia({ ...resultadosIndependencia })
    if (!showDashsFirstTime) setShowDashsFirstTime(true)
    setShowDashFT(true)
  }

  const calcularContribuicaoMensal = () => {
    let montanteFinal = 0
    let rentabilidadeAnual = independenciaFinanceiraValues.RentabilidadeAnual / 100
    let rentabilidadeMensal = ((1 + rentabilidadeAnual) ** (1 / 12)) - 1
    let rentabilidadeAnualIndependencia = independenciaFinanceiraValues.RentabilidadeAnualIndependencia / 100
    let rentabilidadeMensalIndependencia = ((1 + rentabilidadeAnualIndependencia) ** (1 / 12)) - 1
    if (independenciaFinanceiraValues.CalculoTipo === "OPT3") {
      montanteFinal = (independenciaFinanceiraValues.ValorParaCalculo) / rentabilidadeMensalIndependencia
    } else {
      montanteFinal = (independenciaFinanceiraValues.ValorParaCalculo)
    }
    let aporteInicial = Number(independenciaFinanceiraValues.AporteInicial)
    let montanteAtual = aporteInicial
    let tempoInvestindo = (independenciaFinanceiraValues.AposentadoriaIdade - clienteInfos.age) * 12 // 25 => Idade do cliente
    let inflacao = independenciaFinanceiraValues.InflacaoAnual / 100
    let aporteMaximoPossivel = montanteFinal / tempoInvestindo
    let aporteAtual = aporteMaximoPossivel
    let fatorInflacao = (1 + inflacao) ** ((tempoInvestindo / 12))

    let loopAporte = () => {
      // Verificando se apenas com a rentabilidade mensal o objetivo já é alcançado
      for (let i = 1; i <= tempoInvestindo; i++) {
        // Rentabilidade
        montanteAtual += montanteAtual * rentabilidadeMensal
      }

      if (montanteAtual >= montanteFinal) {
        let fatorInflacao = (1 + inflacao) ** ((tempoInvestindo / 12))
        resultadosIndependencia.aporteInicial = Number(independenciaFinanceiraValues.AporteInicial)
        resultadosIndependencia.rentabilidadeAnual = independenciaFinanceiraValues.RentabilidadeAnual
        resultadosIndependencia.contribuicaoMensal = 0
        // Calculando rentabilidade mensal
        let rendaMensal = montanteAtual * rentabilidadeMensalIndependencia
        resultadosIndependencia.rendaMensal = rendaMensal
        resultadosIndependencia.rendaMensalHoje = rendaMensal / fatorInflacao
        // Reserva
        resultadosIndependencia.reservaAcumulada = montanteAtual
        resultadosIndependencia.reservaAcumuladaHoje = montanteAtual / fatorInflacao
        setVariablesFilledCorrectly(false)
        return null
      }

      setVariablesFilledCorrectly(true)

      montanteAtual = aporteInicial

      for (let i = 1; i <= tempoInvestindo; i++) {
        // Rentabilidade
        montanteAtual += montanteAtual * rentabilidadeMensal
        // Aporte Mensal
        montanteAtual += aporteAtual
        // Aumento do aporte
        if (i % 12 == 0 && i < tempoInvestindo) {
          aporteAtual += aporteAtual * inflacao
        }
      }

      if ((montanteAtual - montanteFinal) > 100) {
        if (montanteAtual / montanteFinal > 1.5) {
          aporteMaximoPossivel -= aporteMaximoPossivel * 0.5
        } else if (montanteAtual / montanteFinal > 1.2) {
          aporteMaximoPossivel -= aporteMaximoPossivel * 0.05
        } else if (montanteAtual / montanteFinal > 1.05) {
          aporteMaximoPossivel -= aporteMaximoPossivel * 0.005
        } else {
          aporteMaximoPossivel -= aporteMaximoPossivel * 0.0005
        }

        // Resetando valores e atribuindo novo aporte
        aporteAtual = aporteMaximoPossivel
        montanteAtual = aporteInicial

        loopAporte()
      } else {
        resultadosIndependencia.aporteInicial = Number(independenciaFinanceiraValues.AporteInicial)
        resultadosIndependencia.rentabilidadeAnual = independenciaFinanceiraValues.RentabilidadeAnual
        resultadosIndependencia.contribuicaoMensal = aporteAtual / fatorInflacao

        // Reserva OPT 2
        if (independenciaFinanceiraValues.CalculoTipo === "OPT2" &&
          montanteAtual < (Number(independenciaFinanceiraValues.ValorParaCalculo) * 1.1)) {
          montanteAtual = Number(independenciaFinanceiraValues.ValorParaCalculo)
        }
        resultadosIndependencia.reservaAcumulada = montanteAtual
        resultadosIndependencia.reservaAcumuladaHoje = montanteAtual / fatorInflacao

        // Renda OPT 3
        let rendaMensal = montanteAtual * rentabilidadeMensalIndependencia
        if (independenciaFinanceiraValues.CalculoTipo === "OPT3" &&
          rendaMensal < (Number(independenciaFinanceiraValues.ValorParaCalculo) * 1.1)) {
          rendaMensal = Number(independenciaFinanceiraValues.ValorParaCalculo)
        }
        resultadosIndependencia.rendaMensal = rendaMensal
        resultadosIndependencia.rendaMensalHoje = rendaMensal / fatorInflacao
      }
    }

    loopAporte()

    if (independenciaFinanceiraValues.AposentadoriaTipo === "TEMPORARIA") {
      // Setting Renda Mensal TEMPORARIA
      var montanteAcumulado = resultadosIndependencia.reservaAcumulada
      var prazo = independenciaFinanceiraValues.AposentadoriaPrazo * 12


      let montanteAcumuladoLoop = montanteAcumulado
      var valorParaSacar = montanteAcumulado / prazo

      let loopIndependenciaTemporaria = () => {
        for (let i = 0; i < prazo; i++) {
          montanteAcumuladoLoop += montanteAcumuladoLoop * rentabilidadeMensalIndependencia
          montanteAcumuladoLoop -= valorParaSacar
        }

        if (montanteAcumuladoLoop > 50) {
          if (montanteAcumuladoLoop > 50000) {
            valorParaSacar += valorParaSacar * 0.005
          } else if (montanteAcumuladoLoop > 5000) {
            valorParaSacar += valorParaSacar * 0.0005
          } else {
            valorParaSacar += valorParaSacar * 0.0000005
          }

          // Resetting value
          montanteAcumuladoLoop = montanteAcumulado
          // Call again
          loopIndependenciaTemporaria()
        }

        // return montanteNoMes
      }

      loopIndependenciaTemporaria()

      resultadosIndependencia.rendaMensalTemp = valorParaSacar
      resultadosIndependencia.rendaMensalHojeTemp = valorParaSacar / fatorInflacao
    }

    // Setting Values
    setResultadosIndependencia({ ...resultadosIndependencia })
    if (!showDashsFirstTime) setShowDashsFirstTime(true)
    setShowDashFT(true)
  }

  const calcularRentabilidade = () => {
    let aporteInicial = Number(independenciaFinanceiraValues.AporteInicial)
    // let rentabilidadeAnual = independenciaFinanceiraValues.rentabilidadeAnual / 100
    // let rentabilidadeMensal = ((1 + rentabilidadeAnual) ** (1 / 12)) - 1
    let rentabilidadeAnualIndependencia = independenciaFinanceiraValues.RentabilidadeAnualIndependencia / 100
    let rentabilidadeMensalIndependencia = ((1 + rentabilidadeAnualIndependencia) ** (1 / 12)) - 1
    let tempoInvestindo = (independenciaFinanceiraValues.AposentadoriaIdade - clienteInfos.age) * 12 // 25 => Idade do cliente
    let inflacao = independenciaFinanceiraValues.InflacaoAnual / 100
    let fatorInflacao = (1 + inflacao) ** ((tempoInvestindo / 12))

    // Variavel informada
    let montanteAtual = aporteInicial
    let montanteFinalNecessario = 0
    if (independenciaFinanceiraValues.varRentabilidade === "RESERVA") {
      montanteFinalNecessario = independenciaFinanceiraValues.valorParaCalculo2
    } else if (independenciaFinanceiraValues.varRentabilidade === "RENDA") {
      montanteFinalNecessario = independenciaFinanceiraValues.valorParaCalculo3 / rentabilidadeMensalIndependencia
    }

    let aporteMensal = independenciaFinanceiraValues.valorParaCalculo1
    let rentabilidadeInicialAnual = 1

    // Verificando se apenas com os aportes o objetivo e alcançado
    let verificacaoMontante = aporteInicial
    for (let i = 1; i <= tempoInvestindo; i++) {
      // Rentabilidade
      verificacaoMontante += aporteMensal
    }
    if (verificacaoMontante > montanteFinalNecessario) {
      // Setting Values
      resultadosIndependencia.aporteInicial = Number(independenciaFinanceiraValues.AporteInicial)
      resultadosIndependencia.rentabilidadeAnual = 0
      resultadosIndependencia.contribuicaoMensal = independenciaFinanceiraValues.valorParaCalculo1
      // Calculando rentabilidade mensal
      let rendaMensal = verificacaoMontante * rentabilidadeMensalIndependencia
      resultadosIndependencia.rendaMensal = rendaMensal
      resultadosIndependencia.rendaMensalHoje = rendaMensal / fatorInflacao

      resultadosIndependencia.reservaAcumulada = verificacaoMontante
      resultadosIndependencia.reservaAcumuladaHoje = verificacaoMontante / fatorInflacao

      setResultadosIndependencia({ ...resultadosIndependencia })

      return null
    }

    let loopRentabilidade = () => {
      let rentabilidadeInicialMensal = ((1 + (rentabilidadeInicialAnual / 100)) ** (1 / 12)) - 1
      for (let i = 1; i <= tempoInvestindo; i++) {
        montanteAtual += montanteAtual * rentabilidadeInicialMensal

        // Aporte
        montanteAtual += aporteMensal

        // Aumento do aporte
        if (i % 12 == 0 && i < tempoInvestindo) {
          aporteMensal += aporteMensal * inflacao
        }
      }
      if ((montanteAtual / montanteFinalNecessario) < 1) {
        if (montanteAtual / montanteFinalNecessario < 0.5) {
          rentabilidadeInicialAnual += rentabilidadeInicialAnual * 0.5
        } else if (montanteAtual / montanteFinalNecessario < 0.7) {
          rentabilidadeInicialAnual += rentabilidadeInicialAnual * 0.05
        } else if (montanteAtual / montanteFinalNecessario < 0.9) {
          rentabilidadeInicialAnual += rentabilidadeInicialAnual * 0.005
        } else {
          rentabilidadeInicialAnual += rentabilidadeInicialAnual * 0.0005
        }
        // Resetando valores e atribuindo novo aporte
        montanteAtual = aporteInicial
        aporteMensal = independenciaFinanceiraValues.valorParaCalculo1

        loopRentabilidade()
      } else {
        resultadosIndependencia.aporteInicial = Number(independenciaFinanceiraValues.AporteInicial)
        resultadosIndependencia.rentabilidadeAnual = rentabilidadeInicialAnual
        resultadosIndependencia.contribuicaoMensal = independenciaFinanceiraValues.valorParaCalculo1
        // Calculando rentabilidade mensal
        let rendaMensal = montanteAtual * rentabilidadeMensalIndependencia
        if (independenciaFinanceiraValues.varRentabilidade === "RENDA") {
          resultadosIndependencia.rendaMensal = independenciaFinanceiraValues.valorParaCalculo3
          resultadosIndependencia.reservaAcumulada = montanteAtual
        } else {
          resultadosIndependencia.rendaMensal = rendaMensal
          resultadosIndependencia.reservaAcumulada = independenciaFinanceiraValues.valorParaCalculo2
        }
        resultadosIndependencia.rendaMensalHoje = rendaMensal / fatorInflacao

        resultadosIndependencia.reservaAcumuladaHoje = montanteAtual / fatorInflacao

        setResultadosIndependencia({ ...resultadosIndependencia })
      }
    }


    loopRentabilidade()
  }

  const handleMomentoDeVida = (event: React.ChangeEvent<HTMLInputElement>) => {
    cliente.MomentoDeVida = event.target.value
    setCliente({ ...cliente })
  }

  const handleCalculoTipo = (event: React.ChangeEvent<HTMLInputElement>) => {
    independenciaFinanceiraValues.CalculoTipo = event.target.value
    setIndependenciaFinanceiraValues({ ...independenciaFinanceiraValues })
  }
  
  const validate = (index: number, event: any) => {
    // The first if is to resolve an error when the accessor fills a value and then clears the entire value
    // The value assign a undefined when this occurs
    if (isNaN(Number(event.nativeEvent.data))) return;
    if (event.nativeEvent.inputType === "deleteContentBackward" || event.nativeEvent.inputType === "deleteWordBackward") {
      projetos[index]['Valor'] = ""
    } else {
      let newValue = parseFloat((event.target.value.replace("R$", "").replaceAll(".", ""))).toFixed(2)
      projetos[index]['Valor'] = newValue
    }

    setProjetos([...projetos])


    // if (value == undefined) {
    //     projetos[index]['Valor'] = ''
    //     setProjetos([...projetos])
    // } else {
    //     // value = parseFloat(value.replace(",", ".")).toFixed(2)
    //     projetos[index]['Valor'] = value
    //     setProjetos([...projetos])
    // }
  }

  const handleCurrencyAporteInicial = (event: any) => {
    independenciaFinanceiraValues.AporteInicial = Number(event.target.value)
    setIndependenciaFinanceiraValues({ ...independenciaFinanceiraValues })
  };

  const handleCurrencyValorParaCalculo = (event: any) => {
    independenciaFinanceiraValues.ValorParaCalculo = Number(event.target.value)
    setIndependenciaFinanceiraValues({ ...independenciaFinanceiraValues })
  };

  const handleCurrencyProjetos = (event: any) => {
    if (Number(event.target.value) > 0) {
      projetos[Number(event.target.name)].Valor = Number(event.target.value)
      setProjetos([...projetos])
    } else {
      projetos[Number(event.target.name)].Valor = null
      setProjetos([...projetos])
    }
  };


  return (
    <>
      <Container maxWidth="md" sx={{ padding: 3, backgroundColor: "white", borderRadius: 2, mb: 3 }}>
        <Box component="form"
          sx={{ px: 5, py: 1 }}
          noValidate
          autoComplete="off"
        >
          <Typography variant="h5" mb={2}>
            Jornada do Investidor
          </Typography>
          <Typography variant="body1" mb={2} sx={{ lineHeight: "17px" }}>
            A jornada de um investidor pode ser dividida em 3 etapas principais: acumulação, rentabilização e usufruto. Essa sequência foi elaborada para ajudá-lo a entender o seu atual momento de vida e onde - e como - você deseja chegar. Assim, você poderá compreender com mais clareza quais são os passos necessários para progredir nessa jornada.
          </Typography>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12}>
              <Grid item xs={12} >
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={cliente.MomentoDeVida}
                  onChange={handleMomentoDeVida}
                  sx={{ '&.Mui-checked': { color: '#512178' }, textAlign: "center" }}
                >
                  <Grid item xs={12} md={4}>
                    <FormControlLabel value="acumulo" control={<Radio size="small" sx={{ '&.Mui-checked': { color: '#512178' } }} />} label="Fase de acúmulo" />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControlLabel value="rentabilizar" control={<Radio size="small" sx={{ '&.Mui-checked': { color: '#512178' } }} />} label="Fase de rentabilizar" />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControlLabel value="usufruir" control={<Radio size="small" sx={{ '&.Mui-checked': { color: '#512178' } }} />} label="Fase para usufruir" />
                  </Grid>
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Typography variant="body1" >
                {cliente.MomentoDeVida == "acumulo" ? "* Foco em aumentar a renda e a capacidade de aportes *" :
                  cliente.MomentoDeVida == "rentabilizar" ? "* Foco na constância dos aportes e na maximização da rentabilidade *" :
                    cliente.MomentoDeVida == "usufruir" ? "* Foco na segurança e rentabilidade dos investimentos *" : ""
                }
              </Typography>

            </Grid>
          </Grid>

          <Typography variant="h5" mb={2}>
            Nível de risco
          </Typography>
          <Typography variant="body1" mb={2} sx={{ lineHeight: "17px" }}>
            O nível de risco se trata de uma estimativa acerca do perfil dos investimentos do seu cliente.
          </Typography>
          <Typography variant="body1" fontWeight={"bold"} textAlign={"center"}
            color={perfilCliente.nivelDeRisco === "1" ? "success.main" :
              perfilCliente.nivelDeRisco === "2" ? "blue" : "primary"
            }>
            {perfilCliente.nivelDeRiscoText}
          </Typography>
          <Grid item justifyContent={"center"} display="flex">
            {SliderFunc()}
          </Grid>
        </Box>
      </Container>
      <Container maxWidth="md" sx={{ padding: 3, backgroundColor: "white", borderRadius: 2, mb: 3 }}>
        <Box component="form"
          sx={{ px: 5, py: 1 }}
          noValidate
          autoComplete="off"
        >
          <Typography variant="h5" mb={2}>
            Independência Financeira
          </Typography>
          <Typography variant="body1" mb={2} sx={{ lineHeight: "17px" }}>
            O cliente se encontra em fase de construção de independência?
          </Typography>

          <FormControlLabel
            sx={{ m: 0 }}
            label="Se o seu cliente não deseja independência financeira, clique aqui para desativar."
            labelPlacement="start"
            control={
              <Switch
                checked={independenciaFinanceiraValues.AtingiuIndependenciaFinanceira} onClick={() => { changeThereIsIndependencia() }}
              />
            }
          />

          {!independenciaFinanceiraValues.AtingiuIndependenciaFinanceira ? "" :
            <Box>
              <Grid container mb={3}>
                <Grid item xs={6} textAlign="center" marginTop={3}>
                  <Typography variant="h6">{clienteInfos.name}</Typography>
                </Grid>
                <Grid item xs={6} textAlign="center" marginTop={3} marginLeft={0}>
                  <Typography variant="h6">
                    {
                      clienteInfos.age != null ? clienteInfos.age + " anos" :
                        "Data de nascimento não informada"
                    }
                  </Typography>
                </Grid>
              </Grid>

              <Grid container mb={2} spacing={5}>
                <Grid item xs={12} md={6} sx={{ maxHeight: "120px", position: "relative" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ position: "relative", top: "-7px" }}>
                      <TextField label="Qual a idade que deseja se aposentar?" fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
                        // onChange={(event) => onChangeInput('Numero', event.target.value)} value={cliente.Numero}
                        onChange={(event) => changeIndependenciaFinanceiraValues('AposentadoriaIdade', Number(event.target.value))} value={independenciaFinanceiraValues.AposentadoriaIdade}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ position: "relative", top: "11px" }}>
                      <TextField
                        label="Aporte inicial" required fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
                        value={independenciaFinanceiraValues.AporteInicial}
                        onChange={handleCurrencyAporteInicial}
                        InputProps={{
                          inputComponent: NumberFormatCustom as any,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                  <Box sx={{ border: 1, borderColor: "#c4c4c4", borderRadius: 1, minHeight: "100px" }}>
                    <Grid container p={2} position={"relative"} justifyContent="center">
                      <Typography variant="h6" sx={{ px: 2, position: "absolute", top: "-12px", backgroundColor: "white" }}>
                        Aposentadoria (anos)
                      </Typography>
                      <Grid item xs={12}>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={cliente.MomentoDeVida}
                          onChange={handleMomentoDeVida}
                          sx={{ '&.Mui-checked': { color: '#512178' }, textAlign: "center" }}
                        >
                          <Grid item xs={6}>
                            <FormControlLabel value="acumulo" control={<Radio size="small" sx={{ '&.Mui-checked': { color: '#512178' } }} />} label="Vitalícia" />
                          </Grid>
                          <Grid item xs={6}>
                            <FormControlLabel value="rentabilizar" disabled control={<Radio size="small" sx={{ '&.Mui-checked': { color: '#512178' } }} />} label="Temporária" />
                          </Grid>
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={5}>
                        <FormControl fullWidth size="small" margin="normal" >
                          <InputLabel id="demo-select-small">Rentabilidade</InputLabel>
                          <Select
                            labelId="demo-select-small"
                            id="demo-simple-select"
                            onChange={(event) => changeIndependenciaFinanceiraValues('RentabilidadeAnualIndependencia', Number(event.target.value))}
                            value={independenciaFinanceiraValues.RentabilidadeAnualIndependencia}
                            label="Rentabilidade"
                          >
                            <MenuItem value="6">6%</MenuItem>
                            <MenuItem value="7">7%</MenuItem>
                            <MenuItem value="8">8%</MenuItem>
                            <MenuItem value="9">9%</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={5}>
                        <TextField label="Prazo" disabled fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
                        // onChange={(event) => onChangeInput('Numero', event.target.value)} value={cliente.Numero}
                        // onChange={(event) => changeIndependenciaFinanceiraValues('AposentadoriaIdade', Number(event.target.value))} value={independenciaFinanceiraValues.AposentadoriaIdade}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <Box sx={{ border: 1, borderColor: "#c4c4c4", borderRadius: 1, minHeight: "120px" }}>
                    <Grid container p={2} position={"relative"} justifyContent="center">
                      <Typography variant="h6" sx={{ px: 2, position: "absolute", top: "-12px", backgroundColor: "white" }}>
                        Condições para independência
                      </Typography>
                      <Grid item xs={12}>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={independenciaFinanceiraValues.CalculoTipo}
                          onChange={handleCalculoTipo}

                          sx={{ '&.Mui-checked': { color: '#512178' }, textAlign: "center" }}
                        >
                          <Grid item xs={6} md={3}>
                            <FormControlLabel value="OPT1" control={<Radio size="small" sx={{ '&.Mui-checked': { color: '#512178' } }} />} label="Valor da contribuição mensal" />
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <FormControlLabel value="OPT2" control={<Radio size="small" sx={{ '&.Mui-checked': { color: '#512178' } }} />} label="Reserva que pretende acumular" />
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <FormControlLabel value="OPT3" control={<Radio size="small" sx={{ '&.Mui-checked': { color: '#512178' } }} />} label="Renda desejada" />
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <FormControlLabel disabled value="OPT41" control={<Radio size="small" sx={{ '&.Mui-checked': { color: '#512178' } }} />} label="Descobrir rentabilidade" />
                          </Grid>
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          required fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
                          value={independenciaFinanceiraValues.ValorParaCalculo}
                          onChange={handleCurrencyValorParaCalculo}
                          InputProps={{
                            inputComponent: NumberFormatCustom as any,
                          }}
                          label={
                            independenciaFinanceiraValues.CalculoTipo === "OPT1" ? "Contribuição mensal" :
                              independenciaFinanceiraValues.CalculoTipo === "OPT2" ? "Reserva" :
                                independenciaFinanceiraValues.CalculoTipo === "OPT3" ? "Renda" : ""
                          }
                        />
                      </Grid>
                      {/* <Grid item xs={1}></Grid>
                                    <Grid item xs={5}>
                                        <TextField label="Qual a idade que deseja se aposentar?" fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
                                            onChange={(event) => changeIndependenciaFinanceiraValues('AposentadoriaIdade', Number(event.target.value))} value={independenciaFinanceiraValues.AposentadoriaIdade}
                                        />
                                    </Grid> */}
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small" margin="normal" >
                    <InputLabel id="demo-select-small">Rentabilidade anual estimada</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-simple-select"
                      onChange={(event) => changeIndependenciaFinanceiraValues('RentabilidadeAnual', Number(event.target.value))}
                      value={independenciaFinanceiraValues.RentabilidadeAnual}
                      label="Rentabilidade anual estimada"
                    >
                      <MenuItem value="6">6%</MenuItem>
                      <MenuItem value="7">7%</MenuItem>
                      <MenuItem value="8">8%</MenuItem>
                      <MenuItem value="9">9%</MenuItem>
                      <MenuItem value="10">10%</MenuItem>
                      <MenuItem value="11">11%</MenuItem>
                      <MenuItem value="12">12%</MenuItem>
                      <MenuItem value="13">13%</MenuItem>
                      <MenuItem value="14">14%</MenuItem>
                      <MenuItem value="15">15%</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small" margin="normal" >
                    <InputLabel id="demo-select-small">Inflação anual estimada</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-simple-select"
                      onChange={(event) => changeIndependenciaFinanceiraValues('InflacaoAnual',
                        Number(event.target.value))} value={independenciaFinanceiraValues.InflacaoAnual}
                      label="Inflação anual estimada"
                      defaultValue={"5"}
                    >
                      <MenuItem value="4">4%</MenuItem>
                      <MenuItem value="5">5%</MenuItem>
                      <MenuItem value="6">6%</MenuItem>
                      <MenuItem value="7">7%</MenuItem>
                      <MenuItem value="8">8%</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Box sx={{ width: "100%", textAlign: "center", mt: "10px" }}>
                <Box component={"hr"} sx={{ border: "1px solid #f0f1f2", position: "relative", top: "24px" }}></Box>
                <Button variant="contained" color="secondary" startIcon={<CalculateIcon />} sx={{ backgroundColor: 'secondary' }}
                  onClick={() => callTipoCalculo()}
                >
                  Simular Cálculo
                </Button>
              </Box>

              {!showDashsFirstTime ? "" :
                <Grid item>
                  <Typography variant="h6" my={3}>
                    Resultado simulação
                  </Typography>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6} lg={4} position="relative" >
                      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ border: 1, borderColor: "#c4c4c4", borderRadius: 1, minHeight: "100px" }}>
                        {/* SVG ICON */}
                        <Box sx={{ position: "absolute", top: "12px", backgroundColor: "#ffffff", width: "35px", height: "35px" }}>
                          {SVGImages.svgReservaAcumulada()}
                        </Box>
                        {/* Infos to show */}
                        <Typography variant="h6">Reserva Acumulada</Typography>
                        <Typography variant="h5">
                          {resultadosIndependencia.reservaAcumulada.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} position="relative" >
                      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ border: 1, borderColor: "#c4c4c4", borderRadius: 1, minHeight: "100px" }}>
                        {/* SVG ICON */}
                        <Box sx={{ position: "absolute", top: "12px", backgroundColor: "#ffffff", width: "35px", height: "35px" }}>
                          {SVGImages.svgRendaMensal()}
                        </Box>
                        {/* Infos to show */}
                        <Typography variant="h6">Renda Mensal</Typography>
                        <Typography variant="h5">
                          {independenciaFinanceiraValues.AposentadoriaTipo === "TEMPORARIA" ?
                            resultadosIndependencia.rendaMensalTemp.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                            : resultadosIndependencia.rendaMensal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} position="relative" >
                      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ border: 1, borderColor: "#c4c4c4", borderRadius: 1, minHeight: "100px" }}>
                        {/* SVG ICON */}
                        <Box sx={{ position: "absolute", top: "12px", backgroundColor: "#ffffff", width: "35px", height: "35px" }}>
                          {SVGImages.svgAporteInicial()}
                        </Box>
                        {/* Infos to show */}
                        <Typography variant="h6">Rentabilidade Anual</Typography>
                        <Typography variant="h5">
                          {resultadosIndependencia.rentabilidadeAnual.toFixed(2).toString().replace(".", ",") + " %"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} position="relative" >
                      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ border: 1, borderColor: "#c4c4c4", borderRadius: 1, minHeight: "100px" }}>
                        {/* SVG ICON */}
                        <Box sx={{ position: "absolute", top: "12px", backgroundColor: "#ffffff", width: "35px", height: "35px" }}>
                          {SVGImages.svgReservaAcumuladaHoje()}
                        </Box>
                        {/* Infos to show */}
                        <Typography variant="h6" textAlign="center">Reserva Acumulada<br />Valores de hoje</Typography>
                        <Typography variant="h5">
                          {resultadosIndependencia.reservaAcumuladaHoje.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} position="relative">
                      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ border: 1, borderColor: "#c4c4c4", borderRadius: 1, minHeight: "100px" }}>
                        {/* SVG ICON */}
                        <Box sx={{ position: "absolute", top: "12px", backgroundColor: "#ffffff", width: "35px", height: "35px" }}>
                          {SVGImages.svgRendaMensalHoje()}
                        </Box>
                        {/* Infos to show */}
                        <Typography variant="h6" textAlign="center">Renda Mensal<br />Valores de hoje</Typography>
                        <Typography variant="h5">
                          {independenciaFinanceiraValues.AposentadoriaTipo === "TEMPORARIA" ?
                            resultadosIndependencia.rendaMensalHojeTemp.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                            : resultadosIndependencia.rendaMensalHoje.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} position="relative" >
                      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ border: 1, borderColor: "#c4c4c4", borderRadius: 1, minHeight: "100px" }}>
                        {/* SVG ICON */}
                        <Box sx={{ position: "absolute", top: "12px", backgroundColor: "#ffffff", width: "35px", height: "35px" }}>
                          {SVGImages.svgContribuicaoMensal()}
                        </Box>
                        {/* Infos to show */}
                        <Typography variant="h6">Contribuição Mensal</Typography>
                        <Typography variant="h5">
                          {resultadosIndependencia.contribuicaoMensal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={3} lg={4}></Grid>
                    <Grid item xs={12} md={6} lg={4} position="relative" >
                      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ border: 1, borderColor: "#c4c4c4", borderRadius: 1, minHeight: "100px" }}>
                        {/* SVG ICON */}
                        <Box sx={{ position: "absolute", top: "12px", backgroundColor: "#ffffff", width: "35px", height: "35px" }}>
                          {SVGImages.svgAporteInicial()}
                        </Box>
                        {/* Infos to show */}
                        <Typography variant="h6">Aporte Inicial</Typography>
                        <Typography variant="h5">
                          {resultadosIndependencia.aporteInicial.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </Typography>
                      </Box>
                    </Grid>

                  </Grid>
                </Grid>
              }
            </Box>
          }
        </Box>
      </Container>

      <Snackbar open={openSnackbarFailed} autoHideDuration={6000} onClose={handleCloseAllBackdrops}>
        <Alert onClose={handleCloseAllBackdrops} severity="error" sx={{ fontSize: 14, boxShadow: 3 }}>
          {textToSnackbar}
        </Alert>
      </Snackbar>

      <Snackbar open={openSnackbarSuccess} autoHideDuration={6000} onClose={handleCloseAllBackdrops}>
        <Alert onClose={handleCloseAllBackdrops} severity="success" sx={{ fontSize: 14, boxShadow: 3 }}>
          {textToSnackbar}
        </Alert>
      </Snackbar>

    </>
  )
}

export default ProjetosComponent;