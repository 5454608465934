const ClienteDependentesValidation = (dependentes: any) => {
    // const [erros, setErros] = useState<string[]>([])
    let errorsList = <any>[]
    let errosCount = 0

    dependentes.forEach((dependente: any) => {
        if (dependente.NomeCompleto.trim() == '' || dependente.NomeCompleto.length < 3 || dependente.NomeCompleto.length > 150) {
            errorsList.push('O nome completo dos dependentes não pode ser vazio.')
            errosCount++
        }

        // Checking date is valid
        // const today = new Date()
        // const dependenteBirthDate = new Date(dependente.DataNascimento)
        // const [monthToday, dayToday, yearToday] = [today.getMonth() + 1, today.getDate(), today.getFullYear()];
        // const [monthDependent, dayDependent, yearDependent] = [dependenteBirthDate.getMonth() + 1, dependenteBirthDate.getDate() + 1, dependenteBirthDate.getFullYear()]
        // if (dependente.DataNascimento.trim() == '') {
        //     errorsList.push('A data de nascimento deve ser informada.')
        //     errosCount++
        // } else if (yearDependent > yearToday) {
        //     errorsList.push('A data de nascimento é inválida.')
        //     errosCount++
        // } else if (yearToday == yearDependent && monthDependent > monthToday) {
        //     errorsList.push('A data de nascimento é inválida.')
        //     errosCount++
        // } else if (yearToday == yearDependent && monthDependent == monthToday && dayDependent > dayToday) {
        //     errorsList.push('A data de nascimento é inválida.')
        //     errosCount++
        // }

        if (dependente.Parentesco.trim() == '' || dependente.Parentesco.length < 3 || dependente.Parentesco.length > 150) {
            errorsList.push('O grau de parentesco não pode ser vazio.')
            errosCount++
        }
    })

    return {
        isValid: errosCount === 0,
        errorDescriptions: errorsList
    }

}

export default ClienteDependentesValidation