import Container from '@mui/material/Container'
import { useEffect } from 'react'
import AdminService from '../../../Services/AdminService'

export const UsersRegistrationComponent = () => {
  let adminServices = new AdminService()

  useEffect(() => {
    adminServices.ListarUsuariosPendentes()
      .then((value) => {
      })
      .catch()
  }, [])

  return <>
    <Container maxWidth="md">

    </Container>
  </>
}