import React, { useMemo } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import EventIcon from '@mui/icons-material/Event';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ButtonBaseProps, FormControl, FormLabel } from '@mui/material';
import { useParams } from 'react-router-dom';
import StorageHelper from '../../../../Services/Helpers/StorageHelper';
import { useClientContext } from '../ClientContext';
import { interfaceSchedule } from '../../../../Interface/intarfaceSchedule';
import ClientesService from '../../../../Services/ClientesService';
import { createTask } from '../crudTask';
import { formattingDateToISO } from '../usefulFunctions';
import { enqueueSnackbar } from 'notistack';
import { usePermissions } from '../../../../Hooks/usePermissionsUser';
import { IPermissionsUser } from '../../../../Interface/interfacePermissions';
import { activityWriteDate } from '../../../../Helpers/formatters';

export const ScheduleActivity = () => {
  const { activities, setActivities } = useClientContext();
  const storageHelper = new StorageHelper();
  const clientService = new ClientesService;
  const { client, updateClient } = useClientContext();
  const { permissions } = usePermissions();

  const assessorId = storageHelper.GetUsuarioId();
  const { userId } = useParams<any>()

  const [newActivitie, setNewActivitie] = React.useState<interfaceSchedule>({
    DataEvento: null, AtividadeFinalizada: false, Title: "", Descricao: "",
    AssessorUsuarioId: assessorId, ClienteId: userId, Visto: false, DataTermino: null,
    StatusTarefa: "OPEN", AtividadeFinalizadaData: null, AtividadeTodoDia: false,
    CriadoPorArea: null
  } as interfaceSchedule);

  const determineCriadoPorArea = () => {
    // Verifica se o assessorId não é igual ao AssessorUsuarioId do cliente
    if (client?.AssessorUsuarioId !== assessorId) {
      if (permissions.some(p => p.PermissionName === 'Seguros' || p.PermissionName === 'AdminSeguros')) {
        return 'Seguros';
      }
      if (permissions.some(p => p.PermissionName === 'Câmbio' || p.PermissionName === 'AdminCâmbio')) {
        return 'Câmbio';
      }
    }
    return null; // Retorna null se o assessorId for igual ao AssessorUsuarioId ou se nenhuma das permissões for encontrada
  };

  const handleAddTitle = (type: string) => {
    setNewActivitie(prevState => ({
      ...prevState,
      Title: type,
      CriadoPorArea: determineCriadoPorArea()
    }));
  };

  const handleDateChange = (newDate: string) => {
    setNewActivitie(prevState => ({
      ...prevState,
      DataEvento: formattingDateToISO(new Date(newDate)), // Atualiza apenas a data
      AtividadeFinalizada: new Date(newDate).getTime() < new Date().getTime(),
      AtividadeFinalizadaData: new Date(newDate).getTime() < new Date().getTime() ? formattingDateToISO(new Date()) : null,
    }));
  };

  // Menu options contact and meeting
  const [openSelectContato, setOpenSelectContato] = React.useState(false);
  const anchorRefSelectContato = React.useRef<HTMLButtonElement>(null);

  const [openSelectReuniao, setOpenSelectReuniao] = React.useState(false);
  const anchorRefSelectReuniao = React.useRef<HTMLButtonElement>(null);

  const handleOpenMenu = (menu: string) => {
    if (menu == "CONTATO") {
      setOpenSelectContato(true)
    } else {
      setOpenSelectReuniao(true)
    }
  };

  const handleCloseMenu = () => {
    setOpenSelectContato(false)
    setOpenSelectReuniao(false)
  };

  interface buttonsToSetDateInterface {
    name: string, isActivated: boolean, action: Function
  }

  const [buttonsToSetDate, setButtonsToSetDate] = React.useState<buttonsToSetDateInterface[]>([
    {
      name: "Agora (atividade concluída)",
      isActivated: false,
      action: (index: number) => {
        let newDate = new Date();
        setNewActivitie(prevState => ({
          ...prevState,
          DataEvento: formattingDateToISO(newDate),
          AtividadeTodoDia: false,
          AtividadeFinalizada: true,
          AtividadeFinalizadaData: formattingDateToISO(newDate),
        }));

        // Atualiza o estado dos botões
        setButtonsToSetDate(prevButtons =>
          prevButtons.map((button, i) => ({
            ...button,
            isActivated: i === index,
          }))
        );
      }
    },
    {
      name: "Personalizar data",
      isActivated: false,
      action: (index: number) => {
        setNewActivitie(prevState => ({
          ...prevState,
          DataEvento: formattingDateToISO(new Date()),
          AtividadeTodoDia: false,
        }));

        // Atualiza o estado dos botões
        setButtonsToSetDate(prevButtons =>
          prevButtons.map((button, i) => ({
            ...button,
            isActivated: i === index,
          }))
        );
      }
    },
    {
      name: "Sem data",
      isActivated: false,
      action: (index: number) => {
        setNewActivitie(prevState => ({
          ...prevState,
          AtividadeSemData: true,
          DataEvento: null,
          AtividadeFinalizada: false,
        }));

        // Atualiza o estado dos botões
        setButtonsToSetDate(prevButtons =>
          prevButtons.map((button, i) => ({
            ...button,
            isActivated: i === index,
          }))
        );
      }
    },
  ]);

  interface ButtonToChooseDateInterface extends ButtonBaseProps {
    variantText: "text" | "outlined" | "contained";
  }
  const ButtonToChooseDate: React.FC<ButtonToChooseDateInterface> = ({ children, onClick, variantText }) => {
    return <Button variant={variantText} size="small" disableElevation sx={{ fontWeight: "normal" }}
      onClick={onClick}
    >
      {children}
    </Button>
  }

  // Option to personalized date
  const [personalizedDate, setPersonalizedDate] = React.useState(false)

  React.useEffect(() => {
    if (buttonsToSetDate[1].isActivated) {
      setPersonalizedDate(true)
    } else {
      setPersonalizedDate(false)
    }
  }, [buttonsToSetDate])

  const [openBackdrop, setOpenBackdrop] = React.useState(false)

  function clearNewActivitie() {
    // Clear last infos
    newActivitie.Title = ""
    newActivitie.Descricao = ""
    newActivitie.DataEvento = null
    setNewActivitie({ ...newActivitie })
  }

  function handleCreateActivitie() {
    setOpenBackdrop(true)

    createTask(newActivitie)
      .then((value) => {
        setActivities([value, ...activities])
        if (client?.Status === 'LEAD' && newActivitie.Title === '1° reunião') {
          updateClient({ ...client, Status: 'R1' });
        } else if ((client?.Status === 'LEAD' || client?.Status === 'R1') && (newActivitie.Title !== 'Contato' && newActivitie.Title !== 'Marcar reunião' && newActivitie.Title !== 'Tarefa' && newActivitie.Title !== 'Aporte')) {
          updateClient({ ...client, Status: 'OTHERS_MEETING' });
        }
        clearNewActivitie();
        clientService.UpdateUltimaAtividade(client?.Id);
      })
      .catch(() => {
        enqueueSnackbar('Ops... tivemos um problema.', { variant: 'error' });
      })
      .finally(() => {
        setOpenBackdrop(false);
      })
  }

  const showOptionAporte = useMemo(() => {
    const isInsuranceUser = permissions.some((v: IPermissionsUser) => (v.PermissionName === 'Câmbio' || v.PermissionName === 'AdminCâmbio'));
    const isAdvisorClientOwner = client?.AssessorUsuarioId === assessorId;

    return isAdvisorClientOwner || !isInsuranceUser;

  }, [client, permissions, assessorId]);

  if (!client) return <></>;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight="normal" mb={2} style={{ position: "relative" }}>
            Agendar uma atividade:
            {/* <Tooltip placement="top" title="Lorem ipsum dolor sit amet, consectetur adipisicing elit." sx={{ position: "relative", left: 5, top: 2 }}>
                            <InfoIcon style={{ fontSize: 13 }} />
                        </Tooltip> */}
            {!newActivitie.Title ? <></> :
              <Chip style={{ marginLeft: "12px" }} label={newActivitie.Title} size="small"
                onDelete={() => {
                  // Clear infos
                  clearNewActivitie()

                  buttonsToSetDate.map((value, index) => {
                    buttonsToSetDate[index].isActivated = false
                  })
                  setButtonsToSetDate([...buttonsToSetDate])
                }}
                sx={{ fontWeight: "normal" }} />
            }
          </Typography>
          <Box textAlign="center" sx={{ mt: 4, mb: 1 }}>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <ButtonStyled
                endIcon={<ArrowDropDownIcon />}
                disableTouchRipple
                onMouseEnter={() => handleOpenMenu("CONTATO")}
                onMouseLeave={() => handleCloseMenu()}
                ref={anchorRefSelectContato}
              >
                Contato
                <Popper
                  open={openSelectContato}
                  anchorEl={anchorRefSelectContato.current}
                  role={undefined}
                  placement="bottom"
                  transition
                >
                  {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps}>
                      <Paper>
                        <ClickAwayListener onClickAway={() => { }}>
                          <MenuList>
                            <MenuItem onClick={() => {
                              handleAddTitle("Contato");
                              handleCloseMenu();
                            }}>
                              Contato
                            </MenuItem>
                            <MenuItem onClick={() => {
                              handleAddTitle("Tentativa de Contato");
                              handleCloseMenu();
                            }}>
                              Tentativa de Contato
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </ButtonStyled>
              <ButtonStyled
                endIcon={<ArrowDropDownIcon />}
                disableTouchRipple

                onMouseEnter={() => handleOpenMenu("REUNIAO")}
                onMouseLeave={() => handleCloseMenu()}
                ref={anchorRefSelectReuniao}
              >
                Reunião
                <Popper
                  open={openSelectReuniao}
                  anchorEl={anchorRefSelectReuniao.current}
                  role={undefined}
                  placement="bottom"
                  transition
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}

                    >
                      <Paper>
                        <ClickAwayListener onClickAway={() => { }}>
                          <MenuList>
                            {
                              client.Status === 'LEAD' && <MenuItem onClick={() => {
                                handleAddTitle("1° reunião")
                                handleCloseMenu()
                              }}>
                                1° reunião
                              </MenuItem>
                            }
                            {
                              client.Status === 'R1' && <MenuItem onClick={() => {
                                handleAddTitle("2° reunião")
                                handleCloseMenu()
                              }}>
                                2° reunião
                              </MenuItem>
                            }
                            <MenuItem onClick={() => {
                              handleAddTitle("Reunião")
                              handleCloseMenu()
                            }}>
                              Reunião
                            </MenuItem>
                            <MenuItem onClick={() => {
                              handleAddTitle("Reunião seguro")
                              handleCloseMenu()
                            }}>
                              Reunião seguro
                            </MenuItem>
                            <MenuItem onClick={() => {
                              handleAddTitle("Reunião câmbio")
                              handleCloseMenu()
                            }}>
                              Reunião câmbio
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </ButtonStyled>
              <ButtonStyled onClick={() => handleAddTitle("Tarefa")}>Tarefa</ButtonStyled>
              {showOptionAporte && <ButtonStyled onClick={() => handleAddTitle("Aporte")}>Aporte</ButtonStyled>}
            </ButtonGroup>
          </Box>
        </Grid>
        {newActivitie.Title == "" ? <></> :
          <>
            <Grid item xs={12}>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Typography variant="body1">
                  Selecione o tempo:
                </Typography>

                {
                  newActivitie.DataEvento ? <Chip label={activityWriteDate(newActivitie.DataEvento, newActivitie.AtividadeTodoDia)} size="small" sx={{ fontWeight: "normal" }}
                    onDelete={() => {
                      newActivitie.DataEvento = null
                      setNewActivitie({ ...newActivitie })

                      // Deselect all button who was active
                      buttonsToSetDate.map((value, index) => {
                        buttonsToSetDate[index].isActivated = false
                      })
                      setButtonsToSetDate([...buttonsToSetDate])
                    }}
                  /> : <></>
                }
              </Stack>

            </Grid>

            <Grid item xs={12}
              sx={{ display: "flex", flexWrap: "wrap", gap: 2, justifyContent: "center" }}
            >
              {
                buttonsToSetDate.map(((buttonInfos, index) => {
                  return <ButtonToChooseDate key={index} variantText={buttonsToSetDate[index].isActivated ? "contained" : "outlined"} onClick={() => buttonsToSetDate[index].action(index)}>
                    {buttonInfos.name}
                  </ButtonToChooseDate>
                }))
              }
            </Grid>

            {
              personalizedDate ? <Grid item xs={12} textAlign="center">
                <Typography variant="body1">
                  Data e hora:
                </Typography>
                <TextField
                  id="datetime-local"
                  type="datetime-local"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={newActivitie.DataEvento}
                  onChange={(event) => {
                    handleDateChange(event.target.value);
                  }}
                />
              </Grid> : <></>
            }

            {
              buttonsToSetDate.filter((obj) => obj.isActivated ? true : false).length > 0 ? <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <FormLabel>Comentário</FormLabel>
                  <TextField
                    multiline
                    rows={6}
                    fullWidth
                    value={newActivitie.Descricao}
                    inputProps={{
                      maxLength: 500
                    }}
                    helperText={`${newActivitie.Descricao.length}/500 caracteres restantes`}
                    onChange={(event) => {
                      const newValue = event.target.value;

                      if (newValue.length <= 500) {
                        newActivitie.Descricao = newValue;
                        setNewActivitie({ ...newActivitie });
                      }
                    }}
                  />
                </FormControl>
              </Grid> : <></>

            }

            <Grid item xs={12}>
              <Box textAlign="center" mt={2}>
                <Button
                  onClick={() => {
                    handleCreateActivitie();
                  }}
                  disabled={buttonsToSetDate.filter((obj) => obj.isActivated ? true : false).length > 0 ? false : true}
                  disableElevation
                  endIcon={<EventIcon />} variant="contained" color="primary" size="small">
                  {buttonsToSetDate[2].isActivated || buttonsToSetDate[0].isActivated ? "Criar " : "Agendar "} atividade
                </Button>
              </Box>
            </Grid>

          </>
        }

      </Grid>


      {/* Backdrop */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

    </>
  )
}

const ButtonStyled = styled(Button)({
  borderColor: "#0000003b", fontWeight: "normal",
  "&:hover": {
    // border: "1px solid #0000003b",
  },

  "&:disabled": {
    border: "1px solid #0000003b",
    color: "#512178",
    borderColor: "#0000003b", fontWeight: "normal",
  }
})