import { Box, Button, Tooltip } from "@mui/material"
import { useState } from "react";
import { useClientContext } from "../../ClientContext";
import { interfaceClient } from "../../../../../Interface/interfaceClient";
import { ClientEditForm } from "./ClientEditForm";
import EditIcon from '@mui/icons-material/Edit';
import { ClientInfoList } from "./ClientInfoList";

export const RegistrationData = () => {
  const { client, updateClient } = useClientContext();
  const [editMode, setEditMode] = useState(false);

  // Atualiza o cliente no contexto e no servidor ao salvar
  const handleSave = async (editedClient: interfaceClient) => {
    if (client) {
      const updatedClient = { ...client, ...editedClient };

      await updateClient(updatedClient);
      setEditMode(false);
    }
  };

  return <Box position="relative">
    {
      !editMode && <Tooltip title="Editar Informações">
        <Button
          startIcon={<EditIcon fontSize='small' />}
          size='small'
          variant='contained'
          disableElevation
          onClick={() => setEditMode(!editMode)}
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            zIndex: 10
          }}
        >
          Editar
        </Button>
      </Tooltip>
    }

    {editMode ? (
      <ClientEditForm handleSave={handleSave} setEditMode={setEditMode} />
    ) : (
      <ClientInfoList client={client as interfaceClient} />
    )}
  </Box>
}