import React from 'react';
import { GenericLoading } from '../../GerenicLoading';
import { useAdvisorSegurosContext } from '../../../Hooks/useAdvisorSeguroContext';
import { KanbanBoardSeguros } from '../KanbanSeguro';

export const AdvisorSeguroKanban: React.FC = () => {
  const { loading, filteredKanbanList } = useAdvisorSegurosContext();

  if (loading) {
    return <GenericLoading height='50vh' />
  }

  return (
    <KanbanBoardSeguros
      isDraggable={false}
      loading={loading}
      filteredKanbanList={filteredKanbanList}
    />
  );
};
