import React, { useState } from 'react';
import { Box, Button, Chip, Divider, FormControl, Grid, InputLabel, ListItem, ListItemText, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { useClientContext } from '../ClientContext';
import { formatCurrencyBRL } from '../../../../Helpers/formatters';
import { NumberFormatCustom } from '../../../../Helpers/Components';
import { interfaceClient } from '../../../../Interface/interfaceClient';
import { LoadingButton } from '@mui/lab';

const isValidDate = (date: string) => {
  return moment(date, moment.ISO_8601, true).isValid();
};

interface ClientInfo {
  title: string;
  value: string | undefined;
  formatFunction?: (value: string) => string;
}

export const InsuranceData: React.FC = () => {
  const { client, clientExpenses, clientRevenue, updateClient } = useClientContext();
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formaPagamentoSeguros, setFormaPagamentoSeguros] = useState(client?.FormaPagamentoSeguros || 'Mensal');
  const [paSeguros, setPaSeguros] = useState(client?.PASeguros || 0);

  if (!client) {
    return null;
  }

  const insuranceInfo: ClientInfo[] = [
    { title: "Profissão", value: client.Profissao ?? '-' },
    {
      title: "Data de Nascimento",
      value: client.DataNascimento ?? '-',
      formatFunction: (value: string) => isValidDate(value) ? moment(value).format('DD/MM/YYYY') : '-',
    },
    {
      title: "Renda Bruta Mensal",
      value: clientRevenue?.RendaBrutaMensal?.toString(),
      formatFunction: (value: string) => value ? formatCurrencyBRL(value) : '-',
    },
    {
      title: "Despesa Mensal",
      value: clientExpenses?.DespesaMensal?.toString(),
      formatFunction: (value: string) => value ? formatCurrencyBRL(value) : '-',
    },
    {
      title: "Patrimônio Estimado",
      value: client.PatrimonioEstimado?.toString(),
      formatFunction: (value: string) => value ? formatCurrencyBRL(value) : '-',
    },
    {
      title: "Aporte Mensal",
      value: clientRevenue?.AporteMensal?.toString(),
      formatFunction: (value: string) => value ? formatCurrencyBRL(value) : '-',
    },
    { title: "Possui Seguros", value: client.PossuiSeguros ? 'Sim' : 'Não' },
  ];

  const handleEditClick = async () => {
    if (isEditing) {
      setLoading(true);
      const updatedClient: interfaceClient = {
        ...client,
        PASeguros: paSeguros,
        FormaPagamentoSeguros: formaPagamentoSeguros,
      };
      await updateClient(updatedClient);
      setLoading(false);
    }
    setIsEditing(!isEditing);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography fontSize={14}>Interesses</Typography>
        <Grid container spacing={1} sx={{ mb: 1, mt: 0.1 }}>
          {client.InteresseReuniaoSegurosDeVida && (
            <Grid item>
              <Tooltip title="Seguro de Vida">
                <Chip label="SV" size="small" color="primary" />
              </Tooltip>
            </Grid>
          )}
          {client.InteresseReuniaoPlanoDeSaude && (
            <Grid item>
              <Tooltip title="Plano de Saúde">
                <Chip label="PS" size="small" color="info" />
              </Tooltip>
            </Grid>
          )}
          {client.InteresseReuniaoPlanejamentoPatrimonial && (
            <Grid item>
              <Tooltip title="Planejamento Patrimonial">
                <Chip label="PPS" size="small" color="secondary" />
              </Tooltip>
            </Grid>
          )}
          {client.InteresseReuniaoOutros && (
            <Grid item>
              <Tooltip title="Outros">
                <Chip label="Outros" size="small" color="default" />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container item xs={12} md={6}>
        {insuranceInfo.map((info, index) => (
          <Grid item xs={6} key={index}>
            <ListItem sx={{ p: 0 }}>
              <ListItemText
                primary={<Typography fontSize={12} color="text.secondary">{info.title}</Typography>}
                secondary={
                  <Typography sx={{ fontSize: 15, fontWeight: 'bold' }}>
                    {info.formatFunction ? info.formatFunction(info.value ?? '-') : info.value}
                  </Typography>
                }
              />
            </ListItem>
          </Grid>
        ))}
        <Grid item xs={12} mt={1} mb={2}>
          <Divider />
        </Grid>
        <Grid container item spacing={2} justifyContent='center'>
          <Grid item xs={6}>
            {isEditing ? (
              <TextField
                label='PA'
                fullWidth size='small'
                value={paSeguros}
                onChange={(event) => { setPaSeguros(Number(event.target.value)) }}
                name="paValue"
                id="formatted-numberformat-input"
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                }}
              />
            ) : (
              <>
                <Typography fontSize={14} color="text.secondary" textAlign='center'>PA</Typography>
                <Typography sx={{ fontSize: 15, fontWeight: 'bold', textAlign: 'center' }}>
                  {client.PASeguros ? formatCurrencyBRL(client.PASeguros) : '-'}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={6}>
            {isEditing ? (
              <FormControl fullWidth size='small'>
                <InputLabel id="payment-option-label">Forma de Pagamento</InputLabel>
                <Select
                  labelId="payment-option-label"
                  id="payment-option"
                  value={formaPagamentoSeguros}
                  label="Forma de Pagamento"
                  onChange={(e) => setFormaPagamentoSeguros(e.target.value as string)}
                  disabled={!isEditing}
                >
                  <MenuItem value="Mensal">Mensal</MenuItem>
                  <MenuItem value="Anual">Anual</MenuItem>
                  <MenuItem value="Único">Único</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <>
                <Typography fontSize={14} color="text.secondary" textAlign='center'>Pagamento</Typography>
                <Typography sx={{ fontSize: 15, fontWeight: 'bold', textAlign: 'center' }}>
                  {client.FormaPagamentoSeguros ? client.FormaPagamentoSeguros : '-'}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item mt={2} ml={2} m={0}>
            {isEditing && <Button color='secondary' size='small' onClick={() => setIsEditing(false)}>
              Cancelar
            </Button>}
            <LoadingButton
              sx={{ ml: 2 }}
              variant="outlined"
              size='small'
              onClick={handleEditClick}
              loading={loading}
            >
              {isEditing ? 'Salvar' : 'Editar'}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={6} alignContent='start'>
        <Grid item xs={12}>
          <Typography fontSize={12} color="text.secondary">Descrição</Typography>
          <Typography
            component='pre'
            sx={{
              fontSize: 15, fontWeight: 'bold',
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word'
            }}
          >
            {client.DescricaoSeguros ? client.DescricaoSeguros :
              <>
                <Typography sx={{ color: 'text.secondary', textAlign: 'center', opacity: 0.5, mt: 10 }}>
                  <SentimentDissatisfiedIcon sx={{ fontSize: 50, mb: 1 }} />
                  <br />
                  Nenhuma descrição inserida.
                </Typography>
              </>
            }
          </Typography>
        </Grid>
        <Grid item xs={12} mt={4}>
          <Typography fontSize={12} color="text.secondary">Configuração Familiar</Typography>
          <Typography
            component='pre'
            sx={{
              fontSize: 15, fontWeight: 'bold',
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word'
            }}
          >
            {client.ConfiguracaoFamiliar ? client.ConfiguracaoFamiliar :
              <>
                <Typography sx={{ color: 'text.secondary', textAlign: 'center', opacity: 0.5, mt: 10 }}>
                  <SentimentDissatisfiedIcon sx={{ fontSize: 50, mb: 1 }} />
                  <br />
                  Nenhuma configuração familiar inserida.
                </Typography>
              </>
            }
          </Typography>
        </Grid>

        {(client.StatusSeguros === 'CANCELADO' || client.StatusSeguros === 'PERDEU') && (
          <Grid item xs={12} mt={4}>
            <Typography fontSize={12} color="text.secondary">Cliente Perdido</Typography>
            <Typography
              component='pre'
              sx={{
                fontSize: 15, fontWeight: 'bold',
              }}
            >
              {client.MotivoRemocaoSeguros ? client.MotivoRemocaoSeguros : 'Motivo não informado'}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid >
  );
};
