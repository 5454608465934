import Box from '@mui/material/Box'
import { ClientesLista } from '../../Common/DivisionsOfClient/Clientes/ClientesListaComponent';

const LeadsPage = (props: any) => {
  return (
    <Box sx={{ pt: 3, px: 7 }}>
      <div className="content main">
        <ClientesLista />
      </div>
    </Box>
  )
}

export default LeadsPage;