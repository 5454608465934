import { Container, Paper } from "@mui/material"
import { ClientMirroringProvider } from "./context"
import { ClientsMirrorList } from "./Grid"

export const ClientMirroring = () => {
  return <>
    <ClientMirroringProvider>
      <Container maxWidth='md'>
        <Paper elevation={0} sx={{ padding: 3 }}>
          <ClientsMirrorList />
        </Paper>
      </Container>
    </ClientMirroringProvider>
  </>
}