export default class Clientes {
    constructor() {
        this.Id = ''
        this.NomeCompleto = ''
        this.CPF = ''
        this.DataNascimento = '1999-01-01'
        this.Email = ''
        this.AposentadoriaAnos = NaN
        this.EstadoCivil = ''
        this.Profissao = ''
        // this.FormaContratacao = []
        this.Sexo = ''
        this.TelefoneContatoPrimario = ''
        this.TelefoneContatoSecundario = ''
        this.CEP = ''
        this.Cidade = ''
        this.Estado = ''
        this.Endereco = ''
        this.Numero = NaN
        this.Complemento = ''
        this.Bairro = ''
        this.AssessorUsuarioId = ''
        this.Status = 'LEAD'
        this.UltimaAtividade = new Date()
        this.Preco = NaN
        this.PossuiDependentes = true
        this.PerfilInvestidor = '1'
        this.MomentoDeVida = "acumulo"
        this.PDFGerado = false
        this.Origem = ''
        this.PossuiAdministradoraBens = false
        this.CidadeNatal = ''
        this.PotencialDeInvestimento = ''
        this.Temperatura = ''
        this.Relacionamento = ''
    }

    Id?: string;
    NomeCompleto: string;
    DataNascimento: string;
    Email: string;
    AposentadoriaAnos: number;
    EstadoCivil: string;
    CPF: string;
    Profissao: string;
    Sexo: string;
    TelefoneContatoPrimario: string;
    TelefoneContatoSecundario: string;
    CEP: string;
    Cidade: string;
    Estado: string;
    Endereco: string;
    Numero: number;
    Complemento: string;
    Bairro: string;
    AssessorUsuarioId: string;
    Status: string;
    UltimaAtividade: Date;
    Preco: number;
    PossuiDependentes: boolean;
    PerfilInvestidor: string;
    MomentoDeVida: string;
    PDFGerado: boolean;
    Origem: string;
    PossuiAdministradoraBens: boolean;
    CidadeNatal: string;
    PotencialDeInvestimento: string;
    Temperatura: string;
    Relacionamento: string;

    /*
    isValid(): boolean {
        this.Errors = []

        if(this.NomeCompleto.trim() == '' || this.NomeCompleto.length < 3 || this.NomeCompleto.length > 150)
            this.Errors.push(new DefaultError('O nome completo não pode ser vazio e deve conter entre 3 e 150 caracteres', 'Nome Completo'))

        //TODO: Adicionar validação de e-mail
        if(this.Email.trim() == '' || this.Email.length < 3 || this.Email.length > 100)
            this.Errors.push(new DefaultError('O Email não pode ser vazio e deve conter entre 3 e 100 caracteres', 'Email'))

        if(this.EstadoCivil.trim() == '' || this.EstadoCivil.length > 50)
            this.Errors.push(new DefaultError('O Estado Civil não pode ser vazio e deve conter entre 3 e 50 caracteres', 'Estado Civil'))

        if(this.Profissao.trim() == '' || this.Profissao.length > 60)
            this.Errors.push(new DefaultError('O Estado Civil não pode ser vazio e deve conter entre 3 e 60 caracteres', 'Profissão'))

        if(this.CPF.trim() == '' || this.CPF.length != 14)
            this.Errors.push(new DefaultError('O não foi preenchido ou não é válido', 'CPF'))

        if(this.Sexo != 'M' && this.Sexo != 'F')
            this.Errors.push(new DefaultError('O Sexo deve ser informado', 'Sexo'))


        return this.Errors.length === 0
    }

    getErrors(): DefaultError[] {
        return this.Errors;
    }

    */
}