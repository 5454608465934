import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {  } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import NumberFormat from 'react-number-format';
import DeleteIcon from '@mui/icons-material/Delete';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumberFormatCustom = React.forwardRef<NumberFormat<any>, CustomProps>(
    function NumberFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={ref}
                prefix={'R$'}
                decimalSeparator={','}
                thousandSeparator={'.'}
                fixedDecimalScale={true}
                decimalScale={2}
                name={props.name}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
            />
        );
    },
);

const AtivosLiquidaveisComponent = ({ ativosLiquidaveis, setAtivosLiquidaveis, addAtivo, removerAtivo, thereIsAtivos, setThereIsAtivos }: any) => {

    const onChangeInput = (index: number, field: string, value: string) => {
        ativosLiquidaveis[index][field] = value
        setAtivosLiquidaveis([...ativosLiquidaveis])
    }

    const validate = (index: number, event: any) => {
        if (isNaN(Number(event.nativeEvent.data))) return;
        if (event.nativeEvent.inputType === "deleteContentBackward" || event.nativeEvent.inputType === "deleteWordBackward") {
            ativosLiquidaveis[index]['Valor'] = ""
        } else {
            let newValue = parseFloat((event.target.value.replace("R$", "").replaceAll(".", ""))).toFixed(2)
            ativosLiquidaveis[index]['Valor'] = newValue
        }
        setAtivosLiquidaveis([...ativosLiquidaveis])
    }

    const handleCurrencyAtivos = (event: any) => {
        if (Number(event.target.value) > 0) {
            ativosLiquidaveis[Number(event.target.name)].Valor = Number(event.target.value)
            setAtivosLiquidaveis([...ativosLiquidaveis])
        } else {
            ativosLiquidaveis[Number(event.target.name)].Valor = null
            setAtivosLiquidaveis([...ativosLiquidaveis])
        }
    };

    return (
        <>
            {!(ativosLiquidaveis.length == 0) ? "" :
                <Container maxWidth="md" sx={{ padding: 3, pt: 1, backgroundColor: "white", borderRadius: 2, mb: 3 }}>
                    <Box component="form" autoComplete="off"
                        sx={{ px: 5, py: 1 }}
                    >
                        <Box sx={{ width: "100%", textAlign: "center" }}>
                            <Box component={"hr"} sx={{ border: "1px solid #f0f1f2", position: "relative", top: "24px" }}></Box>
                            <Button variant="contained" size="small" color="secondary" sx={{ backgroundColor: 'secondary' }}
                                onClick={() => addAtivo()} disableElevation
                            >
                                + Adicionar ativo
                            </Button>
                        </Box>
                    </Box>
                </Container>
            }
            {ativosLiquidaveis.map((ativo: any, indexAtivo: number) => {
                return (
                    <Container key={indexAtivo} maxWidth="md" sx={{ padding: 3, backgroundColor: "white", borderRadius: 2, mb: 3 }}>
                        <Box component="form" autoComplete="off"
                            sx={{ px: 5, py: 1 }}
                        >
                            <Grid container columnSpacing={3} rowSpacing={1}>
                                <>
                                    <Grid item xs={12}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <Typography variant="h5" mb={2}>
                                                    Ativo financeiro {indexAtivo + 1}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Button onClick={() => removerAtivo(indexAtivo)} size="small" variant="contained" sx={{ backgroundColor: "error.main", fontWeight: "500" }} startIcon={<DeleteIcon />}>
                                                    Remover Ativo {indexAtivo + 1}
                                                </Button>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth size="small" margin="normal">
                                            <InputLabel id="demo-select-small">Ativo</InputLabel>
                                            <Select
                                                labelId="demo-select-small"
                                                id="demo-simple-select"
                                                onChange={(event) => onChangeInput(indexAtivo, 'Ativo', event.target.value)}
                                                value={ativo.Ativo}
                                                label="Ativo"

                                            >
                                                <MenuItem value="Carteira de Investimentos">Carteira de Investimentos</MenuItem>
                                                <MenuItem value="Liquidez">Liquidez</MenuItem>
                                                <MenuItem value="Seguro de Vida">Seguro de vida</MenuItem>
                                                <MenuItem value="Previdência PGBL">Previdência PGBL</MenuItem>
                                                <MenuItem value="Previdência VGBL">Previdência VGBL</MenuItem>
                                                <MenuItem value="FGTS">FGTS</MenuItem>
                                                <MenuItem value="Outros">Outros</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Valor" required fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
                                            value={ativo.Valor}
                                            name={String(indexAtivo)}
                                            onChange={handleCurrencyAtivos}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom as any,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Comentario" fullWidth id="outlined-basic" variant="outlined" size="small" margin="normal"
                                            value={ativo.Comentario}
                                            onChange={(event) => onChangeInput(indexAtivo, 'Comentario', event.target.value)}
                                        />
                                    </Grid>
                                </>
                            </Grid>

                            {!(indexAtivo === ativosLiquidaveis.length - 1) ? "" :
                                <Box sx={{ width: "100%", textAlign: "center", mt: "30px" }}>
                                    <Box component={"hr"} sx={{ border: "1px solid #f0f1f2", position: "relative", top: "24px" }}></Box>
                                    <Button variant="contained" size="small" color="secondary" sx={{ backgroundColor: 'secondary' }}
                                        onClick={() => addAtivo()} disableElevation
                                    >
                                        + Adicionar outro ativo
                                    </Button>
                                </Box>
                            }


                        </Box>
                    </Container>
                )
            })}


            {/* <div className="row">
                <div className="col s12">
                    {
                        ativosLiquidaveis?.map((planejamento: ClientesAtivosEBens, index: number) => {
                            return <>
                                <form className="container-steps">
                                    <div className="row" key={index}>
                                        <div className="header">
                                            <div className="col s8">
                                                <h2>Ativo financeiro {index + 1}</h2>
                                            </div>
                                            <div className="col s4 right-align">
                                                <a className="btn danger btn-small" onClick={() => removerAtivo(index)}>Remover Ativo {index + 1}</a>
                                            </div>
                                        </div>

                                        <div className="input-field col s6">
                                            <select
                                                onChange={(event) => onChangeInput(index, 'Ativo', event.target.value)} value={planejamento.Ativo}
                                                name="ativos"
                                            >
                                                <option value="" disabled selected>Selecione</option>
                                                <option value="Carteira de Investimentos">Carteira de Investimentos</option>
                                                <option value="Liquidez">Liquidez</option>
                                                <option value="Seguro de Vida">Seguro de vida</option>
                                                <option value="Previdência PGBL">Previdência PGBL</option>
                                                <option value="Previdência VGBL">Previdência VGBL</option>
                                                <option value="FGTS">FGTS</option>
                                                <option value="Outros">Outros</option>
                                            </select>
                                            <label className="select">Ativo *</label>
                                        </div>
                                        <div className="input-field col s6">
                                            <CurrencyInput
                                                id="Valor"
                                                name="Valor"
                                                value={planejamento.Valor}
                                                groupSeparator="."
                                                decimalSeparator=","
                                                prefix="R$"
                                                onChange={(event) => validate(index, event)}
                                                autoComplete="off"
                                            />
                                            <label htmlFor="Valor">Valor *</label>
                                        </div>

                                        <div className="input-field col s12">
                                            <input type="text" id="Comentario" autoComplete="off" name="Comentario" onChange={(event) => onChangeInput(index, 'Comentario', event.target.value)}
                                                value={planejamento.Comentario}></input>
                                            <label htmlFor="Comentario">Comentário</label>
                                        </div>
                                    </div>
                                    {!(index === ativosLiquidaveis.length - 1) ? "" :
                                        <div className="row">
                                            <div className="submit">
                                                <a className="btn primary col s6 l3 text-bold space-down-up" onClick={() => addAtivo()}>
                                                    + Adicionar outro ativo
                                                </a>
                                            </div>
                                        </div>
                                    }
                                </form>
                            </>
                        })
                    }
                    {ativosLiquidaveis.length > 0 ? "" :
                        <form>
                            <div className="row">
                                <div className="submit">
                                    <a className="btn primary col s6 l4 offset-l4 text-bold space-down-up center-all" onClick={() => addAtivo()}>
                                        + Adicionar ativo
                                    </a>
                                </div>
                            </div>
                        </form>
                    }
                </div>
            </div> */}
        </>
    )
}

export default AtivosLiquidaveisComponent;