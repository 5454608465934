import ClientesService from "../../../Services/ClientesService";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';

const Dependentes = ({ dependentes, setDependentes, cliente, setCliente }: any) => {
    const clientesService = new ClientesService()

    const onChangeDependentes = (index: number, field: string, event: any) => {
        dependentes[index][field] = event
        setDependentes([...dependentes])
    }

    const removeDependente = (index: number) => {
        if (dependentes[index].hasOwnProperty("Id") && dependentes[index].Id != "") {
            clientesService.DeletarDependente(dependentes[index].Id)
                .then((e) => { })
                .catch((e) => {
                })
        }

        dependentes.splice(index, 1)
        setDependentes([...dependentes])
    }

    const addDependente = () => {
        dependentes.push({ NomeCompleto: "", DataNascimento: new Date(), Parentesco: "", ClienteId: cliente.Id, Idade: null, Id: '' })
        setDependentes([...dependentes])
    }

    const changeThereIsDependentes = () => {
        cliente.PossuiDependentes = !cliente.PossuiDependentes
        setCliente({ ...cliente })

        clientesService.UpdatePossuiDependentes(cliente.Id, cliente.PossuiDependentes)
            .then(() => {

            })
            .catch(() => {
            })
    }

    const styleListOfInputs = {
        width: "100%", height: "40px",
        border: 1, borderColor: "#e6e6e6",
        fontSize: "1rem", pl: "10px", "&:focus-visible": { outline: '0px' },
    }

    const SelectInputBorderRight = styled(InputBase)(({ theme }) => ({
        '& .MuiInputBase-input': {
            //   backgroundColor: theme.palette.background.paper,
            border: '1px solid #c4c4c4ab',
            borderRadius: "0px 5px 5px 0px",
            padding: '7px 26px 7px 12px',
        },
    }));

    return <>
        {/* Dependentes */}
        <Container maxWidth="md" sx={{ padding: 3, backgroundColor: "white", borderRadius: 2, mb: 3 }}>
            <Box component="form"
                sx={{ px: 5, py: 1 }}
                noValidate
                autoComplete="off"
            >
                <Typography variant="h5" mb={2}>
                    Dependentes
                </Typography>
                <FormControlLabel
                    label="Se o seu cliente não possuir dependentes, clique aqui para desativar."
                    labelPlacement="start"
                    control={
                        <Switch
                            checked={cliente.PossuiDependentes} onClick={() => { changeThereIsDependentes() }}
                        />
                    }
                />
                {!cliente.PossuiDependentes ? "" : (
                    <>
                        <Grid container sx={{ mt: 3, mb: 2 }}>
                            <Grid item xs={6} sx={{ textAlign: "center" }}>
                                <Typography variant="h6">
                                    Nome
                                </Typography>
                            </Grid>
                            <Grid item xs={3} sx={{ textAlign: "center" }}>
                                <Typography variant="h6">
                                    Idade
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: "center" }}>
                                <Typography variant="h6">
                                    Parentesco
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* <Grid container>
                            <Grid item xs={6} sx={{ textAlign: "center" }}>
                                
                            </Grid>
                            <Grid item xs={3} sx={{ textAlign: "center" }}>
                                
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: "center", pl: "30px" }}>
                                
                            </Grid>
                        </Grid> */}

                        {dependentes.map((dependente: any, indexDependente: number) => {
                            return <Grid container key={indexDependente}>
                                <Grid item xs={6} sx={{ pl: 0 }}>
                                    <FormControl fullWidth>
                                        <InputBase
                                            fullWidth
                                            // name={String(indexAtivo)}
                                            autoFocus={false}
                                            sx={{
                                                height: "36px",
                                                width: "100%",
                                                borderLeft: 0,
                                                borderRight: 0,
                                                border: '1px solid #c4c4c4ab',
                                                borderRadius: "5px 0px 0px 5px",
                                                padding: '5px 26px 5px 12px',
                                            }}
                                            value={dependente.NomeCompleto}
                                            onChange={(event: any) => onChangeDependentes(indexDependente, "NomeCompleto", event.target.value)}
                                        // inputComponent={NumberFormatCustom as any}
                                        // onChange={handleCurrencyAtivos}
                                        />

                                    </FormControl>
                                    {/* <Box component={"input"}
                                            value={dependente.NomeCompleto}
                                            onChange={(event: any) => onChangeDependentes(indexDependente, "NomeCompleto", event.target.value)}
                                            sx={
                                                { ...styleListOfInputs }
                                            } /> */}
                                </Grid>
                                <Grid item xs={3}>
                                    <InputBase
                                        fullWidth
                                        // name={String(indexAtivo)}
                                        autoFocus={false}
                                        value={dependente.Idade}
                                        type="number"
                                        onChange={(event: any) => onChangeDependentes(indexDependente, "Idade", Number(event.target.value))}
                                        sx={{
                                            height: "36px",
                                            width: "100%",
                                            borderLeft: 0,
                                            borderRight: 0,
                                            border: '1px solid #c4c4c4ab',
                                            borderRadius: "none",
                                            padding: '5px 26px 5px 12px',
                                        }}
                                    // value={ativo.Valor}
                                    // inputComponent={NumberFormatCustom as any}
                                    // onChange={handleCurrencyAtivos}
                                    />
                                    {/* <Box component={"input"}
                                            value={dependente.Idade}
                                            onChange={(event: any) => onChangeDependentes(indexDependente, "Idade", event.target.value)}
                                            sx={
                                                { ...styleListOfInputs }
                                            } /> */}
                                </Grid>
                                <Grid item xs={2}>
                                    <Select
                                        onChange={(event) => onChangeDependentes(indexDependente, 'Parentesco', event.target.value)}
                                        value={dependente.Parentesco || ""}
                                        input={<SelectInputBorderRight />}
                                        fullWidth size="small" margin="none"
                                        defaultValue=""

                                    >
                                        <MenuItem disabled key="Selecione" value="Selecione..."><em>Selecione...</em></MenuItem>
                                        <MenuItem key="Mãe" value="Mãe">Mãe</MenuItem>
                                        <MenuItem key="Pai" value="Pai">Pai</MenuItem>
                                        <MenuItem key="Irmão(a)" value="Irmão(a)">Irmão(a)</MenuItem>
                                        <MenuItem key="Filho(a)" value="Filho(a)">Filho(a)</MenuItem>
                                        <MenuItem key="Esposo(a)" value="Esposo(a)">Esposo(a)</MenuItem>
                                        <MenuItem key="Avô / Avó" value="Avô / Avó">Avô / Avó</MenuItem>
                                        <MenuItem key="Outros" value="Outros">Outros</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton aria-label="Example" sx={{ ml: "20px", position: "relative" }}>
                                        <DeleteForeverIcon sx={{ color: "#512178" }}
                                            onClick={() => removeDependente(indexDependente)} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        })}
                        <Box sx={{ width: "100%", textAlign: "center", mt: "30px" }}>
                            <Box component={"hr"} sx={{ border: "1px solid #f0f1f2", position: "relative", top: "24px" }}></Box>
                            <Button variant="contained" size="small" color="secondary" sx={{ backgroundColor: 'secondary' }}
                                onClick={() => addDependente()}
                            >
                                + Adicionar Dependente
                            </Button>
                        </Box>
                    </>
                )

                }

            </Box>
        </Container>

        {/* <form className="container-steps row">
            <div className="header">
                <h2>Dependentes</h2>
                <div className="switch">
                    Se o seu cliente não possuir dependentes, clique aqui para desativar.
                    <label>
                        <input type="checkbox" name="independencia" checked={cliente.PossuiDependentes} onClick={() => { changeThereIsDependentes() }} />
                        <span className="lever"></span>
                    </label>
                </div>
            </div>
            <div className="space-bottom"></div>
            <div className="expenses-dependent">
                <div className={!cliente.PossuiDependentes ? "col s12 hide" : ""}>
                    <div className="col s12 header-expenses">
                        <h4 className="col s6 center-align">Nome</h4>
                        <h4 className="col s3 center-align">Idade</h4>
                        <h4 className="col s2 center-align">Parentesco</h4>
                    </div>

                    {dependentes.map((dependente: any, indexDependente: number) => {
                        return (
                            !(true) ?
                                <></>
                                :
                                <div>
                                    <div className="col s6 input-expenses-dependent">
                                        <input onChange={(event) => onChangeDependentes(indexDependente, "NomeCompleto", event.target.value)} value={dependente.NomeCompleto}
                                            placeholder={indexDependente === 0 ? "Ex: Nome Completo" : ""} id="parentesco" type="text" name="parentesco" autoComplete="off" />
                                    </div>
                                    <div className="col s3 input-expenses-dependent">
                                        <input onChange={(event) => onChangeDependentes(indexDependente, "Idade", event.target.value)} value={dependente.Idade}
                                            placeholder={indexDependente === 0 ? "Ex: 14" : ""} id="parentesco" type="number" name="parentesco" autoComplete="off" />
                                    </div>
                                    <div className="col s2 input-expenses-dependent">
                                        <select
                                            onChange={(event) => onChangeDependentes(indexDependente, 'Parentesco', event.target.value)} value={dependente.Parentesco}
                                            name="parentescos"
                                        >
                                            <option value="" disabled selected>Selecione</option>
                                            <option value="Mãe">Mãe</option>
                                            <option value="Pai">Pai</option>
                                            <option value="Irmão(a)">Irmão(a)</option>
                                            <option value="Filho(a)">Filho(a)</option>
                                            <option value="Esposo(a)">Esposo(a)</option>
                                            <option value="Avô / Avó">Avô / Avó</option>
                                            <option value="Outros">Outros</option>
                                        </select>
                                    </div>
                                    <a className="col s1 btn-hover">
                                        <i onClick={() => removeDependente(indexDependente)} className="material-icons delete-expense">delete_forever</i>
                                    </a>
                                </div>
                        )
                    })}

                    <div className="col s12 button-add-expense">
                        <hr className="col s3 m4 xl5 simulated-calc" />
                        <a className="btn col s6 m4 xl2 text-bold" onClick={() => addDependente()}>
                            + Adicionar Dependente
                        </a>
                        <hr className="col s3 m4 xl5 simulated-calc" />
                    </div>
                </div>
            </div>
        </form> */}
    </>
}

export default Dependentes