// import Leads from "../../Common/DivisionsOfClient/Leads/LeadsComponent"
import Box from '@mui/material/Box';
import { useState } from 'react';
import { useTasks } from '../../../Hooks/useTasks';
import TaskTable from '../../Common/Tasks/TasksTable';
import { TaskDialog } from '../../Common/Tasks/TaskDialog';
import { ICreateTask, IUpdateTask, interfaceTask } from '../../../Interface/interfaceTask';
import { Button, Checkbox, InputLabel, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import { CreateTaskForm } from '../../Common/Tasks/CreateTaskForm';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';

const TasksListPage = () => {
  const { loading, fetchTask, addCommentToTask, createTask,
    assignUsers, updateTask, deleteTask, updateStatusTask,
    tasksFiltered, handleShowTasksByStatus, tasksStatusToShow,
    handleUserFilterChange, taskUserFilter
  } = useTasks();

  const [open, setOpen] = useState(false);
  const [task, setTask] = useState<interfaceTask>();

  const [openCreateTaskForm, setOpenCreateTaskForm] = useState(false);

  const handleCreateTask = (task: ICreateTask) => {
    setLoadingTask(true);
    createTask(task)
      .then(v => {
        if (v) {
          setOpenCreateTaskForm(false);
          handleClickOpen(v.Id)
        }
      })
      .catch()
      .finally(() => setLoadingTask(false));
  }

  const handleUpdateTask = (task: IUpdateTask, handleCancelEditing: any) => {
    setLoadingTask(true);
    updateTask(task)
      .then(v => {
        if (v) {
          setTask({ ...v });
        }
      })
      .catch()
      .finally(() => {
        setLoadingTask(false);
        handleCancelEditing();
      });
  }

  const handleDeleteTask = (taskId: string) => {
    setLoadingTask(true);
    setOpen(false);
    deleteTask(taskId)
      .catch()
      .finally(() => setLoadingTask(false));
  }

  const handleUpdateStatusTask = (taskId: string, status: number) => {
    setLoadingTask(true);
    updateStatusTask(taskId, status)
      .then(v => {
        if (v) {
          setTask({ ...v });
        }
      })
      .catch()
      .finally(() => setLoadingTask(false));
  }

  const addComment = async (taskId: string, texto: string) => {
    return addCommentToTask(taskId, texto)
      .then(v => {
        setTask(v!)
      })
      .catch()
  }

  const [loadingTask, setLoadingTask] = useState(false);
  const handleClickOpen = (idTask: string) => {
    setLoadingTask(true);
    fetchTask(idTask)
      .then(v => {
        setTask(v);
        setOpen(true);
      })
      .catch()
      .finally(() => setLoadingTask(false));
  };

  const handleClose = () => {
    setOpen(false);
    setOpenCreateTaskForm(false);
  };

  const userFilterLabels = {
    'created': 'Criadas por mim',
    'assigned': 'Atribuídas a mim',
  };

  return (
    <Box sx={{ pt: 3, px: 7 }}>
      <Typography variant="h2" sx={{ mt: 3, mb: 5 }}>Tarefas</Typography>
      <div className="content main">
        <Box sx={{
          width: '100%',
          backgroundColor: "#ffffff",
          borderRadius: 1,
          p: 3
        }}>
          <Box sx={{ mb: 4 }}>
            <FormControl size='small'>
              <InputLabel id="filter-by-status-label">Filtrar por status:</InputLabel>
              <Select
                sx={{ width: 250 }}
                labelId="filter-by-status-label"
                label="Filtrar por status:"
                id="filter-by-status"
                size='small'
                multiple
                value={tasksStatusToShow}
                onChange={(event) => handleShowTasksByStatus(event.target.value as (0 | 1 | 2)[])}
                renderValue={(selected) => {
                  const options = ['Em aberto e atrasadas', 'Concluídas', 'Canceladas'];
                  return (selected as number[]).map((value) => options[value]).join(', ');
                }}
              >
                {['Em aberto e atrasadas', 'Concluídas', 'Canceladas'].map((option, index: any) => (
                  <MenuItem key={index} value={index}>
                    <Checkbox checked={tasksStatusToShow.indexOf(index) > -1} />
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ ml: 2, minWidth: 200 }} size='small'>
              <InputLabel id="user-task-filter-label">Filtrar por criador:</InputLabel>
              <Select
                labelId="user-task-filter-label"
                id="user-task-filter"
                multiple
                value={taskUserFilter}
                onChange={(event) => handleUserFilterChange(event.target.value as ('created' | 'assigned')[])}
                renderValue={(selected) => selected.map(value => userFilterLabels[value]).join(', ')}
                label="Filtrar por criador:"
                size='small'
              >
                {Object.entries(userFilterLabels).map(([value, label]) => (
                  <MenuItem key={value} value={value as 'created' | 'assigned'}>
                    <Checkbox checked={taskUserFilter.includes(value as 'created' | 'assigned')} />
                    <ListItemText primary={label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <TaskTable tasks={tasksFiltered} loading={loading} openDialogTask={handleClickOpen} />
          <Button
            variant='contained'
            onClick={() => setOpenCreateTaskForm(true)}
            disableElevation
          >
            Criar nova tarefa
          </Button>
        </Box>
      </div>
      {task && task.Id &&
        <TaskDialog
          open={open}
          onClose={handleClose}
          task={task}
          addCommentToTask={addComment}
          deleteTask={handleDeleteTask}
          updateTask={handleUpdateTask}
          updateStatusTask={handleUpdateStatusTask}
        />
      }

      <CreateTaskForm open={openCreateTaskForm} onClose={handleClose} createTask={handleCreateTask} assignUsers={assignUsers} />

      <Backdrop
        sx={{ color: '#fff', zIndex: 10000 }}
        open={loadingTask}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>

  )
}

export default TasksListPage;