import * as React from 'react';
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Divider } from '@mui/material';
import { MdPersonAddAlt } from 'react-icons/md';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ClientesService from '../../../Services/ClientesService';
import StorageHelper from '../../../Services/Helpers/StorageHelper';
import { useNavigate } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { ORIGINS, interfaceClient } from '../../../Interface/interfaceClient';
import { enqueueSnackbar } from 'notistack';
import { NumberFormatCustom } from '../../../Helpers/Components';

export const DialogNovoLeadComponent = (params: any) => {
  const clientesService = new ClientesService
  const storageHelper = new StorageHelper
  let navigate = useNavigate();

  const open = params.open
  const onClose = params.onClose

  // Type of contact
  const [contatoTipo, setContatoTipo] = useState("movel")
  const [maskContato, setMaskContato] = useState("")
  const onChangeContatoTipo = (value: string) => {
    setContatoTipo(value)
  }

  // Backdrops
  const [openSuccessAddLead, setOpenSuccessAddLead] = useState(false)
  const handleCloseAllBackdrops = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccessAddLead(false);
  };

  useEffect(() => {
    if (contatoTipo === "fixo") {
      setMaskContato("(99) 9999-9999")
      setContatoTipo("fixo")
    } else if (contatoTipo === "movel") {
      setMaskContato("(99) 99999-9999")
      setContatoTipo("movel")
    }
  }, [contatoTipo])

  const [lead, setLead] = useState<interfaceClient>({
    NomeCompleto: null,
    CPF: null,
    DataNascimento: null,
    Email: null,
    AposentadoriaAnos: null,
    EstadoCivil: null,
    Profissao: null,
    Sexo: null,
    TelefoneContatoPrimario: null,
    TelefoneContatoSecundario: null,
    Status: "LEAD",
    UltimaAtividade: new Date(),
    AssessorUsuarioId: null,
    Preco: null,
    CEP: null,
    Cidade: null,
    Estado: null,
    Bairro: null,
    Endereco: null,
    Numero: null,
    Complemento: null,
    PDFGerado: null,
    PossuiDependentes: null,
    PerfilInvestidor: null,
    MomentoDeVida: null,
    Relacionamento: null,
    Temperatura: null,
    PotencialDeInvestimento: null,
    Origem: null,
    CidadeNatal: null,
    PossuiAdministradoraBens: null,
    DescricaoAdministradoraDeBens: null,
    CodigoCliente: null,
    SubStatus: null,
    MotivoDeGanho: null,
    MotivoDePerda: null,
    MotivoDePausa: null,
    Bio: null,
    Idade: null,
    OrigemMarketing: null,
    FeeFixo: false
  })

  const [loadingAddLead, setLoadingAddLead] = useState(false)

  const addLead = () => {
    lead.AssessorUsuarioId = storageHelper.GetUsuarioId();
    lead.CriadoPor = storageHelper.GetUsuarioId();
    lead.UltimaAtividade = new Date();
    setLead({ ...lead });

    setLoadingAddLead(true);

    clientesService.InserirCliente(lead)
      .then((value) => {
        setOpenSuccessAddLead(true)
        onClose();

        navigate(`/app/perfil-cliente/${value.Id}`, { replace: true });
        window.location.reload();
      })
      .catch((error) => {
        enqueueSnackbar('Ops... tivemos um problema.', { variant: 'error' });
        console.error(error)
      })
      .finally(() => {
        setLoadingAddLead(false)
      })
  }

  const handleChangeFaixaDeAtendimento = (event: SelectChangeEvent) => {
    lead.PotencialDeInvestimento = event.target.value
    setLead({ ...lead });
  };

  const formatPhoneNumber = (value: string) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2)}`;
    }
    if (contatoTipo === "fixo") {
      return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2, 6)}-${phoneNumber.slice(6, 10)}`;
    }
    return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2, 7)}-${phoneNumber.slice(7, 11)}`;
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    setLead({ ...lead, TelefoneContatoPrimario: formattedPhoneNumber });
  };

  const handleProfissaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valor = event.target.value;
    if (valor.length <= 50) {
      setLead({ ...lead, Profissao: valor });
    }
  };

  return (
    <>
      <Dialog open={open} onClose={() => onClose()} maxWidth="md">
        <DialogTitle sx={{ pt: 5, px: 5, pb: 2, fontSize: 20 }}>Criar novo Lead</DialogTitle>
        <DialogContent sx={{ padding: 5 }}>
          <Divider sx={{ mb: 2, borderColor: "#b2b1c330" }} />
          <DialogContentText sx={{ mb: 5 }}>
            <Typography variant="body1">
              Adicione as informações do seu possível cliente de forma
              rápida aqui. O cadastro deste lead estará disponível no menu da esquerda
              da plataforma de acordo com a fase de negócio que você selecionar.
            </Typography>
          </DialogContentText>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={6}>
              <TextField
                autoFocus
                fullWidth
                label="Nome completo"
                required
                size="small"
                variant="outlined"
                autoComplete="off"
                value={lead.NomeCompleto || ""}
                onChange={(event) => {
                  lead.NomeCompleto = event.target.value
                  setLead({ ...lead })
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Email"
                size="small"
                variant="outlined"
                autoComplete="off"
                value={lead.Email || ""}
                onChange={(event) => {
                  lead.Email = event.target.value
                  setLead({ ...lead })
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth size="small" >
                <InputLabel id="demo-select-small">Tipo de Contato</InputLabel>
                <Select
                  labelId="demo-select-small"
                  label="Tipo de Contato"
                  onChange={(event) => onChangeContatoTipo(event.target.value)}
                  value={contatoTipo}
                >
                  <MenuItem value={"fixo"}>Tel. Fixo</MenuItem>
                  <MenuItem value={"movel"}>Celular</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Número"
                size="small"
                variant="outlined"
                autoComplete="off"
                value={lead.TelefoneContatoPrimario || ""}
                onChange={handlePhoneChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small" >
                <InputLabel id="demo-select-small">Temperatura</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-simple-select"
                  label="Temperatura"
                  value={lead.Temperatura || ""}
                  onChange={(event: any) => {
                    lead.Temperatura = event.target.value
                    setLead({ ...lead })
                  }}
                >
                  <MenuItem value="Quente">Quente</MenuItem>
                  <MenuItem value="Morno">Morno</MenuItem>
                  <MenuItem value="Frio">Frio</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="profissao"
                fullWidth
                label="Profissão"
                size="small"
                variant="outlined"
                autoComplete="off"
                helperText={lead.Profissao?.length === 50 ? "A profissão deve ter no máximo 50 caracteres." : ""}
                value={lead.Profissao || ""}
                onChange={handleProfissaoChange}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="origem">Origem</InputLabel>
                <Select
                  labelId="origem"
                  label="Origem"
                  value={lead.Origem || ""}
                  onChange={(event: any) => {
                    lead.Origem = event.target.value;
                    setLead({ ...lead });
                  }}
                >
                  {ORIGINS.map((origim) => (
                    <MenuItem key={origim.value} value={origim.value}>
                      {origim.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label='PL'
                fullWidth
                size='small'
                variant='outlined'
                autoComplete='off'
                value={lead.Preco}
                onChange={(event) => {
                  lead.Preco = Number(event.target.value);
                  setLead({ ...lead });
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                }}
              />
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="faixa-de-atendimento">Faixa de atendimento</InputLabel>
                <Select
                  labelId="faixa-de-atendimento"
                  label="Faixa de atendimento"
                  value={lead.PotencialDeInvestimento || ""}
                  onChange={handleChangeFaixaDeAtendimento}
                >
                  <MenuItem value="Assessoria Digital (Até 300k)">Assessoria Digital (Até 300k)</MenuItem>
                  <MenuItem value="Assessoria Exclusive (300k - 1M)">Assessoria Exclusive (300k - 1M)</MenuItem>
                  <MenuItem value="Assessoria Premium (1M - 3M)">Assessoria Premium (1M - 3M)</MenuItem>
                  <MenuItem value="Assessoria Private (A partir de 3M)">Assessoria Private (A partir de 3M)</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
            {/* <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small" >
                <InputLabel id="demo-select-small">Fase de negócio</InputLabel>
                <Select
                  labelId="demo-select-small"
                  label="Fase de negócio"
                  value={lead.Status || ""}
                  onChange={(event: any) => {
                    lead.Status = event.target.value
                    setLead({ ...lead })
                  }}
                >
                  <MenuItem value="LEAD">Lead</MenuItem>
                  <MenuItem value="R1">R1</MenuItem>
                  <MenuItem value="OTHERS_MEETING">R2</MenuItem>
                  <MenuItem value="WAITING_CLIENT">Aguardando Habilitação</MenuItem>
                  <MenuItem value="CLIENTE">Cliente</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
            <Grid item xs={12} md={12} >
              <TextField
                fullWidth
                id="outlined-multiline-static"
                multiline
                label="Observações (bio do lead)"
                rows={3}
                value={lead.Bio || ""}
                onChange={(event: any) => {
                  lead.Bio = event.target.value
                  setLead({ ...lead })
                }}
              />
            </Grid>
            <Grid item xs={12} textAlign="center" mt={3}>
              <LoadingButton loading={loadingAddLead} onClick={() => addLead()} startIcon={<MdPersonAddAlt style={{ color: "#ffffff" }} />} variant="contained" color="secondary" sx={{ width: 150 }}>
                Criar Lead
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog >

      <Snackbar open={openSuccessAddLead} autoHideDuration={6000} onClose={handleCloseAllBackdrops}>
        <Alert onClose={handleCloseAllBackdrops} severity="success" sx={{ fontSize: 14, boxShadow: 3 }}>
          Lead adicionado com sucesso!
        </Alert>
      </Snackbar>
    </>
  )
}