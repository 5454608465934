import React from 'react';
import { Typography, Box } from '@mui/material';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOffOutlined';

interface NoContentProps {
  message: string;
}

export const NoContent: React.FC<NoContentProps> = ({ message }) => {
  return (
    <Box sx={{
      display: 'flex', alignItems: 'center', backgroundColor: '#51217806', padding: 3, margin: 4,
      justifyContent: 'center', height: '100%', borderRadius: 3
    }}>
      <SpeakerNotesOffIcon fontSize="medium" style={{ color: '#51217890', marginRight: 10 }} />
      <Typography variant="h5" component="h2" color='#51217890'>
        {message}
      </Typography>
    </Box>
  );
};