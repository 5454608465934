import React, { useState } from 'react';
import { Container, Box, Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { AdvisorCambioProvider } from '../../../Hooks/useAdvisorCambioContext';
import { AdvisorConsorciosKanban } from '../../Common/KanbanAdvisor/AdvisorConsorciosKanban';
import { AdvisorConsorciosProvider } from '../../../Hooks/useAdvisorConsorcioContext';
import { AdvisorCambioKanban } from '../../Common/KanbanAdvisor/AdvisorCambioKanban';
import { AdvisorCreditoProvider } from '../../../Hooks/useAdvisorCreditoContext';
import { AdvisorCreditoKanban } from '../../Common/KanbanAdvisor/AdvisorCreditoKanban';
import { AdvisorSegurosProvider } from '../../../Hooks/useAdvisorSeguroContext';
import { AdvisorSeguroKanban } from '../../Common/KanbanAdvisor/AdvisorSeguroKanban';

export const AdvisorKanbanPage: React.FC = () => {
  const [kanbanType, setKanbanType] = useState<'cambio' | 'consorcio' | 'credito' | 'seguro'>('cambio');

  return (
    <Container maxWidth='xl'>
      <Box my={2} textAlign='center'>
        <Typography variant='h4' my={1}>
          Selecione um funil para visualização
        </Typography>
        <ToggleButtonGroup
          value={kanbanType}
          exclusive
          onChange={(e, value) => { value && setKanbanType(value) }}
          aria-label='Kanban Type'
        >
          <ToggleButton value='cambio' aria-label='Câmbio'>
            Câmbio
          </ToggleButton>
          <ToggleButton value='consorcio' aria-label='Consórcio'>
            Consórcio
          </ToggleButton>
          <ToggleButton value='credito' aria-label='Crédito'>
            Crédito
          </ToggleButton>
          <ToggleButton value='seguro' aria-label='Seguro'>
            Seguro
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {kanbanType === 'cambio' && <AdvisorCambioProvider>
        <AdvisorCambioKanban />
      </AdvisorCambioProvider>}

      {kanbanType === 'consorcio' && <AdvisorConsorciosProvider>
        <AdvisorConsorciosKanban />
      </AdvisorConsorciosProvider>}

      {kanbanType === 'credito' && <AdvisorCreditoProvider>
        <AdvisorCreditoKanban />
      </AdvisorCreditoProvider>}

      {kanbanType === 'seguro' && <AdvisorSegurosProvider>
        <AdvisorSeguroKanban />
      </AdvisorSegurosProvider>}
    </Container>
  );
};
