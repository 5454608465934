import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography'

export const DialogTarefaConcluidaComponent = (params: any) => {
  const open = params.open
  const setOpen = params.setOpen
  const handleStatus = params.handleStatus

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs">
        <DialogTitle variant="h3" sx={{ pt: 5, px: 5, pb: 2 }}>Confirmação</DialogTitle>
        <DialogContent sx={{ padding: 5 }}>
          <Divider sx={{ mb: 2, borderColor: "#b2b1c330" }} />
          <DialogContentText sx={{ mb: 5 }}>
            <Typography variant="body1">
              Tem certeza que deseja marcar a atividade como <span style={{ fontWeight: "bold" }}>concluída</span>?
            </Typography>
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={6} textAlign="center" mt={3}>
              <Button variant="text"
                onClick={() => setOpen(false)}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6} textAlign="center" mt={3}>
              <Button variant="contained" color="success"
                onClick={() => {
                  setOpen(false)
                  handleStatus("FINISHED")
                }}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}