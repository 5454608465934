import { Link, Navigate } from "react-router-dom"
import LoginService from "../../../Services/LoginService";
import { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

const LoginComponent = () => {
  const loginService = new LoginService();
  const [loading, setLoading] = useState<boolean>(false)
  const [loginData, setLoginData] = useState({
    Email: '',
    Senha: ''
  })

  const [erro, setErro] = useState("")
  const [loginValido, setLoginValido] = useState(false)
  const [invalidArguments, setInvalidArguments] = useState({ validEmail: false, validPassword: false })

  const onChangeLogin = (value: any) => {
    setLoginData({ ...loginData, Email: value.target.value })
  }

  const onChangeSenha = (value: any) => {
    setLoginData({ ...loginData, Senha: value.target.value })
  }

  const realizarLogin = () => {
    setErro('');
    setLoading(true)

    // Verify email and password valid
    // Email
    const vryEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailValid = vryEmail.test(String(loginData.Email).toLowerCase());
    // Password
    const vryPassword = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    const passwordValid = vryPassword.test(String(loginData.Senha));
    // Checking email and password
    if (!emailValid || !passwordValid) {
      if (!emailValid && !passwordValid) {
        setErro("Email e senha inválidos.")
        setInvalidArguments({ validEmail: true, validPassword: true })
      } else if (!emailValid) {
        setErro("Email inválido.")
        setInvalidArguments({ validEmail: true, validPassword: false })
      } else if (!passwordValid) {
        setErro('A senha deve conter no mínimo oito caracteres, pelo menos uma letra, um número e um caractere especial.')
        setInvalidArguments({ validEmail: false, validPassword: true })
      }
      setLoading(false)
    } else {
      setInvalidArguments({ validEmail: false, validPassword: false })
      loginService
        .Login(loginData)
        .then(() => { setLoginValido(true); })
        .catch((erro: Error) => setErro("Cadastro inválido."))
        .finally(() => setLoading(false));
    }
  }

  const [showPassword, setShowPassword] = useState(false)

  const handleChange = (prop: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowPassword(!showPassword);
  };


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  // useEffect(() => {
  //   const loginObject = JSON.parse(localStorage.getItem('login') || '{}');

  //   const dateExpire = loginObject.DataExpiracao ? new Date(loginObject.DataExpiracao) : null;
  //   const currentDate = new Date();

  //   if (loginObject.JWT && dateExpire && currentDate < dateExpire) {
  //     setLoginValido(true);
  //   } else {
  //     setLoginValido(false);
  //   }
  // }, []);

  if (loginValido) return <Navigate to="/app/dashboard-pbi" />;

  return (
    <>
      <TextField label="Email" fullWidth id="outlined-basic" variant="outlined" margin="normal"
        sx={{ mt: 4 }}
        error={invalidArguments.validEmail}
        helperText={invalidArguments.validEmail ? "Email inválido." : ""}
        onChange={(target) => onChangeLogin(target)} onKeyDown={(e) => { if (e.key === "Enter") realizarLogin() }}
        value={loginData.Email}
      />
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
        <OutlinedInput label="Senha" fullWidth id="outlined-basic"
          error={invalidArguments.validPassword}
          type={showPassword ? 'text' : 'password'}
          onChange={(target) => onChangeSenha(target)} onKeyDown={(e) => { if (e.key === "Enter") realizarLogin() }}
          value={loginData.Senha}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText id="component-helper-text">
          {invalidArguments.validPassword ? "A senha deve conter no mínimo oito caracteres, pelo menos uma letra, um número e um caractere especial." : ""}
        </FormHelperText>
      </FormControl>
      {
        erro == "Cadastro inválido." ?
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2.4 }}>
            <Alert sx={{ alignItems: "center", fontSize: "12px" }} severity="error"><strong>Email e/ou senha inválidos!</strong></Alert>
          </Box>
          : ""
      }
      <Box sx={{ textAlign: "center", my: 3 }}>
        <LoadingButton sx={{ width: "90%", textAlign: "center" }} loading={loading} onClick={() => realizarLogin()} variant="contained">Entrar</LoadingButton>
      </Box>
      <Stack direction="row" spacing={0} justifyContent="space-around" textAlign="center" alignItems="center">
        <Typography component={Link} to='/recuperar-senha'>
          Esqueci minha senha
        </Typography>
        <Typography component={Link} to='/criar-conta'>
          Ainda não tem uma conta?<br></br> <span style={{ textDecoration: "underline", fontWeight: "bold" }}>Cadastre-se aqui.</span>
        </Typography>
      </Stack>
    </>
  );
}

export default LoginComponent;