import { interfaceClient } from "../../../Interface/interfaceClient"
import { interfaceRevenue } from "../../../Interface/interfaceRevenue"
import ClientesService from "../../../Services/ClientesService"

let clientServices = new ClientesService()

export async function updateClient(newInfosOfClient: interfaceClient) {
    return clientServices.UpdateClientes(newInfosOfClient)
}

export async function updateRevenue(newInfosRevenue: interfaceRevenue) {
    return clientServices.UpdateReceitas(newInfosRevenue)
}

export async function createRevenue(newRevenue: interfaceRevenue) {
    let newValue = { ...newRevenue }
    delete newValue.Id
    if (newValue.RendaBrutaMensal == null) newValue.RendaBrutaMensal = 0;
    if (newValue.PotencialSeguro == null) newValue.PotencialSeguro = false;
    return clientServices.InserirReceitas(newValue)
}