import { useMemo, useState, useEffect } from 'react';
import DashBoardSevice from "../Services/DashboardService"
import StorageHelper from '../Services/Helpers/StorageHelper';
import UserService from '../Services/UserService';

export const useUserInfos = () => {
  const dashService = new DashBoardSevice();
  const userService = new UserService();
  const storageHelper = new StorageHelper();

  const assessorId = useMemo(() => {
    return storageHelper.GetUsuarioId();
  }, [storageHelper]);

  const [userInfos, setUserInfos] = useState<any>({})
  const [allSquadsMembers, setAllSquadsMembers] = useState<any>([])

  useEffect(() => {
    if (assessorId) {
      userService.GetUserInfos(assessorId)
        .then(v => setUserInfos(v))

      dashService.ListarSquadsParticipantes()
        .then(v => setAllSquadsMembers(v))
        .catch()
    }
  }, [assessorId])

  const squadMembers = useMemo(() => {
    if (allSquadsMembers && allSquadsMembers.length > 0) {
      let userLogged = {};

      const userSquadId = userInfos?.Squad?.Id;
      if (!userSquadId) {
        return [];
      }

      let newValues = allSquadsMembers.map((v: any) => {
        if (v?.SquadId === userSquadId) {
          if (v?.UsuarioId === assessorId) {
            userLogged = v;
          } else {
            return v;
          }
        }
      }).filter((v: any) => v !== undefined).sort((a: any, b: any) => {
        let nameA = a?.Usuario ? `${a.Usuario.Nome} ${a.Usuario.Sobrenome}` : '';
        let nameB = b?.Usuario ? `${b.Usuario.Nome} ${b.Usuario.Sobrenome}` : '';

        return nameA.localeCompare(nameB);
      });

      newValues = [userLogged, ...newValues].filter((v) => Object.keys(v).length !== 0); // Remove objetos vazios

      return newValues;
    } else {
      return [];
    }
  }, [userInfos, allSquadsMembers, assessorId]);

  return {
    squadMembers, userInfos
  }
}