const ClienteInformacoesFinanceirasValidation = (ativosFinanceiros: any) => {
    // const [erros, setErros] = useState<string[]>([])
    let errorsList = <any>[]
    let errosCount = 0

    let currencyForAnalysis: number
    ativosFinanceiros.forEach((ativo: any) => {
        if (ativo.Ativo.trim() == '' || ativo.Ativo.length < 3 || ativo.Ativo.length > 150) {
            errorsList.push('O ativo precisa estar selecionado.')
            errosCount++
        }
        // currencyForAnalysis = parseFloat(ativo.Valor.replace(",", "."))

        currencyForAnalysis = parseFloat(ativo.Valor)
        if (currencyForAnalysis <= 0 || isNaN(currencyForAnalysis)) {
            errorsList.push('O valor é inválido.')
            errosCount++
        }
        if (ativo.Comentario.length > 500) {
            errorsList.push('O comentário não pode ultrapassar 500 caracteres.')
            errosCount++
        }
    })

    return {
        isValid: errosCount === 0,
        errorDescriptions: errorsList
    }

}

export default ClienteInformacoesFinanceirasValidation