import { AlterarSenhaRequest } from "../Domain/DTO/Request/AlterarSenhaRequest";
import { CadastroRequest } from "../Domain/DTO/Request/CadastroRequest";
import { LoginRequest } from "../Domain/DTO/Request/LoginRequest";
import { RecuperarSenhaRequest } from "../Domain/DTO/Request/RecuperarSenhaRequest";
import { LoginResponse } from "../Domain/DTO/Response/LoginResponse";
import { AUTH_HEADER, DEFAULT_HEADER } from "./Helpers/Headers";
import StorageHelper from "./Helpers/StorageHelper";

export default class LoginService {
    private readonly API_URL = process.env.REACT_APP_API_URL;
    private readonly storageHelper = new StorageHelper();

    public async Login(request: LoginRequest): Promise<LoginResponse> {
        return await fetch(`${this.API_URL}/login`, {
            method: 'POST', headers: DEFAULT_HEADER,
            body: JSON.stringify(request)
        })
            .then(async response => {
                if (response.status == 200)
                    return await response.json()

                if (response.status == 400)
                    await response.json().then(e => {
                        throw new Error(e);
                    })

                if (response.status == 401)
                    await response.json().then(e => {
                        throw new Error(e);
                    })
            })
            .then(data => {
                if (data) {
                    this.storageHelper.SalvarLogin(data);
                    return data;
                } else {
                    throw new Error("Erro")
                }
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    public async Registrar(request: CadastroRequest): Promise<boolean> {
        return await fetch(`${this.API_URL}/usuario/cadastro`, {
            method: 'POST',
            headers: DEFAULT_HEADER,
            body: JSON.stringify(request)
        })
            .then(response => {
                if (response.status == 200)
                    return true;

                if (response.status == 404)
                    throw new Error("Ocorreu um erro. Tente novamente");
            })
            .then(data => true)
            .catch(err => {
                throw new Error(err);
            })
    }

    public async RecuperarSenha(request: RecuperarSenhaRequest): Promise<boolean> {
        return await fetch(`${this.API_URL}/login/recuperar-senha`, {
            method: 'POST',
            headers: DEFAULT_HEADER,
            body: JSON.stringify(request)
        })
            .then(response => {
                if (response.status == 200)
                    return true;

                if (response.status == 404)
                    throw new Error("Ocorreu um erro. Tente novamente");
            })
            .then(data => true)
            .catch(err => {
                throw new Error(err);
            })
    }

    public async AlterarSenha(request: AlterarSenhaRequest): Promise<boolean> {
        return await fetch(`${this.API_URL}/login/alterar-senha`, {
            method: 'POST',
            headers: DEFAULT_HEADER,
            body: JSON.stringify(request)
        })
            .then(response => {
                if (response.status == 200)
                    return true;

                if (response.status == 404)
                    throw new Error("Ocorreu um erro. Tente novamente");
            })
            .then(data => true)
            .catch(err => {
                throw new Error(err);
            })
    }


}