import { useEffect, useState } from "react";
import useGoogleCharts from './useGoogleCharts';
import { jsPDF } from "jspdf";
import StorageHelper from "../../../../Services/Helpers/StorageHelper";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// Advisor
import UserService from "../../../../Services/UserService";
import UserInfos from "../../../../Domain/User"

// Services
import ClientesService from "../../../../Services/ClientesService";
import { useParams } from "react-router-dom";

// Fonts
import addFont1 from './OpenSans-Bold-bold'
import addFont2 from './OpenSans-Regular-normal'

// Images
import rodape from '../../../../Assets/Images/pdf-output/rodape.png'
import PDFService from "../../../../Services/PDFService";

// New Pages
import capaImg from '../../../../Assets/Images/new-pdf-output/capa.png'
import newPage1 from '../../../../Assets/Images/new-pdf-output/page1.png'
import newPage2 from '../../../../Assets/Images/new-pdf-output/page2.png'
import newPage2Resum from '../../../../Assets/Images/new-pdf-output/page2-resum.png'
import newPage3ResumAnalise from '../../../../Assets/Images/new-pdf-output/page3-analise.png'
import newPage3ResumFamiliar from '../../../../Assets/Images/new-pdf-output/page3-planejamentofamiliar.png'

import newPage3 from '../../../../Assets/Images/new-pdf-output/page3.png'
import newPage4 from '../../../../Assets/Images/new-pdf-output/page4.png'
import newPage5 from '../../../../Assets/Images/new-pdf-output/page5.png'
import newPage6 from '../../../../Assets/Images/new-pdf-output/page6.png'
import fase1 from '../../../../Assets/Images/new-pdf-output/fases/fase1.png'
import fase1Selected from '../../../../Assets/Images/new-pdf-output/fases/fase1-selected.png'
import fase2 from '../../../../Assets/Images/new-pdf-output/fases/fase2.png'
import fase2Selected from '../../../../Assets/Images/new-pdf-output/fases/fase2-selected.png'
import fase3 from '../../../../Assets/Images/new-pdf-output/fases/fase3.png'
import fase3Selected from '../../../../Assets/Images/new-pdf-output/fases/fase3-selected.png'
import faseSelected from '../../../../Assets/Images/new-pdf-output/fases/voce esta nesta fase.png'
import smallBorderImg from '../../../../Assets/Images/new-pdf-output/borda-grande.png'
import bigBorderImg from '../../../../Assets/Images/new-pdf-output/borda-pequena.png'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

const infosGeneratePdf = {
    nomeAssessor: 'Nome do Assessor',

    cliente: { nome: 'Nome do Cliente', profissao: 'Advogado', idade: 34 },

    pdfComIndependencia: true,
    anosInvestindo: 10,         // independência financeira
    anosVivendoDeRenda: 0,      // independência financeira alcançada
    //
    projetosDeVida: [{ Id: '', ClienteId: '', Valor: 500000, Descricao: 'Projeto X', TempoParaRealizarAnos: 5 }],
    //
    ativosFinanceiros: [{ Ativo: 'x', Valor: 200000, Comentario: 'ativo exemplo' }],
    //
    ativosNaoLiquidaveis: [{ Ativo: 'y', Valor: 200000, Comentario: 'ativo exemplo' }],

    // aporteInicial: 100000      // soma dos ativos financeiros
    //
    aporteMensal: 5000,         // currency

    //
    dependentes: [{ NomeCompleto: 'João', DataNascimento: new Date(2006, 1, 1), Parentesco: 'Filho' }],

    // 
    rendaBrutaMensal: 0,        // currency
    pensao: 0,                  // currency
    saldoFGTS: 0,               // currency
    outrasRendas: 0,            // currency

    despesaMensal: 12000,       // currency
    contribuicaoDespesas: 0.7,  // porcentagem
    manutencaoPadraoDeVida: 3,  // anos
    restabelecimentoDeVida: 24, // meses
}

const ResultadoComponent = () => {
    let navigate = useNavigate();

    // Services
    let { userId } = useParams()
    const [usuarioId, setUsuarioId] = useState('')

    const clientesService = new ClientesService()
    const [dadosForGenPDF, setDadosForGenPDF] = useState([])
    const [statusRequestCliente, setStatusRequestCliente] = useState(false)
    const [statusRequestAdvisor, setStatusRequestAdvisor] = useState(false)
    const [isPossibleGenPDF, setIsPossibleGenPDF] = useState(true)
    const [finishedPDF, setFinishedPDF] = useState(false)

    // Advisor
    const userService = new UserService()
    const [userInfos, setUserInfos] = useState(new UserInfos())
    const storageHelper = new StorageHelper();

    const google = useGoogleCharts();
    // const [chart, setChart] = useState(null);

    useEffect(() => {
        // if (!google) setGoogle(false)
    }, [google])

    function getAge(dateString) {
        let today = new Date();
        let birthDate = new Date(dateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    const drawIndependenciaFinanceira = (width, height, array) => {
        var data = google.visualization.arrayToDataTable(array)

        var formatter = new google.visualization.NumberFormat(
            {
                decimalSymbol: ',',
                groupingSymbol: '.',
                negativeColor: 'red',
                negativeParens: true,
                prefix: 'R$ '

            });
        formatter.format(data, 1); // Apply formatter to second column
        // formatter.format(data, 2); // Apply formatter to second column

        var options = {
            chart: {
                // title: 'Box Office Earnings in First Two Weeks of Opening',
                // subtitle: 'in millions of dollars (USD)'
            },
            vAxis: {
                title: 'Capital acumulado',
                // format: "short",
                format: "R$###,###",
                decimalSymbol: ',',
                groupingSymbol: '.',
                // count: -1,
                // pattern: 'short',
                textStyle: { fontSize: 11 },
                gridlines: { count: 2 }
            },
            hAxis: {
                // title: 'Data'
                // gridlines: { count: 0 }
            },
            legend: 'none',
            width: width,
            height: height,
            backgroundColor: 'transparent',
            curveType: 'function',



        };

        const newChart = new google.visualization.LineChart(document.getElementById('independenciaFinanceira'));

        newChart.draw(data, options);

        //setChart(newChart);

        return newChart
    }

    const drawIndependenciaFinanceiraComAnalise = (width, height, array) => {
        var data = google.visualization.arrayToDataTable(array)

        var formatter = new google.visualization.NumberFormat(
            {
                decimalSymbol: ',',
                groupingSymbol: '.',
                negativeColor: 'red',
                negativeParens: true,
                prefix: 'R$ '

            });
        formatter.format(data, 1); // Apply formatter to second column
        formatter.format(data, 2); // Apply formatter to second column

        var options = {
            chart: {
                // title: 'Box Office Earnings in First Two Weeks of Opening',
                // subtitle: 'in millions of dollars (USD)'
            },
            vAxis: {
                title: 'Capital acumulado',
                // format: "short",
                format: "R$###,###",
                decimalSymbol: ',',
                groupingSymbol: '.',
                // count: -1,
                pattern: 'short',
                textStyle: { fontSize: 10, bold: true },
                gridlines: { count: 2 }
            },
            hAxis: {
                // title: 'Data'
                // gridlines: { count: 0 }
            },
            legend: 'none',
            width: width,
            height: height,
            backgroundColor: 'transparent',
            curveType: 'function',



        };

        const newChart = new google.visualization.LineChart(document.getElementById('independenciaFinanceiraAnalise'));

        newChart.draw(data, options);

        //setChart(newChart);

        return newChart
    }

    const drawAtivos = (ativos, height = 300, width = 570) => {
        google.charts.load('current', { 'packages': ['corechart'], 'language': 'pt-br' });

        var data = google.visualization.arrayToDataTable(ativos)

        var formatter = new google.visualization.NumberFormat({ decimalSymbol: ',', groupingSymbol: '.', fractionDigits: 0, negativeColor: 'red', negativeParens: true, prefix: "R$" })

        formatter.format(data, 1)
        var options = {
            chartArea: {
                bottom: 40,
                left: 0,
                right: 0,
                top: 40,
            },
            // chartArea: {
            //     height: '50%',
            //     width: '50%'
            // },
            legend: {
                labeledValueText: 'both',
                position: 'labeled',
                textStyle: { fontSize: 24, bold: true },
            },
            pieSliceTextStyle: {
                // bold: true

            },
            pieSliceText: 'none',
            pieSliceBorderColor: 'transparent',
            pieStartAngle: 100,
            height: height,
            width: width,
            backgroundColor: 'transparent',
            pieHole: 0.56,
            slices: {
                0: { color: '#7D3AC0' },
                1: { color: '#EFA68F' },
                2: { color: '#EA7369' },
                3: { color: '#EA548B' },
                4: { color: '#DC4BB3' },
                5: { color: '#B04BCF' },
            },
        };

        // Instantiate and draw our chart, passing in some options.
        const newChart = new google.visualization.PieChart(document.getElementById('pizzaChart'));
        newChart.draw(data, options);

        // setChart(newChart);

        return newChart
    }

    const drawAtivosLiqXNaoLiq = (ativos, height = 300, width = 570) => {
        google.charts.load('current', { 'packages': ['corechart'], 'language': 'pt-br' });

        var data = google.visualization.arrayToDataTable(ativos)

        var formatter = new google.visualization.NumberFormat({ decimalSymbol: ',', groupingSymbol: '.', fractionDigits: 0, negativeColor: 'red', negativeParens: true, prefix: "R$" })

        formatter.format(data, 1)
        var options = {
            chartArea: {
                bottom: 40,
                left: 0,
                right: 0,
                top: 40,
            },
            // chartArea: {
            //     height: '50%',
            //     width: '50%'
            // },
            legend: {
                labeledValueText: 'both',
                position: 'labeled',
                // position: 'bottom',
                textStyle: { fontSize: 24, bold: true },
            },
            pieSliceTextStyle: {
                // bold: true

            },
            pieSliceText: 'none',
            pieSliceBorderColor: 'transparent',
            pieStartAngle: 100,
            height: height,
            width: width,
            backgroundColor: 'transparent',
            slices: {
                // 0: { color: '#EA548B' },
                // 1: { color: '#DC4BB3' },
                // 0: { color: '#B04BCF' },
                0: { color: '#7D3AC0' },
                1: { color: '#EFA68F' },
                // 5: { color: '#EA7369' },
            },
        };

        // Instantiate and draw our chart, passing in some options.
        const newChart = new google.visualization.PieChart(document.getElementById('pizzaChart'));
        newChart.draw(data, options);

        // setChart(newChart);

        return newChart
    }

    const arrayCapitalDesprotegido = () => {
        let seguroDeVida = 0
        dadosForGenPDF[5].map((value) => {
            if (value.Ativo === "Seguro de Vida") seguroDeVida += value.Valor
        })

        let capitalDescoberto = ResultadoCapitalDescoberto()
        if (seguroDeVida <= capitalDescoberto) {
            capitalDescoberto -= seguroDeVida
        } else {
            capitalDescoberto = 0
        }
        return [
            ['Tipo', 'Valor'],
            ['Capital Desprotegido', capitalDescoberto],
            ['Capital Protegido', seguroDeVida]
        ]
    }

    const drawCapitalDesprotegido = (height = 300, width = 570) => {
        google.charts.load('current', { 'packages': ['corechart'], 'language': 'pt-br' });

        var array = arrayCapitalDesprotegido()

        var data = google.visualization.arrayToDataTable(array)

        var options = {
            pieHole: 0.7,
            height: 400,
            width: 400,
            backgroundColor: { fill: 'transparent' },
            legend: 'none',
            pieSliceText: 'none',
            pieStartAngle: 190,
            pieSliceBorderColor: 'transparent',
            slices: {
                0: { color: '#ffc542' },
                1: { color: 'transparent' }
            },
        };

        // Instantiate and draw our chart, passing in some options.
        const newChart = new google.visualization.PieChart(document.getElementById('pizzaChart'));
        newChart.draw(data, options);

        // setChart(newChart);

        return newChart
    }

    const TotalGeralReceitas = () => {
        let totalAtivosFinanceiros = 0
        dadosForGenPDF[5].map((value) => {
            if (value.Ativo === "Seguro de Vida") return;
            totalAtivosFinanceiros += value.Valor
        })

        let totalAtivosImobilizados = 0
        dadosForGenPDF[6].map((value) => {
            totalAtivosImobilizados += value.Valor
        })

        return totalAtivosFinanceiros + totalAtivosImobilizados
    }

    const TotalGeralDespesas = () => {

        /**
         * DESPESAS TEMPORARIAS
         */
        // Total projetos
        // Gasto Dependentes Mensal
        let totalDependentes = 0
        if (dadosForGenPDF[0].PossuiDependentes === true && dadosForGenPDF[8] != undefined && dadosForGenPDF[8].length > 0) {
            dadosForGenPDF[8].map(value => {
                totalDependentes += (value.GastoMensal * value.Duracao)
            })
        }
        totalDependentes = totalDependentes * (dadosForGenPDF[4].ContribuicaoDespesaPorcentagem / 100)

        // Calculando Manutencao do Padrao de Vida
        let manutencaoPadraoDeVida = dadosForGenPDF[3].RendaBrutaMensal * dadosForGenPDF[4].ManutencaoPadraoDeVida
        // Restabelecimento de Vida
        let restabelecimentoDeVida = 12 * dadosForGenPDF[4].RestabelecimentoVidaMeses * dadosForGenPDF[3].RendaBrutaMensal

        /**
         * TOTAL GERAL DEPESAS
         */
        let TotalGeralDespesas = manutencaoPadraoDeVida + restabelecimentoDeVida + totalDependentes
        return TotalGeralDespesas

    }

    const ResultadoCapitalDescoberto = () => {

        let totalGeralDespesas = TotalGeralDespesas()
        let totalAtivosFinanceiros = TotalGeralReceitas()
        let custosDeInventario = totalAtivosFinanceiros * 0.18
        let despesasFuturas = totalGeralDespesas + custosDeInventario
        return despesasFuturas
    }

    const calcularMontanteAcumulado = (independenciaFinanceiraValues, tempoInvestindo) => {
        let aporteInicial = independenciaFinanceiraValues.AporteInicial
        let rentabilidadeAnual = independenciaFinanceiraValues.RentabilidadeAnual / 100
        let rentabilidadeAnualIndependencia = independenciaFinanceiraValues.RentabilidadeAnualIndependencia / 100
        let rentabilidadeMensal = ((1 + rentabilidadeAnual) ** (1 / 12)) - 1
        let rentabilidadeMensalIndependencia = ((1 + rentabilidadeAnualIndependencia) ** (1 / 12)) - 1
        // let tempoInvestindo = (independenciaFinanceiraValues.AposentadoriaIdade - clienteInfos.age) * 12 // 25 => Idade do cliente
        let inflacao = independenciaFinanceiraValues.InflacaoAnual / 100
        tempoInvestindo *= 12
        // Variavel resultado
        let resultadosIndependencia = { arrayFinal: [] }

        // Variavel informada
        let montanteAtual = aporteInicial

        // Cria uma data atual e seta o valor do dia em 1º --> Para o array final
        let date = new Date()
        date.setDate(1)
        resultadosIndependencia.arrayFinal.push(["Data", "Reserva acumulada"])
        resultadosIndependencia.arrayFinal.push([new Date(date.getFullYear(), date.getMonth()), montanteAtual])
        date.setMonth(date.getMonth() + 1)

        // Fazendo os aportes no montante e aumentando o valor do aporte mensalmente, ajustado pela inflacao
        let aporteMensal = independenciaFinanceiraValues.ValorParaCalculo

        for (let i = 1; i <= tempoInvestindo; i++) {
            montanteAtual += montanteAtual * rentabilidadeMensal

            // Aporte
            montanteAtual += aporteMensal

            // Aumento do aporte
            if (i % 12 == 0 && i < tempoInvestindo) {
                aporteMensal += aporteMensal * inflacao
            }

            resultadosIndependencia.arrayFinal.push([new Date(date.getFullYear(), date.getMonth()), montanteAtual])
            // Adicionando mais um mês para a próxima análise.
            date.setMonth(date.getMonth() + 1)


        }
        let fatorInflacao = (1 + inflacao) ** ((tempoInvestindo / 12) - 1)

        // Valor montate atual
        resultadosIndependencia.reservaAcumulada = montanteAtual
        // Valor montante atual hoje
        let montanteAtualHoje = montanteAtual / fatorInflacao
        resultadosIndependencia.reservaAcumuladaHoje = montanteAtualHoje

        // Calculando rentabilidades
        let rentabilidadeMensalVitalicia = 0

        if (independenciaFinanceiraValues.AposentadoriaTipo === "VITALICIA") {
            rentabilidadeMensalVitalicia = montanteAtual * rentabilidadeMensalIndependencia
        }

        // Setting Renda Mensal
        let rendaMensal = montanteAtual * rentabilidadeMensalIndependencia
        resultadosIndependencia.rendaMensal = rendaMensal
        resultadosIndependencia.rendaMensalHoje = rendaMensal / fatorInflacao

        if (independenciaFinanceiraValues.AposentadoriaTipo === "TEMPORARIA") {
            // Setting Renda Mensal TEMPORARIA
            var montanteAcumulado = resultadosIndependencia.reservaAcumulada
            var prazo = independenciaFinanceiraValues.AposentadoriaPrazo * 12


            let montanteAcumuladoLoop = montanteAcumulado
            var valorParaSacar = montanteAcumulado / prazo

            let loopIndependenciaTemporaria = () => {
                for (let i = 0; i < prazo; i++) {
                    montanteAcumuladoLoop += montanteAcumuladoLoop * rentabilidadeMensalIndependencia
                    montanteAcumuladoLoop -= valorParaSacar
                }
                if (montanteAcumuladoLoop > 50) {
                    if (montanteAcumuladoLoop > 50000) {
                        valorParaSacar += valorParaSacar * 0.005
                    } else if (montanteAcumuladoLoop > 5000) {
                        valorParaSacar += valorParaSacar * 0.0005
                    } else {
                        valorParaSacar += valorParaSacar * 0.0000005
                    }

                    // Resetting value
                    montanteAcumuladoLoop = montanteAcumulado
                    // Call again
                    loopIndependenciaTemporaria()
                }

                // return montanteNoMes
            }

            loopIndependenciaTemporaria()

            resultadosIndependencia.rendaMensalTemp = valorParaSacar
            resultadosIndependencia.rendaMensalHojeTemp = valorParaSacar / fatorInflacao
        }

        // Setting Values
        resultadosIndependencia.contribuicaoMensal = independenciaFinanceiraValues.ValorParaCalculo
        resultadosIndependencia.rentabilidadeAnual = independenciaFinanceiraValues.RentabilidadeAnual
        resultadosIndependencia.aporteInicial = independenciaFinanceiraValues.AporteInicial

        return resultadosIndependencia
    }

    const calcularMontanteAcumuladoAnalise = (independenciaFinanceiraValues, tempoInvestindo) => {
        console.log('here')
        let aporteInicial = independenciaFinanceiraValues.AporteInicial
        let rentabilidadeAnual = independenciaFinanceiraValues.RentabilidadeAnual / 100
        let rentabilidadeAnualIndependencia = independenciaFinanceiraValues.RentabilidadeAnualIndependencia / 100
        let rentabilidadeMensal = ((1 + rentabilidadeAnual) ** (1 / 12)) - 1
        let rentabilidadeMensalIndependencia = ((1 + rentabilidadeAnualIndependencia) ** (1 / 12)) - 1

        // Outros investimentos
        let rentabilidadeAnualOutros = 9 / 100
        let rentabilidadeMensalOutros = ((1 + rentabilidadeAnualOutros) ** (1 / 12)) - 1

        let inflacao = independenciaFinanceiraValues.InflacaoAnual / 100
        tempoInvestindo *= 12
        // Variavel resultado
        let resultadosIndependencia = { arrayFinal: [] }

        // Variavel informada
        let montanteAtual = aporteInicial
        let montanteAtualOutros = aporteInicial

        // Cria uma data atual e seta o valor do dia em 1º --> Para o array final
        let date = new Date()
        date.setDate(1)
        resultadosIndependencia.arrayFinal.push(["Data", "Reserva acumulada", "Reserva acumulada outros"])
        resultadosIndependencia.arrayFinal.push([new Date(date.getFullYear(), date.getMonth()), Number(montanteAtual.toFixed(2)), Number(montanteAtualOutros.toFixed(2))])
        date.setMonth(date.getMonth() + 1)

        // Projetos para diminuir ou nao
        let projetos = dadosForGenPDF[2]
        let tempoInvestindoProjetos = 0

        // Fazendo os aportes no montante e aumentando o valor do aporte mensalmente, ajustado pela inflacao
        let aporteMensal = independenciaFinanceiraValues.ValorParaCalculo
        for (let i = 1; i <= tempoInvestindo; i++) {
            montanteAtual += montanteAtual * rentabilidadeMensal
            montanteAtualOutros += montanteAtualOutros * rentabilidadeMensalOutros

            // Aporte
            montanteAtual += aporteMensal
            montanteAtualOutros += aporteMensal

            // Aumento do aporte
            if (i % 12 == 0 && i < tempoInvestindo) {
                aporteMensal += aporteMensal * inflacao

                // Para analisar projetos
                tempoInvestindoProjetos += 1
                projetos.map(projeto => {
                    if (projeto.TempoParaRealizarAnos === tempoInvestindoProjetos) {
                        montanteAtual -= projeto.Valor
                        montanteAtualOutros -= projeto.Valor
                    }
                })
            }

            resultadosIndependencia.arrayFinal.push([new Date(date.getFullYear(), date.getMonth()), Number(montanteAtual.toFixed(2)), Number(montanteAtualOutros.toFixed(2))])
            // Adicionando mais um mês para a próxima análise.
            date.setMonth(date.getMonth() + 1)
        }

        let fatorInflacao = (1 + inflacao) ** ((tempoInvestindo / 12) - 1)

        // Valor montate atual
        resultadosIndependencia.reservaAcumulada = montanteAtual
        resultadosIndependencia.reservaAcumuladaOutros = montanteAtualOutros
        // Valor montante atual hoje
        let montanteAtualHoje = montanteAtual / fatorInflacao
        let montanteAtualHojeOutros = montanteAtualOutros / fatorInflacao
        resultadosIndependencia.reservaAcumuladaHoje = montanteAtualHoje
        resultadosIndependencia.reservaAcumuladaHojeOutros = montanteAtualHojeOutros / fatorInflacao


        // Calculando rentabilidades
        let rentabilidadeMensalVitalicia = 0

        if (independenciaFinanceiraValues.AposentadoriaTipo === "VITALICIA") {
            rentabilidadeMensalVitalicia = montanteAtual * rentabilidadeMensalIndependencia
        }

        // Setting Renda Mensal
        let rendaMensal = montanteAtual * rentabilidadeMensalIndependencia
        resultadosIndependencia.rendaMensal = rendaMensal
        resultadosIndependencia.rendaMensalHoje = rendaMensal / fatorInflacao

        if (independenciaFinanceiraValues.AposentadoriaTipo === "TEMPORARIA") {
            // Setting Renda Mensal TEMPORARIA
            var montanteAcumulado = resultadosIndependencia.reservaAcumulada
            var prazo = independenciaFinanceiraValues.AposentadoriaPrazo * 12


            let montanteAcumuladoLoop = montanteAcumulado
            var valorParaSacar = montanteAcumulado / prazo

            let loopIndependenciaTemporaria = () => {
                for (let i = 0; i < prazo; i++) {
                    montanteAcumuladoLoop += montanteAcumuladoLoop * rentabilidadeMensalIndependencia
                    montanteAcumuladoLoop -= valorParaSacar
                }
                if (montanteAcumuladoLoop > 50) {
                    if (montanteAcumuladoLoop > 50000) {
                        valorParaSacar += valorParaSacar * 0.005
                    } else if (montanteAcumuladoLoop > 5000) {
                        valorParaSacar += valorParaSacar * 0.0005
                    } else {
                        valorParaSacar += valorParaSacar * 0.0000005
                    }

                    // Resetting value
                    montanteAcumuladoLoop = montanteAcumulado
                    // Call again
                    loopIndependenciaTemporaria()
                }

                // return montanteNoMes
            }

            loopIndependenciaTemporaria()

            resultadosIndependencia.rendaMensalTemp = valorParaSacar
            resultadosIndependencia.rendaMensalHojeTemp = valorParaSacar / fatorInflacao
        }

        // Setting Values
        resultadosIndependencia.contribuicaoMensal = independenciaFinanceiraValues.ValorParaCalculo
        resultadosIndependencia.rentabilidadeAnual = independenciaFinanceiraValues.RentabilidadeAnual
        resultadosIndependencia.aporteInicial = independenciaFinanceiraValues.AporteInicial

        return resultadosIndependencia
    }

    const calcularContribuicaoMensal = (independenciaFinanceiraValues, tempoInvestindo) => {
        let montanteFinal = 0
        let rentabilidadeAnual = independenciaFinanceiraValues.RentabilidadeAnual / 100
        let rentabilidadeMensal = ((1 + rentabilidadeAnual) ** (1 / 12)) - 1
        let rentabilidadeAnualIndependencia = independenciaFinanceiraValues.RentabilidadeAnualIndependencia / 100
        let rentabilidadeMensalIndependencia = ((1 + rentabilidadeAnualIndependencia) ** (1 / 12)) - 1
        if (independenciaFinanceiraValues.CalculoTipo === "OPT3") {
            montanteFinal = independenciaFinanceiraValues.ValorParaCalculo / rentabilidadeMensalIndependencia
        } else {
            montanteFinal = independenciaFinanceiraValues.ValorParaCalculo
        }
        let aporteInicial = independenciaFinanceiraValues.AporteInicial
        let montanteAtual = aporteInicial
        // let tempoInvestindo = (independenciaFinanceiraValues.AposentadoriaIdade - clienteInfos.age) * 12 // 25 => Idade do cliente
        tempoInvestindo *= 12
        let inflacao = independenciaFinanceiraValues.InflacaoAnual / 100
        let aporteMaximoPossivel = montanteFinal / tempoInvestindo
        let aporteAtual = aporteMaximoPossivel
        let fatorInflacao = (1 + inflacao) ** ((tempoInvestindo / 12) - 1)

        let resultadosIndependencia = { arrayFinal: [] }

        // Cria uma data atual e seta o valor do dia em 1º --> Para o array final
        let date = new Date()
        date.setDate(1)

        let loopAporte = () => {
            // Verificando se apenas com a rentabilidade mensal o objetivo já é alcançado
            for (let i = 1; i <= tempoInvestindo; i++) {
                // Rentabilidade
                montanteAtual += montanteAtual * rentabilidadeMensal
                resultadosIndependencia.arrayFinal.push(["Data", "Reserva acumulada"])

                if (i % 3 == 0 || i == 1 || i == tempoInvestindo) {
                    resultadosIndependencia.arrayFinal.push([new Date(date.getFullYear(), date.getMonth()), Number(montanteAtual.toFixed(2))])
                }
                date.setMonth(date.getMonth() + 1)
            }

            if (montanteAtual >= montanteFinal) {
                let fatorInflacao = (1 + inflacao) ** ((tempoInvestindo / 12) - 1)
                resultadosIndependencia.aporteInicial = independenciaFinanceiraValues.AporteInicial
                resultadosIndependencia.rentabilidadeAnual = independenciaFinanceiraValues.RentabilidadeAnual
                resultadosIndependencia.contribuicaoMensal = 0
                // Calculando rentabilidade mensal
                let rendaMensal = montanteAtual * rentabilidadeMensalIndependencia
                resultadosIndependencia.rendaMensal = rendaMensal
                resultadosIndependencia.rendaMensalHoje = rendaMensal / fatorInflacao
                // Reserva
                resultadosIndependencia.reservaAcumulada = montanteAtual
                resultadosIndependencia.reservaAcumuladaHoje = montanteAtual / fatorInflacao
                return;
            } else {
                resultadosIndependencia.arrayFinal = []
                date = new Date()
            }

            montanteAtual = aporteInicial

            resultadosIndependencia.arrayFinal.push(["Data", "Reserva acumulada"])
            resultadosIndependencia.arrayFinal.push([new Date(date.getFullYear(), date.getMonth()), Number(montanteAtual.toFixed(2))])
            date.setMonth(date.getMonth() + 1)

            for (let i = 1; i <= tempoInvestindo; i++) {
                // Rentabilidade
                montanteAtual += montanteAtual * rentabilidadeMensal
                // Aporte Mensal
                montanteAtual += aporteAtual
                // Aumento do aporte
                if (i % 12 == 0 && i < tempoInvestindo) {
                    aporteAtual += aporteAtual * inflacao
                }
                if (i % 3 == 0 || i == 1 || i == tempoInvestindo) {
                    resultadosIndependencia.arrayFinal.push([new Date(date.getFullYear(), date.getMonth()), Number(montanteAtual.toFixed(2))])
                }
                // Adicionando mais um mês para a próxima análise.
                date.setMonth(date.getMonth() + 1)
            }

            if ((montanteAtual - montanteFinal) > 100) {
                if (montanteAtual / montanteFinal > 1.5) {
                    aporteMaximoPossivel -= aporteMaximoPossivel * 0.5
                } else if (montanteAtual / montanteFinal > 1.2) {
                    aporteMaximoPossivel -= aporteMaximoPossivel * 0.05
                } else if (montanteAtual / montanteFinal > 1.05) {
                    aporteMaximoPossivel -= aporteMaximoPossivel * 0.005
                } else {
                    aporteMaximoPossivel -= aporteMaximoPossivel * 0.002
                }

                // Resetando valores e atribuindo novo aporte
                aporteAtual = aporteMaximoPossivel
                montanteAtual = aporteInicial

                resultadosIndependencia.arrayFinal = []
                loopAporte()
            } else {
                resultadosIndependencia.aporteInicial = independenciaFinanceiraValues.AporteInicial
                resultadosIndependencia.rentabilidadeAnual = independenciaFinanceiraValues.RentabilidadeAnual
                resultadosIndependencia.contribuicaoMensal = aporteAtual / fatorInflacao

                // Reserva OPT 2
                if (independenciaFinanceiraValues.CalculoTipo === "OPT2" &&
                    montanteAtual < (independenciaFinanceiraValues.ValorParaCalculo * 1.1)) {
                    montanteAtual = independenciaFinanceiraValues.ValorParaCalculo
                }
                resultadosIndependencia.reservaAcumulada = montanteAtual
                resultadosIndependencia.reservaAcumuladaHoje = montanteAtual / fatorInflacao

                // Renda OPT 3
                let rendaMensal = montanteAtual * rentabilidadeMensalIndependencia
                if (independenciaFinanceiraValues.CalculoTipo === "OPT3" &&
                    rendaMensal < (independenciaFinanceiraValues.ValorParaCalculo * 1.1)) {
                    rendaMensal = independenciaFinanceiraValues.ValorParaCalculo
                }
                resultadosIndependencia.rendaMensal = rendaMensal
                resultadosIndependencia.rendaMensalHoje = rendaMensal / fatorInflacao
            }
        }

        loopAporte()

        if (independenciaFinanceiraValues.AposentadoriaTipo === "TEMPORARIA") {
            // Setting Renda Mensal TEMPORARIA
            var montanteAcumulado = resultadosIndependencia.reservaAcumulada
            var prazo = independenciaFinanceiraValues.AposentadoriaPrazo * 12


            let montanteAcumuladoLoop = montanteAcumulado
            var valorParaSacar = montanteAcumulado / prazo

            let loopIndependenciaTemporaria = () => {
                for (let i = 0; i < prazo; i++) {
                    montanteAcumuladoLoop += montanteAcumuladoLoop * rentabilidadeMensalIndependencia
                    montanteAcumuladoLoop -= valorParaSacar
                }
                if (montanteAcumuladoLoop > 50) {
                    if (montanteAcumuladoLoop > 50000) {
                        valorParaSacar += valorParaSacar * 0.005
                    } else if (montanteAcumuladoLoop > 5000) {
                        valorParaSacar += valorParaSacar * 0.0005
                    } else {
                        valorParaSacar += valorParaSacar * 0.0000005
                    }

                    // Resetting value
                    montanteAcumuladoLoop = montanteAcumulado
                    // Call again
                    loopIndependenciaTemporaria()
                }

                // return montanteNoMes
            }

            loopIndependenciaTemporaria()

            resultadosIndependencia.rendaMensalTemp = valorParaSacar
            resultadosIndependencia.rendaMensalHojeTemp = valorParaSacar / fatorInflacao
        }

        // Setting Values
        return resultadosIndependencia
    }

    const calcularContribuicaoMensalAnalise = (independenciaFinanceiraValues, tempoInvestindo) => {
        let montanteFinal = 0
        // Outros investimentos

        let rentabilidadeAnual = independenciaFinanceiraValues.RentabilidadeAnual / 100
        let rentabilidadeMensal = ((1 + rentabilidadeAnual) ** (1 / 12)) - 1
        // Outros investimentos
        let rentabilidadeAnualOutros = 9 / 100
        let rentabilidadeMensalOutros = ((1 + rentabilidadeAnualOutros) ** (1 / 12)) - 1

        let rentabilidadeAnualIndependencia = independenciaFinanceiraValues.RentabilidadeAnualIndependencia / 100
        let rentabilidadeMensalIndependencia = ((1 + rentabilidadeAnualIndependencia) ** (1 / 12)) - 1
        if (independenciaFinanceiraValues.CalculoTipo === "OPT3") {
            montanteFinal = independenciaFinanceiraValues.ValorParaCalculo / rentabilidadeMensalIndependencia
        } else {
            montanteFinal = independenciaFinanceiraValues.ValorParaCalculo
        }
        let aporteInicial = independenciaFinanceiraValues.AporteInicial

        let montanteAtual = aporteInicial
        // Outros investimentos
        let montanteAtualOutros = aporteInicial


        tempoInvestindo *= 12
        let inflacao = independenciaFinanceiraValues.InflacaoAnual / 100
        let aporteMaximoPossivel = montanteFinal / tempoInvestindo
        let aporteAtual = aporteMaximoPossivel
        let fatorInflacao = (1 + inflacao) ** ((tempoInvestindo / 12) - 1)

        let resultadosIndependencia = { arrayFinal: [] }

        // Cria uma data atual e seta o valor do dia em 1º --> Para o array final
        let date = new Date()
        date.setDate(1)

        resultadosIndependencia.arrayFinal.push(["Data", "Reserva acumulada", "Reserva acumulada outros"])

        // Projetos para diminuir ou nao
        let projetos = dadosForGenPDF[2]
        let tempoInvestindoProjetos = 0
        let thereIsProjeto = false

        let loopAporte = () => {
            // Verificando se apenas com a rentabilidade mensal o objetivo já é alcançado
            for (let i = 1; i <= tempoInvestindo; i++) {
                // Rentabilidade
                montanteAtual += montanteAtual * rentabilidadeMensal
                // Outros investimentos
                montanteAtualOutros += montanteAtualOutros * rentabilidadeMensalOutros

                if (i % 3 === 0 || i == tempoInvestindo || i == 1) {
                    resultadosIndependencia.arrayFinal.push([new Date(date.getFullYear(), date.getMonth()), Number(montanteAtual.toFixed(2)), Number(montanteAtualOutros.toFixed(2))])
                }
                date.setMonth(date.getMonth() + 1)
            }

            if (montanteAtual >= montanteFinal) {
                let fatorInflacao = (1 + inflacao) ** ((tempoInvestindo / 12) - 1)
                resultadosIndependencia.aporteInicial = independenciaFinanceiraValues.AporteInicial
                resultadosIndependencia.rentabilidadeAnual = independenciaFinanceiraValues.RentabilidadeAnual
                resultadosIndependencia.contribuicaoMensal = 0
                // Calculando rentabilidade mensal
                let rendaMensal = montanteAtual * rentabilidadeMensalIndependencia
                resultadosIndependencia.rendaMensal = rendaMensal
                resultadosIndependencia.rendaMensalHoje = rendaMensal / fatorInflacao
                // Calculando rentabilidade mensal Outros
                let rendaMensalOutros = montanteAtualOutros * rentabilidadeMensalIndependencia
                resultadosIndependencia.rendaMensalOutros = rendaMensalOutros
                resultadosIndependencia.rendaMensalHojeOutros = rendaMensalOutros / fatorInflacao

                // Reserva
                resultadosIndependencia.reservaAcumulada = montanteAtual
                resultadosIndependencia.reservaAcumuladaHoje = montanteAtual / fatorInflacao
                // Reserva Outros
                resultadosIndependencia.reservaAcumuladaOutros = montanteAtualOutros
                resultadosIndependencia.reservaAcumuladaHojeOutros = montanteAtualOutros / fatorInflacao
                return;
            } else {
                resultadosIndependencia.arrayFinal = []
                date = new Date()
            }

            montanteAtual = aporteInicial
            // Outros investimentos
            montanteAtualOutros = aporteInicial

            resultadosIndependencia.arrayFinal.push(["Data", "Reserva acumulada", "Reserva acumulada outros"])
            resultadosIndependencia.arrayFinal.push([new Date(date.getFullYear(), date.getMonth()), Number(montanteAtual.toFixed(2)), Number(montanteAtualOutros.toFixed(2))])
            date.setMonth(date.getMonth() + 1)

            for (let i = 1; i <= tempoInvestindo; i++) {
                // Rentabilidade
                // Aporte Mensal
                montanteAtual += montanteAtual * rentabilidadeMensal
                montanteAtual += aporteAtual

                // Outros investimentos
                // Aporte Mensal
                montanteAtualOutros += montanteAtualOutros * rentabilidadeMensalOutros
                montanteAtualOutros += aporteAtual

                // Aumento do aporte
                if (i % 12 == 0 && i < tempoInvestindo) {
                    aporteAtual += aporteAtual * inflacao

                    // Para analisar projetos
                    tempoInvestindoProjetos += 1
                    projetos.map(projeto => {
                        if (projeto.TempoParaRealizarAnos === tempoInvestindoProjetos) {
                            montanteAtual -= projeto.Valor
                            montanteAtualOutros -= projeto.Valor
                            thereIsProjeto = true
                        }
                    })
                }

                if (i % 3 == 0 || i == 1 || i == tempoInvestindo || thereIsProjeto) {
                    resultadosIndependencia.arrayFinal.push([new Date(date.getFullYear(), date.getMonth()), Number(montanteAtual), Number(montanteAtualOutros)])
                    thereIsProjeto = false
                }
                // Adicionando mais um mês para a próxima análise.
                date.setMonth(date.getMonth() + 1)
            }
            if ((montanteAtual - montanteFinal) > 100) {
                if (montanteAtual / montanteFinal > 1.5) {
                    aporteMaximoPossivel -= aporteMaximoPossivel * 0.5
                } else if (montanteAtual / montanteFinal > 1.2) {
                    aporteMaximoPossivel -= aporteMaximoPossivel * 0.05
                } else if (montanteAtual / montanteFinal > 1.05) {
                    aporteMaximoPossivel -= aporteMaximoPossivel * 0.005
                } else {
                    aporteMaximoPossivel -= aporteMaximoPossivel * 0.002
                }

                // Resetando valores e atribuindo novo aporte
                aporteAtual = aporteMaximoPossivel
                montanteAtual = aporteInicial
                montanteAtualOutros = aporteInicial
                resultadosIndependencia.arrayFinal = []
                tempoInvestindoProjetos = 0
                loopAporte()
            } else {
                resultadosIndependencia.aporteInicial = independenciaFinanceiraValues.AporteInicial
                resultadosIndependencia.rentabilidadeAnual = independenciaFinanceiraValues.RentabilidadeAnual
                resultadosIndependencia.contribuicaoMensal = aporteAtual / fatorInflacao

                // Reserva OPT 2
                // if (independenciaFinanceiraValues.CalculoTipo === "OPT2" &&
                //     montanteAtual < (independenciaFinanceiraValues.ValorParaCalculo * 1.1)) {
                //     montanteAtual = independenciaFinanceiraValues.ValorParaCalculo
                // }
                resultadosIndependencia.reservaAcumulada = montanteAtual
                resultadosIndependencia.reservaAcumuladaHoje = montanteAtual / fatorInflacao
                resultadosIndependencia.reservaAcumuladaHojeOutros = montanteAtualOutros / fatorInflacao

                // Renda OPT 3
                let rendaMensal = montanteAtual * rentabilidadeMensalIndependencia
                if (independenciaFinanceiraValues.CalculoTipo === "OPT3" &&
                    rendaMensal < (independenciaFinanceiraValues.ValorParaCalculo * 1.1)) {
                    rendaMensal = independenciaFinanceiraValues.ValorParaCalculo
                }
                resultadosIndependencia.rendaMensal = rendaMensal
                resultadosIndependencia.rendaMensalHoje = rendaMensal / fatorInflacao
            }
        }

        loopAporte()

        if (independenciaFinanceiraValues.AposentadoriaTipo === "TEMPORARIA") {
            // Setting Renda Mensal TEMPORARIA
            var montanteAcumulado = resultadosIndependencia.reservaAcumulada
            var prazo = independenciaFinanceiraValues.AposentadoriaPrazo * 12


            let montanteAcumuladoLoop = montanteAcumulado
            var valorParaSacar = montanteAcumulado / prazo

            let loopIndependenciaTemporaria = () => {
                for (let i = 0; i < prazo; i++) {
                    montanteAcumuladoLoop += montanteAcumuladoLoop * rentabilidadeMensalIndependencia
                    montanteAcumuladoLoop -= valorParaSacar
                }
                if (montanteAcumuladoLoop > 50) {
                    if (montanteAcumuladoLoop > 50000) {
                        valorParaSacar += valorParaSacar * 0.005
                    } else if (montanteAcumuladoLoop > 5000) {
                        valorParaSacar += valorParaSacar * 0.0005
                    } else {
                        valorParaSacar += valorParaSacar * 0.0000005
                    }

                    // Resetting value
                    montanteAcumuladoLoop = montanteAcumulado
                    // Call again
                    loopIndependenciaTemporaria()
                }

                // return montanteNoMes
            }

            loopIndependenciaTemporaria()

            resultadosIndependencia.rendaMensalTemp = valorParaSacar
            resultadosIndependencia.rendaMensalHojeTemp = valorParaSacar / fatorInflacao
        }

        // Setting Values
        return resultadosIndependencia
    }

    const [completedPdfText, setCompletedPdfText] = useState("0%")
    const [pdf, setPdf] = useState(new jsPDF({ compress: true }))
    const [completedStepsPDF, setCompletedStepsPDF] = useState(
        { step1: false, step2: false, step3: false, step4: false, step5: false, step6: false }
    )

    // If no load
    // useEffect(() => {
    //     setTimeout(() => {
    //         if (google) {
    //             window.location.reload(false);
    //         }
    //     }, 5000)
    // }, [])

    useEffect(() => {
        var pageHeight = pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
        var pageWidth = pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();

        function addInfosStep1() {
            let momentoDeVida = dadosForGenPDF[0].MomentoDeVida
            let momentoDeVidaImg1 = new Image()
            let momentoDeVidaImg2 = new Image()
            let momentoDeVidaImg3 = new Image()
            let momentoDeVidaSelected = new Image()
            momentoDeVidaSelected.src = faseSelected
            let positionSelected = { x: 19, y: 86 }
            if (momentoDeVida === "acumulo") {
                momentoDeVidaImg1.src = fase1Selected
                momentoDeVidaImg2.src = fase2
                momentoDeVidaImg3.src = fase3
            } else if (momentoDeVida === "rentabilizar") {
                positionSelected.x += 55
                positionSelected.y -= 3
                momentoDeVidaImg1.src = fase1
                momentoDeVidaImg2.src = fase2Selected
                momentoDeVidaImg3.src = fase3
            } else if (momentoDeVida === "usufruir") {
                positionSelected.x += 110
                positionSelected.y -= 12
                momentoDeVidaImg1.src = fase1
                momentoDeVidaImg2.src = fase2
                momentoDeVidaImg3.src = fase3Selected
            } else {
                momentoDeVidaImg1.src = fase1Selected
                momentoDeVidaImg2.src = fase2
                momentoDeVidaImg3.src = fase3
            }

            pdf.addImage(momentoDeVidaSelected, 'PNG', positionSelected.x, positionSelected.y, 63, 68)
            pdf.addImage(momentoDeVidaImg1, 'PNG', 29, 104, 42, 42)
            pdf.addImage(momentoDeVidaImg2, 'PNG', 84, 100, 42, 42)
            pdf.addImage(momentoDeVidaImg3, 'PNG', 139, 91, 42, 42)

            let nivelDeRiscoTolerado = dadosForGenPDF[0].PerfilInvestidor
            let textNivelDeRisco = ""
            let positionTextNivelDeRisco = { x: 38, y: 167.5 }
            switch (nivelDeRiscoTolerado) {
                case "1": {
                    textNivelDeRisco = "Você tolera um baixo nível de risco."
                    break;
                }
                case "2": {
                    positionTextNivelDeRisco.y -= 1.7
                    positionTextNivelDeRisco.x += 2
                    textNivelDeRisco = "Você tolera um nível de risco moderado."
                    break;
                }
                case "3": {
                    textNivelDeRisco = "Você tolera um alto nível de risco."
                    break;
                }
            }
            pdf.setTextColor(255, 255, 255);
            pdf.setFont('OpenSans', 'bold')
            pdf.setFontSize(8);
            pdf.text(positionTextNivelDeRisco.x, positionTextNivelDeRisco.y, textNivelDeRisco, { maxWidth: '27' });

            setPdf({ ...pdf })
        }

        if (completedStepsPDF.step1) {
            setCompletedPdfText("16%")

            pdf.addPage();

            let page1 = new Image()
            page1.src = newPage1
            pdf.addImage(page1, 'PNG', 0, 0, pageWidth, pageHeight)

            addInfosStep1()
            addBaseboard()

            setPdf({ ...pdf })
            completedStepsPDF.step2 = true
            setCompletedStepsPDF({ ...completedStepsPDF })
        }
    }, [completedStepsPDF.step1])

    useEffect(() => {
        var pageHeight = pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
        var pageWidth = pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();

        let independenciaFinanceiraValues = dadosForGenPDF[7]
        function addInfosStep2Independencia() {
            let dataNascimentoCliente = new Date(dadosForGenPDF[0].DataNascimento)
            let idadeCliente = getAge(dataNascimentoCliente)
            let anosInvestindo = independenciaFinanceiraValues.AposentadoriaIdade - idadeCliente

            pdf.setTextColor(110, 107, 207);
            pdf.setFont('OpenSans', 'bold')
            pdf.setFontSize(10);
            pdf.text(36, 181, independenciaFinanceiraValues.AposentadoriaIdade.toString() + " anos", { align: 'center' });
            pdf.text(81.7, 181, anosInvestindo.toString() + " anos", { align: 'center' });
            pdf.text(127.5, 181, independenciaFinanceiraValues.AporteInicial.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), { align: 'center' });

            pdf.setFont('OpenSans', 'normal')
            pdf.setFontSize(9);
            pdf.text(127.5, 266, independenciaFinanceiraValues.RentabilidadeAnual.toString() + "%", { align: 'center' });
            pdf.text(173.3, 266, independenciaFinanceiraValues.InflacaoAnual.toString() + "%", { align: 'center' });
        }

        if (completedStepsPDF.step2) {
            setCompletedPdfText("32%")
            pdf.addPage();

            if (independenciaFinanceiraValues.AtingiuIndependenciaFinanceira) {
                // Com independencia
                let page2 = new Image()
                page2.src = newPage2
                pdf.addImage(page2, 'PNG', 0, 0, pageWidth, pageHeight)
                addInfosStep2Independencia()
            } else {
                // Sem independencia
                let page2Resum = new Image()
                page2Resum.src = newPage2Resum
                pdf.addImage(page2Resum, 'PNG', 0, 0, pageWidth, pageHeight)
            }

            setPdf({ ...pdf })
            completedStepsPDF.step3 = true
            setCompletedStepsPDF({ ...completedStepsPDF })
        }


    }, [completedStepsPDF.step2])

    useEffect(() => {
        var pageHeight = pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
        var pageWidth = pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
        function addInfosStep3Independencia() {
            let independenciaFinanceiraValues = dadosForGenPDF[7]

            let dataNascimentoCliente = new Date(dadosForGenPDF[0].DataNascimento)
            let idadeCliente = getAge(dataNascimentoCliente)
            let anosInvestindo = independenciaFinanceiraValues.AposentadoriaIdade - idadeCliente

            // Creating Logic for Switch Calculate Function
            // if (independenciaFinanceiraValues.)
            let resultado
            if (independenciaFinanceiraValues.CalculoTipo === "OPT1") {
                resultado = calcularMontanteAcumulado(independenciaFinanceiraValues, anosInvestindo)
            } else if (independenciaFinanceiraValues.CalculoTipo === "OPT2" || independenciaFinanceiraValues.CalculoTipo === "OPT3") {
                resultado = calcularContribuicaoMensal(independenciaFinanceiraValues, anosInvestindo)
            }

            pdf.addImage(drawIndependenciaFinanceira(500, 300, resultado.arrayFinal).getImageURI(), 12, 202, 110, 60)


            pdf.setTextColor(110, 107, 207);
            pdf.setFont('OpenSans', 'bold')
            pdf.setFontSize(10);

            pdf.text(173, 181, resultado.contribuicaoMensal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), { align: 'center' });

            pdf.setFontSize(7);
            pdf.text(101, 206.5, resultado.reservaAcumulada.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), { align: 'center' });

            pdf.setFontSize(10);
            pdf.text(126.5, 244.5, resultado.reservaAcumuladaHoje.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), { align: 'center' });
            pdf.text(173, 244.5, resultado.rendaMensalHoje.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), { align: 'center' });

            pdf.setFontSize(12);
            pdf.text(153, 220, resultado.rendaMensal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), { align: 'center' });

        }

        if (completedStepsPDF.step3) {
            setCompletedPdfText("50%")

            if (dadosForGenPDF[7].AtingiuIndependenciaFinanceira) {
                addInfosStep3Independencia()
                addBaseboard()
            }

            setPdf({ ...pdf })
            completedStepsPDF.step4 = true
            setCompletedStepsPDF({ ...completedStepsPDF })


            var elem = document.getElementById("independenciaFinanceira");
            elem.parentNode.removeChild(elem);
        }
    }, [completedStepsPDF.step3])

    useEffect(() => {
        var pageHeight = pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
        var pageWidth = pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
        function addInfosStep4WithIndependencia() {
            let ativosFinanceirosCliente = dadosForGenPDF[5]

            ativosFinanceirosCliente.sort((a, b) => {
                return b.Valor - a.Valor
            })

            let smallBorder = new Image()
            smallBorder.src = smallBorderImg

            let inicialPosition = { x: 18, y: 125 }
            ativosFinanceirosCliente.map((value, index) => {
                if (index > 4) {
                    pdf.setTextColor(0, 0, 0);
                    pdf.setFont('OpenSans', 'normal')
                    pdf.setFontSize(10);
                    pdf.text(inicialPosition.x + 28, inicialPosition.y + 3.5, "...");
                    return;
                }

                pdf.addImage(smallBorder, 'PNG', inicialPosition.x, inicialPosition.y, 60, 11)

                pdf.setFontSize(7);

                // String name to show
                let nameToShow = value.Ativo
                if (nameToShow.length > 16) nameToShow = nameToShow.substring(0, 18) + "...";
                pdf.text(inicialPosition.x + 4, inicialPosition.y + 6.5, nameToShow);

                // Price to show
                pdf.setTextColor(110, 107, 207);
                pdf.setFont('OpenSans', 'bold')
                pdf.text(inicialPosition.x + 35, inicialPosition.y + 6.5, value.Valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));

                inicialPosition.y += 8
            })

            /**
             * Ativos Não Liquidáveis
             */

            let ativosNaoLiquidaveisCliente = dadosForGenPDF[6]

            ativosNaoLiquidaveisCliente.sort((a, b) => {
                return b.Valor - a.Valor
            })

            let bigBorder = new Image()
            bigBorder.src = bigBorderImg

            let inicialPositionNaoLiquidaveis = { x: 83, y: 125 }
            ativosNaoLiquidaveisCliente.map((value, index) => {
                if (index > 4) {
                    pdf.setTextColor(0, 0, 0);
                    pdf.setFont('OpenSans', 'normal')
                    pdf.setFontSize(10);
                    pdf.text(inicialPositionNaoLiquidaveis.x + 56, inicialPositionNaoLiquidaveis.y + 3.5, "...");
                    return;
                }
                pdf.addImage(bigBorder, 'PNG', inicialPositionNaoLiquidaveis.x, inicialPositionNaoLiquidaveis.y, 110, 11)

                pdf.setFontSize(7);

                // String name to show
                let nameToShow = value.Ativo
                if (nameToShow.length > 22) nameToShow = nameToShow.substring(0, 22) + "...";
                pdf.text(inicialPositionNaoLiquidaveis.x + 6, inicialPositionNaoLiquidaveis.y + 6.5, nameToShow);

                // Comentario to show
                let comentarioToShow = value.Comentario
                if (comentarioToShow.length > 28) comentarioToShow = comentarioToShow.substring(0, 28) + "...";
                pdf.text(inicialPositionNaoLiquidaveis.x + 65, inicialPositionNaoLiquidaveis.y + 6.5, comentarioToShow);


                // Price to show
                pdf.setTextColor(110, 107, 207);
                pdf.setFont('OpenSans', 'bold')
                pdf.text(inicialPositionNaoLiquidaveis.x + 40, inicialPositionNaoLiquidaveis.y + 6.5, value.Valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));

                inicialPositionNaoLiquidaveis.y += 8

            })

            // Drawing Pie Charts 1
            let arrayToAtivosFinanceiros = [["Ativos Financeiros", "Valor"]]
            ativosFinanceirosCliente.map(ativo => {
                if (ativo.Ativo === "Seguro de Vida") return;
                arrayToAtivosFinanceiros.push([ativo.Ativo, ativo.Valor])
            })
            pdf.addImage(drawAtivos(arrayToAtivosFinanceiros).getImageURI(), 21.5, 68, 50, 27);

            // Drawing Pie Charts 2
            let arrayToAtivosNaoLiquidaveis = [["Ativos imobilizados", "Valor"]]
            ativosNaoLiquidaveisCliente.map(ativo => {
                arrayToAtivosNaoLiquidaveis.push([ativo.Ativo, ativo.Valor])
            })
            pdf.addImage(drawAtivos(arrayToAtivosNaoLiquidaveis).getImageURI(), 80, 68, 50, 27);

            // Drawing Pie Charts 3
            let arrayFinalToAtivosX = [["Ativos", "Valor"]]

            let totalAtivosFinanceiros = 0
            ativosFinanceirosCliente.map(ativo => {
                totalAtivosFinanceiros += ativo.Valor
            })
            arrayFinalToAtivosX.push(["Ativos Financeiros", totalAtivosFinanceiros])

            let totalAtivosImobilizados = 0
            ativosNaoLiquidaveisCliente.map(ativo => {
                totalAtivosImobilizados += ativo.Valor
            })
            arrayFinalToAtivosX.push(["Ativos Imobilizados", totalAtivosImobilizados])

            pdf.addImage(drawAtivosLiqXNaoLiq(arrayFinalToAtivosX).getImageURI(), 138, 70, 50, 27);

            /**
             * Capital Desprotegido
             */
            let capitalDescoberto = ResultadoCapitalDescoberto()
            let totalCapitalDescobertoSemSeguro = capitalDescoberto

            // Calculando Porcentagem Capital Desprotegido
            let porcentagemCapitalDesprotegido = 0

            let seguroDeVida = 0
            dadosForGenPDF[5].map((value) => {
                if (value.Ativo === "Seguro de Vida") seguroDeVida = value.Valor
            })
            let totalCapitalDescobertoComSeguro = capitalDescoberto
            if (seguroDeVida <= totalCapitalDescobertoComSeguro) {
                totalCapitalDescobertoComSeguro -= seguroDeVida
            } else {
                capitalDescoberto = 0
            }
            if (capitalDescoberto > 0) {
                porcentagemCapitalDesprotegido = ((totalCapitalDescobertoComSeguro / totalCapitalDescobertoSemSeguro) * 100).toFixed(0)
            }
            if (porcentagemCapitalDesprotegido > 100) {
                porcentagemCapitalDesprotegido = 100
            }
            pdf.setFontSize(10);
            pdf.setFont('OpenSans', 'bold')
            pdf.setTextColor(125, 58, 192);
            pdf.text(148, 195, porcentagemCapitalDesprotegido.toString() + "%", { maxWidth: '50', align: 'center' });

            if (seguroDeVida > 0) {
                pdf.setTextColor(255, 255, 255);
                pdf.text(174, 192, totalCapitalDescobertoComSeguro.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), { align: 'center' })

                pdf.setFontSize(6);
                let textToSeguro = "O seguro está cobrindo:"
                let textToSeguroValor = ""
                if (seguroDeVida > totalCapitalDescobertoSemSeguro) {
                    textToSeguroValor = totalCapitalDescobertoSemSeguro.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                } else {
                    textToSeguroValor = seguroDeVida.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                }
                pdf.text(174, 196, textToSeguro, { align: 'center' })
                pdf.text(174, 198.5, textToSeguroValor, { align: 'center' })
            } else {
                pdf.setTextColor(255, 255, 255);
                pdf.setFontSize(10);
                pdf.text(174, 194.5, capitalDescoberto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), { align: 'center' })
            }

            pdf.addImage(drawCapitalDesprotegido().getImageURI(), 135.7, 180.7, 25, 25)

            /**
             * Dados Financeiros
             */

            let totalAtivosLiquidaveis = 0
            ativosFinanceirosCliente.map(value => {
                if (value.Ativo === "Seguro de Vida") return;
                totalAtivosLiquidaveis += value.Valor
            })

            let totalAtivosNaoLiquidaveis = 0
            ativosNaoLiquidaveisCliente.map(value => {
                totalAtivosNaoLiquidaveis += value.Valor
            })

            pdf.setFontSize(9);
            pdf.setTextColor(110, 107, 207);
            pdf.setFont('OpenSans', 'bold')
            pdf.text(38, 199, totalAtivosLiquidaveis.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), { align: 'center' });
            pdf.text(80.5, 199, totalAtivosNaoLiquidaveis.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), { align: 'center' });

            /**
             * Receitas and Despesas
             */

            let receitas = dadosForGenPDF[3]
            let despesas = dadosForGenPDF[4]

            pdf.setFontSize(8);
            pdf.setFont('OpenSans', 'normal')
            if (receitas.RendaBrutaMensal != null) {
                pdf.text(73, 236, receitas.RendaBrutaMensal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
            } else {
                pdf.text(73, 236, "R$0,00");
            }

            if (receitas.SaldoAcumuladoFGTS != null) {
                pdf.text(73, 244, receitas.SaldoAcumuladoFGTS.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
            } else {
                pdf.text(73, 244, "R$0,00");
            }

            if (receitas.OutrasRendasMensais != null && receitas.OutrasRendasMensais >= 0) {
                pdf.text(73, 251.5, receitas.OutrasRendasMensais.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
            }

            pdf.text(168, 236, despesas.DespesaMensal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
            pdf.text(168, 244, despesas.ContribuicaoDespesaPorcentagem + "%");
            pdf.text(168, 251.5, despesas.ManutencaoPadraoDeVida + " ano(s)");
            pdf.text(168, 259, despesas.RestabelecimentoVidaMeses + " meses");

        }

        function addInfosStep4WithoutIndependencia() {
            let ativosFinanceirosCliente = dadosForGenPDF[5]

            ativosFinanceirosCliente.sort((a, b) => {
                return b.Valor - a.Valor
            })

            let smallBorder = new Image()
            smallBorder.src = smallBorderImg

            let inicialPosition = { x: 18, y: 135 }
            ativosFinanceirosCliente.map((value, index) => {
                if (index >= 4) {
                    pdf.setTextColor(0, 0, 0);
                    pdf.setFont('OpenSans', 'normal')
                    pdf.setFontSize(10);
                    pdf.text(inicialPosition.x + 28, inicialPosition.y + 3, "...");
                    return;
                }

                pdf.addImage(smallBorder, 'PNG', inicialPosition.x, inicialPosition.y, 60, 11)

                pdf.setFontSize(7);

                // String name to show
                pdf.setTextColor(110, 107, 207);
                let nameToShow = value.Ativo
                if (nameToShow.length > 16) nameToShow = nameToShow.substring(0, 18) + "...";
                pdf.text(inicialPosition.x + 4, inicialPosition.y + 6.5, nameToShow);

                // Price to show
                pdf.setFont('OpenSans', 'bold')
                pdf.text(inicialPosition.x + 35, inicialPosition.y + 6.5, value.Valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));

                inicialPosition.y += 8
            })

            /**
             * Ativos Não Liquidáveis
             */

            let ativosNaoLiquidaveisCliente = dadosForGenPDF[6]

            ativosNaoLiquidaveisCliente.sort((a, b) => {
                return b.Valor - a.Valor
            })

            let bigBorder = new Image()
            bigBorder.src = bigBorderImg

            let inicialPositionNaoLiquidaveis = { x: 83, y: 135 }
            ativosNaoLiquidaveisCliente.map((value, index) => {
                if (index >= 4) {
                    pdf.setTextColor(0, 0, 0);
                    pdf.setFont('OpenSans', 'normal')
                    pdf.setFontSize(10);
                    pdf.text(inicialPositionNaoLiquidaveis.x + 56, inicialPositionNaoLiquidaveis.y + 3, "...");
                    return;
                }
                pdf.addImage(bigBorder, 'PNG', inicialPositionNaoLiquidaveis.x, inicialPositionNaoLiquidaveis.y, 110, 11)

                pdf.setFontSize(7);

                // String name to show
                let nameToShow = value.Ativo
                if (nameToShow.length > 22) nameToShow = nameToShow.substring(0, 22) + "...";
                pdf.text(inicialPositionNaoLiquidaveis.x + 6, inicialPositionNaoLiquidaveis.y + 6.5, nameToShow);

                // Comentario to show
                let comentarioToShow = value.Comentario
                if (comentarioToShow.length > 28) comentarioToShow = comentarioToShow.substring(0, 28) + "...";
                pdf.text(inicialPositionNaoLiquidaveis.x + 65, inicialPositionNaoLiquidaveis.y + 6.5, comentarioToShow);


                // Price to show
                pdf.setTextColor(110, 107, 207);
                pdf.setFont('OpenSans', 'bold')
                pdf.text(inicialPositionNaoLiquidaveis.x + 40, inicialPositionNaoLiquidaveis.y + 6.5, value.Valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));

                inicialPositionNaoLiquidaveis.y += 8

            })

            // Drawing Pie Charts 1
            let arrayToAtivosFinanceiros = [["Ativos Financeiros", "Valor"]]
            ativosFinanceirosCliente.map(ativo => {
                if (ativo.Ativo === "Seguro de Vida") return;
                arrayToAtivosFinanceiros.push([ativo.Ativo, ativo.Valor])
            })
            pdf.addImage(drawAtivos(arrayToAtivosFinanceiros).getImageURI(), 21.5, 93, 50, 27);

            // Drawing Pie Charts 2
            let arrayToAtivosNaoLiquidaveis = [["Ativos imobilizados", "Valor"]]
            ativosNaoLiquidaveisCliente.map(ativo => {
                arrayToAtivosNaoLiquidaveis.push([ativo.Ativo, ativo.Valor])
            })
            pdf.addImage(drawAtivos(arrayToAtivosNaoLiquidaveis).getImageURI(), 80, 93, 50, 27);

            // Drawing Pie Charts 3
            let arrayFinalToAtivosX = [["Ativos", "Valor"]]

            let totalAtivosFinanceiros = 0
            ativosFinanceirosCliente.map(ativo => {
                totalAtivosFinanceiros += ativo.Valor
            })
            arrayFinalToAtivosX.push(["Ativos Financeiros", totalAtivosFinanceiros])

            let totalAtivosImobilizados = 0
            ativosNaoLiquidaveisCliente.map(ativo => {
                totalAtivosImobilizados += ativo.Valor
            })
            arrayFinalToAtivosX.push(["Ativos Imobilizados", totalAtivosImobilizados])

            pdf.addImage(drawAtivosLiqXNaoLiq(arrayFinalToAtivosX).getImageURI(), 138, 93, 50, 27);

            /**
             * Capital Desprotegido
             */
            let capitalDescoberto = ResultadoCapitalDescoberto()
            let totalCapitalDescobertoSemSeguro = capitalDescoberto

            // Calculando Porcentagem Capital Desprotegido
            let porcentagemCapitalDesprotegido = 0

            let seguroDeVida = 0
            dadosForGenPDF[5].map((value) => {
                if (value.Ativo === "Seguro de Vida") seguroDeVida = value.Valor
            })
            let totalCapitalDescobertoComSeguro = capitalDescoberto
            if (seguroDeVida <= totalCapitalDescobertoComSeguro) {
                totalCapitalDescobertoComSeguro -= seguroDeVida
            } else {
                capitalDescoberto = 0
            }
            if (capitalDescoberto > 0) {
                porcentagemCapitalDesprotegido = ((totalCapitalDescobertoComSeguro / totalCapitalDescobertoSemSeguro) * 100).toFixed(0)
            }
            if (porcentagemCapitalDesprotegido > 100) {
                porcentagemCapitalDesprotegido = 100
            }
            pdf.setFontSize(10);
            pdf.setFont('OpenSans', 'bold')
            pdf.setTextColor(125, 58, 192);
            pdf.text(148.7, 204.5, porcentagemCapitalDesprotegido.toString() + "%", { maxWidth: '50', align: 'center' });

            if (seguroDeVida > 0) {
                pdf.setTextColor(255, 255, 255);
                pdf.text(174, 202, totalCapitalDescobertoComSeguro.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), { align: 'center' })

                pdf.setFontSize(6);
                let textToSeguro = "O seguro está cobrindo:"
                let textToSeguroValor = ""
                if (seguroDeVida > totalCapitalDescobertoSemSeguro) {
                    textToSeguroValor = totalCapitalDescobertoSemSeguro.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                } else {
                    textToSeguroValor = seguroDeVida.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                }
                pdf.text(174, 206.3, textToSeguro, { align: 'center' })
                pdf.text(174, 208.9, textToSeguroValor, { align: 'center' })
            } else {
                pdf.setTextColor(255, 255, 255);
                pdf.setFontSize(10);
                pdf.text(174, 204, capitalDescoberto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), { align: 'center' })
            }

            pdf.addImage(drawCapitalDesprotegido().getImageURI(), 136.2, 190.5, 25, 25)

            /**
             * Dados Financeiros
             */

            let totalAtivosLiquidaveis = 0
            ativosFinanceirosCliente.map(value => {
                if (value.Ativo === "Seguro de Vida") return;
                totalAtivosLiquidaveis += value.Valor
            })

            let totalAtivosNaoLiquidaveis = 0
            ativosNaoLiquidaveisCliente.map(value => {
                totalAtivosNaoLiquidaveis += value.Valor
            })

            pdf.setFontSize(9);
            pdf.setTextColor(110, 107, 207);
            pdf.setFont('OpenSans', 'bold')
            pdf.text(39, 209, totalAtivosLiquidaveis.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), { align: 'center' });
            pdf.text(80.5, 209, totalAtivosNaoLiquidaveis.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), { align: 'center' });

            /**
             * Receitas and Despesas
             */

            let receitas = dadosForGenPDF[3]
            let despesas = dadosForGenPDF[4]

            pdf.setFontSize(8);
            pdf.setFont('OpenSans', 'normal')

            let startPosition = { x: 73, y: 241.2 }
            pdf.text(73, startPosition.y, receitas.RendaBrutaMensal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
            pdf.text(73, startPosition.y + 7.5, receitas.SaldoAcumuladoFGTS.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
            pdf.text(73, startPosition.y + 15, receitas.OutrasRendasMensais.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));

            pdf.text(168, startPosition.y, despesas.DespesaMensal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
            pdf.text(168, startPosition.y + 7.5, despesas.ContribuicaoDespesaPorcentagem + "%");
            pdf.text(168, startPosition.y + 15.2, despesas.ManutencaoPadraoDeVida + " ano(s)");
            pdf.text(168, startPosition.y + 22.9, despesas.RestabelecimentoVidaMeses + " meses");

            // pdf.text(73, 236, receitas.RendaBrutaMensal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
            // pdf.text(73, 244, receitas.SaldoAcumuladoFGTS.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
            // pdf.text(73, 251.5, receitas.OutrasRendasMensais.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));

            // pdf.text(168, 236, despesas.DespesaMensal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
            // pdf.text(168, 244, despesas.ContribuicaoDespesaPorcentagem + "%");
            // pdf.text(168, 251.5, despesas.ManutencaoPadraoDeVida + " ano(s)");
            // pdf.text(168, 259, despesas.RestabelecimentoVidaMeses + " meses");

        }

        if (completedStepsPDF.step3) {
            setCompletedPdfText("67%")

            if (dadosForGenPDF[7].AtingiuIndependenciaFinanceira) {
                pdf.addPage();

                let page3 = new Image()
                page3.src = newPage3
                pdf.addImage(page3, 'PNG', 0, 0, pageWidth, pageHeight)

                addInfosStep4WithIndependencia()
            } else {
                addInfosStep4WithoutIndependencia()
            }

            addBaseboard()

            setPdf({ ...pdf })
            completedStepsPDF.step5 = true
            setCompletedStepsPDF({ ...completedStepsPDF })

            var elem = document.getElementById("pizzaChart");
            elem.parentNode.removeChild(elem);

        }
    }, [completedStepsPDF.step4])

    useEffect(() => {
        var pageHeight = pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
        var pageWidth = pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
        function addInfosStep5() {
            let dependentes = dadosForGenPDF[1]
            let custos = dadosForGenPDF[8]

            let smallBorder = new Image()
            smallBorder.src = smallBorderImg

            pdf.setFontSize(8);
            pdf.setTextColor(0, 0, 0);
            pdf.setFont('OpenSans', 'normal')

            let positionDependentes = { x1: pageWidth * 0.12, x2: pageWidth * 0.53 }
            if (dadosForGenPDF[0].PossuiDependentes && dependentes.length > 0) {
                dependentes.map((dependente, index) => {
                    if (index > 1) return;

                    pdf.setFontSize(10);
                    pdf.setFont('OpenSans', 'bold')

                    let positionX = 0

                    if (index == 0) {
                        positionX = positionDependentes.x1
                    } else {
                        positionX = positionDependentes.x2
                    }
                    pdf.text(positionX, 56, dependente.Parentesco);

                    pdf.setFontSize(7.5);
                    pdf.setFont('OpenSans', 'normal')

                    let startPositionCustoY = 59
                    custos.map((custo) => {
                        if (custo.ClientesDependenteId === dependente.Id) {
                            pdf.addImage(smallBorder, 'PNG', positionX - 6, startPositionCustoY, 85, 11)
                            startPositionCustoY += 9

                            let descricaoToShow = custo.Descricao
                            if (descricaoToShow.length > 25) descricaoToShow = descricaoToShow.substring(0, 21) + "...";

                            pdf.text(positionX - 2, startPositionCustoY - 3, descricaoToShow);
                            pdf.text(positionX + 34, startPositionCustoY - 3, custo.GastoMensal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
                            pdf.text(positionX + 60, startPositionCustoY - 3, custo.Duracao.toString() + " meses");
                        }
                    })
                })
            }


            // Projetos e Objetivos de Vida
            let projetosDeVida = dadosForGenPDF[2]

            let startPositions = { x1: pageWidth * 0.11, x2: pageWidth * 0.53 }
            let startPositionCustoY = 240

            if (projetosDeVida.length > 0) {
                projetosDeVida.map((projeto, index) => {
                    if (index > 7) return;

                    let positionX = 0

                    if (index % 2 === 0) {
                        positionX = startPositions.x1
                    } else {
                        positionX = startPositions.x2
                    }

                    pdf.addImage(smallBorder, 'PNG', positionX - 6, startPositionCustoY, 85, 11)

                    let descricaoToShow = projeto.Descricao
                    if (descricaoToShow.length > 22) descricaoToShow = descricaoToShow.substring(0, 22) + "...";

                    pdf.text(positionX - 2, startPositionCustoY + 6, descricaoToShow);
                    pdf.text(positionX + 34, startPositionCustoY + 6, projeto.Valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
                    pdf.text(positionX + 60, startPositionCustoY + 6, projeto.TempoParaRealizarAnos + " ano(s)");

                    if (index % 2 === 1) {
                        startPositionCustoY += 9
                    }
                })
            }


            let independenciaFinanceiraValues = dadosForGenPDF[7]

            let anosInvestindo = 0
            if (independenciaFinanceiraValues.AtingiuIndependenciaFinanceira) {
                let dataNascimentoCliente = new Date(dadosForGenPDF[0].DataNascimento)
                let idadeCliente = getAge(dataNascimentoCliente)
                anosInvestindo = independenciaFinanceiraValues.AposentadoriaIdade - idadeCliente
            } else {
                anosInvestindo = independenciaFinanceiraValues.AposentadoriaIdade
            }

            let resultado
            if (independenciaFinanceiraValues.CalculoTipo === "OPT1") {
                resultado = calcularMontanteAcumuladoAnalise(independenciaFinanceiraValues, anosInvestindo)
            } else if (independenciaFinanceiraValues.CalculoTipo === "OPT2" || independenciaFinanceiraValues.CalculoTipo === "OPT3") {
                resultado = calcularContribuicaoMensalAnalise(independenciaFinanceiraValues, anosInvestindo)
            }
            // let resultado = calcularMontanteAcumuladoAnalise(independenciaFinanceiraValues, anosInvestindo)
            pdf.addImage(drawIndependenciaFinanceiraComAnalise(780, 340, resultado.arrayFinal).getImageURI(), 12, 142)
            pdf.setTextColor(110, 107, 207);
            pdf.setFont('OpenSans', 'bold')
            pdf.setFontSize(10);
            pdf.text(123.5, 131, resultado.reservaAcumuladaHoje.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), { align: 'center' });
            pdf.text(170, 131, resultado.reservaAcumuladaHojeOutros.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), { align: 'center' });

        }

        function addInfosStep5JustFamily() {
            let dependentes = dadosForGenPDF[1]
            let custos = dadosForGenPDF[8]

            let smallBorder = new Image()
            smallBorder.src = smallBorderImg

            pdf.setFontSize(8);
            pdf.setTextColor(0, 0, 0);
            pdf.setFont('OpenSans', 'normal')


            let positionDependentes = { x1: pageWidth * 0.12, x2: pageWidth * 0.53 }
            let startPositionCustoY = 59
            let startPositionParentescoY = 56
            if (dadosForGenPDF[0].PossuiDependentes && dependentes.length > 0) {
                dependentes.map((dependente, index) => {
                    if (index > 5) return;

                    pdf.setFontSize(10);
                    pdf.setFont('OpenSans', 'bold')

                    let positionX = 0

                    if (index % 2 == 0) {
                        positionX = positionDependentes.x1
                    } else {
                        positionX = positionDependentes.x2
                    }
                    pdf.text(positionX, startPositionParentescoY, dependente.Parentesco);

                    pdf.setFontSize(7.5);
                    pdf.setFont('OpenSans', 'normal')

                    // Count para mostrar apenas 3 custos
                    let countCustos = 0
                    let addedThreeDots = false
                    custos.map((custo) => {
                        if (countCustos <= 2 && custo.ClientesDependenteId === dependente.Id) {
                            pdf.addImage(smallBorder, 'PNG', positionX - 6, startPositionCustoY, 85, 11)
                            startPositionCustoY += 9

                            let descricaoToShow = custo.Descricao
                            if (descricaoToShow.length > 25) descricaoToShow = descricaoToShow.substring(0, 21) + "...";

                            pdf.text(positionX - 2, startPositionCustoY - 3, descricaoToShow);
                            pdf.text(positionX + 34, startPositionCustoY - 3, custo.GastoMensal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
                            pdf.text(positionX + 60, startPositionCustoY - 3, custo.Duracao.toString() + " meses");

                            countCustos++
                        } else if (!addedThreeDots && custo.ClientesDependenteId === dependente.Id) {
                            startPositionCustoY += 9
                            pdf.text(positionX + 35, startPositionCustoY - 5, '(...)', { align: 'center' });
                            addedThreeDots = true
                        }
                    })
                    if (index % 2 == 0) {
                        // Custos - Zerando posição em Y na MESMA linha (e acrescentando o espaçamento)
                        let fatorMult = 0
                        if (index === 0) fatorMult = 0
                        if (index === 2) fatorMult = 1
                        if (index === 4) fatorMult = 2
                        startPositionCustoY = 59 + (38 * fatorMult)
                    }
                    if (index % 2 == 1) {
                        // Parentesco
                        startPositionParentescoY += 38

                        // Custos - Zerando posição em Y na PRÓXIMA linha (e acrescentando o espaçamento)
                        let fatorMult = 0
                        if (index === 1) fatorMult = 1
                        if (index === 3) fatorMult = 2
                        startPositionCustoY = 59 + (38 * fatorMult)
                    }
                })
            }
        }

        function addInfosStep5JustAnalise() {
            let smallBorder = new Image()
            smallBorder.src = smallBorderImg

            pdf.setFontSize(8);
            pdf.setTextColor(0, 0, 0);
            pdf.setFont('OpenSans', 'normal')

            let projetosDeVida = dadosForGenPDF[2]

            let startPositions = { x1: pageWidth * 0.11, x2: pageWidth * 0.53 }
            let startPositionCustoY = 154

            if (projetosDeVida.length > 0) {
                projetosDeVida.map((projeto, index) => {
                    if (index > 7) return;

                    let positionX = 0

                    if (index % 2 === 0) {
                        positionX = startPositions.x1
                    } else {
                        positionX = startPositions.x2
                    }

                    pdf.addImage(smallBorder, 'PNG', positionX - 6, startPositionCustoY, 85, 11)

                    let descricaoToShow = projeto.Descricao
                    if (descricaoToShow.length > 22) descricaoToShow = descricaoToShow.substring(0, 22) + "...";

                    pdf.text(positionX - 2, startPositionCustoY + 6, descricaoToShow);
                    pdf.text(positionX + 34, startPositionCustoY + 6, projeto.Valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
                    pdf.text(positionX + 60, startPositionCustoY + 6, projeto.TempoParaRealizarAnos + " ano(s)");

                    if (index % 2 === 1) {
                        startPositionCustoY += 9
                    }
                })
            }


            let independenciaFinanceiraValues = dadosForGenPDF[7]

            let anosInvestindo = 0
            if (independenciaFinanceiraValues.AtingiuIndependenciaFinanceira) {
                let dataNascimentoCliente = new Date(dadosForGenPDF[0].DataNascimento)
                let idadeCliente = getAge(dataNascimentoCliente)
                anosInvestindo = independenciaFinanceiraValues.AposentadoriaIdade - idadeCliente
            } else {
                anosInvestindo = independenciaFinanceiraValues.AposentadoriaIdade
            }

            let resultado

            if (!independenciaFinanceiraValues.AtingiuIndependenciaFinanceira) {
                resultado = calcularMontanteAcumuladoAnalise(independenciaFinanceiraValues, anosInvestindo)
            } else if (independenciaFinanceiraValues.CalculoTipo === "OPT1") {
                resultado = calcularMontanteAcumuladoAnalise(independenciaFinanceiraValues, anosInvestindo)
            } else if (independenciaFinanceiraValues.CalculoTipo === "OPT2" || independenciaFinanceiraValues.CalculoTipo === "OPT3") {
                resultado = calcularContribuicaoMensalAnalise(independenciaFinanceiraValues, anosInvestindo)
            }
            console.log(resultado)
            // let resultado = calcularMontanteAcumuladoAnalise(independenciaFinanceiraValues, anosInvestindo)
            pdf.addImage(drawIndependenciaFinanceiraComAnalise(780, 340, resultado.arrayFinal).getImageURI(), 12, 59)
            pdf.setTextColor(110, 107, 207);
            pdf.setFont('OpenSans', 'bold')
            pdf.setFontSize(10);
            pdf.text(123.5, 48, resultado.reservaAcumuladaHoje.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), { align: 'center' });
            pdf.text(170, 48, resultado.reservaAcumuladaHojeOutros.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), { align: 'center' });

        }

        if (completedStepsPDF.step5) {
            setCompletedPdfText("83%")

            if (dadosForGenPDF[0].PossuiDependentes && dadosForGenPDF[2].length > 0) {
                pdf.addPage();

                let page3 = new Image()
                page3.src = newPage4
                pdf.addImage(page3, 'PNG', 0, 0, pageWidth, pageHeight)

                addInfosStep5()
                addBaseboard()
            } else if (dadosForGenPDF[0].PossuiDependentes) {
                pdf.addPage();

                let page3 = new Image()
                page3.src = newPage3ResumFamiliar
                pdf.addImage(page3, 'PNG', 0, 0, pageWidth, pageHeight)

                addInfosStep5JustFamily()
            } else if (dadosForGenPDF[2].length > 0) {
                pdf.addPage();

                let page3 = new Image()
                page3.src = newPage3ResumAnalise
                pdf.addImage(page3, 'PNG', 0, 0, pageWidth, pageHeight)

                addInfosStep5JustAnalise()
                addBaseboard()
            }

            setPdf({ ...pdf })
            completedStepsPDF.step6 = true
            setCompletedStepsPDF({ ...completedStepsPDF })

            var elem = document.getElementById("independenciaFinanceiraAnalise");
            elem.parentNode.removeChild(elem);

        }
    }, [completedStepsPDF.step5])

    useEffect(() => {
        var pageHeight = pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
        var pageWidth = pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
        function addInfosStep6() {
            pdf.addPage();

            let page5 = new Image()
            page5.src = newPage5
            pdf.addImage(page5, 'PNG', 0, 0, pageWidth, pageHeight)

            /**
            * New PAGE (7)
            */

            pdf.addPage();

            let page6 = new Image()
            page6.src = newPage6
            pdf.addImage(page6, 'PNG', 0, 0, pageWidth, pageHeight)

            pdf.setFontSize(10);
            pdf.setTextColor(0, 0, 0);
            pdf.setFont('OpenSans', 'bold')
            pdf.text(106, 89, (userInfos.Nome + " " + userInfos.Sobrenome), { align: 'center' });
            pdf.text(106, 94, userInfos.Email, { align: 'center' });
        }

        if (completedStepsPDF.step6) {
            setCompletedPdfText("100%")
            addInfosStep6()

            setPdf({ ...pdf })
            completedStepsPDF.step2 = true
            setCompletedStepsPDF({ ...completedStepsPDF })

        }
    }, [completedStepsPDF.step6])

    useEffect(() => {
        if (completedStepsPDF.step1 && completedStepsPDF.step2 && completedStepsPDF.step3
            && completedStepsPDF.step4 && completedStepsPDF.step5 && completedStepsPDF.step6) {

            try {
                // let bloburl = doc.output('bloburl')

                document.getElementById('main-iframe').setAttribute('src', pdf.output('bloburl'));

                setTimeout(() => {
                    setFinishedPDF(true)
                    setPdfFile(pdf)
                }, 2000)

                // var pdfFinal = btoa(doc.output())
                // sendToServer(pdfFinal)

                // var elem = document.getElementById("capitalDesprotegido");
                // elem.parentNode.removeChild(elem);
            } catch (error) {
                console.log(error)
            }
        }
    }, [completedStepsPDF])

    const addBaseboard = () => {
        var pageHeight = pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
        var pageWidth = pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
        // Rodape IMG
        var img = new Image()
        img.src = rodape
        pdf.addImage(img, 'PNG', 0, pageHeight - 25, pageWidth, 30)
    }

    const GeneratePDF = () => {
        if (google) {
            try {
                // Name advisor
                infosGeneratePdf.nomeAssessor = userInfos.Nome + ' ' + userInfos.Sobrenome

                // Infos General Client
                infosGeneratePdf.cliente.nome = dadosForGenPDF[0].NomeCompleto
                infosGeneratePdf.cliente.profissao = dadosForGenPDF[0].Profissao
                // Age of client
                var today = new Date()
                var birthDate = new Date(dadosForGenPDF[0].DataNascimento);
                var age = today.getFullYear() - birthDate.getFullYear();
                var m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                }
                infosGeneratePdf.cliente.idade = age
                // Dependentes
                infosGeneratePdf.dependentes = dadosForGenPDF[1]

                // Projetos de Vida
                infosGeneratePdf.projetosDeVida = dadosForGenPDF[2]

                // Receitas
                infosGeneratePdf.aporteMensal = dadosForGenPDF[3].AporteMensal
                infosGeneratePdf.rendaBrutaMensal = dadosForGenPDF[3].RendaBrutaMensal
                infosGeneratePdf.pensao = dadosForGenPDF[3].PensaoINSSOutros
                infosGeneratePdf.saldoFGTS = dadosForGenPDF[3].SaldoAcumuladoFGTS
                infosGeneratePdf.outrasRendas = dadosForGenPDF[3].OutrasRendasMensais
                // Despesas
                infosGeneratePdf.despesaMensal = dadosForGenPDF[4].DespesaMensal
                infosGeneratePdf.contribuicaoDespesas = dadosForGenPDF[4].ContribuicaoDespesaPorcentagem / 100
                infosGeneratePdf.manutencaoPadraoDeVida = dadosForGenPDF[4].ManutencaoPadraoDeVida
                infosGeneratePdf.restabelecimentoDeVida = dadosForGenPDF[4].RestabelecimentoVidaMeses
                // Ativos Financeiros
                infosGeneratePdf.ativosFinanceiros = dadosForGenPDF[5]
                // Ativos Nao Liquidaveis
                infosGeneratePdf.ativosNaoLiquidaveis = dadosForGenPDF[6]
            } catch {
                // return <Redirect to={`/primeira-reuniao/${dadosForGenPDF[0].Id}`} />
                // setIsPossibleGenPDF(false)
                return null
            }

            // if (dadosForGenPDF[0].Status === "LEAD") {
            //     clientesService.UpdateStatus(dadosForGenPDF[0].Id, "NEGOCIO")
            //         .then(e => {

            //         })
            //         .catch(e => {
            //             console.log(e)
            //         })
            // }

            if (dadosForGenPDF[0].PDFGerado === false) {
                // userService.InserirFunilComercial({
                //     "AssessorUsuarioId": dadosForGenPDF[0].AssessorUsuarioId,
                //     "ClienteId": dadosForGenPDF[0].Id,
                //     "Data": new Date(),
                //     "Acao": "PRIMEIRA-REUNIAO"
                // }).then().catch()

                // userService.InserirFunilComercial({
                //     "AssessorUsuarioId": dadosForGenPDF[0].AssessorUsuarioId,
                //     "ClienteId": dadosForGenPDF[0].Id,
                //     "Data": new Date(),
                //     "Acao": "NEGOCIO"
                // }).then().catch()

                // if (dadosForGenPDF[3].PotencialSeguro) {
                //     userService.InserirFunilComercial({
                //         "AssessorUsuarioId": dadosForGenPDF[0].AssessorUsuarioId,
                //         "ClienteId": dadosForGenPDF[0].Id,
                //         "Data": new Date(),
                //         "Acao": "IND-SEGURO"
                //     }).then().catch()
                // }

                let newStatusPDF = { clienteId: dadosForGenPDF[0].Id, pdfGerado: true }
                clientesService.UpdatePDFGerado(newStatusPDF)
                    .then((r) => { })
                    .catch(error => console.log("Error ao atualizar pdf gerado."))
            }

            // var doc = new jsPDF({ compress: true });
            // Add Fonts
            pdf.addFileToVFS("OpenSans-Bold.ttf", addFont1)
            pdf.addFont("OpenSans-Bold.ttf", "OpenSans", "bold");
            pdf.addFileToVFS("OpenSans-Regular.ttf", addFont2)
            pdf.addFont("OpenSans-Regular.ttf", "OpenSans", "normal");

            // Dimensions of Page
            var pageHeight = pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
            var pageWidth = pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();

            // Capa
            let capa = new Image()
            capa.src = capaImg
            pdf.addImage(capa, 'PNG', 0, 0, pageWidth, pageHeight)

            //
            pdf.addImage(capa, 'PNG', 0, 0, pageWidth, pageHeight)
            setPdf({ ...pdf })

            pdf.setFont('OpenSans', 'bold')
            pdf.setTextColor(0, 0, 0);
            pdf.setFontSize(15);
            pdf.text(105, 157, dadosForGenPDF[0].NomeCompleto, { align: 'center' });

            pdf.setFont('OpenSans', 'normal')
            pdf.setFontSize(8);
            let idadeCliente = getAge(new Date(dadosForGenPDF[0].DataNascimento))
            let textForShow = dadosForGenPDF[0].Profissao + "  |  " + idadeCliente.toString() + " anos"
            pdf.text(105, 163, textForShow, { align: 'center' });

            completedStepsPDF.step1 = true
            setCompletedStepsPDF({ ...completedStepsPDF })
        }
    }

    const [pdfFile, setPdfFile] = useState(null)

    const sendToServer = (pdfFinal) => {
        var pdfService = new PDFService()
        var storage = new StorageHelper()
        var formData = new FormData()

        formData.append('file', pdfFinal)
        formData.append('assessorId', storage.GetUsuarioId())
        formData.append('clienteId', userId)

        pdfService.SalvarPDF(formData)
    }

    useEffect(() => {
        if (userId) {
            setUsuarioId(userId)
            Promise.all([
                clientesService.BuscarCliente(userId)
                    .then((e) => {
                        // Formatting Date
                        e.DataNascimento = e.DataNascimento.toString().slice(0, 10)
                        const dataNasc = new Date(e.DataNascimento)
                        dataNasc.setDate(dataNasc.getDate() + 1)
                        e.DataNascimento = dataNasc
                        return e
                    })
                    .catch(e => e),

                clientesService.ListaDependentes(userId)
                    .then((e) => {
                        return e
                    })
                    .catch(e => e),

                clientesService.ListarProjetos(userId)
                    .then((e) => {
                        return e
                    })
                    .catch(e => e),

                clientesService.ListaReceita(userId)
                    .then((e) => {
                        return e
                    })
                    .catch(e => {
                        console.log(e)
                    }),

                clientesService.GetDespesas(userId)
                    .then((e) => {
                        return e
                    }).catch((e) => {
                        console.log(e)
                    }),

                clientesService.ListarAtivos(userId)
                    .then((e) => {
                        return e
                    }).catch(e => e),

                clientesService.ListarBens(userId)
                    .then((e) => {
                        return e
                    }).catch(e => e),
                clientesService.GetIndependenciaFinanceira(userId)
                    .then((e) => {
                        return e
                    }).catch(e => e),
                // clientesService.GetTempoInvestimento(userId)
                //     .then((e) => {
                //         return e
                //     })
                //     .catch(e => {
                //         console.log(e)
                //     }),
            ]).then((values) => {
                let valuesCliente = values

                // Dependentes
                if (values[0].PossuiDependentes && values[1].length > 0) {
                    let dependentes = values[1]

                    // Array with promises for promise all
                    let dependentesArrayGet = []
                    dependentes.map((dependente, index) => {
                        dependentesArrayGet.push(
                            clientesService.ListarCustosDependente(dependente.Id)
                                .then((custos) => {
                                    return custos
                                })
                                .catch(() => {
                                    return ("Error")
                                })
                        )
                    })
                    Promise.all(dependentesArrayGet).then((custos) => {
                        let mergedCustos = custos.flat(1)
                        valuesCliente.push(mergedCustos)

                        setDadosForGenPDF(valuesCliente)
                        setStatusRequestCliente(true)
                    })
                } else {
                    setDadosForGenPDF(valuesCliente)
                    setStatusRequestCliente(true)
                }
            })
        } else {
            // setInitialLoadingEnd(true)
        }

    }, [])

    useEffect(() => {
        if (google && statusRequestCliente && statusRequestAdvisor) {
            try {
                GeneratePDF()
            } catch (error) {
                console.log(error)
            }
            setStatusRequestCliente(false)
            setStatusRequestAdvisor(false)
        }
    }, [google, statusRequestCliente, statusRequestAdvisor])

    useEffect(() => {
        const assessorId = storageHelper.GetUsuarioId();

        userService.GetUserInfos(assessorId)
            .then(e => {
                setUserInfos(e)
                setStatusRequestAdvisor(true)
            })
            .catch(e => {
                // console.log(e)
            })
    }, [])

    // if (!isPossibleGenPDF) {
    //     return <Navigate to={`/app/plano-de-voo/${dadosForGenPDF[0].Id}`} />
    // }

    const downloadPDF = () => {
        function addZero(i) {
            if (i < 10) { i = "0" + i }
            return i;
        }

        let dateNow = new Date()
        let h = addZero(dateNow.getHours());
        let m = addZero(dateNow.getMinutes());
        let time = h + "h" + m + "m";
        let namePdf = 'Marco Investimentos - ' + infosGeneratePdf.cliente.nome + ' - Data ' + dateNow.getDate() + '-' + (dateNow.getMonth() + 1) + '-' + dateNow.getFullYear() // + ' ' + time

        pdf.setDocumentProperties({ compress: true })
        setPdf({ ...pdf })
        pdfFile.save(namePdf);
    }

    return (
        <>
            <Container maxWidth="md" sx={{ padding: 3, mb: 3 }}>
                {finishedPDF ? "" :
                    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                        <Typography variant="h5">Carregando resultado...</Typography>
                        <Typography variant="h6">Por favor, aguarde.</Typography>
                        <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                            <CircularProgress size={20} />
                            <Typography variant="h6"> {completedPdfText} concluído</Typography>
                        </Box>
                    </Box>}

                {!finishedPDF ? "" :
                    <Stack textAlign="center" my={2} spacing={1}>
                        <Box>
                            <Button onClick={() => downloadPDF()} variant="contained">
                                Baixar PDF
                            </Button>
                        </Box>
                        <Box>
                            {/* <Button onClick={() => setIsPossibleGenPDF(false)} variant="text"> */}
                            <Button onClick={() => navigate(`/app/plano-de-voo/${dadosForGenPDF[0].Id}`, { replace: true })} variant="text">
                                Deseja modificar as informações?&nbsp; <Box component={"span"} sx={{ textDecoration: "underline" }}>Clique aqui.</Box>
                            </Button>
                        </Box>
                    </Stack>
                }

                <div className="col s12">
                    <iframe id="main-iframe"></iframe>
                </div>
            </Container>

            {<div id="pizzaChart" className={!google ? 'd-none' : ''} />}
            {<div id="independenciaFinanceira" className={!google ? 'd-none ' : ''} />}
            {<div id="independenciaFinanceiraAnalise" className={!google ? 'd-none ' : ''} />}
            {<div id="capitalDesprotegido" className={!google ? 'd-none' : ''} />}
        </>
    )
}

export default ResultadoComponent;