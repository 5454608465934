import { SquadsContext } from "../Context/SquadsConatext"
import React, { useEffect, useState } from "react"
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip';
import { Checkbox, FormControlLabel, Typography, Button } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import SquadsServices from '../../../../../Services/SquadsService'

export const UsersWithoutSquad = () => {
  // Context
  const context: any = React.useContext(SquadsContext)

  // Squads Services
  let squadsServices = new SquadsServices()

  let squads = context.squads
  let assessores = context.assessores
  let usersWithoutSquad = context.usersWithoutSquad

  const [usersToAddSquad, setUsersToAddSquad] = useState<any>([])
  const [squadIdToAdd, setSquadIdToAdd] = useState("")
  function handleUserToAddSquad(user: any, index: number) {
    checkboxUsers[index] = !checkboxUsers[index]
    setCheckboxUsers([...checkboxUsers])

    // Adding the user
    let filteredUsers = usersToAddSquad.filter((value: any) => {
      return value.Id == user.Id
    })
    if (filteredUsers.length == 0) {
      usersToAddSquad.push(user)
    } else {
      let indexToRemove = usersToAddSquad.findIndex((value: any) => {
        return value.Id == user.Id
      })
      usersToAddSquad.splice(indexToRemove, 1)
    }
    setUsersToAddSquad([...usersToAddSquad])
  }

  const [checkboxUsers, setCheckboxUsers] = useState<boolean[]>([])
  useEffect(() => {
    if (usersWithoutSquad.length > 0) {
      usersWithoutSquad.map((value: any, index: number) => {
        checkboxUsers[index] = false
      })
      setCheckboxUsers([...checkboxUsers])
    }
  }, [usersWithoutSquad])

  function handleAddAssessoresToSquad() {
    if (squadIdToAdd == "") {
      context.openAlertMessageFailed("Selecione algum squad para inserir os usuários.")
      return;
    }

    usersToAddSquad.map((value: any) => {
      let userToAdd = {
        SquadId: squadIdToAdd,
        UsuarioId: value.Id,
        SquadAdmin: false
      }

      squadsServices.InserirParticipante(userToAdd)
        .then(() => {
          window.location.reload();
        })
        .catch()
    })


  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">
            Selecione os assessores que você deseja inserir em algum squad...
          </Typography>
        </Grid>
        <Grid container spacing={1} sx={{ maxHeight: 350, overflowY: "auto", mt: 1 }}>
          {
            usersWithoutSquad.length > 0 ?
              usersWithoutSquad.map((value: any, index: number) => {
                return (
                  <>
                    <Grid item xs={12}>
                      <FormControlLabel control={<Checkbox value={checkboxUsers[index]} size="small" />}
                        onChange={() => handleUserToAddSquad(value, index)}
                        value={checkboxUsers[index]}
                        label={<>
                          <Typography variant="h5">
                            {`${value.Nome} ${value.Sobrenome}`}
                          </Typography>
                          <Typography variant="body2">
                            {`${value.Email}`}
                          </Typography>
                        </>}
                      />
                    </Grid>
                  </>
                )
              }) : <></>
          }
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} mt={1} justifyContent="center">
            {
              usersToAddSquad.length > 0 ?
                usersToAddSquad.map((value: any) => {
                  return (
                    <Grid item>
                      <Chip size="small" label={`${value.Nome} ${value.Sobrenome}`} />
                    </Grid>
                  )
                })
                : <></>
            }
          </Grid>
        </Grid>
        {
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Stack direction="row" spacing={1} justifyContent="center">
              {
                squads.length > 0 ?
                  <FormControl sx={{ minWidth: 200 }} size="small" disabled={usersToAddSquad.length > 0 ? false : true}>
                    <InputLabel id="select-squad">Selecione o squad</InputLabel>
                    <Select
                      labelId="select-squad"
                      value={squadIdToAdd}
                      label="Selecione o squad"
                      onChange={(event: any) => setSquadIdToAdd(event.target.value)}
                    >
                      {
                        squads.map((value: any, index: number) => {
                          return <MenuItem value={value.Id}>{value.Nome}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl> : <></>
              }

              <Button
                onClick={() => handleAddAssessoresToSquad()}
                sx={{ minWidth: 200 }} variant="outlined" color="secondary" disabled={usersToAddSquad.length > 0 ? false : true}>
                Salvar
              </Button>
            </Stack>
          </Grid>
        }

      </Grid>
    </>
  )
}