import { Container, Paper } from '@mui/material'
import { TaskGroupsProvider } from './context';
import { DialogTaskGroups } from './Dialog';
import { TaskList } from './Grid';

export const TaskGroups = () => {
  return <>
    <TaskGroupsProvider>
      <Container maxWidth='sm'>
        <Paper elevation={0} sx={{ padding: 3 }}>
          <TaskList />
        </Paper>
      </Container>
      <DialogTaskGroups />
    </TaskGroupsProvider>
  </>
}