import { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

const NotfoundPage = () => {
	const [redirectLogin, setRedirectLogin] = useState(false)

	useEffect(() => {
		setRedirectLogin(false)
	}, [])


	const hash = useLocation().hash
	useEffect(() => {
		switch (hash) {
			case "#criar-conta-sucesso": {
				break;
			}
			case "#recuperar-senha-sucesso": {
				break;
			}
			case "#senha-alterada": {
				break;
			}

		}
	}, [hash])

	if (redirectLogin)
		return <Navigate to="/app" />

	return (
		<>
			<Stack sx={{ width: "100vw", height: "100vh" }} direction="row" spacing={0}>
				<Grid container spacing={0}>
					<Grid item xs={12} lg={6}>
						<Container maxWidth="sm" sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
							<Box sx={{ width: "90%" }}>
								<Link to="/" className="logo-lab"></Link>
								<Typography variant="h3" color="primary" textAlign="center">
									Plataforma do Assessor
								</Typography>
								<Typography variant="body1" fontSize={15} textAlign="center">
									Página não encontrada.
								</Typography>
							</Box>
						</Container>
					</Grid>
					<Grid item lg={6}>
						<Box className="bg-login" sx={{ height: "100%", display: { xs: "none", lg: "block" } }} />
					</Grid>
				</Grid>
			</Stack >
		</>
	)
}

export default NotfoundPage;