import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from 'react';
import { ClientNotes } from './Notes';
import { ClientActivities } from './Activities';
import { RegistrationData } from './RegistrationData/RegistrationData';
import { PatrimonyData } from './PatrimonyData';
import { useClientContext } from '../ClientContext';
import { InsuranceData } from './InsuranceData';
import { OthersNegocios } from './OthersNegocios';

export const MenuViews = () => {
  const { client } = useClientContext();

  const [menuSelected, setMenuSelected] = useState('notes');

  const handleChangeMenu = (event: React.SyntheticEvent, newValue: string) => {
    setMenuSelected(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={menuSelected}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeMenu}>
            <Tab label='Notas' value='notes' />
            <Tab label='Atividades' value='activities' />
            <Tab label='Dados Cadastrais' value='registrationData' />
            <Tab label='Dados Patrimoniais' value='patrimonyData' />
            {client?.StatusSeguros && <Tab label='Informações de Seguros' value='insuranceData' />}
            <Tab label='Outros Negócios' value='othersNegocios' />
          </TabList>
        </Box>
        <TabPanel value='notes'>
          <ClientNotes />
        </TabPanel>
        <TabPanel value='activities'>
          <ClientActivities />
        </TabPanel>
        <TabPanel value='registrationData'>
          <RegistrationData />
        </TabPanel>
        <TabPanel value='patrimonyData'>
          <PatrimonyData />
        </TabPanel>
        {client?.StatusSeguros && <TabPanel value='insuranceData'>
          <InsuranceData />
        </TabPanel>}
        <TabPanel value='othersNegocios'>
          <OthersNegocios />
        </TabPanel>
      </TabContext>
    </Box>
  );
}